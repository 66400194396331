/* tslint:disable */
/* eslint-disable */
import { HttpClient, HttpContext, HttpResponse } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { filter, map } from 'rxjs/operators';

import { BaseService } from '../base-service';
import { ApiConfiguration } from '../api-configuration';
import { StrictHttpResponse } from '../strict-http-response';
import { RequestBuilder } from '../request-builder';

import { AdDashboardDropdownItem } from '../models/ad-dashboard-dropdown-item';
import { AdDashboardQueryReq } from '../models/ad-dashboard-query-req';
import { AdDashboardResultRes } from '../models/ad-dashboard-result-res';
import { AdDashboardResultSummaryRes } from '../models/ad-dashboard-result-summary-res';
import { ExportExcelFileRes } from '../models/export-excel-file-res';
import { MasterCategoryAndSeverityResItem } from '../models/master-category-and-severity-res-item';

@Injectable({ providedIn: 'root' })
export class AuditDashboardService extends BaseService {
  constructor(config: ApiConfiguration, http: HttpClient) {
    super(config, http);
  }

  /** Path part for operation `apiAuditDashboardQueryPost()` */
  static readonly ApiAuditDashboardQueryPostPath = '/api/audit/dashboard/query';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `apiAuditDashboardQueryPost()` instead.
   *
   * This method sends `application/json` and handles request body of type `application/json`.
   */
  apiAuditDashboardQueryPost$Response(
    params?: {
      body?: AdDashboardQueryReq
    },
    context?: HttpContext
  ): Observable<StrictHttpResponse<AdDashboardResultRes>> {
    const rb = new RequestBuilder(this.rootUrl, AuditDashboardService.ApiAuditDashboardQueryPostPath, 'post');
    if (params) {
      rb.body(params.body, 'application/json');
    }

    return this.http.request(
      rb.build({ responseType: 'json', accept: 'application/json', context })
    ).pipe(
      filter((r: any): r is HttpResponse<any> => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<AdDashboardResultRes>;
      })
    );
  }

  /**
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `apiAuditDashboardQueryPost$Response()` instead.
   *
   * This method sends `application/json` and handles request body of type `application/json`.
   */
  apiAuditDashboardQueryPost(
    params?: {
      body?: AdDashboardQueryReq
    },
    context?: HttpContext
  ): Observable<AdDashboardResultRes> {
    return this.apiAuditDashboardQueryPost$Response(params, context).pipe(
      map((r: StrictHttpResponse<AdDashboardResultRes>): AdDashboardResultRes => r.body)
    );
  }

  /** Path part for operation `apiAuditDashboardSummaryPost()` */
  static readonly ApiAuditDashboardSummaryPostPath = '/api/audit/dashboard/summary';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `apiAuditDashboardSummaryPost()` instead.
   *
   * This method sends `application/json` and handles request body of type `application/json`.
   */
  apiAuditDashboardSummaryPost$Response(
    params?: {
      body?: AdDashboardQueryReq
    },
    context?: HttpContext
  ): Observable<StrictHttpResponse<AdDashboardResultSummaryRes>> {
    const rb = new RequestBuilder(this.rootUrl, AuditDashboardService.ApiAuditDashboardSummaryPostPath, 'post');
    if (params) {
      rb.body(params.body, 'application/json');
    }

    return this.http.request(
      rb.build({ responseType: 'json', accept: 'application/json', context })
    ).pipe(
      filter((r: any): r is HttpResponse<any> => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<AdDashboardResultSummaryRes>;
      })
    );
  }

  /**
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `apiAuditDashboardSummaryPost$Response()` instead.
   *
   * This method sends `application/json` and handles request body of type `application/json`.
   */
  apiAuditDashboardSummaryPost(
    params?: {
      body?: AdDashboardQueryReq
    },
    context?: HttpContext
  ): Observable<AdDashboardResultSummaryRes> {
    return this.apiAuditDashboardSummaryPost$Response(params, context).pipe(
      map((r: StrictHttpResponse<AdDashboardResultSummaryRes>): AdDashboardResultSummaryRes => r.body)
    );
  }

  /** Path part for operation `apiAuditDashboardBookingJobPost()` */
  static readonly ApiAuditDashboardBookingJobPostPath = '/api/audit/dashboard/booking-job';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `apiAuditDashboardBookingJobPost()` instead.
   *
   * This method sends `application/json` and handles request body of type `application/json`.
   */
  apiAuditDashboardBookingJobPost$Response(
    params?: {
      body?: Array<string>
    },
    context?: HttpContext
  ): Observable<StrictHttpResponse<void>> {
    const rb = new RequestBuilder(this.rootUrl, AuditDashboardService.ApiAuditDashboardBookingJobPostPath, 'post');
    if (params) {
      rb.body(params.body, 'application/json');
    }

    return this.http.request(
      rb.build({ responseType: 'text', accept: '*/*', context })
    ).pipe(
      filter((r: any): r is HttpResponse<any> => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return (r as HttpResponse<any>).clone({ body: undefined }) as StrictHttpResponse<void>;
      })
    );
  }

  /**
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `apiAuditDashboardBookingJobPost$Response()` instead.
   *
   * This method sends `application/json` and handles request body of type `application/json`.
   */
  apiAuditDashboardBookingJobPost(
    params?: {
      body?: Array<string>
    },
    context?: HttpContext
  ): Observable<void> {
    return this.apiAuditDashboardBookingJobPost$Response(params, context).pipe(
      map((r: StrictHttpResponse<void>): void => r.body)
    );
  }

  /** Path part for operation `apiAuditDashboardMasterCategorySeverityContainPost()` */
  static readonly ApiAuditDashboardMasterCategorySeverityContainPostPath = '/api/audit/dashboard/master-category-severity-contain';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `apiAuditDashboardMasterCategorySeverityContainPost()` instead.
   *
   * This method doesn't expect any request body.
   */
  apiAuditDashboardMasterCategorySeverityContainPost$Response(
    params?: {
      containText?: string;
      max?: number;
      workGroupList?: Array<number>;
    },
    context?: HttpContext
  ): Observable<StrictHttpResponse<Array<MasterCategoryAndSeverityResItem>>> {
    const rb = new RequestBuilder(this.rootUrl, AuditDashboardService.ApiAuditDashboardMasterCategorySeverityContainPostPath, 'post');
    if (params) {
      rb.query('containText', params.containText, {});
      rb.query('max', params.max, {});
      rb.query('workGroupList', params.workGroupList, {});
    }

    return this.http.request(
      rb.build({ responseType: 'json', accept: 'application/json', context })
    ).pipe(
      filter((r: any): r is HttpResponse<any> => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<Array<MasterCategoryAndSeverityResItem>>;
      })
    );
  }

  /**
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `apiAuditDashboardMasterCategorySeverityContainPost$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  apiAuditDashboardMasterCategorySeverityContainPost(
    params?: {
      containText?: string;
      max?: number;
      workGroupList?: Array<number>;
    },
    context?: HttpContext
  ): Observable<Array<MasterCategoryAndSeverityResItem>> {
    return this.apiAuditDashboardMasterCategorySeverityContainPost$Response(params, context).pipe(
      map((r: StrictHttpResponse<Array<MasterCategoryAndSeverityResItem>>): Array<MasterCategoryAndSeverityResItem> => r.body)
    );
  }

  /** Path part for operation `apiAuditDashboardExportPost()` */
  static readonly ApiAuditDashboardExportPostPath = '/api/audit/dashboard/export';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `apiAuditDashboardExportPost()` instead.
   *
   * This method sends `application/json` and handles request body of type `application/json`.
   */
  apiAuditDashboardExportPost$Response(
    params?: {
      body?: AdDashboardQueryReq
    },
    context?: HttpContext
  ): Observable<StrictHttpResponse<ExportExcelFileRes>> {
    const rb = new RequestBuilder(this.rootUrl, AuditDashboardService.ApiAuditDashboardExportPostPath, 'post');
    if (params) {
      rb.body(params.body, 'application/json');
    }

    return this.http.request(
      rb.build({ responseType: 'json', accept: 'application/json', context })
    ).pipe(
      filter((r: any): r is HttpResponse<any> => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<ExportExcelFileRes>;
      })
    );
  }

  /**
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `apiAuditDashboardExportPost$Response()` instead.
   *
   * This method sends `application/json` and handles request body of type `application/json`.
   */
  apiAuditDashboardExportPost(
    params?: {
      body?: AdDashboardQueryReq
    },
    context?: HttpContext
  ): Observable<ExportExcelFileRes> {
    return this.apiAuditDashboardExportPost$Response(params, context).pipe(
      map((r: StrictHttpResponse<ExportExcelFileRes>): ExportExcelFileRes => r.body)
    );
  }

  /** Path part for operation `apiAuditDashboardDdlListGet()` */
  static readonly ApiAuditDashboardDdlListGetPath = '/api/audit/dashboard/ddl-list';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `apiAuditDashboardDdlListGet()` instead.
   *
   * This method doesn't expect any request body.
   */
  apiAuditDashboardDdlListGet$Response(
    params?: {
    },
    context?: HttpContext
  ): Observable<StrictHttpResponse<AdDashboardDropdownItem>> {
    const rb = new RequestBuilder(this.rootUrl, AuditDashboardService.ApiAuditDashboardDdlListGetPath, 'get');
    if (params) {
    }

    return this.http.request(
      rb.build({ responseType: 'json', accept: 'application/json', context })
    ).pipe(
      filter((r: any): r is HttpResponse<any> => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<AdDashboardDropdownItem>;
      })
    );
  }

  /**
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `apiAuditDashboardDdlListGet$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  apiAuditDashboardDdlListGet(
    params?: {
    },
    context?: HttpContext
  ): Observable<AdDashboardDropdownItem> {
    return this.apiAuditDashboardDdlListGet$Response(params, context).pipe(
      map((r: StrictHttpResponse<AdDashboardDropdownItem>): AdDashboardDropdownItem => r.body)
    );
  }

}
