/* tslint:disable */
/* eslint-disable */
import { HttpClient, HttpContext, HttpResponse } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { filter, map } from 'rxjs/operators';

import { BaseService } from '../base-service';
import { ApiConfiguration } from '../api-configuration';
import { StrictHttpResponse } from '../strict-http-response';
import { RequestBuilder } from '../request-builder';

import { MasterCriteriaItem } from '../models/master-criteria-item';

@Injectable({ providedIn: 'root' })
export class MasterCriteriaService extends BaseService {
  constructor(config: ApiConfiguration, http: HttpClient) {
    super(config, http);
  }

  /** Path part for operation `apiMasterCriteriaGet()` */
  static readonly ApiMasterCriteriaGetPath = '/api/master/criteria';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `apiMasterCriteriaGet()` instead.
   *
   * This method doesn't expect any request body.
   */
  apiMasterCriteriaGet$Response(
    params?: {
      id?: string;
    },
    context?: HttpContext
  ): Observable<StrictHttpResponse<MasterCriteriaItem>> {
    const rb = new RequestBuilder(this.rootUrl, MasterCriteriaService.ApiMasterCriteriaGetPath, 'get');
    if (params) {
      rb.query('id', params.id, {});
    }

    return this.http.request(
      rb.build({ responseType: 'json', accept: 'application/json', context })
    ).pipe(
      filter((r: any): r is HttpResponse<any> => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<MasterCriteriaItem>;
      })
    );
  }

  /**
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `apiMasterCriteriaGet$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  apiMasterCriteriaGet(
    params?: {
      id?: string;
    },
    context?: HttpContext
  ): Observable<MasterCriteriaItem> {
    return this.apiMasterCriteriaGet$Response(params, context).pipe(
      map((r: StrictHttpResponse<MasterCriteriaItem>): MasterCriteriaItem => r.body)
    );
  }

  /** Path part for operation `apiMasterCriteriaPut()` */
  static readonly ApiMasterCriteriaPutPath = '/api/master/criteria';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `apiMasterCriteriaPut()` instead.
   *
   * This method sends `application/json` and handles request body of type `application/json`.
   */
  apiMasterCriteriaPut$Response(
    params?: {
      body?: MasterCriteriaItem
    },
    context?: HttpContext
  ): Observable<StrictHttpResponse<MasterCriteriaItem>> {
    const rb = new RequestBuilder(this.rootUrl, MasterCriteriaService.ApiMasterCriteriaPutPath, 'put');
    if (params) {
      rb.body(params.body, 'application/json');
    }

    return this.http.request(
      rb.build({ responseType: 'json', accept: 'application/json', context })
    ).pipe(
      filter((r: any): r is HttpResponse<any> => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<MasterCriteriaItem>;
      })
    );
  }

  /**
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `apiMasterCriteriaPut$Response()` instead.
   *
   * This method sends `application/json` and handles request body of type `application/json`.
   */
  apiMasterCriteriaPut(
    params?: {
      body?: MasterCriteriaItem
    },
    context?: HttpContext
  ): Observable<MasterCriteriaItem> {
    return this.apiMasterCriteriaPut$Response(params, context).pipe(
      map((r: StrictHttpResponse<MasterCriteriaItem>): MasterCriteriaItem => r.body)
    );
  }

  /** Path part for operation `apiMasterCriteriaPost()` */
  static readonly ApiMasterCriteriaPostPath = '/api/master/criteria';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `apiMasterCriteriaPost()` instead.
   *
   * This method sends `application/json` and handles request body of type `application/json`.
   */
  apiMasterCriteriaPost$Response(
    params?: {
      body?: MasterCriteriaItem
    },
    context?: HttpContext
  ): Observable<StrictHttpResponse<MasterCriteriaItem>> {
    const rb = new RequestBuilder(this.rootUrl, MasterCriteriaService.ApiMasterCriteriaPostPath, 'post');
    if (params) {
      rb.body(params.body, 'application/json');
    }

    return this.http.request(
      rb.build({ responseType: 'json', accept: 'application/json', context })
    ).pipe(
      filter((r: any): r is HttpResponse<any> => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<MasterCriteriaItem>;
      })
    );
  }

  /**
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `apiMasterCriteriaPost$Response()` instead.
   *
   * This method sends `application/json` and handles request body of type `application/json`.
   */
  apiMasterCriteriaPost(
    params?: {
      body?: MasterCriteriaItem
    },
    context?: HttpContext
  ): Observable<MasterCriteriaItem> {
    return this.apiMasterCriteriaPost$Response(params, context).pipe(
      map((r: StrictHttpResponse<MasterCriteriaItem>): MasterCriteriaItem => r.body)
    );
  }

  /** Path part for operation `apiMasterCriteriaDelete()` */
  static readonly ApiMasterCriteriaDeletePath = '/api/master/criteria';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `apiMasterCriteriaDelete()` instead.
   *
   * This method doesn't expect any request body.
   */
  apiMasterCriteriaDelete$Response(
    params?: {
      id?: string;
    },
    context?: HttpContext
  ): Observable<StrictHttpResponse<void>> {
    const rb = new RequestBuilder(this.rootUrl, MasterCriteriaService.ApiMasterCriteriaDeletePath, 'delete');
    if (params) {
      rb.query('id', params.id, {});
    }

    return this.http.request(
      rb.build({ responseType: 'text', accept: '*/*', context })
    ).pipe(
      filter((r: any): r is HttpResponse<any> => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return (r as HttpResponse<any>).clone({ body: undefined }) as StrictHttpResponse<void>;
      })
    );
  }

  /**
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `apiMasterCriteriaDelete$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  apiMasterCriteriaDelete(
    params?: {
      id?: string;
    },
    context?: HttpContext
  ): Observable<void> {
    return this.apiMasterCriteriaDelete$Response(params, context).pipe(
      map((r: StrictHttpResponse<void>): void => r.body)
    );
  }

  /** Path part for operation `apiMasterCriteriaAllGet()` */
  static readonly ApiMasterCriteriaAllGetPath = '/api/master/criteria/All';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `apiMasterCriteriaAllGet()` instead.
   *
   * This method doesn't expect any request body.
   */
  apiMasterCriteriaAllGet$Response(
    params?: {
    },
    context?: HttpContext
  ): Observable<StrictHttpResponse<Array<MasterCriteriaItem>>> {
    const rb = new RequestBuilder(this.rootUrl, MasterCriteriaService.ApiMasterCriteriaAllGetPath, 'get');
    if (params) {
    }

    return this.http.request(
      rb.build({ responseType: 'json', accept: 'application/json', context })
    ).pipe(
      filter((r: any): r is HttpResponse<any> => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<Array<MasterCriteriaItem>>;
      })
    );
  }

  /**
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `apiMasterCriteriaAllGet$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  apiMasterCriteriaAllGet(
    params?: {
    },
    context?: HttpContext
  ): Observable<Array<MasterCriteriaItem>> {
    return this.apiMasterCriteriaAllGet$Response(params, context).pipe(
      map((r: StrictHttpResponse<Array<MasterCriteriaItem>>): Array<MasterCriteriaItem> => r.body)
    );
  }

  /** Path part for operation `apiMasterCriteriaMaintainGet()` */
  static readonly ApiMasterCriteriaMaintainGetPath = '/api/master/criteria/Maintain';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `apiMasterCriteriaMaintainGet()` instead.
   *
   * This method doesn't expect any request body.
   */
  apiMasterCriteriaMaintainGet$Response(
    params?: {
      workgroups?: Array<number>;
    },
    context?: HttpContext
  ): Observable<StrictHttpResponse<Array<MasterCriteriaItem>>> {
    const rb = new RequestBuilder(this.rootUrl, MasterCriteriaService.ApiMasterCriteriaMaintainGetPath, 'get');
    if (params) {
      rb.query('workgroups', params.workgroups, {});
    }

    return this.http.request(
      rb.build({ responseType: 'json', accept: 'application/json', context })
    ).pipe(
      filter((r: any): r is HttpResponse<any> => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<Array<MasterCriteriaItem>>;
      })
    );
  }

  /**
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `apiMasterCriteriaMaintainGet$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  apiMasterCriteriaMaintainGet(
    params?: {
      workgroups?: Array<number>;
    },
    context?: HttpContext
  ): Observable<Array<MasterCriteriaItem>> {
    return this.apiMasterCriteriaMaintainGet$Response(params, context).pipe(
      map((r: StrictHttpResponse<Array<MasterCriteriaItem>>): Array<MasterCriteriaItem> => r.body)
    );
  }

  /** Path part for operation `apiMasterCriteriaActiveGet()` */
  static readonly ApiMasterCriteriaActiveGetPath = '/api/master/criteria/Active';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `apiMasterCriteriaActiveGet()` instead.
   *
   * This method doesn't expect any request body.
   */
  apiMasterCriteriaActiveGet$Response(
    params?: {
    },
    context?: HttpContext
  ): Observable<StrictHttpResponse<Array<MasterCriteriaItem>>> {
    const rb = new RequestBuilder(this.rootUrl, MasterCriteriaService.ApiMasterCriteriaActiveGetPath, 'get');
    if (params) {
    }

    return this.http.request(
      rb.build({ responseType: 'json', accept: 'application/json', context })
    ).pipe(
      filter((r: any): r is HttpResponse<any> => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<Array<MasterCriteriaItem>>;
      })
    );
  }

  /**
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `apiMasterCriteriaActiveGet$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  apiMasterCriteriaActiveGet(
    params?: {
    },
    context?: HttpContext
  ): Observable<Array<MasterCriteriaItem>> {
    return this.apiMasterCriteriaActiveGet$Response(params, context).pipe(
      map((r: StrictHttpResponse<Array<MasterCriteriaItem>>): Array<MasterCriteriaItem> => r.body)
    );
  }

}
