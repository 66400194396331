/* tslint:disable */
/* eslint-disable */
import { HttpClient, HttpContext, HttpResponse } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { filter, map } from 'rxjs/operators';

import { BaseService } from '../base-service';
import { ApiConfiguration } from '../api-configuration';
import { StrictHttpResponse } from '../strict-http-response';
import { RequestBuilder } from '../request-builder';

import { ContactReq } from '../models/contact-req';
import { ContactSearchQueryReq } from '../models/contact-search-query-req';
import { ContactSearchQueryRes } from '../models/contact-search-query-res';

@Injectable({ providedIn: 'root' })
export class CtMgrContactService extends BaseService {
  constructor(config: ApiConfiguration, http: HttpClient) {
    super(config, http);
  }

  /** Path part for operation `apiContentMgrContactQueryPost()` */
  static readonly ApiContentMgrContactQueryPostPath = '/api/content-mgr/contact/query';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `apiContentMgrContactQueryPost()` instead.
   *
   * This method sends `application/json` and handles request body of type `application/json`.
   */
  apiContentMgrContactQueryPost$Response(
    params?: {
      body?: ContactSearchQueryReq
    },
    context?: HttpContext
  ): Observable<StrictHttpResponse<ContactSearchQueryRes>> {
    const rb = new RequestBuilder(this.rootUrl, CtMgrContactService.ApiContentMgrContactQueryPostPath, 'post');
    if (params) {
      rb.body(params.body, 'application/json');
    }

    return this.http.request(
      rb.build({ responseType: 'json', accept: 'application/json', context })
    ).pipe(
      filter((r: any): r is HttpResponse<any> => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<ContactSearchQueryRes>;
      })
    );
  }

  /**
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `apiContentMgrContactQueryPost$Response()` instead.
   *
   * This method sends `application/json` and handles request body of type `application/json`.
   */
  apiContentMgrContactQueryPost(
    params?: {
      body?: ContactSearchQueryReq
    },
    context?: HttpContext
  ): Observable<ContactSearchQueryRes> {
    return this.apiContentMgrContactQueryPost$Response(params, context).pipe(
      map((r: StrictHttpResponse<ContactSearchQueryRes>): ContactSearchQueryRes => r.body)
    );
  }

  /** Path part for operation `apiContentMgrContactPut()` */
  static readonly ApiContentMgrContactPutPath = '/api/content-mgr/contact';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `apiContentMgrContactPut()` instead.
   *
   * This method sends `application/json` and handles request body of type `application/json`.
   */
  apiContentMgrContactPut$Response(
    params?: {
      body?: ContactReq
    },
    context?: HttpContext
  ): Observable<StrictHttpResponse<void>> {
    const rb = new RequestBuilder(this.rootUrl, CtMgrContactService.ApiContentMgrContactPutPath, 'put');
    if (params) {
      rb.body(params.body, 'application/json');
    }

    return this.http.request(
      rb.build({ responseType: 'text', accept: '*/*', context })
    ).pipe(
      filter((r: any): r is HttpResponse<any> => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return (r as HttpResponse<any>).clone({ body: undefined }) as StrictHttpResponse<void>;
      })
    );
  }

  /**
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `apiContentMgrContactPut$Response()` instead.
   *
   * This method sends `application/json` and handles request body of type `application/json`.
   */
  apiContentMgrContactPut(
    params?: {
      body?: ContactReq
    },
    context?: HttpContext
  ): Observable<void> {
    return this.apiContentMgrContactPut$Response(params, context).pipe(
      map((r: StrictHttpResponse<void>): void => r.body)
    );
  }

  /** Path part for operation `apiContentMgrContactPost()` */
  static readonly ApiContentMgrContactPostPath = '/api/content-mgr/contact';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `apiContentMgrContactPost()` instead.
   *
   * This method sends `application/json` and handles request body of type `application/json`.
   */
  apiContentMgrContactPost$Response(
    params?: {
      body?: ContactReq
    },
    context?: HttpContext
  ): Observable<StrictHttpResponse<void>> {
    const rb = new RequestBuilder(this.rootUrl, CtMgrContactService.ApiContentMgrContactPostPath, 'post');
    if (params) {
      rb.body(params.body, 'application/json');
    }

    return this.http.request(
      rb.build({ responseType: 'text', accept: '*/*', context })
    ).pipe(
      filter((r: any): r is HttpResponse<any> => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return (r as HttpResponse<any>).clone({ body: undefined }) as StrictHttpResponse<void>;
      })
    );
  }

  /**
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `apiContentMgrContactPost$Response()` instead.
   *
   * This method sends `application/json` and handles request body of type `application/json`.
   */
  apiContentMgrContactPost(
    params?: {
      body?: ContactReq
    },
    context?: HttpContext
  ): Observable<void> {
    return this.apiContentMgrContactPost$Response(params, context).pipe(
      map((r: StrictHttpResponse<void>): void => r.body)
    );
  }

  /** Path part for operation `apiContentMgrContactDelete()` */
  static readonly ApiContentMgrContactDeletePath = '/api/content-mgr/contact';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `apiContentMgrContactDelete()` instead.
   *
   * This method doesn't expect any request body.
   */
  apiContentMgrContactDelete$Response(
    params?: {
      req?: string;
    },
    context?: HttpContext
  ): Observable<StrictHttpResponse<void>> {
    const rb = new RequestBuilder(this.rootUrl, CtMgrContactService.ApiContentMgrContactDeletePath, 'delete');
    if (params) {
      rb.query('req', params.req, {});
    }

    return this.http.request(
      rb.build({ responseType: 'text', accept: '*/*', context })
    ).pipe(
      filter((r: any): r is HttpResponse<any> => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return (r as HttpResponse<any>).clone({ body: undefined }) as StrictHttpResponse<void>;
      })
    );
  }

  /**
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `apiContentMgrContactDelete$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  apiContentMgrContactDelete(
    params?: {
      req?: string;
    },
    context?: HttpContext
  ): Observable<void> {
    return this.apiContentMgrContactDelete$Response(params, context).pipe(
      map((r: StrictHttpResponse<void>): void => r.body)
    );
  }

}
