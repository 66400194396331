/* tslint:disable */
/* eslint-disable */
import { HttpClient, HttpContext, HttpResponse } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { filter, map } from 'rxjs/operators';

import { BaseService } from '../base-service';
import { ApiConfiguration } from '../api-configuration';
import { StrictHttpResponse } from '../strict-http-response';
import { RequestBuilder } from '../request-builder';

import { AccountWithRoleRes } from '../models/account-with-role-res';
import { WorkGroupRes } from '../models/work-group-res';

@Injectable({ providedIn: 'root' })
export class AccountService extends BaseService {
  constructor(config: ApiConfiguration, http: HttpClient) {
    super(config, http);
  }

  /** Path part for operation `apiAccountMeGet()` */
  static readonly ApiAccountMeGetPath = '/api/account/me';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `apiAccountMeGet()` instead.
   *
   * This method doesn't expect any request body.
   */
  apiAccountMeGet$Response(
    params?: {
    },
    context?: HttpContext
  ): Observable<StrictHttpResponse<string>> {
    const rb = new RequestBuilder(this.rootUrl, AccountService.ApiAccountMeGetPath, 'get');
    if (params) {
    }

    return this.http.request(
      rb.build({ responseType: 'json', accept: 'application/json', context })
    ).pipe(
      filter((r: any): r is HttpResponse<any> => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<string>;
      })
    );
  }

  /**
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `apiAccountMeGet$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  apiAccountMeGet(
    params?: {
    },
    context?: HttpContext
  ): Observable<string> {
    return this.apiAccountMeGet$Response(params, context).pipe(
      map((r: StrictHttpResponse<string>): string => r.body)
    );
  }

  /** Path part for operation `apiAccountOriginalGet()` */
  static readonly ApiAccountOriginalGetPath = '/api/account/original';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `apiAccountOriginalGet()` instead.
   *
   * This method doesn't expect any request body.
   */
  apiAccountOriginalGet$Response(
    params?: {
    },
    context?: HttpContext
  ): Observable<StrictHttpResponse<string>> {
    const rb = new RequestBuilder(this.rootUrl, AccountService.ApiAccountOriginalGetPath, 'get');
    if (params) {
    }

    return this.http.request(
      rb.build({ responseType: 'json', accept: 'application/json', context })
    ).pipe(
      filter((r: any): r is HttpResponse<any> => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<string>;
      })
    );
  }

  /**
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `apiAccountOriginalGet$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  apiAccountOriginalGet(
    params?: {
    },
    context?: HttpContext
  ): Observable<string> {
    return this.apiAccountOriginalGet$Response(params, context).pipe(
      map((r: StrictHttpResponse<string>): string => r.body)
    );
  }

  /** Path part for operation `apiAccountGet()` */
  static readonly ApiAccountGetPath = '/api/account';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `apiAccountGet()` instead.
   *
   * This method doesn't expect any request body.
   */
  apiAccountGet$Response(
    params?: {
    },
    context?: HttpContext
  ): Observable<StrictHttpResponse<AccountWithRoleRes>> {
    const rb = new RequestBuilder(this.rootUrl, AccountService.ApiAccountGetPath, 'get');
    if (params) {
    }

    return this.http.request(
      rb.build({ responseType: 'json', accept: 'application/json', context })
    ).pipe(
      filter((r: any): r is HttpResponse<any> => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<AccountWithRoleRes>;
      })
    );
  }

  /**
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `apiAccountGet$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  apiAccountGet(
    params?: {
    },
    context?: HttpContext
  ): Observable<AccountWithRoleRes> {
    return this.apiAccountGet$Response(params, context).pipe(
      map((r: StrictHttpResponse<AccountWithRoleRes>): AccountWithRoleRes => r.body)
    );
  }

  /** Path part for operation `apiAccountPrepareFromMdmGet()` */
  static readonly ApiAccountPrepareFromMdmGetPath = '/api/account/prepare-from-mdm';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `apiAccountPrepareFromMdmGet()` instead.
   *
   * This method doesn't expect any request body.
   */
  apiAccountPrepareFromMdmGet$Response(
    params?: {
    },
    context?: HttpContext
  ): Observable<StrictHttpResponse<void>> {
    const rb = new RequestBuilder(this.rootUrl, AccountService.ApiAccountPrepareFromMdmGetPath, 'get');
    if (params) {
    }

    return this.http.request(
      rb.build({ responseType: 'text', accept: '*/*', context })
    ).pipe(
      filter((r: any): r is HttpResponse<any> => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return (r as HttpResponse<any>).clone({ body: undefined }) as StrictHttpResponse<void>;
      })
    );
  }

  /**
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `apiAccountPrepareFromMdmGet$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  apiAccountPrepareFromMdmGet(
    params?: {
    },
    context?: HttpContext
  ): Observable<void> {
    return this.apiAccountPrepareFromMdmGet$Response(params, context).pipe(
      map((r: StrictHttpResponse<void>): void => r.body)
    );
  }

  /** Path part for operation `apiAccountPrepareFromGdcGet()` */
  static readonly ApiAccountPrepareFromGdcGetPath = '/api/account/prepare-from-gdc';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `apiAccountPrepareFromGdcGet()` instead.
   *
   * This method doesn't expect any request body.
   */
  apiAccountPrepareFromGdcGet$Response(
    params?: {
      gdcKey?: string;
      loginKey?: string;
    },
    context?: HttpContext
  ): Observable<StrictHttpResponse<void>> {
    const rb = new RequestBuilder(this.rootUrl, AccountService.ApiAccountPrepareFromGdcGetPath, 'get');
    if (params) {
      rb.query('gdcKey', params.gdcKey, {});
      rb.query('loginKey', params.loginKey, {});
    }

    return this.http.request(
      rb.build({ responseType: 'text', accept: '*/*', context })
    ).pipe(
      filter((r: any): r is HttpResponse<any> => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return (r as HttpResponse<any>).clone({ body: undefined }) as StrictHttpResponse<void>;
      })
    );
  }

  /**
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `apiAccountPrepareFromGdcGet$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  apiAccountPrepareFromGdcGet(
    params?: {
      gdcKey?: string;
      loginKey?: string;
    },
    context?: HttpContext
  ): Observable<void> {
    return this.apiAccountPrepareFromGdcGet$Response(params, context).pipe(
      map((r: StrictHttpResponse<void>): void => r.body)
    );
  }

  /** Path part for operation `apiAccountPrepareFromMdmByEmailGet()` */
  static readonly ApiAccountPrepareFromMdmByEmailGetPath = '/api/account/prepare-from-mdm/by-email';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `apiAccountPrepareFromMdmByEmailGet()` instead.
   *
   * This method doesn't expect any request body.
   */
  apiAccountPrepareFromMdmByEmailGet$Response(
    params?: {
      email?: string;
    },
    context?: HttpContext
  ): Observable<StrictHttpResponse<void>> {
    const rb = new RequestBuilder(this.rootUrl, AccountService.ApiAccountPrepareFromMdmByEmailGetPath, 'get');
    if (params) {
      rb.query('email', params.email, {});
    }

    return this.http.request(
      rb.build({ responseType: 'text', accept: '*/*', context })
    ).pipe(
      filter((r: any): r is HttpResponse<any> => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return (r as HttpResponse<any>).clone({ body: undefined }) as StrictHttpResponse<void>;
      })
    );
  }

  /**
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `apiAccountPrepareFromMdmByEmailGet$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  apiAccountPrepareFromMdmByEmailGet(
    params?: {
      email?: string;
    },
    context?: HttpContext
  ): Observable<void> {
    return this.apiAccountPrepareFromMdmByEmailGet$Response(params, context).pipe(
      map((r: StrictHttpResponse<void>): void => r.body)
    );
  }

  /** Path part for operation `apiAccountPrepareFromGdcByEmailGet()` */
  static readonly ApiAccountPrepareFromGdcByEmailGetPath = '/api/account/prepare-from-gdc/by-email';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `apiAccountPrepareFromGdcByEmailGet()` instead.
   *
   * This method doesn't expect any request body.
   */
  apiAccountPrepareFromGdcByEmailGet$Response(
    params?: {
      email?: string;
      gdcKey?: string;
      loginKey?: string;
    },
    context?: HttpContext
  ): Observable<StrictHttpResponse<void>> {
    const rb = new RequestBuilder(this.rootUrl, AccountService.ApiAccountPrepareFromGdcByEmailGetPath, 'get');
    if (params) {
      rb.query('email', params.email, {});
      rb.query('gdcKey', params.gdcKey, {});
      rb.query('loginKey', params.loginKey, {});
    }

    return this.http.request(
      rb.build({ responseType: 'text', accept: '*/*', context })
    ).pipe(
      filter((r: any): r is HttpResponse<any> => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return (r as HttpResponse<any>).clone({ body: undefined }) as StrictHttpResponse<void>;
      })
    );
  }

  /**
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `apiAccountPrepareFromGdcByEmailGet$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  apiAccountPrepareFromGdcByEmailGet(
    params?: {
      email?: string;
      gdcKey?: string;
      loginKey?: string;
    },
    context?: HttpContext
  ): Observable<void> {
    return this.apiAccountPrepareFromGdcByEmailGet$Response(params, context).pipe(
      map((r: StrictHttpResponse<void>): void => r.body)
    );
  }

  /** Path part for operation `apiAccountSwapPost()` */
  static readonly ApiAccountSwapPostPath = '/api/account/swap';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `apiAccountSwapPost()` instead.
   *
   * This method doesn't expect any request body.
   */
  apiAccountSwapPost$Response(
    params?: {
      email?: string;
    },
    context?: HttpContext
  ): Observable<StrictHttpResponse<void>> {
    const rb = new RequestBuilder(this.rootUrl, AccountService.ApiAccountSwapPostPath, 'post');
    if (params) {
      rb.query('email', params.email, {});
    }

    return this.http.request(
      rb.build({ responseType: 'text', accept: '*/*', context })
    ).pipe(
      filter((r: any): r is HttpResponse<any> => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return (r as HttpResponse<any>).clone({ body: undefined }) as StrictHttpResponse<void>;
      })
    );
  }

  /**
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `apiAccountSwapPost$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  apiAccountSwapPost(
    params?: {
      email?: string;
    },
    context?: HttpContext
  ): Observable<void> {
    return this.apiAccountSwapPost$Response(params, context).pipe(
      map((r: StrictHttpResponse<void>): void => r.body)
    );
  }

  /** Path part for operation `apiAccountSwapDelete()` */
  static readonly ApiAccountSwapDeletePath = '/api/account/swap';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `apiAccountSwapDelete()` instead.
   *
   * This method doesn't expect any request body.
   */
  apiAccountSwapDelete$Response(
    params?: {
    },
    context?: HttpContext
  ): Observable<StrictHttpResponse<void>> {
    const rb = new RequestBuilder(this.rootUrl, AccountService.ApiAccountSwapDeletePath, 'delete');
    if (params) {
    }

    return this.http.request(
      rb.build({ responseType: 'text', accept: '*/*', context })
    ).pipe(
      filter((r: any): r is HttpResponse<any> => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return (r as HttpResponse<any>).clone({ body: undefined }) as StrictHttpResponse<void>;
      })
    );
  }

  /**
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `apiAccountSwapDelete$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  apiAccountSwapDelete(
    params?: {
    },
    context?: HttpContext
  ): Observable<void> {
    return this.apiAccountSwapDelete$Response(params, context).pipe(
      map((r: StrictHttpResponse<void>): void => r.body)
    );
  }

  /** Path part for operation `apiAccountWorkgroupAllGet()` */
  static readonly ApiAccountWorkgroupAllGetPath = '/api/account/workgroup/all';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `apiAccountWorkgroupAllGet()` instead.
   *
   * This method doesn't expect any request body.
   */
  apiAccountWorkgroupAllGet$Response(
    params?: {
    },
    context?: HttpContext
  ): Observable<StrictHttpResponse<Array<WorkGroupRes>>> {
    const rb = new RequestBuilder(this.rootUrl, AccountService.ApiAccountWorkgroupAllGetPath, 'get');
    if (params) {
    }

    return this.http.request(
      rb.build({ responseType: 'json', accept: 'application/json', context })
    ).pipe(
      filter((r: any): r is HttpResponse<any> => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<Array<WorkGroupRes>>;
      })
    );
  }

  /**
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `apiAccountWorkgroupAllGet$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  apiAccountWorkgroupAllGet(
    params?: {
    },
    context?: HttpContext
  ): Observable<Array<WorkGroupRes>> {
    return this.apiAccountWorkgroupAllGet$Response(params, context).pipe(
      map((r: StrictHttpResponse<Array<WorkGroupRes>>): Array<WorkGroupRes> => r.body)
    );
  }

  /** Path part for operation `apiAccountWorkgroupAllNoneUserGet()` */
  static readonly ApiAccountWorkgroupAllNoneUserGetPath = '/api/account/workgroup/all-none-user';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `apiAccountWorkgroupAllNoneUserGet()` instead.
   *
   * This method doesn't expect any request body.
   */
  apiAccountWorkgroupAllNoneUserGet$Response(
    params?: {
    },
    context?: HttpContext
  ): Observable<StrictHttpResponse<Array<WorkGroupRes>>> {
    const rb = new RequestBuilder(this.rootUrl, AccountService.ApiAccountWorkgroupAllNoneUserGetPath, 'get');
    if (params) {
    }

    return this.http.request(
      rb.build({ responseType: 'json', accept: 'application/json', context })
    ).pipe(
      filter((r: any): r is HttpResponse<any> => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<Array<WorkGroupRes>>;
      })
    );
  }

  /**
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `apiAccountWorkgroupAllNoneUserGet$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  apiAccountWorkgroupAllNoneUserGet(
    params?: {
    },
    context?: HttpContext
  ): Observable<Array<WorkGroupRes>> {
    return this.apiAccountWorkgroupAllNoneUserGet$Response(params, context).pipe(
      map((r: StrictHttpResponse<Array<WorkGroupRes>>): Array<WorkGroupRes> => r.body)
    );
  }

}
