/* tslint:disable */
/* eslint-disable */
import { HttpClient, HttpContext, HttpResponse } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { filter, map } from 'rxjs/operators';

import { BaseService } from '../base-service';
import { ApiConfiguration } from '../api-configuration';
import { StrictHttpResponse } from '../strict-http-response';
import { RequestBuilder } from '../request-builder';

import { MasterCategoryAndSeverityCriteriaFlagItem } from '../models/master-category-and-severity-criteria-flag-item';
import { MasterCategoryAndSeverityDropdownItem } from '../models/master-category-and-severity-dropdown-item';
import { MasterCategoryAndSeverityItem } from '../models/master-category-and-severity-item';
import { MasterCategoryAndSeverityLogItem } from '../models/master-category-and-severity-log-item';
import { MasterCategoryAndSeverityOperationSchedule } from '../models/master-category-and-severity-operation-schedule';
import { MasterCategoryAndSeverityQueryItem } from '../models/master-category-and-severity-query-item';
import { MasterCategoryAndSeverityResultItem } from '../models/master-category-and-severity-result-item';

@Injectable({ providedIn: 'root' })
export class MasterCategorySeverityService extends BaseService {
  constructor(config: ApiConfiguration, http: HttpClient) {
    super(config, http);
  }

  /** Path part for operation `apiMasterCategorySeverityQueryPost()` */
  static readonly ApiMasterCategorySeverityQueryPostPath = '/api/master/categorySeverity/Query';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `apiMasterCategorySeverityQueryPost()` instead.
   *
   * This method sends `application/json` and handles request body of type `application/json`.
   */
  apiMasterCategorySeverityQueryPost$Response(
    params?: {
      body?: MasterCategoryAndSeverityQueryItem
    },
    context?: HttpContext
  ): Observable<StrictHttpResponse<MasterCategoryAndSeverityResultItem>> {
    const rb = new RequestBuilder(this.rootUrl, MasterCategorySeverityService.ApiMasterCategorySeverityQueryPostPath, 'post');
    if (params) {
      rb.body(params.body, 'application/json');
    }

    return this.http.request(
      rb.build({ responseType: 'json', accept: 'application/json', context })
    ).pipe(
      filter((r: any): r is HttpResponse<any> => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<MasterCategoryAndSeverityResultItem>;
      })
    );
  }

  /**
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `apiMasterCategorySeverityQueryPost$Response()` instead.
   *
   * This method sends `application/json` and handles request body of type `application/json`.
   */
  apiMasterCategorySeverityQueryPost(
    params?: {
      body?: MasterCategoryAndSeverityQueryItem
    },
    context?: HttpContext
  ): Observable<MasterCategoryAndSeverityResultItem> {
    return this.apiMasterCategorySeverityQueryPost$Response(params, context).pipe(
      map((r: StrictHttpResponse<MasterCategoryAndSeverityResultItem>): MasterCategoryAndSeverityResultItem => r.body)
    );
  }

  /** Path part for operation `apiMasterCategorySeverityByPkGet()` */
  static readonly ApiMasterCategorySeverityByPkGetPath = '/api/master/categorySeverity/ByPK';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `apiMasterCategorySeverityByPkGet()` instead.
   *
   * This method doesn't expect any request body.
   */
  apiMasterCategorySeverityByPkGet$Response(
    params?: {
      id?: string;
    },
    context?: HttpContext
  ): Observable<StrictHttpResponse<MasterCategoryAndSeverityItem>> {
    const rb = new RequestBuilder(this.rootUrl, MasterCategorySeverityService.ApiMasterCategorySeverityByPkGetPath, 'get');
    if (params) {
      rb.query('id', params.id, {});
    }

    return this.http.request(
      rb.build({ responseType: 'json', accept: 'application/json', context })
    ).pipe(
      filter((r: any): r is HttpResponse<any> => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<MasterCategoryAndSeverityItem>;
      })
    );
  }

  /**
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `apiMasterCategorySeverityByPkGet$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  apiMasterCategorySeverityByPkGet(
    params?: {
      id?: string;
    },
    context?: HttpContext
  ): Observable<MasterCategoryAndSeverityItem> {
    return this.apiMasterCategorySeverityByPkGet$Response(params, context).pipe(
      map((r: StrictHttpResponse<MasterCategoryAndSeverityItem>): MasterCategoryAndSeverityItem => r.body)
    );
  }

  /** Path part for operation `apiMasterCategorySeverityByAribaCodeIdGet()` */
  static readonly ApiMasterCategorySeverityByAribaCodeIdGetPath = '/api/master/categorySeverity/ByAribaCodeId';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `apiMasterCategorySeverityByAribaCodeIdGet()` instead.
   *
   * This method doesn't expect any request body.
   */
  apiMasterCategorySeverityByAribaCodeIdGet$Response(
    params?: {
      aribaCodeId?: string;
      fkWorkGroupId?: number;
    },
    context?: HttpContext
  ): Observable<StrictHttpResponse<MasterCategoryAndSeverityItem>> {
    const rb = new RequestBuilder(this.rootUrl, MasterCategorySeverityService.ApiMasterCategorySeverityByAribaCodeIdGetPath, 'get');
    if (params) {
      rb.query('aribaCodeId', params.aribaCodeId, {});
      rb.query('fkWorkGroupId', params.fkWorkGroupId, {});
    }

    return this.http.request(
      rb.build({ responseType: 'json', accept: 'application/json', context })
    ).pipe(
      filter((r: any): r is HttpResponse<any> => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<MasterCategoryAndSeverityItem>;
      })
    );
  }

  /**
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `apiMasterCategorySeverityByAribaCodeIdGet$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  apiMasterCategorySeverityByAribaCodeIdGet(
    params?: {
      aribaCodeId?: string;
      fkWorkGroupId?: number;
    },
    context?: HttpContext
  ): Observable<MasterCategoryAndSeverityItem> {
    return this.apiMasterCategorySeverityByAribaCodeIdGet$Response(params, context).pipe(
      map((r: StrictHttpResponse<MasterCategoryAndSeverityItem>): MasterCategoryAndSeverityItem => r.body)
    );
  }

  /** Path part for operation `apiMasterCategorySeverityPut()` */
  static readonly ApiMasterCategorySeverityPutPath = '/api/master/categorySeverity';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `apiMasterCategorySeverityPut()` instead.
   *
   * This method sends `application/json` and handles request body of type `application/json`.
   */
  apiMasterCategorySeverityPut$Response(
    params?: {
      body?: MasterCategoryAndSeverityItem
    },
    context?: HttpContext
  ): Observable<StrictHttpResponse<MasterCategoryAndSeverityItem>> {
    const rb = new RequestBuilder(this.rootUrl, MasterCategorySeverityService.ApiMasterCategorySeverityPutPath, 'put');
    if (params) {
      rb.body(params.body, 'application/json');
    }

    return this.http.request(
      rb.build({ responseType: 'json', accept: 'application/json', context })
    ).pipe(
      filter((r: any): r is HttpResponse<any> => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<MasterCategoryAndSeverityItem>;
      })
    );
  }

  /**
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `apiMasterCategorySeverityPut$Response()` instead.
   *
   * This method sends `application/json` and handles request body of type `application/json`.
   */
  apiMasterCategorySeverityPut(
    params?: {
      body?: MasterCategoryAndSeverityItem
    },
    context?: HttpContext
  ): Observable<MasterCategoryAndSeverityItem> {
    return this.apiMasterCategorySeverityPut$Response(params, context).pipe(
      map((r: StrictHttpResponse<MasterCategoryAndSeverityItem>): MasterCategoryAndSeverityItem => r.body)
    );
  }

  /** Path part for operation `apiMasterCategorySeverityPost()` */
  static readonly ApiMasterCategorySeverityPostPath = '/api/master/categorySeverity';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `apiMasterCategorySeverityPost()` instead.
   *
   * This method sends `application/json` and handles request body of type `application/json`.
   */
  apiMasterCategorySeverityPost$Response(
    params?: {
      body?: MasterCategoryAndSeverityItem
    },
    context?: HttpContext
  ): Observable<StrictHttpResponse<MasterCategoryAndSeverityItem>> {
    const rb = new RequestBuilder(this.rootUrl, MasterCategorySeverityService.ApiMasterCategorySeverityPostPath, 'post');
    if (params) {
      rb.body(params.body, 'application/json');
    }

    return this.http.request(
      rb.build({ responseType: 'json', accept: 'application/json', context })
    ).pipe(
      filter((r: any): r is HttpResponse<any> => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<MasterCategoryAndSeverityItem>;
      })
    );
  }

  /**
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `apiMasterCategorySeverityPost$Response()` instead.
   *
   * This method sends `application/json` and handles request body of type `application/json`.
   */
  apiMasterCategorySeverityPost(
    params?: {
      body?: MasterCategoryAndSeverityItem
    },
    context?: HttpContext
  ): Observable<MasterCategoryAndSeverityItem> {
    return this.apiMasterCategorySeverityPost$Response(params, context).pipe(
      map((r: StrictHttpResponse<MasterCategoryAndSeverityItem>): MasterCategoryAndSeverityItem => r.body)
    );
  }

  /** Path part for operation `apiMasterCategorySeverityDelete()` */
  static readonly ApiMasterCategorySeverityDeletePath = '/api/master/categorySeverity';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `apiMasterCategorySeverityDelete()` instead.
   *
   * This method doesn't expect any request body.
   */
  apiMasterCategorySeverityDelete$Response(
    params?: {
      id?: string;
    },
    context?: HttpContext
  ): Observable<StrictHttpResponse<void>> {
    const rb = new RequestBuilder(this.rootUrl, MasterCategorySeverityService.ApiMasterCategorySeverityDeletePath, 'delete');
    if (params) {
      rb.query('id', params.id, {});
    }

    return this.http.request(
      rb.build({ responseType: 'text', accept: '*/*', context })
    ).pipe(
      filter((r: any): r is HttpResponse<any> => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return (r as HttpResponse<any>).clone({ body: undefined }) as StrictHttpResponse<void>;
      })
    );
  }

  /**
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `apiMasterCategorySeverityDelete$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  apiMasterCategorySeverityDelete(
    params?: {
      id?: string;
    },
    context?: HttpContext
  ): Observable<void> {
    return this.apiMasterCategorySeverityDelete$Response(params, context).pipe(
      map((r: StrictHttpResponse<void>): void => r.body)
    );
  }

  /** Path part for operation `apiMasterCategorySeverityMaintainGet()` */
  static readonly ApiMasterCategorySeverityMaintainGetPath = '/api/master/categorySeverity/Maintain';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `apiMasterCategorySeverityMaintainGet()` instead.
   *
   * This method doesn't expect any request body.
   */
  apiMasterCategorySeverityMaintainGet$Response(
    params?: {
      fkWorkGroupId?: number;
    },
    context?: HttpContext
  ): Observable<StrictHttpResponse<Array<MasterCategoryAndSeverityItem>>> {
    const rb = new RequestBuilder(this.rootUrl, MasterCategorySeverityService.ApiMasterCategorySeverityMaintainGetPath, 'get');
    if (params) {
      rb.query('fkWorkGroupId', params.fkWorkGroupId, {});
    }

    return this.http.request(
      rb.build({ responseType: 'json', accept: 'application/json', context })
    ).pipe(
      filter((r: any): r is HttpResponse<any> => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<Array<MasterCategoryAndSeverityItem>>;
      })
    );
  }

  /**
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `apiMasterCategorySeverityMaintainGet$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  apiMasterCategorySeverityMaintainGet(
    params?: {
      fkWorkGroupId?: number;
    },
    context?: HttpContext
  ): Observable<Array<MasterCategoryAndSeverityItem>> {
    return this.apiMasterCategorySeverityMaintainGet$Response(params, context).pipe(
      map((r: StrictHttpResponse<Array<MasterCategoryAndSeverityItem>>): Array<MasterCategoryAndSeverityItem> => r.body)
    );
  }

  /** Path part for operation `apiMasterCategorySeverityContainByLevelGet()` */
  static readonly ApiMasterCategorySeverityContainByLevelGetPath = '/api/master/categorySeverity/ContainByLevel';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `apiMasterCategorySeverityContainByLevelGet()` instead.
   *
   * This method doesn't expect any request body.
   */
  apiMasterCategorySeverityContainByLevelGet$Response(
    params?: {
      level?: string;
      containText?: string;
      max?: number;
      fkWorkGroupIds?: Array<number>;
    },
    context?: HttpContext
  ): Observable<StrictHttpResponse<Array<MasterCategoryAndSeverityItem>>> {
    const rb = new RequestBuilder(this.rootUrl, MasterCategorySeverityService.ApiMasterCategorySeverityContainByLevelGetPath, 'get');
    if (params) {
      rb.query('level', params.level, {});
      rb.query('containText', params.containText, {});
      rb.query('max', params.max, {});
      rb.query('fkWorkGroupIds', params.fkWorkGroupIds, {});
    }

    return this.http.request(
      rb.build({ responseType: 'json', accept: 'application/json', context })
    ).pipe(
      filter((r: any): r is HttpResponse<any> => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<Array<MasterCategoryAndSeverityItem>>;
      })
    );
  }

  /**
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `apiMasterCategorySeverityContainByLevelGet$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  apiMasterCategorySeverityContainByLevelGet(
    params?: {
      level?: string;
      containText?: string;
      max?: number;
      fkWorkGroupIds?: Array<number>;
    },
    context?: HttpContext
  ): Observable<Array<MasterCategoryAndSeverityItem>> {
    return this.apiMasterCategorySeverityContainByLevelGet$Response(params, context).pipe(
      map((r: StrictHttpResponse<Array<MasterCategoryAndSeverityItem>>): Array<MasterCategoryAndSeverityItem> => r.body)
    );
  }

  /** Path part for operation `apiMasterCategorySeverityDropdownSelectionGet()` */
  static readonly ApiMasterCategorySeverityDropdownSelectionGetPath = '/api/master/categorySeverity/DropdownSelection';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `apiMasterCategorySeverityDropdownSelectionGet()` instead.
   *
   * This method doesn't expect any request body.
   */
  apiMasterCategorySeverityDropdownSelectionGet$Response(
    params?: {
      fkWorkGroupId?: number;
    },
    context?: HttpContext
  ): Observable<StrictHttpResponse<MasterCategoryAndSeverityDropdownItem>> {
    const rb = new RequestBuilder(this.rootUrl, MasterCategorySeverityService.ApiMasterCategorySeverityDropdownSelectionGetPath, 'get');
    if (params) {
      rb.query('fkWorkGroupId', params.fkWorkGroupId, {});
    }

    return this.http.request(
      rb.build({ responseType: 'json', accept: 'application/json', context })
    ).pipe(
      filter((r: any): r is HttpResponse<any> => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<MasterCategoryAndSeverityDropdownItem>;
      })
    );
  }

  /**
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `apiMasterCategorySeverityDropdownSelectionGet$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  apiMasterCategorySeverityDropdownSelectionGet(
    params?: {
      fkWorkGroupId?: number;
    },
    context?: HttpContext
  ): Observable<MasterCategoryAndSeverityDropdownItem> {
    return this.apiMasterCategorySeverityDropdownSelectionGet$Response(params, context).pipe(
      map((r: StrictHttpResponse<MasterCategoryAndSeverityDropdownItem>): MasterCategoryAndSeverityDropdownItem => r.body)
    );
  }

  /** Path part for operation `apiMasterCategorySeverityExportGet()` */
  static readonly ApiMasterCategorySeverityExportGetPath = '/api/master/categorySeverity/Export';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `apiMasterCategorySeverityExportGet()` instead.
   *
   * This method doesn't expect any request body.
   */
  apiMasterCategorySeverityExportGet$Response(
    params?: {
      workGroupId?: number;
    },
    context?: HttpContext
  ): Observable<StrictHttpResponse<void>> {
    const rb = new RequestBuilder(this.rootUrl, MasterCategorySeverityService.ApiMasterCategorySeverityExportGetPath, 'get');
    if (params) {
      rb.query('workGroupId', params.workGroupId, {});
    }

    return this.http.request(
      rb.build({ responseType: 'text', accept: '*/*', context })
    ).pipe(
      filter((r: any): r is HttpResponse<any> => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return (r as HttpResponse<any>).clone({ body: undefined }) as StrictHttpResponse<void>;
      })
    );
  }

  /**
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `apiMasterCategorySeverityExportGet$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  apiMasterCategorySeverityExportGet(
    params?: {
      workGroupId?: number;
    },
    context?: HttpContext
  ): Observable<void> {
    return this.apiMasterCategorySeverityExportGet$Response(params, context).pipe(
      map((r: StrictHttpResponse<void>): void => r.body)
    );
  }

  /** Path part for operation `apiMasterCategorySeverityImportPost()` */
  static readonly ApiMasterCategorySeverityImportPostPath = '/api/master/categorySeverity/Import';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `apiMasterCategorySeverityImportPost()` instead.
   *
   * This method doesn't expect any request body.
   */
  apiMasterCategorySeverityImportPost$Response(
    params?: {
    },
    context?: HttpContext
  ): Observable<StrictHttpResponse<void>> {
    const rb = new RequestBuilder(this.rootUrl, MasterCategorySeverityService.ApiMasterCategorySeverityImportPostPath, 'post');
    if (params) {
    }

    return this.http.request(
      rb.build({ responseType: 'text', accept: '*/*', context })
    ).pipe(
      filter((r: any): r is HttpResponse<any> => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return (r as HttpResponse<any>).clone({ body: undefined }) as StrictHttpResponse<void>;
      })
    );
  }

  /**
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `apiMasterCategorySeverityImportPost$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  apiMasterCategorySeverityImportPost(
    params?: {
    },
    context?: HttpContext
  ): Observable<void> {
    return this.apiMasterCategorySeverityImportPost$Response(params, context).pipe(
      map((r: StrictHttpResponse<void>): void => r.body)
    );
  }

  /** Path part for operation `apiMasterCategorySeverityActivityLogsGet()` */
  static readonly ApiMasterCategorySeverityActivityLogsGetPath = '/api/master/categorySeverity/ActivityLogs';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `apiMasterCategorySeverityActivityLogsGet()` instead.
   *
   * This method doesn't expect any request body.
   */
  apiMasterCategorySeverityActivityLogsGet$Response(
    params?: {
      workgroups?: Array<number>;
    },
    context?: HttpContext
  ): Observable<StrictHttpResponse<Array<MasterCategoryAndSeverityLogItem>>> {
    const rb = new RequestBuilder(this.rootUrl, MasterCategorySeverityService.ApiMasterCategorySeverityActivityLogsGetPath, 'get');
    if (params) {
      rb.query('workgroups', params.workgroups, {});
    }

    return this.http.request(
      rb.build({ responseType: 'json', accept: 'application/json', context })
    ).pipe(
      filter((r: any): r is HttpResponse<any> => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<Array<MasterCategoryAndSeverityLogItem>>;
      })
    );
  }

  /**
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `apiMasterCategorySeverityActivityLogsGet$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  apiMasterCategorySeverityActivityLogsGet(
    params?: {
      workgroups?: Array<number>;
    },
    context?: HttpContext
  ): Observable<Array<MasterCategoryAndSeverityLogItem>> {
    return this.apiMasterCategorySeverityActivityLogsGet$Response(params, context).pipe(
      map((r: StrictHttpResponse<Array<MasterCategoryAndSeverityLogItem>>): Array<MasterCategoryAndSeverityLogItem> => r.body)
    );
  }

  /** Path part for operation `apiMasterCategorySeverityCheckDuplicateForAribraCodeIdGet()` */
  static readonly ApiMasterCategorySeverityCheckDuplicateForAribraCodeIdGetPath = '/api/master/categorySeverity/CheckDuplicateForAribraCodeId';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `apiMasterCategorySeverityCheckDuplicateForAribraCodeIdGet()` instead.
   *
   * This method doesn't expect any request body.
   */
  apiMasterCategorySeverityCheckDuplicateForAribraCodeIdGet$Response(
    params?: {
      aribaCodeId?: string;
      fkWorkGroupId?: number;
      pkMasterCategorySeverityId?: string;
    },
    context?: HttpContext
  ): Observable<StrictHttpResponse<boolean>> {
    const rb = new RequestBuilder(this.rootUrl, MasterCategorySeverityService.ApiMasterCategorySeverityCheckDuplicateForAribraCodeIdGetPath, 'get');
    if (params) {
      rb.query('aribaCodeId', params.aribaCodeId, {});
      rb.query('fkWorkGroupId', params.fkWorkGroupId, {});
      rb.query('pkMasterCategorySeverityId', params.pkMasterCategorySeverityId, {});
    }

    return this.http.request(
      rb.build({ responseType: 'json', accept: 'application/json', context })
    ).pipe(
      filter((r: any): r is HttpResponse<any> => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return (r as HttpResponse<any>).clone({ body: String((r as HttpResponse<any>).body) === 'true' }) as StrictHttpResponse<boolean>;
      })
    );
  }

  /**
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `apiMasterCategorySeverityCheckDuplicateForAribraCodeIdGet$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  apiMasterCategorySeverityCheckDuplicateForAribraCodeIdGet(
    params?: {
      aribaCodeId?: string;
      fkWorkGroupId?: number;
      pkMasterCategorySeverityId?: string;
    },
    context?: HttpContext
  ): Observable<boolean> {
    return this.apiMasterCategorySeverityCheckDuplicateForAribraCodeIdGet$Response(params, context).pipe(
      map((r: StrictHttpResponse<boolean>): boolean => r.body)
    );
  }

  /** Path part for operation `apiMasterCategorySeverityCalCalculatedSeverityGet()` */
  static readonly ApiMasterCategorySeverityCalCalculatedSeverityGetPath = '/api/master/categorySeverity/CalCalculatedSeverity';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `apiMasterCategorySeverityCalCalculatedSeverityGet()` instead.
   *
   * This method doesn't expect any request body.
   */
  apiMasterCategorySeverityCalCalculatedSeverityGet$Response(
    params?: {
      severityE?: string;
      severityS?: string;
      severityG?: string;
    },
    context?: HttpContext
  ): Observable<StrictHttpResponse<string>> {
    const rb = new RequestBuilder(this.rootUrl, MasterCategorySeverityService.ApiMasterCategorySeverityCalCalculatedSeverityGetPath, 'get');
    if (params) {
      rb.query('severityE', params.severityE, {});
      rb.query('severityS', params.severityS, {});
      rb.query('severityG', params.severityG, {});
    }

    return this.http.request(
      rb.build({ responseType: 'json', accept: 'application/json', context })
    ).pipe(
      filter((r: any): r is HttpResponse<any> => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<string>;
      })
    );
  }

  /**
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `apiMasterCategorySeverityCalCalculatedSeverityGet$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  apiMasterCategorySeverityCalCalculatedSeverityGet(
    params?: {
      severityE?: string;
      severityS?: string;
      severityG?: string;
    },
    context?: HttpContext
  ): Observable<string> {
    return this.apiMasterCategorySeverityCalCalculatedSeverityGet$Response(params, context).pipe(
      map((r: StrictHttpResponse<string>): string => r.body)
    );
  }

  /** Path part for operation `apiMasterCategorySeverityInitialCriteriaFlagsGet()` */
  static readonly ApiMasterCategorySeverityInitialCriteriaFlagsGetPath = '/api/master/categorySeverity/InitialCriteriaFlags';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `apiMasterCategorySeverityInitialCriteriaFlagsGet()` instead.
   *
   * This method doesn't expect any request body.
   */
  apiMasterCategorySeverityInitialCriteriaFlagsGet$Response(
    params?: {
      workGroupId?: number;
    },
    context?: HttpContext
  ): Observable<StrictHttpResponse<Array<MasterCategoryAndSeverityCriteriaFlagItem>>> {
    const rb = new RequestBuilder(this.rootUrl, MasterCategorySeverityService.ApiMasterCategorySeverityInitialCriteriaFlagsGetPath, 'get');
    if (params) {
      rb.query('workGroupId', params.workGroupId, {});
    }

    return this.http.request(
      rb.build({ responseType: 'json', accept: 'application/json', context })
    ).pipe(
      filter((r: any): r is HttpResponse<any> => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<Array<MasterCategoryAndSeverityCriteriaFlagItem>>;
      })
    );
  }

  /**
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `apiMasterCategorySeverityInitialCriteriaFlagsGet$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  apiMasterCategorySeverityInitialCriteriaFlagsGet(
    params?: {
      workGroupId?: number;
    },
    context?: HttpContext
  ): Observable<Array<MasterCategoryAndSeverityCriteriaFlagItem>> {
    return this.apiMasterCategorySeverityInitialCriteriaFlagsGet$Response(params, context).pipe(
      map((r: StrictHttpResponse<Array<MasterCategoryAndSeverityCriteriaFlagItem>>): Array<MasterCategoryAndSeverityCriteriaFlagItem> => r.body)
    );
  }

  /** Path part for operation `apiMasterCategorySeverityExpireTagGet()` */
  static readonly ApiMasterCategorySeverityExpireTagGetPath = '/api/master/categorySeverity/ExpireTag';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `apiMasterCategorySeverityExpireTagGet()` instead.
   *
   * This method doesn't expect any request body.
   */
  apiMasterCategorySeverityExpireTagGet$Response(
    params?: {
    },
    context?: HttpContext
  ): Observable<StrictHttpResponse<void>> {
    const rb = new RequestBuilder(this.rootUrl, MasterCategorySeverityService.ApiMasterCategorySeverityExpireTagGetPath, 'get');
    if (params) {
    }

    return this.http.request(
      rb.build({ responseType: 'text', accept: '*/*', context })
    ).pipe(
      filter((r: any): r is HttpResponse<any> => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return (r as HttpResponse<any>).clone({ body: undefined }) as StrictHttpResponse<void>;
      })
    );
  }

  /**
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `apiMasterCategorySeverityExpireTagGet$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  apiMasterCategorySeverityExpireTagGet(
    params?: {
    },
    context?: HttpContext
  ): Observable<void> {
    return this.apiMasterCategorySeverityExpireTagGet$Response(params, context).pipe(
      map((r: StrictHttpResponse<void>): void => r.body)
    );
  }

  /** Path part for operation `apiMasterCategorySeverityOperationSchedulerGet()` */
  static readonly ApiMasterCategorySeverityOperationSchedulerGetPath = '/api/master/categorySeverity/OperationScheduler';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `apiMasterCategorySeverityOperationSchedulerGet()` instead.
   *
   * This method doesn't expect any request body.
   */
  apiMasterCategorySeverityOperationSchedulerGet$Response(
    params?: {
      workGroupId?: number;
    },
    context?: HttpContext
  ): Observable<StrictHttpResponse<Array<MasterCategoryAndSeverityOperationSchedule>>> {
    const rb = new RequestBuilder(this.rootUrl, MasterCategorySeverityService.ApiMasterCategorySeverityOperationSchedulerGetPath, 'get');
    if (params) {
      rb.query('workGroupId', params.workGroupId, {});
    }

    return this.http.request(
      rb.build({ responseType: 'json', accept: 'application/json', context })
    ).pipe(
      filter((r: any): r is HttpResponse<any> => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<Array<MasterCategoryAndSeverityOperationSchedule>>;
      })
    );
  }

  /**
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `apiMasterCategorySeverityOperationSchedulerGet$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  apiMasterCategorySeverityOperationSchedulerGet(
    params?: {
      workGroupId?: number;
    },
    context?: HttpContext
  ): Observable<Array<MasterCategoryAndSeverityOperationSchedule>> {
    return this.apiMasterCategorySeverityOperationSchedulerGet$Response(params, context).pipe(
      map((r: StrictHttpResponse<Array<MasterCategoryAndSeverityOperationSchedule>>): Array<MasterCategoryAndSeverityOperationSchedule> => r.body)
    );
  }

}
