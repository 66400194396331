/* tslint:disable */
/* eslint-disable */
import { HttpClient, HttpContext, HttpResponse } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { filter, map } from 'rxjs/operators';

import { BaseService } from '../base-service';
import { ApiConfiguration } from '../api-configuration';
import { StrictHttpResponse } from '../strict-http-response';
import { RequestBuilder } from '../request-builder';

import { ExportExcelFileRes } from '../models/export-excel-file-res';
import { MasterModuleTemplateCriteriaItem } from '../models/master-module-template-criteria-item';
import { MasterModuleTemplateDropdownItem } from '../models/master-module-template-dropdown-item';
import { MasterModuleTemplateItem } from '../models/master-module-template-item';

@Injectable({ providedIn: 'root' })
export class MasterModuleTemplateService extends BaseService {
  constructor(config: ApiConfiguration, http: HttpClient) {
    super(config, http);
  }

  /** Path part for operation `apiMasterModuleTemplateGet()` */
  static readonly ApiMasterModuleTemplateGetPath = '/api/master/ModuleTemplate';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `apiMasterModuleTemplateGet()` instead.
   *
   * This method doesn't expect any request body.
   */
  apiMasterModuleTemplateGet$Response(
    params?: {
      id?: string;
    },
    context?: HttpContext
  ): Observable<StrictHttpResponse<MasterModuleTemplateItem>> {
    const rb = new RequestBuilder(this.rootUrl, MasterModuleTemplateService.ApiMasterModuleTemplateGetPath, 'get');
    if (params) {
      rb.query('id', params.id, {});
    }

    return this.http.request(
      rb.build({ responseType: 'json', accept: 'application/json', context })
    ).pipe(
      filter((r: any): r is HttpResponse<any> => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<MasterModuleTemplateItem>;
      })
    );
  }

  /**
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `apiMasterModuleTemplateGet$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  apiMasterModuleTemplateGet(
    params?: {
      id?: string;
    },
    context?: HttpContext
  ): Observable<MasterModuleTemplateItem> {
    return this.apiMasterModuleTemplateGet$Response(params, context).pipe(
      map((r: StrictHttpResponse<MasterModuleTemplateItem>): MasterModuleTemplateItem => r.body)
    );
  }

  /** Path part for operation `apiMasterModuleTemplatePut()` */
  static readonly ApiMasterModuleTemplatePutPath = '/api/master/ModuleTemplate';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `apiMasterModuleTemplatePut()` instead.
   *
   * This method sends `application/json` and handles request body of type `application/json`.
   */
  apiMasterModuleTemplatePut$Response(
    params?: {
      body?: MasterModuleTemplateItem
    },
    context?: HttpContext
  ): Observable<StrictHttpResponse<MasterModuleTemplateItem>> {
    const rb = new RequestBuilder(this.rootUrl, MasterModuleTemplateService.ApiMasterModuleTemplatePutPath, 'put');
    if (params) {
      rb.body(params.body, 'application/json');
    }

    return this.http.request(
      rb.build({ responseType: 'json', accept: 'application/json', context })
    ).pipe(
      filter((r: any): r is HttpResponse<any> => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<MasterModuleTemplateItem>;
      })
    );
  }

  /**
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `apiMasterModuleTemplatePut$Response()` instead.
   *
   * This method sends `application/json` and handles request body of type `application/json`.
   */
  apiMasterModuleTemplatePut(
    params?: {
      body?: MasterModuleTemplateItem
    },
    context?: HttpContext
  ): Observable<MasterModuleTemplateItem> {
    return this.apiMasterModuleTemplatePut$Response(params, context).pipe(
      map((r: StrictHttpResponse<MasterModuleTemplateItem>): MasterModuleTemplateItem => r.body)
    );
  }

  /** Path part for operation `apiMasterModuleTemplatePost()` */
  static readonly ApiMasterModuleTemplatePostPath = '/api/master/ModuleTemplate';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `apiMasterModuleTemplatePost()` instead.
   *
   * This method sends `application/json` and handles request body of type `application/json`.
   */
  apiMasterModuleTemplatePost$Response(
    params?: {
      body?: MasterModuleTemplateItem
    },
    context?: HttpContext
  ): Observable<StrictHttpResponse<MasterModuleTemplateItem>> {
    const rb = new RequestBuilder(this.rootUrl, MasterModuleTemplateService.ApiMasterModuleTemplatePostPath, 'post');
    if (params) {
      rb.body(params.body, 'application/json');
    }

    return this.http.request(
      rb.build({ responseType: 'json', accept: 'application/json', context })
    ).pipe(
      filter((r: any): r is HttpResponse<any> => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<MasterModuleTemplateItem>;
      })
    );
  }

  /**
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `apiMasterModuleTemplatePost$Response()` instead.
   *
   * This method sends `application/json` and handles request body of type `application/json`.
   */
  apiMasterModuleTemplatePost(
    params?: {
      body?: MasterModuleTemplateItem
    },
    context?: HttpContext
  ): Observable<MasterModuleTemplateItem> {
    return this.apiMasterModuleTemplatePost$Response(params, context).pipe(
      map((r: StrictHttpResponse<MasterModuleTemplateItem>): MasterModuleTemplateItem => r.body)
    );
  }

  /** Path part for operation `apiMasterModuleTemplateDelete()` */
  static readonly ApiMasterModuleTemplateDeletePath = '/api/master/ModuleTemplate';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `apiMasterModuleTemplateDelete()` instead.
   *
   * This method doesn't expect any request body.
   */
  apiMasterModuleTemplateDelete$Response(
    params?: {
      id?: string;
    },
    context?: HttpContext
  ): Observable<StrictHttpResponse<void>> {
    const rb = new RequestBuilder(this.rootUrl, MasterModuleTemplateService.ApiMasterModuleTemplateDeletePath, 'delete');
    if (params) {
      rb.query('id', params.id, {});
    }

    return this.http.request(
      rb.build({ responseType: 'text', accept: '*/*', context })
    ).pipe(
      filter((r: any): r is HttpResponse<any> => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return (r as HttpResponse<any>).clone({ body: undefined }) as StrictHttpResponse<void>;
      })
    );
  }

  /**
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `apiMasterModuleTemplateDelete$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  apiMasterModuleTemplateDelete(
    params?: {
      id?: string;
    },
    context?: HttpContext
  ): Observable<void> {
    return this.apiMasterModuleTemplateDelete$Response(params, context).pipe(
      map((r: StrictHttpResponse<void>): void => r.body)
    );
  }

  /** Path part for operation `apiMasterModuleTemplateAllGet()` */
  static readonly ApiMasterModuleTemplateAllGetPath = '/api/master/ModuleTemplate/All';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `apiMasterModuleTemplateAllGet()` instead.
   *
   * This method doesn't expect any request body.
   */
  apiMasterModuleTemplateAllGet$Response(
    params?: {
    },
    context?: HttpContext
  ): Observable<StrictHttpResponse<Array<MasterModuleTemplateItem>>> {
    const rb = new RequestBuilder(this.rootUrl, MasterModuleTemplateService.ApiMasterModuleTemplateAllGetPath, 'get');
    if (params) {
    }

    return this.http.request(
      rb.build({ responseType: 'json', accept: 'application/json', context })
    ).pipe(
      filter((r: any): r is HttpResponse<any> => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<Array<MasterModuleTemplateItem>>;
      })
    );
  }

  /**
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `apiMasterModuleTemplateAllGet$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  apiMasterModuleTemplateAllGet(
    params?: {
    },
    context?: HttpContext
  ): Observable<Array<MasterModuleTemplateItem>> {
    return this.apiMasterModuleTemplateAllGet$Response(params, context).pipe(
      map((r: StrictHttpResponse<Array<MasterModuleTemplateItem>>): Array<MasterModuleTemplateItem> => r.body)
    );
  }

  /** Path part for operation `apiMasterModuleTemplateMaintainGet()` */
  static readonly ApiMasterModuleTemplateMaintainGetPath = '/api/master/ModuleTemplate/Maintain';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `apiMasterModuleTemplateMaintainGet()` instead.
   *
   * This method doesn't expect any request body.
   */
  apiMasterModuleTemplateMaintainGet$Response(
    params?: {
    },
    context?: HttpContext
  ): Observable<StrictHttpResponse<Array<MasterModuleTemplateItem>>> {
    const rb = new RequestBuilder(this.rootUrl, MasterModuleTemplateService.ApiMasterModuleTemplateMaintainGetPath, 'get');
    if (params) {
    }

    return this.http.request(
      rb.build({ responseType: 'json', accept: 'application/json', context })
    ).pipe(
      filter((r: any): r is HttpResponse<any> => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<Array<MasterModuleTemplateItem>>;
      })
    );
  }

  /**
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `apiMasterModuleTemplateMaintainGet$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  apiMasterModuleTemplateMaintainGet(
    params?: {
    },
    context?: HttpContext
  ): Observable<Array<MasterModuleTemplateItem>> {
    return this.apiMasterModuleTemplateMaintainGet$Response(params, context).pipe(
      map((r: StrictHttpResponse<Array<MasterModuleTemplateItem>>): Array<MasterModuleTemplateItem> => r.body)
    );
  }

  /** Path part for operation `apiMasterModuleTemplateMaintainWithCriteriaPost()` */
  static readonly ApiMasterModuleTemplateMaintainWithCriteriaPostPath = '/api/master/ModuleTemplate/MaintainWithCriteria';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `apiMasterModuleTemplateMaintainWithCriteriaPost()` instead.
   *
   * This method sends `application/json` and handles request body of type `application/json`.
   */
  apiMasterModuleTemplateMaintainWithCriteriaPost$Response(
    params?: {
      body?: MasterModuleTemplateCriteriaItem
    },
    context?: HttpContext
  ): Observable<StrictHttpResponse<Array<MasterModuleTemplateItem>>> {
    const rb = new RequestBuilder(this.rootUrl, MasterModuleTemplateService.ApiMasterModuleTemplateMaintainWithCriteriaPostPath, 'post');
    if (params) {
      rb.body(params.body, 'application/json');
    }

    return this.http.request(
      rb.build({ responseType: 'json', accept: 'application/json', context })
    ).pipe(
      filter((r: any): r is HttpResponse<any> => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<Array<MasterModuleTemplateItem>>;
      })
    );
  }

  /**
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `apiMasterModuleTemplateMaintainWithCriteriaPost$Response()` instead.
   *
   * This method sends `application/json` and handles request body of type `application/json`.
   */
  apiMasterModuleTemplateMaintainWithCriteriaPost(
    params?: {
      body?: MasterModuleTemplateCriteriaItem
    },
    context?: HttpContext
  ): Observable<Array<MasterModuleTemplateItem>> {
    return this.apiMasterModuleTemplateMaintainWithCriteriaPost$Response(params, context).pipe(
      map((r: StrictHttpResponse<Array<MasterModuleTemplateItem>>): Array<MasterModuleTemplateItem> => r.body)
    );
  }

  /** Path part for operation `apiMasterModuleTemplateActiveGet()` */
  static readonly ApiMasterModuleTemplateActiveGetPath = '/api/master/ModuleTemplate/Active';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `apiMasterModuleTemplateActiveGet()` instead.
   *
   * This method doesn't expect any request body.
   */
  apiMasterModuleTemplateActiveGet$Response(
    params?: {
      workgroupId?: number;
    },
    context?: HttpContext
  ): Observable<StrictHttpResponse<Array<MasterModuleTemplateItem>>> {
    const rb = new RequestBuilder(this.rootUrl, MasterModuleTemplateService.ApiMasterModuleTemplateActiveGetPath, 'get');
    if (params) {
      rb.query('workgroupId', params.workgroupId, {});
    }

    return this.http.request(
      rb.build({ responseType: 'json', accept: 'application/json', context })
    ).pipe(
      filter((r: any): r is HttpResponse<any> => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<Array<MasterModuleTemplateItem>>;
      })
    );
  }

  /**
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `apiMasterModuleTemplateActiveGet$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  apiMasterModuleTemplateActiveGet(
    params?: {
      workgroupId?: number;
    },
    context?: HttpContext
  ): Observable<Array<MasterModuleTemplateItem>> {
    return this.apiMasterModuleTemplateActiveGet$Response(params, context).pipe(
      map((r: StrictHttpResponse<Array<MasterModuleTemplateItem>>): Array<MasterModuleTemplateItem> => r.body)
    );
  }

  /** Path part for operation `apiMasterModuleTemplateDropdownSelectionGet()` */
  static readonly ApiMasterModuleTemplateDropdownSelectionGetPath = '/api/master/ModuleTemplate/DropdownSelection';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `apiMasterModuleTemplateDropdownSelectionGet()` instead.
   *
   * This method doesn't expect any request body.
   */
  apiMasterModuleTemplateDropdownSelectionGet$Response(
    params?: {
      workgroupId?: number;
    },
    context?: HttpContext
  ): Observable<StrictHttpResponse<MasterModuleTemplateDropdownItem>> {
    const rb = new RequestBuilder(this.rootUrl, MasterModuleTemplateService.ApiMasterModuleTemplateDropdownSelectionGetPath, 'get');
    if (params) {
      rb.query('workgroupId', params.workgroupId, {});
    }

    return this.http.request(
      rb.build({ responseType: 'json', accept: 'application/json', context })
    ).pipe(
      filter((r: any): r is HttpResponse<any> => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<MasterModuleTemplateDropdownItem>;
      })
    );
  }

  /**
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `apiMasterModuleTemplateDropdownSelectionGet$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  apiMasterModuleTemplateDropdownSelectionGet(
    params?: {
      workgroupId?: number;
    },
    context?: HttpContext
  ): Observable<MasterModuleTemplateDropdownItem> {
    return this.apiMasterModuleTemplateDropdownSelectionGet$Response(params, context).pipe(
      map((r: StrictHttpResponse<MasterModuleTemplateDropdownItem>): MasterModuleTemplateDropdownItem => r.body)
    );
  }

  /** Path part for operation `apiMasterModuleTemplateExportGet()` */
  static readonly ApiMasterModuleTemplateExportGetPath = '/api/master/ModuleTemplate/Export';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `apiMasterModuleTemplateExportGet()` instead.
   *
   * This method doesn't expect any request body.
   */
  apiMasterModuleTemplateExportGet$Response(
    params?: {
      workgroupId?: Array<number>;
    },
    context?: HttpContext
  ): Observable<StrictHttpResponse<ExportExcelFileRes>> {
    const rb = new RequestBuilder(this.rootUrl, MasterModuleTemplateService.ApiMasterModuleTemplateExportGetPath, 'get');
    if (params) {
      rb.query('workgroupId', params.workgroupId, {});
    }

    return this.http.request(
      rb.build({ responseType: 'json', accept: 'application/json', context })
    ).pipe(
      filter((r: any): r is HttpResponse<any> => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<ExportExcelFileRes>;
      })
    );
  }

  /**
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `apiMasterModuleTemplateExportGet$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  apiMasterModuleTemplateExportGet(
    params?: {
      workgroupId?: Array<number>;
    },
    context?: HttpContext
  ): Observable<ExportExcelFileRes> {
    return this.apiMasterModuleTemplateExportGet$Response(params, context).pipe(
      map((r: StrictHttpResponse<ExportExcelFileRes>): ExportExcelFileRes => r.body)
    );
  }

}
