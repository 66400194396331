/* tslint:disable */
/* eslint-disable */
import { HttpClient, HttpContext, HttpResponse } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { filter, map } from 'rxjs/operators';

import { BaseService } from '../base-service';
import { ApiConfiguration } from '../api-configuration';
import { StrictHttpResponse } from '../strict-http-response';
import { RequestBuilder } from '../request-builder';

import { CompanyItem } from '../models/company-item';
import { DropdownItemRes } from '../models/dropdown-item-res';
import { MasterBankThPaymentRes } from '../models/master-bank-th-payment-res';
import { MasterBankThPaymentSearchReq } from '../models/master-bank-th-payment-search-req';
import { MasterBranchBankThRes } from '../models/master-branch-bank-th-res';
import { MasterBranchBankThSearchReq } from '../models/master-branch-bank-th-search-req';
import { MasterCategoryAndSeverityItem } from '../models/master-category-and-severity-item';
import { MasterCountryRes } from '../models/master-country-res';
import { MasterGreenIndustryRes } from '../models/master-green-industry-res';
import { MasterVendorBranchRes } from '../models/master-vendor-branch-res';
import { MasterVendorBusinessStructureRes } from '../models/master-vendor-business-structure-res';
import { MasterVendorCapSizeRes } from '../models/master-vendor-cap-size-res';
import { MasterVendorCompanyTypeRes } from '../models/master-vendor-company-type-res';
import { MasterVendorContactTypeRes } from '../models/master-vendor-contact-type-res';
import { MasterVendorGradeRes } from '../models/master-vendor-grade-res';
import { MasterVendorIsoCertificationRes } from '../models/master-vendor-iso-certification-res';
import { MasterVendorTypeRes } from '../models/master-vendor-type-res';
import { StatusOptRes } from '../models/status-opt-res';
import { VenSchUnspscOptDataRes } from '../models/ven-sch-unspsc-opt-data-res';
import { WorkGroupsRes } from '../models/work-groups-res';

@Injectable({ providedIn: 'root' })
export class MasterVendorService extends BaseService {
  constructor(config: ApiConfiguration, http: HttpClient) {
    super(config, http);
  }

  /** Path part for operation `apiMasterVendorVendorGradeGet()` */
  static readonly ApiMasterVendorVendorGradeGetPath = '/api/masterVendor/VendorGrade';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `apiMasterVendorVendorGradeGet()` instead.
   *
   * This method doesn't expect any request body.
   */
  apiMasterVendorVendorGradeGet$Response(
    params?: {
    },
    context?: HttpContext
  ): Observable<StrictHttpResponse<Array<MasterVendorGradeRes>>> {
    const rb = new RequestBuilder(this.rootUrl, MasterVendorService.ApiMasterVendorVendorGradeGetPath, 'get');
    if (params) {
    }

    return this.http.request(
      rb.build({ responseType: 'json', accept: 'application/json', context })
    ).pipe(
      filter((r: any): r is HttpResponse<any> => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<Array<MasterVendorGradeRes>>;
      })
    );
  }

  /**
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `apiMasterVendorVendorGradeGet$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  apiMasterVendorVendorGradeGet(
    params?: {
    },
    context?: HttpContext
  ): Observable<Array<MasterVendorGradeRes>> {
    return this.apiMasterVendorVendorGradeGet$Response(params, context).pipe(
      map((r: StrictHttpResponse<Array<MasterVendorGradeRes>>): Array<MasterVendorGradeRes> => r.body)
    );
  }

  /** Path part for operation `apiMasterVendorVendorBussinessStructureGet()` */
  static readonly ApiMasterVendorVendorBussinessStructureGetPath = '/api/masterVendor/VendorBussinessStructure';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `apiMasterVendorVendorBussinessStructureGet()` instead.
   *
   * This method doesn't expect any request body.
   */
  apiMasterVendorVendorBussinessStructureGet$Response(
    params?: {
    },
    context?: HttpContext
  ): Observable<StrictHttpResponse<Array<MasterVendorBusinessStructureRes>>> {
    const rb = new RequestBuilder(this.rootUrl, MasterVendorService.ApiMasterVendorVendorBussinessStructureGetPath, 'get');
    if (params) {
    }

    return this.http.request(
      rb.build({ responseType: 'json', accept: 'application/json', context })
    ).pipe(
      filter((r: any): r is HttpResponse<any> => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<Array<MasterVendorBusinessStructureRes>>;
      })
    );
  }

  /**
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `apiMasterVendorVendorBussinessStructureGet$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  apiMasterVendorVendorBussinessStructureGet(
    params?: {
    },
    context?: HttpContext
  ): Observable<Array<MasterVendorBusinessStructureRes>> {
    return this.apiMasterVendorVendorBussinessStructureGet$Response(params, context).pipe(
      map((r: StrictHttpResponse<Array<MasterVendorBusinessStructureRes>>): Array<MasterVendorBusinessStructureRes> => r.body)
    );
  }

  /** Path part for operation `apiMasterVendorVendorCompanyTypeGet()` */
  static readonly ApiMasterVendorVendorCompanyTypeGetPath = '/api/masterVendor/VendorCompanyType';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `apiMasterVendorVendorCompanyTypeGet()` instead.
   *
   * This method doesn't expect any request body.
   */
  apiMasterVendorVendorCompanyTypeGet$Response(
    params?: {
    },
    context?: HttpContext
  ): Observable<StrictHttpResponse<Array<MasterVendorCompanyTypeRes>>> {
    const rb = new RequestBuilder(this.rootUrl, MasterVendorService.ApiMasterVendorVendorCompanyTypeGetPath, 'get');
    if (params) {
    }

    return this.http.request(
      rb.build({ responseType: 'json', accept: 'application/json', context })
    ).pipe(
      filter((r: any): r is HttpResponse<any> => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<Array<MasterVendorCompanyTypeRes>>;
      })
    );
  }

  /**
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `apiMasterVendorVendorCompanyTypeGet$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  apiMasterVendorVendorCompanyTypeGet(
    params?: {
    },
    context?: HttpContext
  ): Observable<Array<MasterVendorCompanyTypeRes>> {
    return this.apiMasterVendorVendorCompanyTypeGet$Response(params, context).pipe(
      map((r: StrictHttpResponse<Array<MasterVendorCompanyTypeRes>>): Array<MasterVendorCompanyTypeRes> => r.body)
    );
  }

  /** Path part for operation `apiMasterVendorVendorTypeGet()` */
  static readonly ApiMasterVendorVendorTypeGetPath = '/api/masterVendor/VendorType';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `apiMasterVendorVendorTypeGet()` instead.
   *
   * This method doesn't expect any request body.
   */
  apiMasterVendorVendorTypeGet$Response(
    params?: {
    },
    context?: HttpContext
  ): Observable<StrictHttpResponse<Array<MasterVendorTypeRes>>> {
    const rb = new RequestBuilder(this.rootUrl, MasterVendorService.ApiMasterVendorVendorTypeGetPath, 'get');
    if (params) {
    }

    return this.http.request(
      rb.build({ responseType: 'json', accept: 'application/json', context })
    ).pipe(
      filter((r: any): r is HttpResponse<any> => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<Array<MasterVendorTypeRes>>;
      })
    );
  }

  /**
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `apiMasterVendorVendorTypeGet$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  apiMasterVendorVendorTypeGet(
    params?: {
    },
    context?: HttpContext
  ): Observable<Array<MasterVendorTypeRes>> {
    return this.apiMasterVendorVendorTypeGet$Response(params, context).pipe(
      map((r: StrictHttpResponse<Array<MasterVendorTypeRes>>): Array<MasterVendorTypeRes> => r.body)
    );
  }

  /** Path part for operation `apiMasterVendorVendorCapSizeGet()` */
  static readonly ApiMasterVendorVendorCapSizeGetPath = '/api/masterVendor/VendorCapSize';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `apiMasterVendorVendorCapSizeGet()` instead.
   *
   * This method doesn't expect any request body.
   */
  apiMasterVendorVendorCapSizeGet$Response(
    params?: {
    },
    context?: HttpContext
  ): Observable<StrictHttpResponse<Array<MasterVendorCapSizeRes>>> {
    const rb = new RequestBuilder(this.rootUrl, MasterVendorService.ApiMasterVendorVendorCapSizeGetPath, 'get');
    if (params) {
    }

    return this.http.request(
      rb.build({ responseType: 'json', accept: 'application/json', context })
    ).pipe(
      filter((r: any): r is HttpResponse<any> => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<Array<MasterVendorCapSizeRes>>;
      })
    );
  }

  /**
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `apiMasterVendorVendorCapSizeGet$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  apiMasterVendorVendorCapSizeGet(
    params?: {
    },
    context?: HttpContext
  ): Observable<Array<MasterVendorCapSizeRes>> {
    return this.apiMasterVendorVendorCapSizeGet$Response(params, context).pipe(
      map((r: StrictHttpResponse<Array<MasterVendorCapSizeRes>>): Array<MasterVendorCapSizeRes> => r.body)
    );
  }

  /** Path part for operation `apiMasterVendorVendorBranchGet()` */
  static readonly ApiMasterVendorVendorBranchGetPath = '/api/masterVendor/VendorBranch';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `apiMasterVendorVendorBranchGet()` instead.
   *
   * This method doesn't expect any request body.
   */
  apiMasterVendorVendorBranchGet$Response(
    params?: {
    },
    context?: HttpContext
  ): Observable<StrictHttpResponse<Array<MasterVendorBranchRes>>> {
    const rb = new RequestBuilder(this.rootUrl, MasterVendorService.ApiMasterVendorVendorBranchGetPath, 'get');
    if (params) {
    }

    return this.http.request(
      rb.build({ responseType: 'json', accept: 'application/json', context })
    ).pipe(
      filter((r: any): r is HttpResponse<any> => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<Array<MasterVendorBranchRes>>;
      })
    );
  }

  /**
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `apiMasterVendorVendorBranchGet$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  apiMasterVendorVendorBranchGet(
    params?: {
    },
    context?: HttpContext
  ): Observable<Array<MasterVendorBranchRes>> {
    return this.apiMasterVendorVendorBranchGet$Response(params, context).pipe(
      map((r: StrictHttpResponse<Array<MasterVendorBranchRes>>): Array<MasterVendorBranchRes> => r.body)
    );
  }

  /** Path part for operation `apiMasterVendorVendorCodeSourceGet()` */
  static readonly ApiMasterVendorVendorCodeSourceGetPath = '/api/masterVendor/VendorCodeSource';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `apiMasterVendorVendorCodeSourceGet()` instead.
   *
   * This method doesn't expect any request body.
   */
  apiMasterVendorVendorCodeSourceGet$Response(
    params?: {
    },
    context?: HttpContext
  ): Observable<StrictHttpResponse<Array<string>>> {
    const rb = new RequestBuilder(this.rootUrl, MasterVendorService.ApiMasterVendorVendorCodeSourceGetPath, 'get');
    if (params) {
    }

    return this.http.request(
      rb.build({ responseType: 'json', accept: 'application/json', context })
    ).pipe(
      filter((r: any): r is HttpResponse<any> => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<Array<string>>;
      })
    );
  }

  /**
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `apiMasterVendorVendorCodeSourceGet$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  apiMasterVendorVendorCodeSourceGet(
    params?: {
    },
    context?: HttpContext
  ): Observable<Array<string>> {
    return this.apiMasterVendorVendorCodeSourceGet$Response(params, context).pipe(
      map((r: StrictHttpResponse<Array<string>>): Array<string> => r.body)
    );
  }

  /** Path part for operation `apiMasterVendorVendorCategoryAndSeverityContainByLevelGet()` */
  static readonly ApiMasterVendorVendorCategoryAndSeverityContainByLevelGetPath = '/api/masterVendor/VendorCategoryAndSeverityContainByLevel';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `apiMasterVendorVendorCategoryAndSeverityContainByLevelGet()` instead.
   *
   * This method doesn't expect any request body.
   */
  apiMasterVendorVendorCategoryAndSeverityContainByLevelGet$Response(
    params?: {
      level?: string;
      containText?: string;
      max?: number;
      fkWorkGroupIds?: Array<number>;
    },
    context?: HttpContext
  ): Observable<StrictHttpResponse<Array<MasterCategoryAndSeverityItem>>> {
    const rb = new RequestBuilder(this.rootUrl, MasterVendorService.ApiMasterVendorVendorCategoryAndSeverityContainByLevelGetPath, 'get');
    if (params) {
      rb.query('level', params.level, {});
      rb.query('containText', params.containText, {});
      rb.query('max', params.max, {});
      rb.query('fkWorkGroupIds', params.fkWorkGroupIds, {});
    }

    return this.http.request(
      rb.build({ responseType: 'json', accept: 'application/json', context })
    ).pipe(
      filter((r: any): r is HttpResponse<any> => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<Array<MasterCategoryAndSeverityItem>>;
      })
    );
  }

  /**
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `apiMasterVendorVendorCategoryAndSeverityContainByLevelGet$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  apiMasterVendorVendorCategoryAndSeverityContainByLevelGet(
    params?: {
      level?: string;
      containText?: string;
      max?: number;
      fkWorkGroupIds?: Array<number>;
    },
    context?: HttpContext
  ): Observable<Array<MasterCategoryAndSeverityItem>> {
    return this.apiMasterVendorVendorCategoryAndSeverityContainByLevelGet$Response(params, context).pipe(
      map((r: StrictHttpResponse<Array<MasterCategoryAndSeverityItem>>): Array<MasterCategoryAndSeverityItem> => r.body)
    );
  }

  /** Path part for operation `apiMasterVendorVendorPlantGet()` */
  static readonly ApiMasterVendorVendorPlantGetPath = '/api/masterVendor/VendorPlant';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `apiMasterVendorVendorPlantGet()` instead.
   *
   * This method doesn't expect any request body.
   */
  apiMasterVendorVendorPlantGet$Response(
    params?: {
    },
    context?: HttpContext
  ): Observable<StrictHttpResponse<Array<CompanyItem>>> {
    const rb = new RequestBuilder(this.rootUrl, MasterVendorService.ApiMasterVendorVendorPlantGetPath, 'get');
    if (params) {
    }

    return this.http.request(
      rb.build({ responseType: 'json', accept: 'application/json', context })
    ).pipe(
      filter((r: any): r is HttpResponse<any> => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<Array<CompanyItem>>;
      })
    );
  }

  /**
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `apiMasterVendorVendorPlantGet$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  apiMasterVendorVendorPlantGet(
    params?: {
    },
    context?: HttpContext
  ): Observable<Array<CompanyItem>> {
    return this.apiMasterVendorVendorPlantGet$Response(params, context).pipe(
      map((r: StrictHttpResponse<Array<CompanyItem>>): Array<CompanyItem> => r.body)
    );
  }

  /** Path part for operation `apiMasterVendorVendorContactTypeGet()` */
  static readonly ApiMasterVendorVendorContactTypeGetPath = '/api/masterVendor/VendorContactType';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `apiMasterVendorVendorContactTypeGet()` instead.
   *
   * This method doesn't expect any request body.
   */
  apiMasterVendorVendorContactTypeGet$Response(
    params?: {
    },
    context?: HttpContext
  ): Observable<StrictHttpResponse<Array<MasterVendorContactTypeRes>>> {
    const rb = new RequestBuilder(this.rootUrl, MasterVendorService.ApiMasterVendorVendorContactTypeGetPath, 'get');
    if (params) {
    }

    return this.http.request(
      rb.build({ responseType: 'json', accept: 'application/json', context })
    ).pipe(
      filter((r: any): r is HttpResponse<any> => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<Array<MasterVendorContactTypeRes>>;
      })
    );
  }

  /**
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `apiMasterVendorVendorContactTypeGet$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  apiMasterVendorVendorContactTypeGet(
    params?: {
    },
    context?: HttpContext
  ): Observable<Array<MasterVendorContactTypeRes>> {
    return this.apiMasterVendorVendorContactTypeGet$Response(params, context).pipe(
      map((r: StrictHttpResponse<Array<MasterVendorContactTypeRes>>): Array<MasterVendorContactTypeRes> => r.body)
    );
  }

  /** Path part for operation `apiMasterVendorVendorCountryGet()` */
  static readonly ApiMasterVendorVendorCountryGetPath = '/api/masterVendor/VendorCountry';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `apiMasterVendorVendorCountryGet()` instead.
   *
   * This method doesn't expect any request body.
   */
  apiMasterVendorVendorCountryGet$Response(
    params?: {
    },
    context?: HttpContext
  ): Observable<StrictHttpResponse<Array<MasterCountryRes>>> {
    const rb = new RequestBuilder(this.rootUrl, MasterVendorService.ApiMasterVendorVendorCountryGetPath, 'get');
    if (params) {
    }

    return this.http.request(
      rb.build({ responseType: 'json', accept: 'application/json', context })
    ).pipe(
      filter((r: any): r is HttpResponse<any> => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<Array<MasterCountryRes>>;
      })
    );
  }

  /**
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `apiMasterVendorVendorCountryGet$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  apiMasterVendorVendorCountryGet(
    params?: {
    },
    context?: HttpContext
  ): Observable<Array<MasterCountryRes>> {
    return this.apiMasterVendorVendorCountryGet$Response(params, context).pipe(
      map((r: StrictHttpResponse<Array<MasterCountryRes>>): Array<MasterCountryRes> => r.body)
    );
  }

  /** Path part for operation `apiMasterVendorBankPaymentGet()` */
  static readonly ApiMasterVendorBankPaymentGetPath = '/api/masterVendor/bank-payment';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `apiMasterVendorBankPaymentGet()` instead.
   *
   * This method doesn't expect any request body.
   */
  apiMasterVendorBankPaymentGet$Response(
    params?: {
    },
    context?: HttpContext
  ): Observable<StrictHttpResponse<Array<MasterBankThPaymentRes>>> {
    const rb = new RequestBuilder(this.rootUrl, MasterVendorService.ApiMasterVendorBankPaymentGetPath, 'get');
    if (params) {
    }

    return this.http.request(
      rb.build({ responseType: 'json', accept: 'application/json', context })
    ).pipe(
      filter((r: any): r is HttpResponse<any> => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<Array<MasterBankThPaymentRes>>;
      })
    );
  }

  /**
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `apiMasterVendorBankPaymentGet$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  apiMasterVendorBankPaymentGet(
    params?: {
    },
    context?: HttpContext
  ): Observable<Array<MasterBankThPaymentRes>> {
    return this.apiMasterVendorBankPaymentGet$Response(params, context).pipe(
      map((r: StrictHttpResponse<Array<MasterBankThPaymentRes>>): Array<MasterBankThPaymentRes> => r.body)
    );
  }

  /** Path part for operation `apiMasterVendorBankPaymentSearchPost()` */
  static readonly ApiMasterVendorBankPaymentSearchPostPath = '/api/masterVendor/bank-payment-search';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `apiMasterVendorBankPaymentSearchPost()` instead.
   *
   * This method sends `application/json` and handles request body of type `application/json`.
   */
  apiMasterVendorBankPaymentSearchPost$Response(
    params?: {
      body?: MasterBankThPaymentSearchReq
    },
    context?: HttpContext
  ): Observable<StrictHttpResponse<Array<MasterBankThPaymentRes>>> {
    const rb = new RequestBuilder(this.rootUrl, MasterVendorService.ApiMasterVendorBankPaymentSearchPostPath, 'post');
    if (params) {
      rb.body(params.body, 'application/json');
    }

    return this.http.request(
      rb.build({ responseType: 'json', accept: 'application/json', context })
    ).pipe(
      filter((r: any): r is HttpResponse<any> => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<Array<MasterBankThPaymentRes>>;
      })
    );
  }

  /**
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `apiMasterVendorBankPaymentSearchPost$Response()` instead.
   *
   * This method sends `application/json` and handles request body of type `application/json`.
   */
  apiMasterVendorBankPaymentSearchPost(
    params?: {
      body?: MasterBankThPaymentSearchReq
    },
    context?: HttpContext
  ): Observable<Array<MasterBankThPaymentRes>> {
    return this.apiMasterVendorBankPaymentSearchPost$Response(params, context).pipe(
      map((r: StrictHttpResponse<Array<MasterBankThPaymentRes>>): Array<MasterBankThPaymentRes> => r.body)
    );
  }

  /** Path part for operation `apiMasterVendorBranchBankGet()` */
  static readonly ApiMasterVendorBranchBankGetPath = '/api/masterVendor/branch-bank';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `apiMasterVendorBranchBankGet()` instead.
   *
   * This method doesn't expect any request body.
   */
  apiMasterVendorBranchBankGet$Response(
    params?: {
    },
    context?: HttpContext
  ): Observable<StrictHttpResponse<Array<MasterBranchBankThRes>>> {
    const rb = new RequestBuilder(this.rootUrl, MasterVendorService.ApiMasterVendorBranchBankGetPath, 'get');
    if (params) {
    }

    return this.http.request(
      rb.build({ responseType: 'json', accept: 'application/json', context })
    ).pipe(
      filter((r: any): r is HttpResponse<any> => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<Array<MasterBranchBankThRes>>;
      })
    );
  }

  /**
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `apiMasterVendorBranchBankGet$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  apiMasterVendorBranchBankGet(
    params?: {
    },
    context?: HttpContext
  ): Observable<Array<MasterBranchBankThRes>> {
    return this.apiMasterVendorBranchBankGet$Response(params, context).pipe(
      map((r: StrictHttpResponse<Array<MasterBranchBankThRes>>): Array<MasterBranchBankThRes> => r.body)
    );
  }

  /** Path part for operation `apiMasterVendorBranchBankSearchPost()` */
  static readonly ApiMasterVendorBranchBankSearchPostPath = '/api/masterVendor/branch-bank-search';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `apiMasterVendorBranchBankSearchPost()` instead.
   *
   * This method sends `application/json` and handles request body of type `application/json`.
   */
  apiMasterVendorBranchBankSearchPost$Response(
    params?: {
      body?: MasterBranchBankThSearchReq
    },
    context?: HttpContext
  ): Observable<StrictHttpResponse<Array<MasterBranchBankThRes>>> {
    const rb = new RequestBuilder(this.rootUrl, MasterVendorService.ApiMasterVendorBranchBankSearchPostPath, 'post');
    if (params) {
      rb.body(params.body, 'application/json');
    }

    return this.http.request(
      rb.build({ responseType: 'json', accept: 'application/json', context })
    ).pipe(
      filter((r: any): r is HttpResponse<any> => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<Array<MasterBranchBankThRes>>;
      })
    );
  }

  /**
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `apiMasterVendorBranchBankSearchPost$Response()` instead.
   *
   * This method sends `application/json` and handles request body of type `application/json`.
   */
  apiMasterVendorBranchBankSearchPost(
    params?: {
      body?: MasterBranchBankThSearchReq
    },
    context?: HttpContext
  ): Observable<Array<MasterBranchBankThRes>> {
    return this.apiMasterVendorBranchBankSearchPost$Response(params, context).pipe(
      map((r: StrictHttpResponse<Array<MasterBranchBankThRes>>): Array<MasterBranchBankThRes> => r.body)
    );
  }

  /** Path part for operation `apiMasterVendorGreenIndustryGet()` */
  static readonly ApiMasterVendorGreenIndustryGetPath = '/api/masterVendor/green-industry';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `apiMasterVendorGreenIndustryGet()` instead.
   *
   * This method doesn't expect any request body.
   */
  apiMasterVendorGreenIndustryGet$Response(
    params?: {
    },
    context?: HttpContext
  ): Observable<StrictHttpResponse<Array<MasterGreenIndustryRes>>> {
    const rb = new RequestBuilder(this.rootUrl, MasterVendorService.ApiMasterVendorGreenIndustryGetPath, 'get');
    if (params) {
    }

    return this.http.request(
      rb.build({ responseType: 'json', accept: 'application/json', context })
    ).pipe(
      filter((r: any): r is HttpResponse<any> => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<Array<MasterGreenIndustryRes>>;
      })
    );
  }

  /**
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `apiMasterVendorGreenIndustryGet$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  apiMasterVendorGreenIndustryGet(
    params?: {
    },
    context?: HttpContext
  ): Observable<Array<MasterGreenIndustryRes>> {
    return this.apiMasterVendorGreenIndustryGet$Response(params, context).pipe(
      map((r: StrictHttpResponse<Array<MasterGreenIndustryRes>>): Array<MasterGreenIndustryRes> => r.body)
    );
  }

  /** Path part for operation `apiMasterVendorCertificateTypeGet()` */
  static readonly ApiMasterVendorCertificateTypeGetPath = '/api/masterVendor/certificate-type';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `apiMasterVendorCertificateTypeGet()` instead.
   *
   * This method doesn't expect any request body.
   */
  apiMasterVendorCertificateTypeGet$Response(
    params?: {
    },
    context?: HttpContext
  ): Observable<StrictHttpResponse<Array<MasterVendorIsoCertificationRes>>> {
    const rb = new RequestBuilder(this.rootUrl, MasterVendorService.ApiMasterVendorCertificateTypeGetPath, 'get');
    if (params) {
    }

    return this.http.request(
      rb.build({ responseType: 'json', accept: 'application/json', context })
    ).pipe(
      filter((r: any): r is HttpResponse<any> => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<Array<MasterVendorIsoCertificationRes>>;
      })
    );
  }

  /**
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `apiMasterVendorCertificateTypeGet$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  apiMasterVendorCertificateTypeGet(
    params?: {
    },
    context?: HttpContext
  ): Observable<Array<MasterVendorIsoCertificationRes>> {
    return this.apiMasterVendorCertificateTypeGet$Response(params, context).pipe(
      map((r: StrictHttpResponse<Array<MasterVendorIsoCertificationRes>>): Array<MasterVendorIsoCertificationRes> => r.body)
    );
  }

  /** Path part for operation `apiMasterVendorUnspscContainGet()` */
  static readonly ApiMasterVendorUnspscContainGetPath = '/api/masterVendor/unspsc-contain';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `apiMasterVendorUnspscContainGet()` instead.
   *
   * This method doesn't expect any request body.
   */
  apiMasterVendorUnspscContainGet$Response(
    params?: {
      keyword?: string;
      max?: number;
      workGroupIds?: Array<number>;
    },
    context?: HttpContext
  ): Observable<StrictHttpResponse<Array<VenSchUnspscOptDataRes>>> {
    const rb = new RequestBuilder(this.rootUrl, MasterVendorService.ApiMasterVendorUnspscContainGetPath, 'get');
    if (params) {
      rb.query('keyword', params.keyword, {});
      rb.query('max', params.max, {});
      rb.query('workGroupIds', params.workGroupIds, {});
    }

    return this.http.request(
      rb.build({ responseType: 'json', accept: 'application/json', context })
    ).pipe(
      filter((r: any): r is HttpResponse<any> => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<Array<VenSchUnspscOptDataRes>>;
      })
    );
  }

  /**
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `apiMasterVendorUnspscContainGet$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  apiMasterVendorUnspscContainGet(
    params?: {
      keyword?: string;
      max?: number;
      workGroupIds?: Array<number>;
    },
    context?: HttpContext
  ): Observable<Array<VenSchUnspscOptDataRes>> {
    return this.apiMasterVendorUnspscContainGet$Response(params, context).pipe(
      map((r: StrictHttpResponse<Array<VenSchUnspscOptDataRes>>): Array<VenSchUnspscOptDataRes> => r.body)
    );
  }

  /** Path part for operation `apiMasterVendorUnspscAribaCodeIdGet()` */
  static readonly ApiMasterVendorUnspscAribaCodeIdGetPath = '/api/masterVendor/unspsc-aribaCodeId';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `apiMasterVendorUnspscAribaCodeIdGet()` instead.
   *
   * This method doesn't expect any request body.
   */
  apiMasterVendorUnspscAribaCodeIdGet$Response(
    params?: {
      aribaCodeId?: string;
      workGroupIds?: Array<number>;
    },
    context?: HttpContext
  ): Observable<StrictHttpResponse<Array<VenSchUnspscOptDataRes>>> {
    const rb = new RequestBuilder(this.rootUrl, MasterVendorService.ApiMasterVendorUnspscAribaCodeIdGetPath, 'get');
    if (params) {
      rb.query('aribaCodeId', params.aribaCodeId, {});
      rb.query('workGroupIds', params.workGroupIds, {});
    }

    return this.http.request(
      rb.build({ responseType: 'json', accept: 'application/json', context })
    ).pipe(
      filter((r: any): r is HttpResponse<any> => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<Array<VenSchUnspscOptDataRes>>;
      })
    );
  }

  /**
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `apiMasterVendorUnspscAribaCodeIdGet$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  apiMasterVendorUnspscAribaCodeIdGet(
    params?: {
      aribaCodeId?: string;
      workGroupIds?: Array<number>;
    },
    context?: HttpContext
  ): Observable<Array<VenSchUnspscOptDataRes>> {
    return this.apiMasterVendorUnspscAribaCodeIdGet$Response(params, context).pipe(
      map((r: StrictHttpResponse<Array<VenSchUnspscOptDataRes>>): Array<VenSchUnspscOptDataRes> => r.body)
    );
  }

  /** Path part for operation `apiMasterVendorProvinceThaiGet()` */
  static readonly ApiMasterVendorProvinceThaiGetPath = '/api/masterVendor/province-thai';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `apiMasterVendorProvinceThaiGet()` instead.
   *
   * This method doesn't expect any request body.
   */
  apiMasterVendorProvinceThaiGet$Response(
    params?: {
    },
    context?: HttpContext
  ): Observable<StrictHttpResponse<Array<DropdownItemRes>>> {
    const rb = new RequestBuilder(this.rootUrl, MasterVendorService.ApiMasterVendorProvinceThaiGetPath, 'get');
    if (params) {
    }

    return this.http.request(
      rb.build({ responseType: 'json', accept: 'application/json', context })
    ).pipe(
      filter((r: any): r is HttpResponse<any> => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<Array<DropdownItemRes>>;
      })
    );
  }

  /**
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `apiMasterVendorProvinceThaiGet$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  apiMasterVendorProvinceThaiGet(
    params?: {
    },
    context?: HttpContext
  ): Observable<Array<DropdownItemRes>> {
    return this.apiMasterVendorProvinceThaiGet$Response(params, context).pipe(
      map((r: StrictHttpResponse<Array<DropdownItemRes>>): Array<DropdownItemRes> => r.body)
    );
  }

  /** Path part for operation `apiMasterVendorGetStatusOptionGet()` */
  static readonly ApiMasterVendorGetStatusOptionGetPath = '/api/masterVendor/get-status-option';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `apiMasterVendorGetStatusOptionGet()` instead.
   *
   * This method doesn't expect any request body.
   */
  apiMasterVendorGetStatusOptionGet$Response(
    params?: {
    },
    context?: HttpContext
  ): Observable<StrictHttpResponse<StatusOptRes>> {
    const rb = new RequestBuilder(this.rootUrl, MasterVendorService.ApiMasterVendorGetStatusOptionGetPath, 'get');
    if (params) {
    }

    return this.http.request(
      rb.build({ responseType: 'json', accept: 'application/json', context })
    ).pipe(
      filter((r: any): r is HttpResponse<any> => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<StatusOptRes>;
      })
    );
  }

  /**
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `apiMasterVendorGetStatusOptionGet$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  apiMasterVendorGetStatusOptionGet(
    params?: {
    },
    context?: HttpContext
  ): Observable<StatusOptRes> {
    return this.apiMasterVendorGetStatusOptionGet$Response(params, context).pipe(
      map((r: StrictHttpResponse<StatusOptRes>): StatusOptRes => r.body)
    );
  }

  /** Path part for operation `apiMasterVendorWorkGroupGet()` */
  static readonly ApiMasterVendorWorkGroupGetPath = '/api/masterVendor/workGroup';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `apiMasterVendorWorkGroupGet()` instead.
   *
   * This method doesn't expect any request body.
   */
  apiMasterVendorWorkGroupGet$Response(
    params?: {
    },
    context?: HttpContext
  ): Observable<StrictHttpResponse<Array<WorkGroupsRes>>> {
    const rb = new RequestBuilder(this.rootUrl, MasterVendorService.ApiMasterVendorWorkGroupGetPath, 'get');
    if (params) {
    }

    return this.http.request(
      rb.build({ responseType: 'json', accept: 'application/json', context })
    ).pipe(
      filter((r: any): r is HttpResponse<any> => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<Array<WorkGroupsRes>>;
      })
    );
  }

  /**
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `apiMasterVendorWorkGroupGet$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  apiMasterVendorWorkGroupGet(
    params?: {
    },
    context?: HttpContext
  ): Observable<Array<WorkGroupsRes>> {
    return this.apiMasterVendorWorkGroupGet$Response(params, context).pipe(
      map((r: StrictHttpResponse<Array<WorkGroupsRes>>): Array<WorkGroupsRes> => r.body)
    );
  }

}
