/* tslint:disable */
/* eslint-disable */
import { HttpClient, HttpContext, HttpResponse } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { filter, map } from 'rxjs/operators';

import { BaseService } from '../base-service';
import { ApiConfiguration } from '../api-configuration';
import { StrictHttpResponse } from '../strict-http-response';
import { RequestBuilder } from '../request-builder';


@Injectable({ providedIn: 'root' })
export class PostEvaluationTaskService extends BaseService {
  constructor(config: ApiConfiguration, http: HttpClient) {
    super(config, http);
  }

  /** Path part for operation `apiPostEvaluationTaskAutoSkipUserEvaluateGet()` */
  static readonly ApiPostEvaluationTaskAutoSkipUserEvaluateGetPath = '/api/post-evaluation-task/auto-skip-user-evaluate';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `apiPostEvaluationTaskAutoSkipUserEvaluateGet()` instead.
   *
   * This method doesn't expect any request body.
   */
  apiPostEvaluationTaskAutoSkipUserEvaluateGet$Response(
    params?: {
      executeDate?: string;
      workGroupId?: number;
    },
    context?: HttpContext
  ): Observable<StrictHttpResponse<boolean>> {
    const rb = new RequestBuilder(this.rootUrl, PostEvaluationTaskService.ApiPostEvaluationTaskAutoSkipUserEvaluateGetPath, 'get');
    if (params) {
      rb.query('executeDate', params.executeDate, {});
      rb.query('workGroupId', params.workGroupId, {});
    }

    return this.http.request(
      rb.build({ responseType: 'json', accept: 'application/json', context })
    ).pipe(
      filter((r: any): r is HttpResponse<any> => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return (r as HttpResponse<any>).clone({ body: String((r as HttpResponse<any>).body) === 'true' }) as StrictHttpResponse<boolean>;
      })
    );
  }

  /**
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `apiPostEvaluationTaskAutoSkipUserEvaluateGet$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  apiPostEvaluationTaskAutoSkipUserEvaluateGet(
    params?: {
      executeDate?: string;
      workGroupId?: number;
    },
    context?: HttpContext
  ): Observable<boolean> {
    return this.apiPostEvaluationTaskAutoSkipUserEvaluateGet$Response(params, context).pipe(
      map((r: StrictHttpResponse<boolean>): boolean => r.body)
    );
  }

  /** Path part for operation `apiPostEvaluationTaskEmailFollowUpGet()` */
  static readonly ApiPostEvaluationTaskEmailFollowUpGetPath = '/api/post-evaluation-task/email-follow-up';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `apiPostEvaluationTaskEmailFollowUpGet()` instead.
   *
   * This method doesn't expect any request body.
   */
  apiPostEvaluationTaskEmailFollowUpGet$Response(
    params?: {
      executeDate?: string;
      workGroupId?: number;
    },
    context?: HttpContext
  ): Observable<StrictHttpResponse<boolean>> {
    const rb = new RequestBuilder(this.rootUrl, PostEvaluationTaskService.ApiPostEvaluationTaskEmailFollowUpGetPath, 'get');
    if (params) {
      rb.query('executeDate', params.executeDate, {});
      rb.query('workGroupId', params.workGroupId, {});
    }

    return this.http.request(
      rb.build({ responseType: 'json', accept: 'application/json', context })
    ).pipe(
      filter((r: any): r is HttpResponse<any> => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return (r as HttpResponse<any>).clone({ body: String((r as HttpResponse<any>).body) === 'true' }) as StrictHttpResponse<boolean>;
      })
    );
  }

  /**
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `apiPostEvaluationTaskEmailFollowUpGet$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  apiPostEvaluationTaskEmailFollowUpGet(
    params?: {
      executeDate?: string;
      workGroupId?: number;
    },
    context?: HttpContext
  ): Observable<boolean> {
    return this.apiPostEvaluationTaskEmailFollowUpGet$Response(params, context).pipe(
      map((r: StrictHttpResponse<boolean>): boolean => r.body)
    );
  }

  /** Path part for operation `apiPostEvaluationTaskAutoCreatePePoDataHighGet()` */
  static readonly ApiPostEvaluationTaskAutoCreatePePoDataHighGetPath = '/api/post-evaluation-task/auto-create-pe-po-data-high';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `apiPostEvaluationTaskAutoCreatePePoDataHighGet()` instead.
   *
   * This method doesn't expect any request body.
   */
  apiPostEvaluationTaskAutoCreatePePoDataHighGet$Response(
    params?: {
      executeDate?: string;
      workGroupId?: number;
    },
    context?: HttpContext
  ): Observable<StrictHttpResponse<boolean>> {
    const rb = new RequestBuilder(this.rootUrl, PostEvaluationTaskService.ApiPostEvaluationTaskAutoCreatePePoDataHighGetPath, 'get');
    if (params) {
      rb.query('executeDate', params.executeDate, {});
      rb.query('workGroupId', params.workGroupId, {});
    }

    return this.http.request(
      rb.build({ responseType: 'json', accept: 'application/json', context })
    ).pipe(
      filter((r: any): r is HttpResponse<any> => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return (r as HttpResponse<any>).clone({ body: String((r as HttpResponse<any>).body) === 'true' }) as StrictHttpResponse<boolean>;
      })
    );
  }

  /**
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `apiPostEvaluationTaskAutoCreatePePoDataHighGet$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  apiPostEvaluationTaskAutoCreatePePoDataHighGet(
    params?: {
      executeDate?: string;
      workGroupId?: number;
    },
    context?: HttpContext
  ): Observable<boolean> {
    return this.apiPostEvaluationTaskAutoCreatePePoDataHighGet$Response(params, context).pipe(
      map((r: StrictHttpResponse<boolean>): boolean => r.body)
    );
  }

  /** Path part for operation `apiPostEvaluationTaskAutoCreatePePoDataHighStartEndGet()` */
  static readonly ApiPostEvaluationTaskAutoCreatePePoDataHighStartEndGetPath = '/api/post-evaluation-task/auto-create-pe-po-data-high-start-end';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `apiPostEvaluationTaskAutoCreatePePoDataHighStartEndGet()` instead.
   *
   * This method doesn't expect any request body.
   */
  apiPostEvaluationTaskAutoCreatePePoDataHighStartEndGet$Response(
    params?: {
      startDate?: string;
      endDate?: string;
      workGroupId?: number;
    },
    context?: HttpContext
  ): Observable<StrictHttpResponse<boolean>> {
    const rb = new RequestBuilder(this.rootUrl, PostEvaluationTaskService.ApiPostEvaluationTaskAutoCreatePePoDataHighStartEndGetPath, 'get');
    if (params) {
      rb.query('startDate', params.startDate, {});
      rb.query('endDate', params.endDate, {});
      rb.query('workGroupId', params.workGroupId, {});
    }

    return this.http.request(
      rb.build({ responseType: 'json', accept: 'application/json', context })
    ).pipe(
      filter((r: any): r is HttpResponse<any> => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return (r as HttpResponse<any>).clone({ body: String((r as HttpResponse<any>).body) === 'true' }) as StrictHttpResponse<boolean>;
      })
    );
  }

  /**
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `apiPostEvaluationTaskAutoCreatePePoDataHighStartEndGet$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  apiPostEvaluationTaskAutoCreatePePoDataHighStartEndGet(
    params?: {
      startDate?: string;
      endDate?: string;
      workGroupId?: number;
    },
    context?: HttpContext
  ): Observable<boolean> {
    return this.apiPostEvaluationTaskAutoCreatePePoDataHighStartEndGet$Response(params, context).pipe(
      map((r: StrictHttpResponse<boolean>): boolean => r.body)
    );
  }

  /** Path part for operation `apiPostEvaluationTaskAutoCreatePePoDataLowMediumGet()` */
  static readonly ApiPostEvaluationTaskAutoCreatePePoDataLowMediumGetPath = '/api/post-evaluation-task/auto-create-pe-po-data-low-medium';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `apiPostEvaluationTaskAutoCreatePePoDataLowMediumGet()` instead.
   *
   * This method doesn't expect any request body.
   */
  apiPostEvaluationTaskAutoCreatePePoDataLowMediumGet$Response(
    params?: {
      executeDate?: string;
      workGroupId?: number;
    },
    context?: HttpContext
  ): Observable<StrictHttpResponse<boolean>> {
    const rb = new RequestBuilder(this.rootUrl, PostEvaluationTaskService.ApiPostEvaluationTaskAutoCreatePePoDataLowMediumGetPath, 'get');
    if (params) {
      rb.query('executeDate', params.executeDate, {});
      rb.query('workGroupId', params.workGroupId, {});
    }

    return this.http.request(
      rb.build({ responseType: 'json', accept: 'application/json', context })
    ).pipe(
      filter((r: any): r is HttpResponse<any> => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return (r as HttpResponse<any>).clone({ body: String((r as HttpResponse<any>).body) === 'true' }) as StrictHttpResponse<boolean>;
      })
    );
  }

  /**
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `apiPostEvaluationTaskAutoCreatePePoDataLowMediumGet$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  apiPostEvaluationTaskAutoCreatePePoDataLowMediumGet(
    params?: {
      executeDate?: string;
      workGroupId?: number;
    },
    context?: HttpContext
  ): Observable<boolean> {
    return this.apiPostEvaluationTaskAutoCreatePePoDataLowMediumGet$Response(params, context).pipe(
      map((r: StrictHttpResponse<boolean>): boolean => r.body)
    );
  }

  /** Path part for operation `apiPostEvaluationTaskAutoCreatePePoDataHighWebcallGet()` */
  static readonly ApiPostEvaluationTaskAutoCreatePePoDataHighWebcallGetPath = '/api/post-evaluation-task/auto-create-pe-po-data-high-webcall';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `apiPostEvaluationTaskAutoCreatePePoDataHighWebcallGet()` instead.
   *
   * This method doesn't expect any request body.
   */
  apiPostEvaluationTaskAutoCreatePePoDataHighWebcallGet$Response(
    params?: {
      appKeys?: string;
    },
    context?: HttpContext
  ): Observable<StrictHttpResponse<boolean>> {
    const rb = new RequestBuilder(this.rootUrl, PostEvaluationTaskService.ApiPostEvaluationTaskAutoCreatePePoDataHighWebcallGetPath, 'get');
    if (params) {
      rb.query('appKeys', params.appKeys, {});
    }

    return this.http.request(
      rb.build({ responseType: 'json', accept: 'application/json', context })
    ).pipe(
      filter((r: any): r is HttpResponse<any> => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return (r as HttpResponse<any>).clone({ body: String((r as HttpResponse<any>).body) === 'true' }) as StrictHttpResponse<boolean>;
      })
    );
  }

  /**
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `apiPostEvaluationTaskAutoCreatePePoDataHighWebcallGet$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  apiPostEvaluationTaskAutoCreatePePoDataHighWebcallGet(
    params?: {
      appKeys?: string;
    },
    context?: HttpContext
  ): Observable<boolean> {
    return this.apiPostEvaluationTaskAutoCreatePePoDataHighWebcallGet$Response(params, context).pipe(
      map((r: StrictHttpResponse<boolean>): boolean => r.body)
    );
  }

  /** Path part for operation `apiPostEvaluationTaskAutoCreatePePoDataLowMediumWebcallGet()` */
  static readonly ApiPostEvaluationTaskAutoCreatePePoDataLowMediumWebcallGetPath = '/api/post-evaluation-task/auto-create-pe-po-data-low-medium-webcall';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `apiPostEvaluationTaskAutoCreatePePoDataLowMediumWebcallGet()` instead.
   *
   * This method doesn't expect any request body.
   */
  apiPostEvaluationTaskAutoCreatePePoDataLowMediumWebcallGet$Response(
    params?: {
      appKeys?: string;
    },
    context?: HttpContext
  ): Observable<StrictHttpResponse<boolean>> {
    const rb = new RequestBuilder(this.rootUrl, PostEvaluationTaskService.ApiPostEvaluationTaskAutoCreatePePoDataLowMediumWebcallGetPath, 'get');
    if (params) {
      rb.query('appKeys', params.appKeys, {});
    }

    return this.http.request(
      rb.build({ responseType: 'json', accept: 'application/json', context })
    ).pipe(
      filter((r: any): r is HttpResponse<any> => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return (r as HttpResponse<any>).clone({ body: String((r as HttpResponse<any>).body) === 'true' }) as StrictHttpResponse<boolean>;
      })
    );
  }

  /**
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `apiPostEvaluationTaskAutoCreatePePoDataLowMediumWebcallGet$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  apiPostEvaluationTaskAutoCreatePePoDataLowMediumWebcallGet(
    params?: {
      appKeys?: string;
    },
    context?: HttpContext
  ): Observable<boolean> {
    return this.apiPostEvaluationTaskAutoCreatePePoDataLowMediumWebcallGet$Response(params, context).pipe(
      map((r: StrictHttpResponse<boolean>): boolean => r.body)
    );
  }

  /** Path part for operation `apiPostEvaluationTaskUpdateCurrentGradeGet()` */
  static readonly ApiPostEvaluationTaskUpdateCurrentGradeGetPath = '/api/post-evaluation-task/update-current-grade';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `apiPostEvaluationTaskUpdateCurrentGradeGet()` instead.
   *
   * This method doesn't expect any request body.
   */
  apiPostEvaluationTaskUpdateCurrentGradeGet$Response(
    params?: {
      executeDate?: string;
      workGroupId?: number;
    },
    context?: HttpContext
  ): Observable<StrictHttpResponse<boolean>> {
    const rb = new RequestBuilder(this.rootUrl, PostEvaluationTaskService.ApiPostEvaluationTaskUpdateCurrentGradeGetPath, 'get');
    if (params) {
      rb.query('executeDate', params.executeDate, {});
      rb.query('workGroupId', params.workGroupId, {});
    }

    return this.http.request(
      rb.build({ responseType: 'json', accept: 'application/json', context })
    ).pipe(
      filter((r: any): r is HttpResponse<any> => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return (r as HttpResponse<any>).clone({ body: String((r as HttpResponse<any>).body) === 'true' }) as StrictHttpResponse<boolean>;
      })
    );
  }

  /**
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `apiPostEvaluationTaskUpdateCurrentGradeGet$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  apiPostEvaluationTaskUpdateCurrentGradeGet(
    params?: {
      executeDate?: string;
      workGroupId?: number;
    },
    context?: HttpContext
  ): Observable<boolean> {
    return this.apiPostEvaluationTaskUpdateCurrentGradeGet$Response(params, context).pipe(
      map((r: StrictHttpResponse<boolean>): boolean => r.body)
    );
  }

  /** Path part for operation `apiPostEvaluationTaskUpdateAnnualGradeGet()` */
  static readonly ApiPostEvaluationTaskUpdateAnnualGradeGetPath = '/api/post-evaluation-task/update-annual-grade';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `apiPostEvaluationTaskUpdateAnnualGradeGet()` instead.
   *
   * This method doesn't expect any request body.
   */
  apiPostEvaluationTaskUpdateAnnualGradeGet$Response(
    params?: {
      executeDate?: string;
    },
    context?: HttpContext
  ): Observable<StrictHttpResponse<boolean>> {
    const rb = new RequestBuilder(this.rootUrl, PostEvaluationTaskService.ApiPostEvaluationTaskUpdateAnnualGradeGetPath, 'get');
    if (params) {
      rb.query('executeDate', params.executeDate, {});
    }

    return this.http.request(
      rb.build({ responseType: 'json', accept: 'application/json', context })
    ).pipe(
      filter((r: any): r is HttpResponse<any> => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return (r as HttpResponse<any>).clone({ body: String((r as HttpResponse<any>).body) === 'true' }) as StrictHttpResponse<boolean>;
      })
    );
  }

  /**
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `apiPostEvaluationTaskUpdateAnnualGradeGet$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  apiPostEvaluationTaskUpdateAnnualGradeGet(
    params?: {
      executeDate?: string;
    },
    context?: HttpContext
  ): Observable<boolean> {
    return this.apiPostEvaluationTaskUpdateAnnualGradeGet$Response(params, context).pipe(
      map((r: StrictHttpResponse<boolean>): boolean => r.body)
    );
  }

  /** Path part for operation `apiPostEvaluationTaskEmailAnnualGradeGet()` */
  static readonly ApiPostEvaluationTaskEmailAnnualGradeGetPath = '/api/post-evaluation-task/email-annual-grade';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `apiPostEvaluationTaskEmailAnnualGradeGet()` instead.
   *
   * This method doesn't expect any request body.
   */
  apiPostEvaluationTaskEmailAnnualGradeGet$Response(
    params?: {
      executeDate?: string;
    },
    context?: HttpContext
  ): Observable<StrictHttpResponse<boolean>> {
    const rb = new RequestBuilder(this.rootUrl, PostEvaluationTaskService.ApiPostEvaluationTaskEmailAnnualGradeGetPath, 'get');
    if (params) {
      rb.query('executeDate', params.executeDate, {});
    }

    return this.http.request(
      rb.build({ responseType: 'json', accept: 'application/json', context })
    ).pipe(
      filter((r: any): r is HttpResponse<any> => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return (r as HttpResponse<any>).clone({ body: String((r as HttpResponse<any>).body) === 'true' }) as StrictHttpResponse<boolean>;
      })
    );
  }

  /**
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `apiPostEvaluationTaskEmailAnnualGradeGet$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  apiPostEvaluationTaskEmailAnnualGradeGet(
    params?: {
      executeDate?: string;
    },
    context?: HttpContext
  ): Observable<boolean> {
    return this.apiPostEvaluationTaskEmailAnnualGradeGet$Response(params, context).pipe(
      map((r: StrictHttpResponse<boolean>): boolean => r.body)
    );
  }

  /** Path part for operation `apiPostEvaluationTaskEmailAnnualGradeNonQueueGet()` */
  static readonly ApiPostEvaluationTaskEmailAnnualGradeNonQueueGetPath = '/api/post-evaluation-task/email-annual-grade-non-queue';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `apiPostEvaluationTaskEmailAnnualGradeNonQueueGet()` instead.
   *
   * This method doesn't expect any request body.
   */
  apiPostEvaluationTaskEmailAnnualGradeNonQueueGet$Response(
    params?: {
      executeDate?: string;
      numInformVendor?: number;
      workGroupId?: number;
    },
    context?: HttpContext
  ): Observable<StrictHttpResponse<boolean>> {
    const rb = new RequestBuilder(this.rootUrl, PostEvaluationTaskService.ApiPostEvaluationTaskEmailAnnualGradeNonQueueGetPath, 'get');
    if (params) {
      rb.query('executeDate', params.executeDate, {});
      rb.query('numInformVendor', params.numInformVendor, {});
      rb.query('workGroupId', params.workGroupId, {});
    }

    return this.http.request(
      rb.build({ responseType: 'json', accept: 'application/json', context })
    ).pipe(
      filter((r: any): r is HttpResponse<any> => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return (r as HttpResponse<any>).clone({ body: String((r as HttpResponse<any>).body) === 'true' }) as StrictHttpResponse<boolean>;
      })
    );
  }

  /**
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `apiPostEvaluationTaskEmailAnnualGradeNonQueueGet$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  apiPostEvaluationTaskEmailAnnualGradeNonQueueGet(
    params?: {
      executeDate?: string;
      numInformVendor?: number;
      workGroupId?: number;
    },
    context?: HttpContext
  ): Observable<boolean> {
    return this.apiPostEvaluationTaskEmailAnnualGradeNonQueueGet$Response(params, context).pipe(
      map((r: StrictHttpResponse<boolean>): boolean => r.body)
    );
  }

  /** Path part for operation `apiPostEvaluationTaskBatchAutoSendPeVmVerifyGet()` */
  static readonly ApiPostEvaluationTaskBatchAutoSendPeVmVerifyGetPath = '/api/post-evaluation-task/batch-auto-send-pe-vm-verify';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `apiPostEvaluationTaskBatchAutoSendPeVmVerifyGet()` instead.
   *
   * This method doesn't expect any request body.
   */
  apiPostEvaluationTaskBatchAutoSendPeVmVerifyGet$Response(
    params?: {
    },
    context?: HttpContext
  ): Observable<StrictHttpResponse<boolean>> {
    const rb = new RequestBuilder(this.rootUrl, PostEvaluationTaskService.ApiPostEvaluationTaskBatchAutoSendPeVmVerifyGetPath, 'get');
    if (params) {
    }

    return this.http.request(
      rb.build({ responseType: 'json', accept: 'application/json', context })
    ).pipe(
      filter((r: any): r is HttpResponse<any> => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return (r as HttpResponse<any>).clone({ body: String((r as HttpResponse<any>).body) === 'true' }) as StrictHttpResponse<boolean>;
      })
    );
  }

  /**
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `apiPostEvaluationTaskBatchAutoSendPeVmVerifyGet$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  apiPostEvaluationTaskBatchAutoSendPeVmVerifyGet(
    params?: {
    },
    context?: HttpContext
  ): Observable<boolean> {
    return this.apiPostEvaluationTaskBatchAutoSendPeVmVerifyGet$Response(params, context).pipe(
      map((r: StrictHttpResponse<boolean>): boolean => r.body)
    );
  }

  /** Path part for operation `apiPostEvaluationTaskBatchCancelPeGet()` */
  static readonly ApiPostEvaluationTaskBatchCancelPeGetPath = '/api/post-evaluation-task/batch-cancel-pe';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `apiPostEvaluationTaskBatchCancelPeGet()` instead.
   *
   * This method doesn't expect any request body.
   */
  apiPostEvaluationTaskBatchCancelPeGet$Response(
    params?: {
      docNos?: string;
      remark?: string;
    },
    context?: HttpContext
  ): Observable<StrictHttpResponse<boolean>> {
    const rb = new RequestBuilder(this.rootUrl, PostEvaluationTaskService.ApiPostEvaluationTaskBatchCancelPeGetPath, 'get');
    if (params) {
      rb.query('docNos', params.docNos, {});
      rb.query('remark', params.remark, {});
    }

    return this.http.request(
      rb.build({ responseType: 'json', accept: 'application/json', context })
    ).pipe(
      filter((r: any): r is HttpResponse<any> => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return (r as HttpResponse<any>).clone({ body: String((r as HttpResponse<any>).body) === 'true' }) as StrictHttpResponse<boolean>;
      })
    );
  }

  /**
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `apiPostEvaluationTaskBatchCancelPeGet$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  apiPostEvaluationTaskBatchCancelPeGet(
    params?: {
      docNos?: string;
      remark?: string;
    },
    context?: HttpContext
  ): Observable<boolean> {
    return this.apiPostEvaluationTaskBatchCancelPeGet$Response(params, context).pipe(
      map((r: StrictHttpResponse<boolean>): boolean => r.body)
    );
  }

  /** Path part for operation `apiPostEvaluationTaskBatchAutoUpdateInboxPeGet()` */
  static readonly ApiPostEvaluationTaskBatchAutoUpdateInboxPeGetPath = '/api/post-evaluation-task/batch-auto-update-inbox-pe';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `apiPostEvaluationTaskBatchAutoUpdateInboxPeGet()` instead.
   *
   * This method doesn't expect any request body.
   */
  apiPostEvaluationTaskBatchAutoUpdateInboxPeGet$Response(
    params?: {
    },
    context?: HttpContext
  ): Observable<StrictHttpResponse<boolean>> {
    const rb = new RequestBuilder(this.rootUrl, PostEvaluationTaskService.ApiPostEvaluationTaskBatchAutoUpdateInboxPeGetPath, 'get');
    if (params) {
    }

    return this.http.request(
      rb.build({ responseType: 'json', accept: 'application/json', context })
    ).pipe(
      filter((r: any): r is HttpResponse<any> => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return (r as HttpResponse<any>).clone({ body: String((r as HttpResponse<any>).body) === 'true' }) as StrictHttpResponse<boolean>;
      })
    );
  }

  /**
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `apiPostEvaluationTaskBatchAutoUpdateInboxPeGet$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  apiPostEvaluationTaskBatchAutoUpdateInboxPeGet(
    params?: {
    },
    context?: HttpContext
  ): Observable<boolean> {
    return this.apiPostEvaluationTaskBatchAutoUpdateInboxPeGet$Response(params, context).pipe(
      map((r: StrictHttpResponse<boolean>): boolean => r.body)
    );
  }

}
