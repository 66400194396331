import { NZ_I18N } from 'ng-zorro-antd/i18n';
import { en_US } from 'ng-zorro-antd/i18n';
import { registerLocaleData } from '@angular/common';
import en from '@angular/common/locales/en';
import { NgModule } from '@angular/core';
import { BrowserModule } from '@angular/platform-browser';

import { AppRoutingModule } from './app-routing.module';
import { AppComponent } from './app.component';
import { th_TH } from 'ng-zorro-antd/i18n';
import { DatePipe } from '@angular/common';
import th from '@angular/common/locales/th';
import { FormsModule } from '@angular/forms';
import { HTTP_INTERCEPTORS, HttpClient, HttpClientModule } from '@angular/common/http';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { IconsProviderModule } from './icons-provider.module';

import { NgxSpinnerModule } from 'ngx-spinner';
import { ApiModule } from './api/api.module';
import { apiBaseUrlGetter } from './utils/appInitializer';

import { MsalModule, MsalRedirectComponent } from '@azure/msal-angular';
import { MsalConfigDynamicModule } from './msal/msal-config-dynamic.module';

import { TranslateLoader, TranslateModule } from '@ngx-translate/core';
import { TranslateHttpLoader } from '@ngx-translate/http-loader';
import { NgScrollbarModule, NG_SCROLLBAR_OPTIONS } from 'ngx-scrollbar';
import { LoginModule } from './login/login.module';

export function HttpLoaderFactory(http: HttpClient) {
  return new TranslateHttpLoader(http);
}

registerLocaleData(en);
registerLocaleData(th);

@NgModule({
  declarations: [
    AppComponent,


  ],
  imports: [
    BrowserModule,
    AppRoutingModule,
    HttpClientModule,
    BrowserAnimationsModule,
    NgScrollbarModule,
    LoginModule,
    NgxSpinnerModule.forRoot({ type: 'ball-scale-multiple' }),
    MsalModule,
    ApiModule.forRoot({ rootUrl: apiBaseUrlGetter() }),
    MsalConfigDynamicModule.forRoot(apiBaseUrlGetter()),
    TranslateModule.forRoot({
      loader: {
        provide: TranslateLoader,
        useFactory: HttpLoaderFactory,
        deps: [HttpClient],
      },
      defaultLanguage: "en"
    }),
  ],
  providers: [
    { provide: NZ_I18N, useValue: en_US },
    { 
      provide: NG_SCROLLBAR_OPTIONS,
      useValue: {
        visibility: 'hover',
      },
   },
    DatePipe
  ],
  bootstrap: [AppComponent, MsalRedirectComponent]
})
export class AppModule { }
