/* tslint:disable */
/* eslint-disable */
import { HttpClient, HttpContext, HttpResponse } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { filter, map } from 'rxjs/operators';

import { BaseService } from '../base-service';
import { ApiConfiguration } from '../api-configuration';
import { StrictHttpResponse } from '../strict-http-response';
import { RequestBuilder } from '../request-builder';

import { AnnouncementReq } from '../models/announcement-req';
import { AnnouncementRes } from '../models/announcement-res';
import { AnnouncementShcQueryReq } from '../models/announcement-shc-query-req';
import { AnnouncementShcQueryRes } from '../models/announcement-shc-query-res';
import { AnnouncementViewLogRes } from '../models/announcement-view-log-res';

@Injectable({ providedIn: 'root' })
export class CtMgrAnnouncementService extends BaseService {
  constructor(config: ApiConfiguration, http: HttpClient) {
    super(config, http);
  }

  /** Path part for operation `apiContentMgrAnnouncementGet()` */
  static readonly ApiContentMgrAnnouncementGetPath = '/api/content-mgr/announcement';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `apiContentMgrAnnouncementGet()` instead.
   *
   * This method doesn't expect any request body.
   */
  apiContentMgrAnnouncementGet$Response(
    params?: {
      announcementId?: string;
    },
    context?: HttpContext
  ): Observable<StrictHttpResponse<AnnouncementRes>> {
    const rb = new RequestBuilder(this.rootUrl, CtMgrAnnouncementService.ApiContentMgrAnnouncementGetPath, 'get');
    if (params) {
      rb.query('announcementId', params.announcementId, {});
    }

    return this.http.request(
      rb.build({ responseType: 'json', accept: 'application/json', context })
    ).pipe(
      filter((r: any): r is HttpResponse<any> => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<AnnouncementRes>;
      })
    );
  }

  /**
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `apiContentMgrAnnouncementGet$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  apiContentMgrAnnouncementGet(
    params?: {
      announcementId?: string;
    },
    context?: HttpContext
  ): Observable<AnnouncementRes> {
    return this.apiContentMgrAnnouncementGet$Response(params, context).pipe(
      map((r: StrictHttpResponse<AnnouncementRes>): AnnouncementRes => r.body)
    );
  }

  /** Path part for operation `apiContentMgrAnnouncementPut()` */
  static readonly ApiContentMgrAnnouncementPutPath = '/api/content-mgr/announcement';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `apiContentMgrAnnouncementPut()` instead.
   *
   * This method sends `application/json` and handles request body of type `application/json`.
   */
  apiContentMgrAnnouncementPut$Response(
    params?: {
      body?: AnnouncementReq
    },
    context?: HttpContext
  ): Observable<StrictHttpResponse<void>> {
    const rb = new RequestBuilder(this.rootUrl, CtMgrAnnouncementService.ApiContentMgrAnnouncementPutPath, 'put');
    if (params) {
      rb.body(params.body, 'application/json');
    }

    return this.http.request(
      rb.build({ responseType: 'text', accept: '*/*', context })
    ).pipe(
      filter((r: any): r is HttpResponse<any> => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return (r as HttpResponse<any>).clone({ body: undefined }) as StrictHttpResponse<void>;
      })
    );
  }

  /**
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `apiContentMgrAnnouncementPut$Response()` instead.
   *
   * This method sends `application/json` and handles request body of type `application/json`.
   */
  apiContentMgrAnnouncementPut(
    params?: {
      body?: AnnouncementReq
    },
    context?: HttpContext
  ): Observable<void> {
    return this.apiContentMgrAnnouncementPut$Response(params, context).pipe(
      map((r: StrictHttpResponse<void>): void => r.body)
    );
  }

  /** Path part for operation `apiContentMgrAnnouncementPost()` */
  static readonly ApiContentMgrAnnouncementPostPath = '/api/content-mgr/announcement';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `apiContentMgrAnnouncementPost()` instead.
   *
   * This method sends `application/json` and handles request body of type `application/json`.
   */
  apiContentMgrAnnouncementPost$Response(
    params?: {
      body?: AnnouncementReq
    },
    context?: HttpContext
  ): Observable<StrictHttpResponse<void>> {
    const rb = new RequestBuilder(this.rootUrl, CtMgrAnnouncementService.ApiContentMgrAnnouncementPostPath, 'post');
    if (params) {
      rb.body(params.body, 'application/json');
    }

    return this.http.request(
      rb.build({ responseType: 'text', accept: '*/*', context })
    ).pipe(
      filter((r: any): r is HttpResponse<any> => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return (r as HttpResponse<any>).clone({ body: undefined }) as StrictHttpResponse<void>;
      })
    );
  }

  /**
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `apiContentMgrAnnouncementPost$Response()` instead.
   *
   * This method sends `application/json` and handles request body of type `application/json`.
   */
  apiContentMgrAnnouncementPost(
    params?: {
      body?: AnnouncementReq
    },
    context?: HttpContext
  ): Observable<void> {
    return this.apiContentMgrAnnouncementPost$Response(params, context).pipe(
      map((r: StrictHttpResponse<void>): void => r.body)
    );
  }

  /** Path part for operation `apiContentMgrAnnouncementDelete()` */
  static readonly ApiContentMgrAnnouncementDeletePath = '/api/content-mgr/announcement';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `apiContentMgrAnnouncementDelete()` instead.
   *
   * This method doesn't expect any request body.
   */
  apiContentMgrAnnouncementDelete$Response(
    params?: {
      req?: string;
    },
    context?: HttpContext
  ): Observable<StrictHttpResponse<void>> {
    const rb = new RequestBuilder(this.rootUrl, CtMgrAnnouncementService.ApiContentMgrAnnouncementDeletePath, 'delete');
    if (params) {
      rb.query('req', params.req, {});
    }

    return this.http.request(
      rb.build({ responseType: 'text', accept: '*/*', context })
    ).pipe(
      filter((r: any): r is HttpResponse<any> => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return (r as HttpResponse<any>).clone({ body: undefined }) as StrictHttpResponse<void>;
      })
    );
  }

  /**
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `apiContentMgrAnnouncementDelete$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  apiContentMgrAnnouncementDelete(
    params?: {
      req?: string;
    },
    context?: HttpContext
  ): Observable<void> {
    return this.apiContentMgrAnnouncementDelete$Response(params, context).pipe(
      map((r: StrictHttpResponse<void>): void => r.body)
    );
  }

  /** Path part for operation `apiContentMgrAnnouncementQueryPost()` */
  static readonly ApiContentMgrAnnouncementQueryPostPath = '/api/content-mgr/announcement/query';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `apiContentMgrAnnouncementQueryPost()` instead.
   *
   * This method sends `application/json` and handles request body of type `application/json`.
   */
  apiContentMgrAnnouncementQueryPost$Response(
    params?: {
      body?: AnnouncementShcQueryReq
    },
    context?: HttpContext
  ): Observable<StrictHttpResponse<AnnouncementShcQueryRes>> {
    const rb = new RequestBuilder(this.rootUrl, CtMgrAnnouncementService.ApiContentMgrAnnouncementQueryPostPath, 'post');
    if (params) {
      rb.body(params.body, 'application/json');
    }

    return this.http.request(
      rb.build({ responseType: 'json', accept: 'application/json', context })
    ).pipe(
      filter((r: any): r is HttpResponse<any> => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<AnnouncementShcQueryRes>;
      })
    );
  }

  /**
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `apiContentMgrAnnouncementQueryPost$Response()` instead.
   *
   * This method sends `application/json` and handles request body of type `application/json`.
   */
  apiContentMgrAnnouncementQueryPost(
    params?: {
      body?: AnnouncementShcQueryReq
    },
    context?: HttpContext
  ): Observable<AnnouncementShcQueryRes> {
    return this.apiContentMgrAnnouncementQueryPost$Response(params, context).pipe(
      map((r: StrictHttpResponse<AnnouncementShcQueryRes>): AnnouncementShcQueryRes => r.body)
    );
  }

  /** Path part for operation `apiContentMgrAnnouncementLogsGet()` */
  static readonly ApiContentMgrAnnouncementLogsGetPath = '/api/content-mgr/announcement/logs';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `apiContentMgrAnnouncementLogsGet()` instead.
   *
   * This method doesn't expect any request body.
   */
  apiContentMgrAnnouncementLogsGet$Response(
    params?: {
      announcementId?: string;
    },
    context?: HttpContext
  ): Observable<StrictHttpResponse<Array<AnnouncementViewLogRes>>> {
    const rb = new RequestBuilder(this.rootUrl, CtMgrAnnouncementService.ApiContentMgrAnnouncementLogsGetPath, 'get');
    if (params) {
      rb.query('announcementId', params.announcementId, {});
    }

    return this.http.request(
      rb.build({ responseType: 'json', accept: 'application/json', context })
    ).pipe(
      filter((r: any): r is HttpResponse<any> => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<Array<AnnouncementViewLogRes>>;
      })
    );
  }

  /**
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `apiContentMgrAnnouncementLogsGet$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  apiContentMgrAnnouncementLogsGet(
    params?: {
      announcementId?: string;
    },
    context?: HttpContext
  ): Observable<Array<AnnouncementViewLogRes>> {
    return this.apiContentMgrAnnouncementLogsGet$Response(params, context).pipe(
      map((r: StrictHttpResponse<Array<AnnouncementViewLogRes>>): Array<AnnouncementViewLogRes> => r.body)
    );
  }

}
