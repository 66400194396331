/* tslint:disable */
/* eslint-disable */
import { HttpClient, HttpContext, HttpResponse } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { filter, map } from 'rxjs/operators';

import { BaseService } from '../base-service';
import { ApiConfiguration } from '../api-configuration';
import { StrictHttpResponse } from '../strict-http-response';
import { RequestBuilder } from '../request-builder';

import { ExportExcelFileRes } from '../models/export-excel-file-res';
import { VenExportReq } from '../models/ven-export-req';
import { VenSchAutoCompleteRes } from '../models/ven-sch-auto-complete-res';
import { VenSchExportReq } from '../models/ven-sch-export-req';
import { VenSchMaQueryRes } from '../models/ven-sch-ma-query-res';
import { VenSchOptDataRes } from '../models/ven-sch-opt-data-res';
import { VenSchProductQueryItemRes } from '../models/ven-sch-product-query-item-res';
import { VenSchQueryItemRes } from '../models/ven-sch-query-item-res';
import { VenSchQueryReq } from '../models/ven-sch-query-req';
import { VenSchQueryRes } from '../models/ven-sch-query-res';
import { VenSchUnspscOptDataRes } from '../models/ven-sch-unspsc-opt-data-res';

@Injectable({ providedIn: 'root' })
export class VendorProfileSearchingService extends BaseService {
  constructor(config: ApiConfiguration, http: HttpClient) {
    super(config, http);
  }

  /** Path part for operation `apiVendorprofileSearchingOptDataGet()` */
  static readonly ApiVendorprofileSearchingOptDataGetPath = '/api/vendorprofile-searching/opt-data';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `apiVendorprofileSearchingOptDataGet()` instead.
   *
   * This method doesn't expect any request body.
   */
  apiVendorprofileSearchingOptDataGet$Response(
    params?: {
      workGroupIds?: Array<number>;
    },
    context?: HttpContext
  ): Observable<StrictHttpResponse<VenSchOptDataRes>> {
    const rb = new RequestBuilder(this.rootUrl, VendorProfileSearchingService.ApiVendorprofileSearchingOptDataGetPath, 'get');
    if (params) {
      rb.query('workGroupIds', params.workGroupIds, {});
    }

    return this.http.request(
      rb.build({ responseType: 'json', accept: 'application/json', context })
    ).pipe(
      filter((r: any): r is HttpResponse<any> => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<VenSchOptDataRes>;
      })
    );
  }

  /**
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `apiVendorprofileSearchingOptDataGet$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  apiVendorprofileSearchingOptDataGet(
    params?: {
      workGroupIds?: Array<number>;
    },
    context?: HttpContext
  ): Observable<VenSchOptDataRes> {
    return this.apiVendorprofileSearchingOptDataGet$Response(params, context).pipe(
      map((r: StrictHttpResponse<VenSchOptDataRes>): VenSchOptDataRes => r.body)
    );
  }

  /** Path part for operation `apiVendorprofileSearchingOptDataUnspscContainGet()` */
  static readonly ApiVendorprofileSearchingOptDataUnspscContainGetPath = '/api/vendorprofile-searching/opt-data/unspsc/contain';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `apiVendorprofileSearchingOptDataUnspscContainGet()` instead.
   *
   * This method doesn't expect any request body.
   */
  apiVendorprofileSearchingOptDataUnspscContainGet$Response(
    params?: {
      keyword?: string;
      max?: number;
      workGroupIds?: Array<number>;
    },
    context?: HttpContext
  ): Observable<StrictHttpResponse<Array<VenSchUnspscOptDataRes>>> {
    const rb = new RequestBuilder(this.rootUrl, VendorProfileSearchingService.ApiVendorprofileSearchingOptDataUnspscContainGetPath, 'get');
    if (params) {
      rb.query('keyword', params.keyword, {});
      rb.query('max', params.max, {});
      rb.query('workGroupIds', params.workGroupIds, {});
    }

    return this.http.request(
      rb.build({ responseType: 'json', accept: 'application/json', context })
    ).pipe(
      filter((r: any): r is HttpResponse<any> => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<Array<VenSchUnspscOptDataRes>>;
      })
    );
  }

  /**
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `apiVendorprofileSearchingOptDataUnspscContainGet$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  apiVendorprofileSearchingOptDataUnspscContainGet(
    params?: {
      keyword?: string;
      max?: number;
      workGroupIds?: Array<number>;
    },
    context?: HttpContext
  ): Observable<Array<VenSchUnspscOptDataRes>> {
    return this.apiVendorprofileSearchingOptDataUnspscContainGet$Response(params, context).pipe(
      map((r: StrictHttpResponse<Array<VenSchUnspscOptDataRes>>): Array<VenSchUnspscOptDataRes> => r.body)
    );
  }

  /** Path part for operation `apiVendorprofileSearchingSearchContainGet()` */
  static readonly ApiVendorprofileSearchingSearchContainGetPath = '/api/vendorprofile-searching/search/contain';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `apiVendorprofileSearchingSearchContainGet()` instead.
   *
   * This method doesn't expect any request body.
   */
  apiVendorprofileSearchingSearchContainGet$Response(
    params?: {
      keyword?: string;
      max?: number;
    },
    context?: HttpContext
  ): Observable<StrictHttpResponse<Array<VenSchAutoCompleteRes>>> {
    const rb = new RequestBuilder(this.rootUrl, VendorProfileSearchingService.ApiVendorprofileSearchingSearchContainGetPath, 'get');
    if (params) {
      rb.query('keyword', params.keyword, {});
      rb.query('max', params.max, {});
    }

    return this.http.request(
      rb.build({ responseType: 'json', accept: 'application/json', context })
    ).pipe(
      filter((r: any): r is HttpResponse<any> => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<Array<VenSchAutoCompleteRes>>;
      })
    );
  }

  /**
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `apiVendorprofileSearchingSearchContainGet$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  apiVendorprofileSearchingSearchContainGet(
    params?: {
      keyword?: string;
      max?: number;
    },
    context?: HttpContext
  ): Observable<Array<VenSchAutoCompleteRes>> {
    return this.apiVendorprofileSearchingSearchContainGet$Response(params, context).pipe(
      map((r: StrictHttpResponse<Array<VenSchAutoCompleteRes>>): Array<VenSchAutoCompleteRes> => r.body)
    );
  }

  /** Path part for operation `apiVendorprofileSearchingQueryPost()` */
  static readonly ApiVendorprofileSearchingQueryPostPath = '/api/vendorprofile-searching/query';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `apiVendorprofileSearchingQueryPost()` instead.
   *
   * This method sends `application/json` and handles request body of type `application/json`.
   */
  apiVendorprofileSearchingQueryPost$Response(
    params?: {
      body?: VenSchQueryReq
    },
    context?: HttpContext
  ): Observable<StrictHttpResponse<VenSchQueryRes>> {
    const rb = new RequestBuilder(this.rootUrl, VendorProfileSearchingService.ApiVendorprofileSearchingQueryPostPath, 'post');
    if (params) {
      rb.body(params.body, 'application/json');
    }

    return this.http.request(
      rb.build({ responseType: 'json', accept: 'application/json', context })
    ).pipe(
      filter((r: any): r is HttpResponse<any> => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<VenSchQueryRes>;
      })
    );
  }

  /**
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `apiVendorprofileSearchingQueryPost$Response()` instead.
   *
   * This method sends `application/json` and handles request body of type `application/json`.
   */
  apiVendorprofileSearchingQueryPost(
    params?: {
      body?: VenSchQueryReq
    },
    context?: HttpContext
  ): Observable<VenSchQueryRes> {
    return this.apiVendorprofileSearchingQueryPost$Response(params, context).pipe(
      map((r: StrictHttpResponse<VenSchQueryRes>): VenSchQueryRes => r.body)
    );
  }

  /** Path part for operation `apiVendorprofileSearchingQueryManualRequestPost()` */
  static readonly ApiVendorprofileSearchingQueryManualRequestPostPath = '/api/vendorprofile-searching/queryManualRequest';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `apiVendorprofileSearchingQueryManualRequestPost()` instead.
   *
   * This method sends `application/json` and handles request body of type `application/json`.
   */
  apiVendorprofileSearchingQueryManualRequestPost$Response(
    params?: {
      body?: VenSchQueryReq
    },
    context?: HttpContext
  ): Observable<StrictHttpResponse<VenSchMaQueryRes>> {
    const rb = new RequestBuilder(this.rootUrl, VendorProfileSearchingService.ApiVendorprofileSearchingQueryManualRequestPostPath, 'post');
    if (params) {
      rb.body(params.body, 'application/json');
    }

    return this.http.request(
      rb.build({ responseType: 'json', accept: 'application/json', context })
    ).pipe(
      filter((r: any): r is HttpResponse<any> => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<VenSchMaQueryRes>;
      })
    );
  }

  /**
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `apiVendorprofileSearchingQueryManualRequestPost$Response()` instead.
   *
   * This method sends `application/json` and handles request body of type `application/json`.
   */
  apiVendorprofileSearchingQueryManualRequestPost(
    params?: {
      body?: VenSchQueryReq
    },
    context?: HttpContext
  ): Observable<VenSchMaQueryRes> {
    return this.apiVendorprofileSearchingQueryManualRequestPost$Response(params, context).pipe(
      map((r: StrictHttpResponse<VenSchMaQueryRes>): VenSchMaQueryRes => r.body)
    );
  }

  /** Path part for operation `apiVendorprofileSearchingExportPost()` */
  static readonly ApiVendorprofileSearchingExportPostPath = '/api/vendorprofile-searching/export';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `apiVendorprofileSearchingExportPost()` instead.
   *
   * This method sends `application/json` and handles request body of type `application/json`.
   */
  apiVendorprofileSearchingExportPost$Response(
    params?: {
      body?: VenSchExportReq
    },
    context?: HttpContext
  ): Observable<StrictHttpResponse<ExportExcelFileRes>> {
    const rb = new RequestBuilder(this.rootUrl, VendorProfileSearchingService.ApiVendorprofileSearchingExportPostPath, 'post');
    if (params) {
      rb.body(params.body, 'application/json');
    }

    return this.http.request(
      rb.build({ responseType: 'json', accept: 'application/json', context })
    ).pipe(
      filter((r: any): r is HttpResponse<any> => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<ExportExcelFileRes>;
      })
    );
  }

  /**
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `apiVendorprofileSearchingExportPost$Response()` instead.
   *
   * This method sends `application/json` and handles request body of type `application/json`.
   */
  apiVendorprofileSearchingExportPost(
    params?: {
      body?: VenSchExportReq
    },
    context?: HttpContext
  ): Observable<ExportExcelFileRes> {
    return this.apiVendorprofileSearchingExportPost$Response(params, context).pipe(
      map((r: StrictHttpResponse<ExportExcelFileRes>): ExportExcelFileRes => r.body)
    );
  }

  /** Path part for operation `apiVendorprofileSearchingExportAllFieldsPost()` */
  static readonly ApiVendorprofileSearchingExportAllFieldsPostPath = '/api/vendorprofile-searching/export-all-fields';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `apiVendorprofileSearchingExportAllFieldsPost()` instead.
   *
   * This method sends `application/json` and handles request body of type `application/json`.
   */
  apiVendorprofileSearchingExportAllFieldsPost$Response(
    params?: {
      body?: VenExportReq
    },
    context?: HttpContext
  ): Observable<StrictHttpResponse<ExportExcelFileRes>> {
    const rb = new RequestBuilder(this.rootUrl, VendorProfileSearchingService.ApiVendorprofileSearchingExportAllFieldsPostPath, 'post');
    if (params) {
      rb.body(params.body, 'application/json');
    }

    return this.http.request(
      rb.build({ responseType: 'json', accept: 'application/json', context })
    ).pipe(
      filter((r: any): r is HttpResponse<any> => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<ExportExcelFileRes>;
      })
    );
  }

  /**
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `apiVendorprofileSearchingExportAllFieldsPost$Response()` instead.
   *
   * This method sends `application/json` and handles request body of type `application/json`.
   */
  apiVendorprofileSearchingExportAllFieldsPost(
    params?: {
      body?: VenExportReq
    },
    context?: HttpContext
  ): Observable<ExportExcelFileRes> {
    return this.apiVendorprofileSearchingExportAllFieldsPost$Response(params, context).pipe(
      map((r: StrictHttpResponse<ExportExcelFileRes>): ExportExcelFileRes => r.body)
    );
  }

  /** Path part for operation `apiVendorprofileSearchingProductsGet()` */
  static readonly ApiVendorprofileSearchingProductsGetPath = '/api/vendorprofile-searching/products';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `apiVendorprofileSearchingProductsGet()` instead.
   *
   * This method doesn't expect any request body.
   */
  apiVendorprofileSearchingProductsGet$Response(
    params?: {
      vendorProfileId?: string;
      productName?: string;
      FKWorkGroups?: Array<number>;
    },
    context?: HttpContext
  ): Observable<StrictHttpResponse<Array<VenSchProductQueryItemRes>>> {
    const rb = new RequestBuilder(this.rootUrl, VendorProfileSearchingService.ApiVendorprofileSearchingProductsGetPath, 'get');
    if (params) {
      rb.query('vendorProfileId', params.vendorProfileId, {});
      rb.query('productName', params.productName, {});
      rb.query('FKWorkGroups', params.FKWorkGroups, {});
    }

    return this.http.request(
      rb.build({ responseType: 'json', accept: 'application/json', context })
    ).pipe(
      filter((r: any): r is HttpResponse<any> => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<Array<VenSchProductQueryItemRes>>;
      })
    );
  }

  /**
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `apiVendorprofileSearchingProductsGet$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  apiVendorprofileSearchingProductsGet(
    params?: {
      vendorProfileId?: string;
      productName?: string;
      FKWorkGroups?: Array<number>;
    },
    context?: HttpContext
  ): Observable<Array<VenSchProductQueryItemRes>> {
    return this.apiVendorprofileSearchingProductsGet$Response(params, context).pipe(
      map((r: StrictHttpResponse<Array<VenSchProductQueryItemRes>>): Array<VenSchProductQueryItemRes> => r.body)
    );
  }

  /** Path part for operation `apiVendorprofileSearchingMarkSpamPost()` */
  static readonly ApiVendorprofileSearchingMarkSpamPostPath = '/api/vendorprofile-searching/mark-spam';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `apiVendorprofileSearchingMarkSpamPost()` instead.
   *
   * This method sends `application/json` and handles request body of type `application/json`.
   */
  apiVendorprofileSearchingMarkSpamPost$Response(
    params?: {
      body?: Array<string>
    },
    context?: HttpContext
  ): Observable<StrictHttpResponse<void>> {
    const rb = new RequestBuilder(this.rootUrl, VendorProfileSearchingService.ApiVendorprofileSearchingMarkSpamPostPath, 'post');
    if (params) {
      rb.body(params.body, 'application/json');
    }

    return this.http.request(
      rb.build({ responseType: 'text', accept: '*/*', context })
    ).pipe(
      filter((r: any): r is HttpResponse<any> => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return (r as HttpResponse<any>).clone({ body: undefined }) as StrictHttpResponse<void>;
      })
    );
  }

  /**
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `apiVendorprofileSearchingMarkSpamPost$Response()` instead.
   *
   * This method sends `application/json` and handles request body of type `application/json`.
   */
  apiVendorprofileSearchingMarkSpamPost(
    params?: {
      body?: Array<string>
    },
    context?: HttpContext
  ): Observable<void> {
    return this.apiVendorprofileSearchingMarkSpamPost$Response(params, context).pipe(
      map((r: StrictHttpResponse<void>): void => r.body)
    );
  }

  /** Path part for operation `apiVendorprofileSearchingSearchByEmailGet()` */
  static readonly ApiVendorprofileSearchingSearchByEmailGetPath = '/api/vendorprofile-searching/search-by-email';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `apiVendorprofileSearchingSearchByEmailGet()` instead.
   *
   * This method doesn't expect any request body.
   */
  apiVendorprofileSearchingSearchByEmailGet$Response(
    params?: {
      emailOfVendor?: string;
    },
    context?: HttpContext
  ): Observable<StrictHttpResponse<VenSchQueryItemRes>> {
    const rb = new RequestBuilder(this.rootUrl, VendorProfileSearchingService.ApiVendorprofileSearchingSearchByEmailGetPath, 'get');
    if (params) {
      rb.query('emailOfVendor', params.emailOfVendor, {});
    }

    return this.http.request(
      rb.build({ responseType: 'json', accept: 'application/json', context })
    ).pipe(
      filter((r: any): r is HttpResponse<any> => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<VenSchQueryItemRes>;
      })
    );
  }

  /**
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `apiVendorprofileSearchingSearchByEmailGet$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  apiVendorprofileSearchingSearchByEmailGet(
    params?: {
      emailOfVendor?: string;
    },
    context?: HttpContext
  ): Observable<VenSchQueryItemRes> {
    return this.apiVendorprofileSearchingSearchByEmailGet$Response(params, context).pipe(
      map((r: StrictHttpResponse<VenSchQueryItemRes>): VenSchQueryItemRes => r.body)
    );
  }

}
