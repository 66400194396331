<div nz-row class="center-screen">
    <div nz-col nzXs="24" nzSm="24" [nzMd]="{ span: 18, offset: 3 }" [nzLg]="{ span: 14, offset: 5 }" [nzXl]="{ span: 8, offset: 8 }">
        <nz-card>
            <form nz-form class="login-form">
                <!--<nz-form-item>
        <nz-form-control nzErrorTip="Please input your username!">
            <nz-input-group nzPrefixIcon="user">
            <input type="text" nz-input formControlName="userName" placeholder="Username" />
            </nz-input-group>
        </nz-form-control>
        </nz-form-item>
        <nz-form-item>
        <nz-form-control nzErrorTip="Please input your Password!">
            <nz-input-group nzPrefixIcon="lock">
            <input type="password" nz-input formControlName="password" placeholder="Password" />
            </nz-input-group>
        </nz-form-control>
        </nz-form-item>
        <div nz-row class="login-form-margin">
        <div nz-col [nzSpan]="12">
            <label nz-checkbox formControlName="remember">
            <span>Remember me</span>
            </label>
        </div>
        <div nz-col [nzSpan]="12">
            <a class="login-form-forgot">Forgot password</a>
        </div>
        </div>-->
                <h1 class="login-form-margin" style="color: #3e609c;">Welcome to</h1>

                <div nz-row>
                    <div nz-col nzXs="24" nzSm="24" [nzMd]="{ span: 22, offset: 1 }" [nzLg]="{ span: 20, offset: 2 }" [nzXl]="{ span: 18, offset: 3 }">
                        <img src="assets/icons/venus/VENUS_Icon_full.png" alt="logo" class="login-form-margin" style="width: 100%;"/>

                        <button nz-button [nzSize]="'large'" class="login-form-button login-form-margin"
                            (click)="loginSCG()" [nzType]="'primary'">{{ 'MENU.LOGIN' | translate }} - SCG</button>
                        <button nz-button [nzSize]="'large'" class="login-form-button login-form-margin"
                            (click)="loginLSP()" [nzType]="'primary'">{{ 'MENU.LOGIN' | translate }} - LSP</button>
                    </div>
                </div>
            </form>
        </nz-card>
    </div>
</div>