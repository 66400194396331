/* tslint:disable */
/* eslint-disable */
import { HttpClient, HttpContext, HttpResponse } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { filter, map } from 'rxjs/operators';

import { BaseService } from '../base-service';
import { ApiConfiguration } from '../api-configuration';
import { StrictHttpResponse } from '../strict-http-response';
import { RequestBuilder } from '../request-builder';

import { HcActivityLogRes } from '../models/hc-activity-log-res';
import { HcApproveMultipleByRefReq } from '../models/hc-approve-multiple-by-ref-req';
import { HcApproveMultipleReq } from '../models/hc-approve-multiple-req';
import { HcApproveReq } from '../models/hc-approve-req';
import { HcApproveRes } from '../models/hc-approve-res';
import { HcApprovedLevelByProductReq } from '../models/hc-approved-level-by-product-req';
import { HcApprovedLevelByUnspscReq } from '../models/hc-approved-level-by-unspsc-req';
import { HcApprovedLevelByVendorReq } from '../models/hc-approved-level-by-vendor-req';
import { HcCalSeverityRes } from '../models/hc-cal-severity-res';
import { HcCreateEventReq } from '../models/hc-create-event-req';
import { HcCreateEventSaveReq } from '../models/hc-create-event-save-req';
import { HcCreateRes } from '../models/hc-create-res';
import { HcExportRes } from '../models/hc-export-res';
import { HcInitRes } from '../models/hc-init-res';
import { HcInterestCreateReq } from '../models/hc-interest-create-req';
import { HcIsUrgentRes } from '../models/hc-is-urgent-res';
import { HcManualCreateAdjustToHigherSeverityReq } from '../models/hc-manual-create-adjust-to-higher-severity-req';
import { HcManualCreateInactiveReq } from '../models/hc-manual-create-inactive-req';
import { HcManualCreateReAssessmentReq } from '../models/hc-manual-create-re-assessment-req';
import { HcManualCreateSustainableReq } from '../models/hc-manual-create-sustainable-req';
import { HcProductInitRes } from '../models/hc-product-init-res';
import { HcProductOptQueryByVendorReq } from '../models/hc-product-opt-query-by-vendor-req';
import { HcProductOptQueryReq } from '../models/hc-product-opt-query-req';
import { HcProductOptQueryRes } from '../models/hc-product-opt-query-res';
import { HcRes } from '../models/hc-res';
import { HcReviseReq } from '../models/hc-revise-req';
import { HcStatementTemplateRes } from '../models/hc-statement-template-res';
import { HcStepBarRes } from '../models/hc-step-bar-res';
import { HcTrnChatReq } from '../models/hc-trn-chat-req';
import { HcTrnChatRes } from '../models/hc-trn-chat-res';
import { HcTrnFormRes } from '../models/hc-trn-form-res';
import { HcTrnMgrRes } from '../models/hc-trn-mgr-res';
import { HcTrnMgrsRes } from '../models/hc-trn-mgrs-res';
import { HcTrnPqRes } from '../models/hc-trn-pq-res';
import { HcTrnProductDto } from '../models/hc-trn-product-dto';
import { HcTrnProductDtoWithoutValidator } from '../models/hc-trn-product-dto-without-validator';
import { HcTrnRelatedPeopleRes } from '../models/hc-trn-related-people-res';
import { HcTrnVendorAccountDto } from '../models/hc-trn-vendor-account-dto';
import { HcUnspscAndPsType } from '../models/hc-unspsc-and-ps-type';
import { HcUpdateVendorCodeReq } from '../models/hc-update-vendor-code-req';
import { HcVendorIsInHealthCheckProcessRes } from '../models/hc-vendor-is-in-health-check-process-res';
import { HcVendorOptQueryReq } from '../models/hc-vendor-opt-query-req';
import { HcVendorOptQueryRes } from '../models/hc-vendor-opt-query-res';
import { HcVendorOptSearchingRes } from '../models/hc-vendor-opt-searching-res';
import { HcVendorWithProductOptQueryItemRes } from '../models/hc-vendor-with-product-opt-query-item-res';
import { HcVerifyConclustionCertificateReq } from '../models/hc-verify-conclustion-certificate-req';
import { HcVerifyConclustionReq } from '../models/hc-verify-conclustion-req';
import { HcVerifyConclustionSaveReq } from '../models/hc-verify-conclustion-save-req';
import { HcVpInitRes } from '../models/hc-vp-init-res';
import { HcWorksheetApprovedLevelRes } from '../models/hc-worksheet-approved-level-res';
import { VenSchUnspscOptDataRes } from '../models/ven-sch-unspsc-opt-data-res';

@Injectable({ providedIn: 'root' })
export class HealthCheckWorksheetService extends BaseService {
  constructor(config: ApiConfiguration, http: HttpClient) {
    super(config, http);
  }

  /** Path part for operation `apiHealthCheckWorksheetDataInitGet()` */
  static readonly ApiHealthCheckWorksheetDataInitGetPath = '/api/health-check/worksheet/data-init';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `apiHealthCheckWorksheetDataInitGet()` instead.
   *
   * This method doesn't expect any request body.
   */
  apiHealthCheckWorksheetDataInitGet$Response(
    params?: {
    },
    context?: HttpContext
  ): Observable<StrictHttpResponse<HcInitRes>> {
    const rb = new RequestBuilder(this.rootUrl, HealthCheckWorksheetService.ApiHealthCheckWorksheetDataInitGetPath, 'get');
    if (params) {
    }

    return this.http.request(
      rb.build({ responseType: 'json', accept: 'application/json', context })
    ).pipe(
      filter((r: any): r is HttpResponse<any> => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<HcInitRes>;
      })
    );
  }

  /**
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `apiHealthCheckWorksheetDataInitGet$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  apiHealthCheckWorksheetDataInitGet(
    params?: {
    },
    context?: HttpContext
  ): Observable<HcInitRes> {
    return this.apiHealthCheckWorksheetDataInitGet$Response(params, context).pipe(
      map((r: StrictHttpResponse<HcInitRes>): HcInitRes => r.body)
    );
  }

  /** Path part for operation `apiHealthCheckWorksheetDataInitVendorPortfolioGet()` */
  static readonly ApiHealthCheckWorksheetDataInitVendorPortfolioGetPath = '/api/health-check/worksheet/data-init/vendor-portfolio';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `apiHealthCheckWorksheetDataInitVendorPortfolioGet()` instead.
   *
   * This method doesn't expect any request body.
   */
  apiHealthCheckWorksheetDataInitVendorPortfolioGet$Response(
    params?: {
    },
    context?: HttpContext
  ): Observable<StrictHttpResponse<HcVpInitRes>> {
    const rb = new RequestBuilder(this.rootUrl, HealthCheckWorksheetService.ApiHealthCheckWorksheetDataInitVendorPortfolioGetPath, 'get');
    if (params) {
    }

    return this.http.request(
      rb.build({ responseType: 'json', accept: 'application/json', context })
    ).pipe(
      filter((r: any): r is HttpResponse<any> => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<HcVpInitRes>;
      })
    );
  }

  /**
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `apiHealthCheckWorksheetDataInitVendorPortfolioGet$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  apiHealthCheckWorksheetDataInitVendorPortfolioGet(
    params?: {
    },
    context?: HttpContext
  ): Observable<HcVpInitRes> {
    return this.apiHealthCheckWorksheetDataInitVendorPortfolioGet$Response(params, context).pipe(
      map((r: StrictHttpResponse<HcVpInitRes>): HcVpInitRes => r.body)
    );
  }

  /** Path part for operation `apiHealthCheckWorksheetDataInitProductGet()` */
  static readonly ApiHealthCheckWorksheetDataInitProductGetPath = '/api/health-check/worksheet/data-init/product';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `apiHealthCheckWorksheetDataInitProductGet()` instead.
   *
   * This method doesn't expect any request body.
   */
  apiHealthCheckWorksheetDataInitProductGet$Response(
    params?: {
    },
    context?: HttpContext
  ): Observable<StrictHttpResponse<HcProductInitRes>> {
    const rb = new RequestBuilder(this.rootUrl, HealthCheckWorksheetService.ApiHealthCheckWorksheetDataInitProductGetPath, 'get');
    if (params) {
    }

    return this.http.request(
      rb.build({ responseType: 'json', accept: 'application/json', context })
    ).pipe(
      filter((r: any): r is HttpResponse<any> => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<HcProductInitRes>;
      })
    );
  }

  /**
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `apiHealthCheckWorksheetDataInitProductGet$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  apiHealthCheckWorksheetDataInitProductGet(
    params?: {
    },
    context?: HttpContext
  ): Observable<HcProductInitRes> {
    return this.apiHealthCheckWorksheetDataInitProductGet$Response(params, context).pipe(
      map((r: StrictHttpResponse<HcProductInitRes>): HcProductInitRes => r.body)
    );
  }

  /** Path part for operation `apiHealthCheckWorksheetGet()` */
  static readonly ApiHealthCheckWorksheetGetPath = '/api/health-check/worksheet';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `apiHealthCheckWorksheetGet()` instead.
   *
   * This method doesn't expect any request body.
   */
  apiHealthCheckWorksheetGet$Response(
    params?: {
      trnId?: string;
    },
    context?: HttpContext
  ): Observable<StrictHttpResponse<HcRes>> {
    const rb = new RequestBuilder(this.rootUrl, HealthCheckWorksheetService.ApiHealthCheckWorksheetGetPath, 'get');
    if (params) {
      rb.query('trnId', params.trnId, {});
    }

    return this.http.request(
      rb.build({ responseType: 'json', accept: 'application/json', context })
    ).pipe(
      filter((r: any): r is HttpResponse<any> => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<HcRes>;
      })
    );
  }

  /**
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `apiHealthCheckWorksheetGet$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  apiHealthCheckWorksheetGet(
    params?: {
      trnId?: string;
    },
    context?: HttpContext
  ): Observable<HcRes> {
    return this.apiHealthCheckWorksheetGet$Response(params, context).pipe(
      map((r: StrictHttpResponse<HcRes>): HcRes => r.body)
    );
  }

  /** Path part for operation `apiHealthCheckWorksheetFormInitGet()` */
  static readonly ApiHealthCheckWorksheetFormInitGetPath = '/api/health-check/worksheet/form-init';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `apiHealthCheckWorksheetFormInitGet()` instead.
   *
   * This method doesn't expect any request body.
   */
  apiHealthCheckWorksheetFormInitGet$Response(
    params?: {
    },
    context?: HttpContext
  ): Observable<StrictHttpResponse<HcRes>> {
    const rb = new RequestBuilder(this.rootUrl, HealthCheckWorksheetService.ApiHealthCheckWorksheetFormInitGetPath, 'get');
    if (params) {
    }

    return this.http.request(
      rb.build({ responseType: 'json', accept: 'application/json', context })
    ).pipe(
      filter((r: any): r is HttpResponse<any> => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<HcRes>;
      })
    );
  }

  /**
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `apiHealthCheckWorksheetFormInitGet$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  apiHealthCheckWorksheetFormInitGet(
    params?: {
    },
    context?: HttpContext
  ): Observable<HcRes> {
    return this.apiHealthCheckWorksheetFormInitGet$Response(params, context).pipe(
      map((r: StrictHttpResponse<HcRes>): HcRes => r.body)
    );
  }

  /** Path part for operation `apiHealthCheckWorksheetStepbarGet()` */
  static readonly ApiHealthCheckWorksheetStepbarGetPath = '/api/health-check/worksheet/stepbar';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `apiHealthCheckWorksheetStepbarGet()` instead.
   *
   * This method doesn't expect any request body.
   */
  apiHealthCheckWorksheetStepbarGet$Response(
    params?: {
      trnId?: string;
    },
    context?: HttpContext
  ): Observable<StrictHttpResponse<HcStepBarRes>> {
    const rb = new RequestBuilder(this.rootUrl, HealthCheckWorksheetService.ApiHealthCheckWorksheetStepbarGetPath, 'get');
    if (params) {
      rb.query('trnId', params.trnId, {});
    }

    return this.http.request(
      rb.build({ responseType: 'json', accept: 'application/json', context })
    ).pipe(
      filter((r: any): r is HttpResponse<any> => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<HcStepBarRes>;
      })
    );
  }

  /**
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `apiHealthCheckWorksheetStepbarGet$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  apiHealthCheckWorksheetStepbarGet(
    params?: {
      trnId?: string;
    },
    context?: HttpContext
  ): Observable<HcStepBarRes> {
    return this.apiHealthCheckWorksheetStepbarGet$Response(params, context).pipe(
      map((r: StrictHttpResponse<HcStepBarRes>): HcStepBarRes => r.body)
    );
  }

  /** Path part for operation `apiHealthCheckWorksheetActivityLogsGet()` */
  static readonly ApiHealthCheckWorksheetActivityLogsGetPath = '/api/health-check/worksheet/activity-logs';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `apiHealthCheckWorksheetActivityLogsGet()` instead.
   *
   * This method doesn't expect any request body.
   */
  apiHealthCheckWorksheetActivityLogsGet$Response(
    params?: {
      trnId?: string;
    },
    context?: HttpContext
  ): Observable<StrictHttpResponse<Array<HcActivityLogRes>>> {
    const rb = new RequestBuilder(this.rootUrl, HealthCheckWorksheetService.ApiHealthCheckWorksheetActivityLogsGetPath, 'get');
    if (params) {
      rb.query('trnId', params.trnId, {});
    }

    return this.http.request(
      rb.build({ responseType: 'json', accept: 'application/json', context })
    ).pipe(
      filter((r: any): r is HttpResponse<any> => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<Array<HcActivityLogRes>>;
      })
    );
  }

  /**
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `apiHealthCheckWorksheetActivityLogsGet$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  apiHealthCheckWorksheetActivityLogsGet(
    params?: {
      trnId?: string;
    },
    context?: HttpContext
  ): Observable<Array<HcActivityLogRes>> {
    return this.apiHealthCheckWorksheetActivityLogsGet$Response(params, context).pipe(
      map((r: StrictHttpResponse<Array<HcActivityLogRes>>): Array<HcActivityLogRes> => r.body)
    );
  }

  /** Path part for operation `apiHealthCheckWorksheetNewRequestReAssessmentPost()` */
  static readonly ApiHealthCheckWorksheetNewRequestReAssessmentPostPath = '/api/health-check/worksheet/new-request/re-assessment';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `apiHealthCheckWorksheetNewRequestReAssessmentPost()` instead.
   *
   * This method sends `application/json` and handles request body of type `application/json`.
   */
  apiHealthCheckWorksheetNewRequestReAssessmentPost$Response(
    params?: {
      body?: HcManualCreateReAssessmentReq
    },
    context?: HttpContext
  ): Observable<StrictHttpResponse<HcCreateRes>> {
    const rb = new RequestBuilder(this.rootUrl, HealthCheckWorksheetService.ApiHealthCheckWorksheetNewRequestReAssessmentPostPath, 'post');
    if (params) {
      rb.body(params.body, 'application/json');
    }

    return this.http.request(
      rb.build({ responseType: 'json', accept: 'application/json', context })
    ).pipe(
      filter((r: any): r is HttpResponse<any> => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<HcCreateRes>;
      })
    );
  }

  /**
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `apiHealthCheckWorksheetNewRequestReAssessmentPost$Response()` instead.
   *
   * This method sends `application/json` and handles request body of type `application/json`.
   */
  apiHealthCheckWorksheetNewRequestReAssessmentPost(
    params?: {
      body?: HcManualCreateReAssessmentReq
    },
    context?: HttpContext
  ): Observable<HcCreateRes> {
    return this.apiHealthCheckWorksheetNewRequestReAssessmentPost$Response(params, context).pipe(
      map((r: StrictHttpResponse<HcCreateRes>): HcCreateRes => r.body)
    );
  }

  /** Path part for operation `apiHealthCheckWorksheetNewRequestAdjustToHigherSeverityPost()` */
  static readonly ApiHealthCheckWorksheetNewRequestAdjustToHigherSeverityPostPath = '/api/health-check/worksheet/new-request/adjust-to-higher-severity';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `apiHealthCheckWorksheetNewRequestAdjustToHigherSeverityPost()` instead.
   *
   * This method sends `application/json` and handles request body of type `application/json`.
   */
  apiHealthCheckWorksheetNewRequestAdjustToHigherSeverityPost$Response(
    params?: {
      body?: HcManualCreateAdjustToHigherSeverityReq
    },
    context?: HttpContext
  ): Observable<StrictHttpResponse<Array<HcCreateRes>>> {
    const rb = new RequestBuilder(this.rootUrl, HealthCheckWorksheetService.ApiHealthCheckWorksheetNewRequestAdjustToHigherSeverityPostPath, 'post');
    if (params) {
      rb.body(params.body, 'application/json');
    }

    return this.http.request(
      rb.build({ responseType: 'json', accept: 'application/json', context })
    ).pipe(
      filter((r: any): r is HttpResponse<any> => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<Array<HcCreateRes>>;
      })
    );
  }

  /**
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `apiHealthCheckWorksheetNewRequestAdjustToHigherSeverityPost$Response()` instead.
   *
   * This method sends `application/json` and handles request body of type `application/json`.
   */
  apiHealthCheckWorksheetNewRequestAdjustToHigherSeverityPost(
    params?: {
      body?: HcManualCreateAdjustToHigherSeverityReq
    },
    context?: HttpContext
  ): Observable<Array<HcCreateRes>> {
    return this.apiHealthCheckWorksheetNewRequestAdjustToHigherSeverityPost$Response(params, context).pipe(
      map((r: StrictHttpResponse<Array<HcCreateRes>>): Array<HcCreateRes> => r.body)
    );
  }

  /** Path part for operation `apiHealthCheckWorksheetNewRequestInactivePost()` */
  static readonly ApiHealthCheckWorksheetNewRequestInactivePostPath = '/api/health-check/worksheet/new-request/inactive';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `apiHealthCheckWorksheetNewRequestInactivePost()` instead.
   *
   * This method sends `application/json` and handles request body of type `application/json`.
   */
  apiHealthCheckWorksheetNewRequestInactivePost$Response(
    params?: {
      body?: HcManualCreateInactiveReq
    },
    context?: HttpContext
  ): Observable<StrictHttpResponse<Array<HcCreateRes>>> {
    const rb = new RequestBuilder(this.rootUrl, HealthCheckWorksheetService.ApiHealthCheckWorksheetNewRequestInactivePostPath, 'post');
    if (params) {
      rb.body(params.body, 'application/json');
    }

    return this.http.request(
      rb.build({ responseType: 'json', accept: 'application/json', context })
    ).pipe(
      filter((r: any): r is HttpResponse<any> => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<Array<HcCreateRes>>;
      })
    );
  }

  /**
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `apiHealthCheckWorksheetNewRequestInactivePost$Response()` instead.
   *
   * This method sends `application/json` and handles request body of type `application/json`.
   */
  apiHealthCheckWorksheetNewRequestInactivePost(
    params?: {
      body?: HcManualCreateInactiveReq
    },
    context?: HttpContext
  ): Observable<Array<HcCreateRes>> {
    return this.apiHealthCheckWorksheetNewRequestInactivePost$Response(params, context).pipe(
      map((r: StrictHttpResponse<Array<HcCreateRes>>): Array<HcCreateRes> => r.body)
    );
  }

  /** Path part for operation `apiHealthCheckWorksheetNewRequestSustainablePost()` */
  static readonly ApiHealthCheckWorksheetNewRequestSustainablePostPath = '/api/health-check/worksheet/new-request/sustainable';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `apiHealthCheckWorksheetNewRequestSustainablePost()` instead.
   *
   * This method sends `application/json` and handles request body of type `application/json`.
   */
  apiHealthCheckWorksheetNewRequestSustainablePost$Response(
    params?: {
      body?: HcManualCreateSustainableReq
    },
    context?: HttpContext
  ): Observable<StrictHttpResponse<HcCreateRes>> {
    const rb = new RequestBuilder(this.rootUrl, HealthCheckWorksheetService.ApiHealthCheckWorksheetNewRequestSustainablePostPath, 'post');
    if (params) {
      rb.body(params.body, 'application/json');
    }

    return this.http.request(
      rb.build({ responseType: 'json', accept: 'application/json', context })
    ).pipe(
      filter((r: any): r is HttpResponse<any> => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<HcCreateRes>;
      })
    );
  }

  /**
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `apiHealthCheckWorksheetNewRequestSustainablePost$Response()` instead.
   *
   * This method sends `application/json` and handles request body of type `application/json`.
   */
  apiHealthCheckWorksheetNewRequestSustainablePost(
    params?: {
      body?: HcManualCreateSustainableReq
    },
    context?: HttpContext
  ): Observable<HcCreateRes> {
    return this.apiHealthCheckWorksheetNewRequestSustainablePost$Response(params, context).pipe(
      map((r: StrictHttpResponse<HcCreateRes>): HcCreateRes => r.body)
    );
  }

  /** Path part for operation `apiHealthCheckWorksheetInterestInHealthCheckProcessProductPost()` */
  static readonly ApiHealthCheckWorksheetInterestInHealthCheckProcessProductPostPath = '/api/health-check/worksheet/interest/in-health-check-process/product';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `apiHealthCheckWorksheetInterestInHealthCheckProcessProductPost()` instead.
   *
   * This method sends `application/json` and handles request body of type `application/json`.
   */
  apiHealthCheckWorksheetInterestInHealthCheckProcessProductPost$Response(
    params?: {
      fkWorkGroupId?: number;
      body?: Array<string>
    },
    context?: HttpContext
  ): Observable<StrictHttpResponse<HcVendorIsInHealthCheckProcessRes>> {
    const rb = new RequestBuilder(this.rootUrl, HealthCheckWorksheetService.ApiHealthCheckWorksheetInterestInHealthCheckProcessProductPostPath, 'post');
    if (params) {
      rb.query('fkWorkGroupId', params.fkWorkGroupId, {});
      rb.body(params.body, 'application/json');
    }

    return this.http.request(
      rb.build({ responseType: 'json', accept: 'application/json', context })
    ).pipe(
      filter((r: any): r is HttpResponse<any> => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<HcVendorIsInHealthCheckProcessRes>;
      })
    );
  }

  /**
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `apiHealthCheckWorksheetInterestInHealthCheckProcessProductPost$Response()` instead.
   *
   * This method sends `application/json` and handles request body of type `application/json`.
   */
  apiHealthCheckWorksheetInterestInHealthCheckProcessProductPost(
    params?: {
      fkWorkGroupId?: number;
      body?: Array<string>
    },
    context?: HttpContext
  ): Observable<HcVendorIsInHealthCheckProcessRes> {
    return this.apiHealthCheckWorksheetInterestInHealthCheckProcessProductPost$Response(params, context).pipe(
      map((r: StrictHttpResponse<HcVendorIsInHealthCheckProcessRes>): HcVendorIsInHealthCheckProcessRes => r.body)
    );
  }

  /** Path part for operation `apiHealthCheckWorksheetInterestSetSelectedProductsPost()` */
  static readonly ApiHealthCheckWorksheetInterestSetSelectedProductsPostPath = '/api/health-check/worksheet/interest/set-selected-products';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `apiHealthCheckWorksheetInterestSetSelectedProductsPost()` instead.
   *
   * This method sends `application/json` and handles request body of type `application/json`.
   */
  apiHealthCheckWorksheetInterestSetSelectedProductsPost$Response(
    params?: {
      body?: Array<string>
    },
    context?: HttpContext
  ): Observable<StrictHttpResponse<string>> {
    const rb = new RequestBuilder(this.rootUrl, HealthCheckWorksheetService.ApiHealthCheckWorksheetInterestSetSelectedProductsPostPath, 'post');
    if (params) {
      rb.body(params.body, 'application/json');
    }

    return this.http.request(
      rb.build({ responseType: 'json', accept: 'application/json', context })
    ).pipe(
      filter((r: any): r is HttpResponse<any> => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<string>;
      })
    );
  }

  /**
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `apiHealthCheckWorksheetInterestSetSelectedProductsPost$Response()` instead.
   *
   * This method sends `application/json` and handles request body of type `application/json`.
   */
  apiHealthCheckWorksheetInterestSetSelectedProductsPost(
    params?: {
      body?: Array<string>
    },
    context?: HttpContext
  ): Observable<string> {
    return this.apiHealthCheckWorksheetInterestSetSelectedProductsPost$Response(params, context).pipe(
      map((r: StrictHttpResponse<string>): string => r.body)
    );
  }

  /** Path part for operation `apiHealthCheckWorksheetInterestGetSelectedProductsGet()` */
  static readonly ApiHealthCheckWorksheetInterestGetSelectedProductsGetPath = '/api/health-check/worksheet/interest/get-selected-products';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `apiHealthCheckWorksheetInterestGetSelectedProductsGet()` instead.
   *
   * This method doesn't expect any request body.
   */
  apiHealthCheckWorksheetInterestGetSelectedProductsGet$Response(
    params?: {
      key?: string;
    },
    context?: HttpContext
  ): Observable<StrictHttpResponse<Array<HcVendorWithProductOptQueryItemRes>>> {
    const rb = new RequestBuilder(this.rootUrl, HealthCheckWorksheetService.ApiHealthCheckWorksheetInterestGetSelectedProductsGetPath, 'get');
    if (params) {
      rb.query('key', params.key, {});
    }

    return this.http.request(
      rb.build({ responseType: 'json', accept: 'application/json', context })
    ).pipe(
      filter((r: any): r is HttpResponse<any> => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<Array<HcVendorWithProductOptQueryItemRes>>;
      })
    );
  }

  /**
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `apiHealthCheckWorksheetInterestGetSelectedProductsGet$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  apiHealthCheckWorksheetInterestGetSelectedProductsGet(
    params?: {
      key?: string;
    },
    context?: HttpContext
  ): Observable<Array<HcVendorWithProductOptQueryItemRes>> {
    return this.apiHealthCheckWorksheetInterestGetSelectedProductsGet$Response(params, context).pipe(
      map((r: StrictHttpResponse<Array<HcVendorWithProductOptQueryItemRes>>): Array<HcVendorWithProductOptQueryItemRes> => r.body)
    );
  }

  /** Path part for operation `apiHealthCheckWorksheetInterestCreateHealthCheckPost()` */
  static readonly ApiHealthCheckWorksheetInterestCreateHealthCheckPostPath = '/api/health-check/worksheet/interest/create-health-check';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `apiHealthCheckWorksheetInterestCreateHealthCheckPost()` instead.
   *
   * This method sends `application/json` and handles request body of type `application/json`.
   */
  apiHealthCheckWorksheetInterestCreateHealthCheckPost$Response(
    params?: {
      body?: HcInterestCreateReq
    },
    context?: HttpContext
  ): Observable<StrictHttpResponse<Array<HcCreateRes>>> {
    const rb = new RequestBuilder(this.rootUrl, HealthCheckWorksheetService.ApiHealthCheckWorksheetInterestCreateHealthCheckPostPath, 'post');
    if (params) {
      rb.body(params.body, 'application/json');
    }

    return this.http.request(
      rb.build({ responseType: 'json', accept: 'application/json', context })
    ).pipe(
      filter((r: any): r is HttpResponse<any> => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<Array<HcCreateRes>>;
      })
    );
  }

  /**
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `apiHealthCheckWorksheetInterestCreateHealthCheckPost$Response()` instead.
   *
   * This method sends `application/json` and handles request body of type `application/json`.
   */
  apiHealthCheckWorksheetInterestCreateHealthCheckPost(
    params?: {
      body?: HcInterestCreateReq
    },
    context?: HttpContext
  ): Observable<Array<HcCreateRes>> {
    return this.apiHealthCheckWorksheetInterestCreateHealthCheckPost$Response(params, context).pipe(
      map((r: StrictHttpResponse<Array<HcCreateRes>>): Array<HcCreateRes> => r.body)
    );
  }

  /** Path part for operation `apiHealthCheckWorksheetRequesterMgrApproveSingleTransactionPost()` */
  static readonly ApiHealthCheckWorksheetRequesterMgrApproveSingleTransactionPostPath = '/api/health-check/worksheet/requester-mgr-approve/single-transaction';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `apiHealthCheckWorksheetRequesterMgrApproveSingleTransactionPost()` instead.
   *
   * This method sends `application/json` and handles request body of type `application/json`.
   */
  apiHealthCheckWorksheetRequesterMgrApproveSingleTransactionPost$Response(
    params?: {
      body?: HcApproveReq
    },
    context?: HttpContext
  ): Observable<StrictHttpResponse<HcApproveRes>> {
    const rb = new RequestBuilder(this.rootUrl, HealthCheckWorksheetService.ApiHealthCheckWorksheetRequesterMgrApproveSingleTransactionPostPath, 'post');
    if (params) {
      rb.body(params.body, 'application/json');
    }

    return this.http.request(
      rb.build({ responseType: 'json', accept: 'application/json', context })
    ).pipe(
      filter((r: any): r is HttpResponse<any> => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<HcApproveRes>;
      })
    );
  }

  /**
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `apiHealthCheckWorksheetRequesterMgrApproveSingleTransactionPost$Response()` instead.
   *
   * This method sends `application/json` and handles request body of type `application/json`.
   */
  apiHealthCheckWorksheetRequesterMgrApproveSingleTransactionPost(
    params?: {
      body?: HcApproveReq
    },
    context?: HttpContext
  ): Observable<HcApproveRes> {
    return this.apiHealthCheckWorksheetRequesterMgrApproveSingleTransactionPost$Response(params, context).pipe(
      map((r: StrictHttpResponse<HcApproveRes>): HcApproveRes => r.body)
    );
  }

  /** Path part for operation `apiHealthCheckWorksheetRequesterMgrApproveMultipleTransactionPost()` */
  static readonly ApiHealthCheckWorksheetRequesterMgrApproveMultipleTransactionPostPath = '/api/health-check/worksheet/requester-mgr-approve/multiple-transaction';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `apiHealthCheckWorksheetRequesterMgrApproveMultipleTransactionPost()` instead.
   *
   * This method sends `application/json` and handles request body of type `application/json`.
   */
  apiHealthCheckWorksheetRequesterMgrApproveMultipleTransactionPost$Response(
    params?: {
      body?: HcApproveMultipleReq
    },
    context?: HttpContext
  ): Observable<StrictHttpResponse<Array<HcApproveRes>>> {
    const rb = new RequestBuilder(this.rootUrl, HealthCheckWorksheetService.ApiHealthCheckWorksheetRequesterMgrApproveMultipleTransactionPostPath, 'post');
    if (params) {
      rb.body(params.body, 'application/json');
    }

    return this.http.request(
      rb.build({ responseType: 'json', accept: 'application/json', context })
    ).pipe(
      filter((r: any): r is HttpResponse<any> => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<Array<HcApproveRes>>;
      })
    );
  }

  /**
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `apiHealthCheckWorksheetRequesterMgrApproveMultipleTransactionPost$Response()` instead.
   *
   * This method sends `application/json` and handles request body of type `application/json`.
   */
  apiHealthCheckWorksheetRequesterMgrApproveMultipleTransactionPost(
    params?: {
      body?: HcApproveMultipleReq
    },
    context?: HttpContext
  ): Observable<Array<HcApproveRes>> {
    return this.apiHealthCheckWorksheetRequesterMgrApproveMultipleTransactionPost$Response(params, context).pipe(
      map((r: StrictHttpResponse<Array<HcApproveRes>>): Array<HcApproveRes> => r.body)
    );
  }

  /** Path part for operation `apiHealthCheckWorksheetRequesterMgrApproveMultipleTransactionByRefPost()` */
  static readonly ApiHealthCheckWorksheetRequesterMgrApproveMultipleTransactionByRefPostPath = '/api/health-check/worksheet/requester-mgr-approve/multiple-transaction/by-ref';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `apiHealthCheckWorksheetRequesterMgrApproveMultipleTransactionByRefPost()` instead.
   *
   * This method sends `application/json` and handles request body of type `application/json`.
   */
  apiHealthCheckWorksheetRequesterMgrApproveMultipleTransactionByRefPost$Response(
    params?: {
      body?: HcApproveMultipleByRefReq
    },
    context?: HttpContext
  ): Observable<StrictHttpResponse<Array<HcApproveRes>>> {
    const rb = new RequestBuilder(this.rootUrl, HealthCheckWorksheetService.ApiHealthCheckWorksheetRequesterMgrApproveMultipleTransactionByRefPostPath, 'post');
    if (params) {
      rb.body(params.body, 'application/json');
    }

    return this.http.request(
      rb.build({ responseType: 'json', accept: 'application/json', context })
    ).pipe(
      filter((r: any): r is HttpResponse<any> => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<Array<HcApproveRes>>;
      })
    );
  }

  /**
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `apiHealthCheckWorksheetRequesterMgrApproveMultipleTransactionByRefPost$Response()` instead.
   *
   * This method sends `application/json` and handles request body of type `application/json`.
   */
  apiHealthCheckWorksheetRequesterMgrApproveMultipleTransactionByRefPost(
    params?: {
      body?: HcApproveMultipleByRefReq
    },
    context?: HttpContext
  ): Observable<Array<HcApproveRes>> {
    return this.apiHealthCheckWorksheetRequesterMgrApproveMultipleTransactionByRefPost$Response(params, context).pipe(
      map((r: StrictHttpResponse<Array<HcApproveRes>>): Array<HcApproveRes> => r.body)
    );
  }

  /** Path part for operation `apiHealthCheckWorksheetRequesterRevisePost()` */
  static readonly ApiHealthCheckWorksheetRequesterRevisePostPath = '/api/health-check/worksheet/requester-revise';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `apiHealthCheckWorksheetRequesterRevisePost()` instead.
   *
   * This method sends `application/json` and handles request body of type `application/json`.
   */
  apiHealthCheckWorksheetRequesterRevisePost$Response(
    params?: {
      body?: HcReviseReq
    },
    context?: HttpContext
  ): Observable<StrictHttpResponse<void>> {
    const rb = new RequestBuilder(this.rootUrl, HealthCheckWorksheetService.ApiHealthCheckWorksheetRequesterRevisePostPath, 'post');
    if (params) {
      rb.body(params.body, 'application/json');
    }

    return this.http.request(
      rb.build({ responseType: 'text', accept: '*/*', context })
    ).pipe(
      filter((r: any): r is HttpResponse<any> => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return (r as HttpResponse<any>).clone({ body: undefined }) as StrictHttpResponse<void>;
      })
    );
  }

  /**
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `apiHealthCheckWorksheetRequesterRevisePost$Response()` instead.
   *
   * This method sends `application/json` and handles request body of type `application/json`.
   */
  apiHealthCheckWorksheetRequesterRevisePost(
    params?: {
      body?: HcReviseReq
    },
    context?: HttpContext
  ): Observable<void> {
    return this.apiHealthCheckWorksheetRequesterRevisePost$Response(params, context).pipe(
      map((r: StrictHttpResponse<void>): void => r.body)
    );
  }

  /** Path part for operation `apiHealthCheckWorksheetProductsImportByExcelPost()` */
  static readonly ApiHealthCheckWorksheetProductsImportByExcelPostPath = '/api/health-check/worksheet/products/import-by-excel';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `apiHealthCheckWorksheetProductsImportByExcelPost()` instead.
   *
   * This method sends `multipart/form-data` and handles request body of type `multipart/form-data`.
   */
  apiHealthCheckWorksheetProductsImportByExcelPost$Response(
    params?: {
      body?: {
'workGroupList'?: Array<number>;
}
    },
    context?: HttpContext
  ): Observable<StrictHttpResponse<Array<HcTrnProductDto>>> {
    const rb = new RequestBuilder(this.rootUrl, HealthCheckWorksheetService.ApiHealthCheckWorksheetProductsImportByExcelPostPath, 'post');
    if (params) {
      rb.body(params.body, 'multipart/form-data');
    }

    return this.http.request(
      rb.build({ responseType: 'json', accept: 'application/json', context })
    ).pipe(
      filter((r: any): r is HttpResponse<any> => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<Array<HcTrnProductDto>>;
      })
    );
  }

  /**
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `apiHealthCheckWorksheetProductsImportByExcelPost$Response()` instead.
   *
   * This method sends `multipart/form-data` and handles request body of type `multipart/form-data`.
   */
  apiHealthCheckWorksheetProductsImportByExcelPost(
    params?: {
      body?: {
'workGroupList'?: Array<number>;
}
    },
    context?: HttpContext
  ): Observable<Array<HcTrnProductDto>> {
    return this.apiHealthCheckWorksheetProductsImportByExcelPost$Response(params, context).pipe(
      map((r: StrictHttpResponse<Array<HcTrnProductDto>>): Array<HcTrnProductDto> => r.body)
    );
  }

  /** Path part for operation `apiHealthCheckWorksheetProductsExportToExcelPost()` */
  static readonly ApiHealthCheckWorksheetProductsExportToExcelPostPath = '/api/health-check/worksheet/products/export-to-excel';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `apiHealthCheckWorksheetProductsExportToExcelPost()` instead.
   *
   * This method sends `application/json` and handles request body of type `application/json`.
   */
  apiHealthCheckWorksheetProductsExportToExcelPost$Response(
    params?: {
      hcTrnId?: string;
      body?: Array<HcTrnProductDtoWithoutValidator>
    },
    context?: HttpContext
  ): Observable<StrictHttpResponse<HcExportRes>> {
    const rb = new RequestBuilder(this.rootUrl, HealthCheckWorksheetService.ApiHealthCheckWorksheetProductsExportToExcelPostPath, 'post');
    if (params) {
      rb.query('hcTrnId', params.hcTrnId, {});
      rb.body(params.body, 'application/json');
    }

    return this.http.request(
      rb.build({ responseType: 'json', accept: 'application/json', context })
    ).pipe(
      filter((r: any): r is HttpResponse<any> => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<HcExportRes>;
      })
    );
  }

  /**
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `apiHealthCheckWorksheetProductsExportToExcelPost$Response()` instead.
   *
   * This method sends `application/json` and handles request body of type `application/json`.
   */
  apiHealthCheckWorksheetProductsExportToExcelPost(
    params?: {
      hcTrnId?: string;
      body?: Array<HcTrnProductDtoWithoutValidator>
    },
    context?: HttpContext
  ): Observable<HcExportRes> {
    return this.apiHealthCheckWorksheetProductsExportToExcelPost$Response(params, context).pipe(
      map((r: StrictHttpResponse<HcExportRes>): HcExportRes => r.body)
    );
  }

  /** Path part for operation `apiHealthCheckWorksheetCreateEventActionPost()` */
  static readonly ApiHealthCheckWorksheetCreateEventActionPostPath = '/api/health-check/worksheet/create-event/action';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `apiHealthCheckWorksheetCreateEventActionPost()` instead.
   *
   * This method sends `application/json` and handles request body of type `application/json`.
   */
  apiHealthCheckWorksheetCreateEventActionPost$Response(
    params?: {
      body?: HcCreateEventReq
    },
    context?: HttpContext
  ): Observable<StrictHttpResponse<void>> {
    const rb = new RequestBuilder(this.rootUrl, HealthCheckWorksheetService.ApiHealthCheckWorksheetCreateEventActionPostPath, 'post');
    if (params) {
      rb.body(params.body, 'application/json');
    }

    return this.http.request(
      rb.build({ responseType: 'text', accept: '*/*', context })
    ).pipe(
      filter((r: any): r is HttpResponse<any> => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return (r as HttpResponse<any>).clone({ body: undefined }) as StrictHttpResponse<void>;
      })
    );
  }

  /**
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `apiHealthCheckWorksheetCreateEventActionPost$Response()` instead.
   *
   * This method sends `application/json` and handles request body of type `application/json`.
   */
  apiHealthCheckWorksheetCreateEventActionPost(
    params?: {
      body?: HcCreateEventReq
    },
    context?: HttpContext
  ): Observable<void> {
    return this.apiHealthCheckWorksheetCreateEventActionPost$Response(params, context).pipe(
      map((r: StrictHttpResponse<void>): void => r.body)
    );
  }

  /** Path part for operation `apiHealthCheckWorksheetCreateEventSavePost()` */
  static readonly ApiHealthCheckWorksheetCreateEventSavePostPath = '/api/health-check/worksheet/create-event/save';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `apiHealthCheckWorksheetCreateEventSavePost()` instead.
   *
   * This method sends `application/json` and handles request body of type `application/json`.
   */
  apiHealthCheckWorksheetCreateEventSavePost$Response(
    params?: {
      body?: HcCreateEventSaveReq
    },
    context?: HttpContext
  ): Observable<StrictHttpResponse<void>> {
    const rb = new RequestBuilder(this.rootUrl, HealthCheckWorksheetService.ApiHealthCheckWorksheetCreateEventSavePostPath, 'post');
    if (params) {
      rb.body(params.body, 'application/json');
    }

    return this.http.request(
      rb.build({ responseType: 'text', accept: '*/*', context })
    ).pipe(
      filter((r: any): r is HttpResponse<any> => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return (r as HttpResponse<any>).clone({ body: undefined }) as StrictHttpResponse<void>;
      })
    );
  }

  /**
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `apiHealthCheckWorksheetCreateEventSavePost$Response()` instead.
   *
   * This method sends `application/json` and handles request body of type `application/json`.
   */
  apiHealthCheckWorksheetCreateEventSavePost(
    params?: {
      body?: HcCreateEventSaveReq
    },
    context?: HttpContext
  ): Observable<void> {
    return this.apiHealthCheckWorksheetCreateEventSavePost$Response(params, context).pipe(
      map((r: StrictHttpResponse<void>): void => r.body)
    );
  }

  /** Path part for operation `apiHealthCheckWorksheetPqInitGet()` */
  static readonly ApiHealthCheckWorksheetPqInitGetPath = '/api/health-check/worksheet/pq-init';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `apiHealthCheckWorksheetPqInitGet()` instead.
   *
   * This method doesn't expect any request body.
   */
  apiHealthCheckWorksheetPqInitGet$Response(
    params?: {
      trnId?: string;
    },
    context?: HttpContext
  ): Observable<StrictHttpResponse<Array<HcTrnPqRes>>> {
    const rb = new RequestBuilder(this.rootUrl, HealthCheckWorksheetService.ApiHealthCheckWorksheetPqInitGetPath, 'get');
    if (params) {
      rb.query('trnId', params.trnId, {});
    }

    return this.http.request(
      rb.build({ responseType: 'json', accept: 'application/json', context })
    ).pipe(
      filter((r: any): r is HttpResponse<any> => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<Array<HcTrnPqRes>>;
      })
    );
  }

  /**
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `apiHealthCheckWorksheetPqInitGet$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  apiHealthCheckWorksheetPqInitGet(
    params?: {
      trnId?: string;
    },
    context?: HttpContext
  ): Observable<Array<HcTrnPqRes>> {
    return this.apiHealthCheckWorksheetPqInitGet$Response(params, context).pipe(
      map((r: StrictHttpResponse<Array<HcTrnPqRes>>): Array<HcTrnPqRes> => r.body)
    );
  }

  /** Path part for operation `apiHealthCheckWorksheetVerifyConclusionActionPost()` */
  static readonly ApiHealthCheckWorksheetVerifyConclusionActionPostPath = '/api/health-check/worksheet/verify-conclusion/action';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `apiHealthCheckWorksheetVerifyConclusionActionPost()` instead.
   *
   * This method sends `application/json` and handles request body of type `application/json`.
   */
  apiHealthCheckWorksheetVerifyConclusionActionPost$Response(
    params?: {
      body?: HcVerifyConclustionReq
    },
    context?: HttpContext
  ): Observable<StrictHttpResponse<void>> {
    const rb = new RequestBuilder(this.rootUrl, HealthCheckWorksheetService.ApiHealthCheckWorksheetVerifyConclusionActionPostPath, 'post');
    if (params) {
      rb.body(params.body, 'application/json');
    }

    return this.http.request(
      rb.build({ responseType: 'text', accept: '*/*', context })
    ).pipe(
      filter((r: any): r is HttpResponse<any> => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return (r as HttpResponse<any>).clone({ body: undefined }) as StrictHttpResponse<void>;
      })
    );
  }

  /**
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `apiHealthCheckWorksheetVerifyConclusionActionPost$Response()` instead.
   *
   * This method sends `application/json` and handles request body of type `application/json`.
   */
  apiHealthCheckWorksheetVerifyConclusionActionPost(
    params?: {
      body?: HcVerifyConclustionReq
    },
    context?: HttpContext
  ): Observable<void> {
    return this.apiHealthCheckWorksheetVerifyConclusionActionPost$Response(params, context).pipe(
      map((r: StrictHttpResponse<void>): void => r.body)
    );
  }

  /** Path part for operation `apiHealthCheckWorksheetVerifyConclusionSavePost()` */
  static readonly ApiHealthCheckWorksheetVerifyConclusionSavePostPath = '/api/health-check/worksheet/verify-conclusion/save';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `apiHealthCheckWorksheetVerifyConclusionSavePost()` instead.
   *
   * This method sends `application/json` and handles request body of type `application/json`.
   */
  apiHealthCheckWorksheetVerifyConclusionSavePost$Response(
    params?: {
      body?: HcVerifyConclustionSaveReq
    },
    context?: HttpContext
  ): Observable<StrictHttpResponse<void>> {
    const rb = new RequestBuilder(this.rootUrl, HealthCheckWorksheetService.ApiHealthCheckWorksheetVerifyConclusionSavePostPath, 'post');
    if (params) {
      rb.body(params.body, 'application/json');
    }

    return this.http.request(
      rb.build({ responseType: 'text', accept: '*/*', context })
    ).pipe(
      filter((r: any): r is HttpResponse<any> => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return (r as HttpResponse<any>).clone({ body: undefined }) as StrictHttpResponse<void>;
      })
    );
  }

  /**
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `apiHealthCheckWorksheetVerifyConclusionSavePost$Response()` instead.
   *
   * This method sends `application/json` and handles request body of type `application/json`.
   */
  apiHealthCheckWorksheetVerifyConclusionSavePost(
    params?: {
      body?: HcVerifyConclustionSaveReq
    },
    context?: HttpContext
  ): Observable<void> {
    return this.apiHealthCheckWorksheetVerifyConclusionSavePost$Response(params, context).pipe(
      map((r: StrictHttpResponse<void>): void => r.body)
    );
  }

  /** Path part for operation `apiHealthCheckWorksheetVerifyConclusionCertificateActionPost()` */
  static readonly ApiHealthCheckWorksheetVerifyConclusionCertificateActionPostPath = '/api/health-check/worksheet/verify-conclusion/certificate/action';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `apiHealthCheckWorksheetVerifyConclusionCertificateActionPost()` instead.
   *
   * This method sends `application/json` and handles request body of type `application/json`.
   */
  apiHealthCheckWorksheetVerifyConclusionCertificateActionPost$Response(
    params?: {
      body?: HcVerifyConclustionCertificateReq
    },
    context?: HttpContext
  ): Observable<StrictHttpResponse<void>> {
    const rb = new RequestBuilder(this.rootUrl, HealthCheckWorksheetService.ApiHealthCheckWorksheetVerifyConclusionCertificateActionPostPath, 'post');
    if (params) {
      rb.body(params.body, 'application/json');
    }

    return this.http.request(
      rb.build({ responseType: 'text', accept: '*/*', context })
    ).pipe(
      filter((r: any): r is HttpResponse<any> => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return (r as HttpResponse<any>).clone({ body: undefined }) as StrictHttpResponse<void>;
      })
    );
  }

  /**
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `apiHealthCheckWorksheetVerifyConclusionCertificateActionPost$Response()` instead.
   *
   * This method sends `application/json` and handles request body of type `application/json`.
   */
  apiHealthCheckWorksheetVerifyConclusionCertificateActionPost(
    params?: {
      body?: HcVerifyConclustionCertificateReq
    },
    context?: HttpContext
  ): Observable<void> {
    return this.apiHealthCheckWorksheetVerifyConclusionCertificateActionPost$Response(params, context).pipe(
      map((r: StrictHttpResponse<void>): void => r.body)
    );
  }

  /** Path part for operation `apiHealthCheckWorksheetFinalMgrApproveSingleTransactionPost()` */
  static readonly ApiHealthCheckWorksheetFinalMgrApproveSingleTransactionPostPath = '/api/health-check/worksheet/final-mgr-approve/single-transaction';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `apiHealthCheckWorksheetFinalMgrApproveSingleTransactionPost()` instead.
   *
   * This method sends `application/json` and handles request body of type `application/json`.
   */
  apiHealthCheckWorksheetFinalMgrApproveSingleTransactionPost$Response(
    params?: {
      body?: HcApproveReq
    },
    context?: HttpContext
  ): Observable<StrictHttpResponse<HcApproveRes>> {
    const rb = new RequestBuilder(this.rootUrl, HealthCheckWorksheetService.ApiHealthCheckWorksheetFinalMgrApproveSingleTransactionPostPath, 'post');
    if (params) {
      rb.body(params.body, 'application/json');
    }

    return this.http.request(
      rb.build({ responseType: 'json', accept: 'application/json', context })
    ).pipe(
      filter((r: any): r is HttpResponse<any> => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<HcApproveRes>;
      })
    );
  }

  /**
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `apiHealthCheckWorksheetFinalMgrApproveSingleTransactionPost$Response()` instead.
   *
   * This method sends `application/json` and handles request body of type `application/json`.
   */
  apiHealthCheckWorksheetFinalMgrApproveSingleTransactionPost(
    params?: {
      body?: HcApproveReq
    },
    context?: HttpContext
  ): Observable<HcApproveRes> {
    return this.apiHealthCheckWorksheetFinalMgrApproveSingleTransactionPost$Response(params, context).pipe(
      map((r: StrictHttpResponse<HcApproveRes>): HcApproveRes => r.body)
    );
  }

  /** Path part for operation `apiHealthCheckWorksheetFinalMgrApproveMultipleTransactionPost()` */
  static readonly ApiHealthCheckWorksheetFinalMgrApproveMultipleTransactionPostPath = '/api/health-check/worksheet/final-mgr-approve/multiple-transaction';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `apiHealthCheckWorksheetFinalMgrApproveMultipleTransactionPost()` instead.
   *
   * This method sends `application/json` and handles request body of type `application/json`.
   */
  apiHealthCheckWorksheetFinalMgrApproveMultipleTransactionPost$Response(
    params?: {
      body?: HcApproveMultipleReq
    },
    context?: HttpContext
  ): Observable<StrictHttpResponse<Array<HcApproveRes>>> {
    const rb = new RequestBuilder(this.rootUrl, HealthCheckWorksheetService.ApiHealthCheckWorksheetFinalMgrApproveMultipleTransactionPostPath, 'post');
    if (params) {
      rb.body(params.body, 'application/json');
    }

    return this.http.request(
      rb.build({ responseType: 'json', accept: 'application/json', context })
    ).pipe(
      filter((r: any): r is HttpResponse<any> => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<Array<HcApproveRes>>;
      })
    );
  }

  /**
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `apiHealthCheckWorksheetFinalMgrApproveMultipleTransactionPost$Response()` instead.
   *
   * This method sends `application/json` and handles request body of type `application/json`.
   */
  apiHealthCheckWorksheetFinalMgrApproveMultipleTransactionPost(
    params?: {
      body?: HcApproveMultipleReq
    },
    context?: HttpContext
  ): Observable<Array<HcApproveRes>> {
    return this.apiHealthCheckWorksheetFinalMgrApproveMultipleTransactionPost$Response(params, context).pipe(
      map((r: StrictHttpResponse<Array<HcApproveRes>>): Array<HcApproveRes> => r.body)
    );
  }

  /** Path part for operation `apiHealthCheckWorksheetFinalMgrApproveMultipleTransactionByRefPost()` */
  static readonly ApiHealthCheckWorksheetFinalMgrApproveMultipleTransactionByRefPostPath = '/api/health-check/worksheet/final-mgr-approve/multiple-transaction/by-ref';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `apiHealthCheckWorksheetFinalMgrApproveMultipleTransactionByRefPost()` instead.
   *
   * This method sends `application/json` and handles request body of type `application/json`.
   */
  apiHealthCheckWorksheetFinalMgrApproveMultipleTransactionByRefPost$Response(
    params?: {
      body?: HcApproveMultipleByRefReq
    },
    context?: HttpContext
  ): Observable<StrictHttpResponse<Array<HcApproveRes>>> {
    const rb = new RequestBuilder(this.rootUrl, HealthCheckWorksheetService.ApiHealthCheckWorksheetFinalMgrApproveMultipleTransactionByRefPostPath, 'post');
    if (params) {
      rb.body(params.body, 'application/json');
    }

    return this.http.request(
      rb.build({ responseType: 'json', accept: 'application/json', context })
    ).pipe(
      filter((r: any): r is HttpResponse<any> => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<Array<HcApproveRes>>;
      })
    );
  }

  /**
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `apiHealthCheckWorksheetFinalMgrApproveMultipleTransactionByRefPost$Response()` instead.
   *
   * This method sends `application/json` and handles request body of type `application/json`.
   */
  apiHealthCheckWorksheetFinalMgrApproveMultipleTransactionByRefPost(
    params?: {
      body?: HcApproveMultipleByRefReq
    },
    context?: HttpContext
  ): Observable<Array<HcApproveRes>> {
    return this.apiHealthCheckWorksheetFinalMgrApproveMultipleTransactionByRefPost$Response(params, context).pipe(
      map((r: StrictHttpResponse<Array<HcApproveRes>>): Array<HcApproveRes> => r.body)
    );
  }

  /** Path part for operation `apiHealthCheckWorksheetUpdateVendorCodePost()` */
  static readonly ApiHealthCheckWorksheetUpdateVendorCodePostPath = '/api/health-check/worksheet/update-vendor-code';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `apiHealthCheckWorksheetUpdateVendorCodePost()` instead.
   *
   * This method sends `application/json` and handles request body of type `application/json`.
   */
  apiHealthCheckWorksheetUpdateVendorCodePost$Response(
    params?: {
      body?: HcUpdateVendorCodeReq
    },
    context?: HttpContext
  ): Observable<StrictHttpResponse<void>> {
    const rb = new RequestBuilder(this.rootUrl, HealthCheckWorksheetService.ApiHealthCheckWorksheetUpdateVendorCodePostPath, 'post');
    if (params) {
      rb.body(params.body, 'application/json');
    }

    return this.http.request(
      rb.build({ responseType: 'text', accept: '*/*', context })
    ).pipe(
      filter((r: any): r is HttpResponse<any> => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return (r as HttpResponse<any>).clone({ body: undefined }) as StrictHttpResponse<void>;
      })
    );
  }

  /**
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `apiHealthCheckWorksheetUpdateVendorCodePost$Response()` instead.
   *
   * This method sends `application/json` and handles request body of type `application/json`.
   */
  apiHealthCheckWorksheetUpdateVendorCodePost(
    params?: {
      body?: HcUpdateVendorCodeReq
    },
    context?: HttpContext
  ): Observable<void> {
    return this.apiHealthCheckWorksheetUpdateVendorCodePost$Response(params, context).pipe(
      map((r: StrictHttpResponse<void>): void => r.body)
    );
  }

  /** Path part for operation `apiHealthCheckWorksheetOptDataRequesterStartWithGet()` */
  static readonly ApiHealthCheckWorksheetOptDataRequesterStartWithGetPath = '/api/health-check/worksheet/opt-data/requester/start-with';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `apiHealthCheckWorksheetOptDataRequesterStartWithGet()` instead.
   *
   * This method doesn't expect any request body.
   */
  apiHealthCheckWorksheetOptDataRequesterStartWithGet$Response(
    params?: {
      startWith?: string;
      workGroupIds?: Array<number>;
    },
    context?: HttpContext
  ): Observable<StrictHttpResponse<Array<HcTrnRelatedPeopleRes>>> {
    const rb = new RequestBuilder(this.rootUrl, HealthCheckWorksheetService.ApiHealthCheckWorksheetOptDataRequesterStartWithGetPath, 'get');
    if (params) {
      rb.query('startWith', params.startWith, {});
      rb.query('workGroupIds', params.workGroupIds, {});
    }

    return this.http.request(
      rb.build({ responseType: 'json', accept: 'application/json', context })
    ).pipe(
      filter((r: any): r is HttpResponse<any> => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<Array<HcTrnRelatedPeopleRes>>;
      })
    );
  }

  /**
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `apiHealthCheckWorksheetOptDataRequesterStartWithGet$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  apiHealthCheckWorksheetOptDataRequesterStartWithGet(
    params?: {
      startWith?: string;
      workGroupIds?: Array<number>;
    },
    context?: HttpContext
  ): Observable<Array<HcTrnRelatedPeopleRes>> {
    return this.apiHealthCheckWorksheetOptDataRequesterStartWithGet$Response(params, context).pipe(
      map((r: StrictHttpResponse<Array<HcTrnRelatedPeopleRes>>): Array<HcTrnRelatedPeopleRes> => r.body)
    );
  }

  /** Path part for operation `apiHealthCheckWorksheetOptDataUserStartWithGet()` */
  static readonly ApiHealthCheckWorksheetOptDataUserStartWithGetPath = '/api/health-check/worksheet/opt-data/user/start-with';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `apiHealthCheckWorksheetOptDataUserStartWithGet()` instead.
   *
   * This method doesn't expect any request body.
   */
  apiHealthCheckWorksheetOptDataUserStartWithGet$Response(
    params?: {
      startWith?: string;
      workGroupIds?: Array<number>;
    },
    context?: HttpContext
  ): Observable<StrictHttpResponse<Array<HcTrnRelatedPeopleRes>>> {
    const rb = new RequestBuilder(this.rootUrl, HealthCheckWorksheetService.ApiHealthCheckWorksheetOptDataUserStartWithGetPath, 'get');
    if (params) {
      rb.query('startWith', params.startWith, {});
      rb.query('workGroupIds', params.workGroupIds, {});
    }

    return this.http.request(
      rb.build({ responseType: 'json', accept: 'application/json', context })
    ).pipe(
      filter((r: any): r is HttpResponse<any> => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<Array<HcTrnRelatedPeopleRes>>;
      })
    );
  }

  /**
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `apiHealthCheckWorksheetOptDataUserStartWithGet$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  apiHealthCheckWorksheetOptDataUserStartWithGet(
    params?: {
      startWith?: string;
      workGroupIds?: Array<number>;
    },
    context?: HttpContext
  ): Observable<Array<HcTrnRelatedPeopleRes>> {
    return this.apiHealthCheckWorksheetOptDataUserStartWithGet$Response(params, context).pipe(
      map((r: StrictHttpResponse<Array<HcTrnRelatedPeopleRes>>): Array<HcTrnRelatedPeopleRes> => r.body)
    );
  }

  /** Path part for operation `apiHealthCheckWorksheetOptDataSourcingStartWithGet()` */
  static readonly ApiHealthCheckWorksheetOptDataSourcingStartWithGetPath = '/api/health-check/worksheet/opt-data/sourcing/start-with';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `apiHealthCheckWorksheetOptDataSourcingStartWithGet()` instead.
   *
   * This method doesn't expect any request body.
   */
  apiHealthCheckWorksheetOptDataSourcingStartWithGet$Response(
    params?: {
      startWith?: string;
      workGroupIds?: Array<number>;
    },
    context?: HttpContext
  ): Observable<StrictHttpResponse<Array<HcTrnRelatedPeopleRes>>> {
    const rb = new RequestBuilder(this.rootUrl, HealthCheckWorksheetService.ApiHealthCheckWorksheetOptDataSourcingStartWithGetPath, 'get');
    if (params) {
      rb.query('startWith', params.startWith, {});
      rb.query('workGroupIds', params.workGroupIds, {});
    }

    return this.http.request(
      rb.build({ responseType: 'json', accept: 'application/json', context })
    ).pipe(
      filter((r: any): r is HttpResponse<any> => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<Array<HcTrnRelatedPeopleRes>>;
      })
    );
  }

  /**
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `apiHealthCheckWorksheetOptDataSourcingStartWithGet$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  apiHealthCheckWorksheetOptDataSourcingStartWithGet(
    params?: {
      startWith?: string;
      workGroupIds?: Array<number>;
    },
    context?: HttpContext
  ): Observable<Array<HcTrnRelatedPeopleRes>> {
    return this.apiHealthCheckWorksheetOptDataSourcingStartWithGet$Response(params, context).pipe(
      map((r: StrictHttpResponse<Array<HcTrnRelatedPeopleRes>>): Array<HcTrnRelatedPeopleRes> => r.body)
    );
  }

  /** Path part for operation `apiHealthCheckWorksheetOptDataApproverDefaultGet()` */
  static readonly ApiHealthCheckWorksheetOptDataApproverDefaultGetPath = '/api/health-check/worksheet/opt-data/approver/default';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `apiHealthCheckWorksheetOptDataApproverDefaultGet()` instead.
   *
   * This method doesn't expect any request body.
   */
  apiHealthCheckWorksheetOptDataApproverDefaultGet$Response(
    params?: {
      requiredApprovedLevel?: number;
      requesterEmployeeId?: string;
      workGroupIds?: Array<number>;
    },
    context?: HttpContext
  ): Observable<StrictHttpResponse<HcTrnMgrsRes>> {
    const rb = new RequestBuilder(this.rootUrl, HealthCheckWorksheetService.ApiHealthCheckWorksheetOptDataApproverDefaultGetPath, 'get');
    if (params) {
      rb.query('requiredApprovedLevel', params.requiredApprovedLevel, {});
      rb.query('requesterEmployeeId', params.requesterEmployeeId, {});
      rb.query('workGroupIds', params.workGroupIds, {});
    }

    return this.http.request(
      rb.build({ responseType: 'json', accept: 'application/json', context })
    ).pipe(
      filter((r: any): r is HttpResponse<any> => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<HcTrnMgrsRes>;
      })
    );
  }

  /**
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `apiHealthCheckWorksheetOptDataApproverDefaultGet$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  apiHealthCheckWorksheetOptDataApproverDefaultGet(
    params?: {
      requiredApprovedLevel?: number;
      requesterEmployeeId?: string;
      workGroupIds?: Array<number>;
    },
    context?: HttpContext
  ): Observable<HcTrnMgrsRes> {
    return this.apiHealthCheckWorksheetOptDataApproverDefaultGet$Response(params, context).pipe(
      map((r: StrictHttpResponse<HcTrnMgrsRes>): HcTrnMgrsRes => r.body)
    );
  }

  /** Path part for operation `apiHealthCheckWorksheetOptDataApproverStartWithGet()` */
  static readonly ApiHealthCheckWorksheetOptDataApproverStartWithGetPath = '/api/health-check/worksheet/opt-data/approver/start-with';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `apiHealthCheckWorksheetOptDataApproverStartWithGet()` instead.
   *
   * This method doesn't expect any request body.
   */
  apiHealthCheckWorksheetOptDataApproverStartWithGet$Response(
    params?: {
      startWith?: string;
      requiredApprovedLevel?: number;
      workGroupIds?: Array<number>;
    },
    context?: HttpContext
  ): Observable<StrictHttpResponse<Array<HcTrnMgrRes>>> {
    const rb = new RequestBuilder(this.rootUrl, HealthCheckWorksheetService.ApiHealthCheckWorksheetOptDataApproverStartWithGetPath, 'get');
    if (params) {
      rb.query('startWith', params.startWith, {});
      rb.query('requiredApprovedLevel', params.requiredApprovedLevel, {});
      rb.query('workGroupIds', params.workGroupIds, {});
    }

    return this.http.request(
      rb.build({ responseType: 'json', accept: 'application/json', context })
    ).pipe(
      filter((r: any): r is HttpResponse<any> => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<Array<HcTrnMgrRes>>;
      })
    );
  }

  /**
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `apiHealthCheckWorksheetOptDataApproverStartWithGet$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  apiHealthCheckWorksheetOptDataApproverStartWithGet(
    params?: {
      startWith?: string;
      requiredApprovedLevel?: number;
      workGroupIds?: Array<number>;
    },
    context?: HttpContext
  ): Observable<Array<HcTrnMgrRes>> {
    return this.apiHealthCheckWorksheetOptDataApproverStartWithGet$Response(params, context).pipe(
      map((r: StrictHttpResponse<Array<HcTrnMgrRes>>): Array<HcTrnMgrRes> => r.body)
    );
  }

  /** Path part for operation `apiHealthCheckWorksheetOptDataVendorReAssessmentSearchGet()` */
  static readonly ApiHealthCheckWorksheetOptDataVendorReAssessmentSearchGetPath = '/api/health-check/worksheet/opt-data/vendor/re-assessment/search';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `apiHealthCheckWorksheetOptDataVendorReAssessmentSearchGet()` instead.
   *
   * This method doesn't expect any request body.
   */
  apiHealthCheckWorksheetOptDataVendorReAssessmentSearchGet$Response(
    params?: {
      keyword?: string;
      workGroupList?: Array<number>;
    },
    context?: HttpContext
  ): Observable<StrictHttpResponse<Array<HcVendorOptSearchingRes>>> {
    const rb = new RequestBuilder(this.rootUrl, HealthCheckWorksheetService.ApiHealthCheckWorksheetOptDataVendorReAssessmentSearchGetPath, 'get');
    if (params) {
      rb.query('keyword', params.keyword, {});
      rb.query('workGroupList', params.workGroupList, {});
    }

    return this.http.request(
      rb.build({ responseType: 'json', accept: 'application/json', context })
    ).pipe(
      filter((r: any): r is HttpResponse<any> => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<Array<HcVendorOptSearchingRes>>;
      })
    );
  }

  /**
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `apiHealthCheckWorksheetOptDataVendorReAssessmentSearchGet$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  apiHealthCheckWorksheetOptDataVendorReAssessmentSearchGet(
    params?: {
      keyword?: string;
      workGroupList?: Array<number>;
    },
    context?: HttpContext
  ): Observable<Array<HcVendorOptSearchingRes>> {
    return this.apiHealthCheckWorksheetOptDataVendorReAssessmentSearchGet$Response(params, context).pipe(
      map((r: StrictHttpResponse<Array<HcVendorOptSearchingRes>>): Array<HcVendorOptSearchingRes> => r.body)
    );
  }

  /** Path part for operation `apiHealthCheckWorksheetOptDataVendorSustainableSearchGet()` */
  static readonly ApiHealthCheckWorksheetOptDataVendorSustainableSearchGetPath = '/api/health-check/worksheet/opt-data/vendor/sustainable/search';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `apiHealthCheckWorksheetOptDataVendorSustainableSearchGet()` instead.
   *
   * This method doesn't expect any request body.
   */
  apiHealthCheckWorksheetOptDataVendorSustainableSearchGet$Response(
    params?: {
      keyword?: string;
      workGroupList?: Array<number>;
    },
    context?: HttpContext
  ): Observable<StrictHttpResponse<Array<HcVendorOptSearchingRes>>> {
    const rb = new RequestBuilder(this.rootUrl, HealthCheckWorksheetService.ApiHealthCheckWorksheetOptDataVendorSustainableSearchGetPath, 'get');
    if (params) {
      rb.query('keyword', params.keyword, {});
      rb.query('workGroupList', params.workGroupList, {});
    }

    return this.http.request(
      rb.build({ responseType: 'json', accept: 'application/json', context })
    ).pipe(
      filter((r: any): r is HttpResponse<any> => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<Array<HcVendorOptSearchingRes>>;
      })
    );
  }

  /**
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `apiHealthCheckWorksheetOptDataVendorSustainableSearchGet$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  apiHealthCheckWorksheetOptDataVendorSustainableSearchGet(
    params?: {
      keyword?: string;
      workGroupList?: Array<number>;
    },
    context?: HttpContext
  ): Observable<Array<HcVendorOptSearchingRes>> {
    return this.apiHealthCheckWorksheetOptDataVendorSustainableSearchGet$Response(params, context).pipe(
      map((r: StrictHttpResponse<Array<HcVendorOptSearchingRes>>): Array<HcVendorOptSearchingRes> => r.body)
    );
  }

  /** Path part for operation `apiHealthCheckWorksheetOptDataUnspscSearchGet()` */
  static readonly ApiHealthCheckWorksheetOptDataUnspscSearchGetPath = '/api/health-check/worksheet/opt-data/unspsc/search';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `apiHealthCheckWorksheetOptDataUnspscSearchGet()` instead.
   *
   * This method doesn't expect any request body.
   */
  apiHealthCheckWorksheetOptDataUnspscSearchGet$Response(
    params?: {
      keyword?: string;
      max?: number;
      workGroupIds?: Array<number>;
    },
    context?: HttpContext
  ): Observable<StrictHttpResponse<Array<VenSchUnspscOptDataRes>>> {
    const rb = new RequestBuilder(this.rootUrl, HealthCheckWorksheetService.ApiHealthCheckWorksheetOptDataUnspscSearchGetPath, 'get');
    if (params) {
      rb.query('keyword', params.keyword, {});
      rb.query('max', params.max, {});
      rb.query('workGroupIds', params.workGroupIds, {});
    }

    return this.http.request(
      rb.build({ responseType: 'json', accept: 'application/json', context })
    ).pipe(
      filter((r: any): r is HttpResponse<any> => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<Array<VenSchUnspscOptDataRes>>;
      })
    );
  }

  /**
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `apiHealthCheckWorksheetOptDataUnspscSearchGet$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  apiHealthCheckWorksheetOptDataUnspscSearchGet(
    params?: {
      keyword?: string;
      max?: number;
      workGroupIds?: Array<number>;
    },
    context?: HttpContext
  ): Observable<Array<VenSchUnspscOptDataRes>> {
    return this.apiHealthCheckWorksheetOptDataUnspscSearchGet$Response(params, context).pipe(
      map((r: StrictHttpResponse<Array<VenSchUnspscOptDataRes>>): Array<VenSchUnspscOptDataRes> => r.body)
    );
  }

  /** Path part for operation `apiHealthCheckWorksheetOptDataVendorInactiveQueryPost()` */
  static readonly ApiHealthCheckWorksheetOptDataVendorInactiveQueryPostPath = '/api/health-check/worksheet/opt-data/vendor/inactive/query';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `apiHealthCheckWorksheetOptDataVendorInactiveQueryPost()` instead.
   *
   * This method sends `application/json` and handles request body of type `application/json`.
   */
  apiHealthCheckWorksheetOptDataVendorInactiveQueryPost$Response(
    params?: {
      body?: HcVendorOptQueryReq
    },
    context?: HttpContext
  ): Observable<StrictHttpResponse<HcVendorOptQueryRes>> {
    const rb = new RequestBuilder(this.rootUrl, HealthCheckWorksheetService.ApiHealthCheckWorksheetOptDataVendorInactiveQueryPostPath, 'post');
    if (params) {
      rb.body(params.body, 'application/json');
    }

    return this.http.request(
      rb.build({ responseType: 'json', accept: 'application/json', context })
    ).pipe(
      filter((r: any): r is HttpResponse<any> => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<HcVendorOptQueryRes>;
      })
    );
  }

  /**
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `apiHealthCheckWorksheetOptDataVendorInactiveQueryPost$Response()` instead.
   *
   * This method sends `application/json` and handles request body of type `application/json`.
   */
  apiHealthCheckWorksheetOptDataVendorInactiveQueryPost(
    params?: {
      body?: HcVendorOptQueryReq
    },
    context?: HttpContext
  ): Observable<HcVendorOptQueryRes> {
    return this.apiHealthCheckWorksheetOptDataVendorInactiveQueryPost$Response(params, context).pipe(
      map((r: StrictHttpResponse<HcVendorOptQueryRes>): HcVendorOptQueryRes => r.body)
    );
  }

  /** Path part for operation `apiHealthCheckWorksheetOptDataProductByUnspscQueryPost()` */
  static readonly ApiHealthCheckWorksheetOptDataProductByUnspscQueryPostPath = '/api/health-check/worksheet/opt-data/product/by-unspsc/query';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `apiHealthCheckWorksheetOptDataProductByUnspscQueryPost()` instead.
   *
   * This method sends `application/json` and handles request body of type `application/json`.
   */
  apiHealthCheckWorksheetOptDataProductByUnspscQueryPost$Response(
    params?: {
      body?: HcProductOptQueryReq
    },
    context?: HttpContext
  ): Observable<StrictHttpResponse<HcProductOptQueryRes>> {
    const rb = new RequestBuilder(this.rootUrl, HealthCheckWorksheetService.ApiHealthCheckWorksheetOptDataProductByUnspscQueryPostPath, 'post');
    if (params) {
      rb.body(params.body, 'application/json');
    }

    return this.http.request(
      rb.build({ responseType: 'json', accept: 'application/json', context })
    ).pipe(
      filter((r: any): r is HttpResponse<any> => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<HcProductOptQueryRes>;
      })
    );
  }

  /**
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `apiHealthCheckWorksheetOptDataProductByUnspscQueryPost$Response()` instead.
   *
   * This method sends `application/json` and handles request body of type `application/json`.
   */
  apiHealthCheckWorksheetOptDataProductByUnspscQueryPost(
    params?: {
      body?: HcProductOptQueryReq
    },
    context?: HttpContext
  ): Observable<HcProductOptQueryRes> {
    return this.apiHealthCheckWorksheetOptDataProductByUnspscQueryPost$Response(params, context).pipe(
      map((r: StrictHttpResponse<HcProductOptQueryRes>): HcProductOptQueryRes => r.body)
    );
  }

  /** Path part for operation `apiHealthCheckWorksheetOptDataProductByVendorQueryPost()` */
  static readonly ApiHealthCheckWorksheetOptDataProductByVendorQueryPostPath = '/api/health-check/worksheet/opt-data/product/by-vendor/query';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `apiHealthCheckWorksheetOptDataProductByVendorQueryPost()` instead.
   *
   * This method sends `application/json` and handles request body of type `application/json`.
   */
  apiHealthCheckWorksheetOptDataProductByVendorQueryPost$Response(
    params?: {
      body?: HcProductOptQueryByVendorReq
    },
    context?: HttpContext
  ): Observable<StrictHttpResponse<HcProductOptQueryRes>> {
    const rb = new RequestBuilder(this.rootUrl, HealthCheckWorksheetService.ApiHealthCheckWorksheetOptDataProductByVendorQueryPostPath, 'post');
    if (params) {
      rb.body(params.body, 'application/json');
    }

    return this.http.request(
      rb.build({ responseType: 'json', accept: 'application/json', context })
    ).pipe(
      filter((r: any): r is HttpResponse<any> => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<HcProductOptQueryRes>;
      })
    );
  }

  /**
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `apiHealthCheckWorksheetOptDataProductByVendorQueryPost$Response()` instead.
   *
   * This method sends `application/json` and handles request body of type `application/json`.
   */
  apiHealthCheckWorksheetOptDataProductByVendorQueryPost(
    params?: {
      body?: HcProductOptQueryByVendorReq
    },
    context?: HttpContext
  ): Observable<HcProductOptQueryRes> {
    return this.apiHealthCheckWorksheetOptDataProductByVendorQueryPost$Response(params, context).pipe(
      map((r: StrictHttpResponse<HcProductOptQueryRes>): HcProductOptQueryRes => r.body)
    );
  }

  /** Path part for operation `apiHealthCheckWorksheetOptDataApprovedLevelUnspscPost()` */
  static readonly ApiHealthCheckWorksheetOptDataApprovedLevelUnspscPostPath = '/api/health-check/worksheet/opt-data/approved-level/unspsc';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `apiHealthCheckWorksheetOptDataApprovedLevelUnspscPost()` instead.
   *
   * This method sends `application/json` and handles request body of type `application/json`.
   */
  apiHealthCheckWorksheetOptDataApprovedLevelUnspscPost$Response(
    params?: {
      body?: HcApprovedLevelByUnspscReq
    },
    context?: HttpContext
  ): Observable<StrictHttpResponse<HcWorksheetApprovedLevelRes>> {
    const rb = new RequestBuilder(this.rootUrl, HealthCheckWorksheetService.ApiHealthCheckWorksheetOptDataApprovedLevelUnspscPostPath, 'post');
    if (params) {
      rb.body(params.body, 'application/json');
    }

    return this.http.request(
      rb.build({ responseType: 'json', accept: 'application/json', context })
    ).pipe(
      filter((r: any): r is HttpResponse<any> => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<HcWorksheetApprovedLevelRes>;
      })
    );
  }

  /**
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `apiHealthCheckWorksheetOptDataApprovedLevelUnspscPost$Response()` instead.
   *
   * This method sends `application/json` and handles request body of type `application/json`.
   */
  apiHealthCheckWorksheetOptDataApprovedLevelUnspscPost(
    params?: {
      body?: HcApprovedLevelByUnspscReq
    },
    context?: HttpContext
  ): Observable<HcWorksheetApprovedLevelRes> {
    return this.apiHealthCheckWorksheetOptDataApprovedLevelUnspscPost$Response(params, context).pipe(
      map((r: StrictHttpResponse<HcWorksheetApprovedLevelRes>): HcWorksheetApprovedLevelRes => r.body)
    );
  }

  /** Path part for operation `apiHealthCheckWorksheetOptDataApprovedLevelProductPost()` */
  static readonly ApiHealthCheckWorksheetOptDataApprovedLevelProductPostPath = '/api/health-check/worksheet/opt-data/approved-level/product';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `apiHealthCheckWorksheetOptDataApprovedLevelProductPost()` instead.
   *
   * This method sends `application/json` and handles request body of type `application/json`.
   */
  apiHealthCheckWorksheetOptDataApprovedLevelProductPost$Response(
    params?: {
      body?: HcApprovedLevelByProductReq
    },
    context?: HttpContext
  ): Observable<StrictHttpResponse<HcWorksheetApprovedLevelRes>> {
    const rb = new RequestBuilder(this.rootUrl, HealthCheckWorksheetService.ApiHealthCheckWorksheetOptDataApprovedLevelProductPostPath, 'post');
    if (params) {
      rb.body(params.body, 'application/json');
    }

    return this.http.request(
      rb.build({ responseType: 'json', accept: 'application/json', context })
    ).pipe(
      filter((r: any): r is HttpResponse<any> => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<HcWorksheetApprovedLevelRes>;
      })
    );
  }

  /**
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `apiHealthCheckWorksheetOptDataApprovedLevelProductPost$Response()` instead.
   *
   * This method sends `application/json` and handles request body of type `application/json`.
   */
  apiHealthCheckWorksheetOptDataApprovedLevelProductPost(
    params?: {
      body?: HcApprovedLevelByProductReq
    },
    context?: HttpContext
  ): Observable<HcWorksheetApprovedLevelRes> {
    return this.apiHealthCheckWorksheetOptDataApprovedLevelProductPost$Response(params, context).pipe(
      map((r: StrictHttpResponse<HcWorksheetApprovedLevelRes>): HcWorksheetApprovedLevelRes => r.body)
    );
  }

  /** Path part for operation `apiHealthCheckWorksheetOptDataApprovedLevelVendorPost()` */
  static readonly ApiHealthCheckWorksheetOptDataApprovedLevelVendorPostPath = '/api/health-check/worksheet/opt-data/approved-level/vendor';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `apiHealthCheckWorksheetOptDataApprovedLevelVendorPost()` instead.
   *
   * This method sends `application/json` and handles request body of type `application/json`.
   */
  apiHealthCheckWorksheetOptDataApprovedLevelVendorPost$Response(
    params?: {
      body?: HcApprovedLevelByVendorReq
    },
    context?: HttpContext
  ): Observable<StrictHttpResponse<HcWorksheetApprovedLevelRes>> {
    const rb = new RequestBuilder(this.rootUrl, HealthCheckWorksheetService.ApiHealthCheckWorksheetOptDataApprovedLevelVendorPostPath, 'post');
    if (params) {
      rb.body(params.body, 'application/json');
    }

    return this.http.request(
      rb.build({ responseType: 'json', accept: 'application/json', context })
    ).pipe(
      filter((r: any): r is HttpResponse<any> => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<HcWorksheetApprovedLevelRes>;
      })
    );
  }

  /**
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `apiHealthCheckWorksheetOptDataApprovedLevelVendorPost$Response()` instead.
   *
   * This method sends `application/json` and handles request body of type `application/json`.
   */
  apiHealthCheckWorksheetOptDataApprovedLevelVendorPost(
    params?: {
      body?: HcApprovedLevelByVendorReq
    },
    context?: HttpContext
  ): Observable<HcWorksheetApprovedLevelRes> {
    return this.apiHealthCheckWorksheetOptDataApprovedLevelVendorPost$Response(params, context).pipe(
      map((r: StrictHttpResponse<HcWorksheetApprovedLevelRes>): HcWorksheetApprovedLevelRes => r.body)
    );
  }

  /** Path part for operation `apiHealthCheckWorksheetOptDataFinalApprovedLevelGet()` */
  static readonly ApiHealthCheckWorksheetOptDataFinalApprovedLevelGetPath = '/api/health-check/worksheet/opt-data/final-approved-level';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `apiHealthCheckWorksheetOptDataFinalApprovedLevelGet()` instead.
   *
   * This method doesn't expect any request body.
   */
  apiHealthCheckWorksheetOptDataFinalApprovedLevelGet$Response(
    params?: {
      hcTrnId?: string;
    },
    context?: HttpContext
  ): Observable<StrictHttpResponse<HcWorksheetApprovedLevelRes>> {
    const rb = new RequestBuilder(this.rootUrl, HealthCheckWorksheetService.ApiHealthCheckWorksheetOptDataFinalApprovedLevelGetPath, 'get');
    if (params) {
      rb.query('hcTrnId', params.hcTrnId, {});
    }

    return this.http.request(
      rb.build({ responseType: 'json', accept: 'application/json', context })
    ).pipe(
      filter((r: any): r is HttpResponse<any> => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<HcWorksheetApprovedLevelRes>;
      })
    );
  }

  /**
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `apiHealthCheckWorksheetOptDataFinalApprovedLevelGet$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  apiHealthCheckWorksheetOptDataFinalApprovedLevelGet(
    params?: {
      hcTrnId?: string;
    },
    context?: HttpContext
  ): Observable<HcWorksheetApprovedLevelRes> {
    return this.apiHealthCheckWorksheetOptDataFinalApprovedLevelGet$Response(params, context).pipe(
      map((r: StrictHttpResponse<HcWorksheetApprovedLevelRes>): HcWorksheetApprovedLevelRes => r.body)
    );
  }

  /** Path part for operation `apiHealthCheckWorksheetOptDataIsUrgentGet()` */
  static readonly ApiHealthCheckWorksheetOptDataIsUrgentGetPath = '/api/health-check/worksheet/opt-data/is-urgent';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `apiHealthCheckWorksheetOptDataIsUrgentGet()` instead.
   *
   * This method doesn't expect any request body.
   */
  apiHealthCheckWorksheetOptDataIsUrgentGet$Response(
    params?: {
      expectedDate?: string;
      FKWorkGroupId?: number;
    },
    context?: HttpContext
  ): Observable<StrictHttpResponse<HcIsUrgentRes>> {
    const rb = new RequestBuilder(this.rootUrl, HealthCheckWorksheetService.ApiHealthCheckWorksheetOptDataIsUrgentGetPath, 'get');
    if (params) {
      rb.query('expectedDate', params.expectedDate, {});
      rb.query('FKWorkGroupId', params.FKWorkGroupId, {});
    }

    return this.http.request(
      rb.build({ responseType: 'json', accept: 'application/json', context })
    ).pipe(
      filter((r: any): r is HttpResponse<any> => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<HcIsUrgentRes>;
      })
    );
  }

  /**
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `apiHealthCheckWorksheetOptDataIsUrgentGet$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  apiHealthCheckWorksheetOptDataIsUrgentGet(
    params?: {
      expectedDate?: string;
      FKWorkGroupId?: number;
    },
    context?: HttpContext
  ): Observable<HcIsUrgentRes> {
    return this.apiHealthCheckWorksheetOptDataIsUrgentGet$Response(params, context).pipe(
      map((r: StrictHttpResponse<HcIsUrgentRes>): HcIsUrgentRes => r.body)
    );
  }

  /** Path part for operation `apiHealthCheckWorksheetOptDataSalesDefaultGet()` */
  static readonly ApiHealthCheckWorksheetOptDataSalesDefaultGetPath = '/api/health-check/worksheet/opt-data/sales/default';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `apiHealthCheckWorksheetOptDataSalesDefaultGet()` instead.
   *
   * This method doesn't expect any request body.
   */
  apiHealthCheckWorksheetOptDataSalesDefaultGet$Response(
    params?: {
      hcTrnId?: string;
    },
    context?: HttpContext
  ): Observable<StrictHttpResponse<Array<HcTrnVendorAccountDto>>> {
    const rb = new RequestBuilder(this.rootUrl, HealthCheckWorksheetService.ApiHealthCheckWorksheetOptDataSalesDefaultGetPath, 'get');
    if (params) {
      rb.query('hcTrnId', params.hcTrnId, {});
    }

    return this.http.request(
      rb.build({ responseType: 'json', accept: 'application/json', context })
    ).pipe(
      filter((r: any): r is HttpResponse<any> => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<Array<HcTrnVendorAccountDto>>;
      })
    );
  }

  /**
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `apiHealthCheckWorksheetOptDataSalesDefaultGet$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  apiHealthCheckWorksheetOptDataSalesDefaultGet(
    params?: {
      hcTrnId?: string;
    },
    context?: HttpContext
  ): Observable<Array<HcTrnVendorAccountDto>> {
    return this.apiHealthCheckWorksheetOptDataSalesDefaultGet$Response(params, context).pipe(
      map((r: StrictHttpResponse<Array<HcTrnVendorAccountDto>>): Array<HcTrnVendorAccountDto> => r.body)
    );
  }

  /** Path part for operation `apiHealthCheckWorksheetOptDataStatementTemplatesGet()` */
  static readonly ApiHealthCheckWorksheetOptDataStatementTemplatesGetPath = '/api/health-check/worksheet/opt-data/statement-templates';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `apiHealthCheckWorksheetOptDataStatementTemplatesGet()` instead.
   *
   * This method doesn't expect any request body.
   */
  apiHealthCheckWorksheetOptDataStatementTemplatesGet$Response(
    params?: {
      workGroupIds?: Array<number>;
    },
    context?: HttpContext
  ): Observable<StrictHttpResponse<Array<HcStatementTemplateRes>>> {
    const rb = new RequestBuilder(this.rootUrl, HealthCheckWorksheetService.ApiHealthCheckWorksheetOptDataStatementTemplatesGetPath, 'get');
    if (params) {
      rb.query('workGroupIds', params.workGroupIds, {});
    }

    return this.http.request(
      rb.build({ responseType: 'json', accept: 'application/json', context })
    ).pipe(
      filter((r: any): r is HttpResponse<any> => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<Array<HcStatementTemplateRes>>;
      })
    );
  }

  /**
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `apiHealthCheckWorksheetOptDataStatementTemplatesGet$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  apiHealthCheckWorksheetOptDataStatementTemplatesGet(
    params?: {
      workGroupIds?: Array<number>;
    },
    context?: HttpContext
  ): Observable<Array<HcStatementTemplateRes>> {
    return this.apiHealthCheckWorksheetOptDataStatementTemplatesGet$Response(params, context).pipe(
      map((r: StrictHttpResponse<Array<HcStatementTemplateRes>>): Array<HcStatementTemplateRes> => r.body)
    );
  }

  /** Path part for operation `apiHealthCheckWorksheetOptDataPsTypesPost()` */
  static readonly ApiHealthCheckWorksheetOptDataPsTypesPostPath = '/api/health-check/worksheet/opt-data/ps-types';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `apiHealthCheckWorksheetOptDataPsTypesPost()` instead.
   *
   * This method sends `application/json` and handles request body of type `application/json`.
   */
  apiHealthCheckWorksheetOptDataPsTypesPost$Response(
    params?: {
      workGroupIds?: Array<number>;
      body?: Array<string>
    },
    context?: HttpContext
  ): Observable<StrictHttpResponse<Array<HcUnspscAndPsType>>> {
    const rb = new RequestBuilder(this.rootUrl, HealthCheckWorksheetService.ApiHealthCheckWorksheetOptDataPsTypesPostPath, 'post');
    if (params) {
      rb.query('workGroupIds', params.workGroupIds, {});
      rb.body(params.body, 'application/json');
    }

    return this.http.request(
      rb.build({ responseType: 'json', accept: 'application/json', context })
    ).pipe(
      filter((r: any): r is HttpResponse<any> => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<Array<HcUnspscAndPsType>>;
      })
    );
  }

  /**
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `apiHealthCheckWorksheetOptDataPsTypesPost$Response()` instead.
   *
   * This method sends `application/json` and handles request body of type `application/json`.
   */
  apiHealthCheckWorksheetOptDataPsTypesPost(
    params?: {
      workGroupIds?: Array<number>;
      body?: Array<string>
    },
    context?: HttpContext
  ): Observable<Array<HcUnspscAndPsType>> {
    return this.apiHealthCheckWorksheetOptDataPsTypesPost$Response(params, context).pipe(
      map((r: StrictHttpResponse<Array<HcUnspscAndPsType>>): Array<HcUnspscAndPsType> => r.body)
    );
  }

  /** Path part for operation `apiHealthCheckWorksheetOptDataCalSeverityPost()` */
  static readonly ApiHealthCheckWorksheetOptDataCalSeverityPostPath = '/api/health-check/worksheet/opt-data/cal-severity';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `apiHealthCheckWorksheetOptDataCalSeverityPost()` instead.
   *
   * This method sends `application/json` and handles request body of type `application/json`.
   */
  apiHealthCheckWorksheetOptDataCalSeverityPost$Response(
    params?: {
      workGroupIds?: Array<number>;
      body?: Array<string>
    },
    context?: HttpContext
  ): Observable<StrictHttpResponse<HcCalSeverityRes>> {
    const rb = new RequestBuilder(this.rootUrl, HealthCheckWorksheetService.ApiHealthCheckWorksheetOptDataCalSeverityPostPath, 'post');
    if (params) {
      rb.query('workGroupIds', params.workGroupIds, {});
      rb.body(params.body, 'application/json');
    }

    return this.http.request(
      rb.build({ responseType: 'json', accept: 'application/json', context })
    ).pipe(
      filter((r: any): r is HttpResponse<any> => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<HcCalSeverityRes>;
      })
    );
  }

  /**
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `apiHealthCheckWorksheetOptDataCalSeverityPost$Response()` instead.
   *
   * This method sends `application/json` and handles request body of type `application/json`.
   */
  apiHealthCheckWorksheetOptDataCalSeverityPost(
    params?: {
      workGroupIds?: Array<number>;
      body?: Array<string>
    },
    context?: HttpContext
  ): Observable<HcCalSeverityRes> {
    return this.apiHealthCheckWorksheetOptDataCalSeverityPost$Response(params, context).pipe(
      map((r: StrictHttpResponse<HcCalSeverityRes>): HcCalSeverityRes => r.body)
    );
  }

  /** Path part for operation `apiHealthCheckWorksheetOptDataHcFormsUnspscPost()` */
  static readonly ApiHealthCheckWorksheetOptDataHcFormsUnspscPostPath = '/api/health-check/worksheet/opt-data/hc-forms/unspsc';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `apiHealthCheckWorksheetOptDataHcFormsUnspscPost()` instead.
   *
   * This method sends `application/json` and handles request body of type `application/json`.
   */
  apiHealthCheckWorksheetOptDataHcFormsUnspscPost$Response(
    params?: {
      hcTrnId?: string;
      workGroupIds?: Array<number>;
      body?: Array<string>
    },
    context?: HttpContext
  ): Observable<StrictHttpResponse<Array<HcTrnFormRes>>> {
    const rb = new RequestBuilder(this.rootUrl, HealthCheckWorksheetService.ApiHealthCheckWorksheetOptDataHcFormsUnspscPostPath, 'post');
    if (params) {
      rb.query('hcTrnId', params.hcTrnId, {});
      rb.query('workGroupIds', params.workGroupIds, {});
      rb.body(params.body, 'application/json');
    }

    return this.http.request(
      rb.build({ responseType: 'json', accept: 'application/json', context })
    ).pipe(
      filter((r: any): r is HttpResponse<any> => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<Array<HcTrnFormRes>>;
      })
    );
  }

  /**
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `apiHealthCheckWorksheetOptDataHcFormsUnspscPost$Response()` instead.
   *
   * This method sends `application/json` and handles request body of type `application/json`.
   */
  apiHealthCheckWorksheetOptDataHcFormsUnspscPost(
    params?: {
      hcTrnId?: string;
      workGroupIds?: Array<number>;
      body?: Array<string>
    },
    context?: HttpContext
  ): Observable<Array<HcTrnFormRes>> {
    return this.apiHealthCheckWorksheetOptDataHcFormsUnspscPost$Response(params, context).pipe(
      map((r: StrictHttpResponse<Array<HcTrnFormRes>>): Array<HcTrnFormRes> => r.body)
    );
  }

  /** Path part for operation `apiHealthCheckWorksheetOptDataHcFormsGet()` */
  static readonly ApiHealthCheckWorksheetOptDataHcFormsGetPath = '/api/health-check/worksheet/opt-data/hc-forms';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `apiHealthCheckWorksheetOptDataHcFormsGet()` instead.
   *
   * This method doesn't expect any request body.
   */
  apiHealthCheckWorksheetOptDataHcFormsGet$Response(
    params?: {
      workGroupIds?: number;
    },
    context?: HttpContext
  ): Observable<StrictHttpResponse<Array<HcTrnFormRes>>> {
    const rb = new RequestBuilder(this.rootUrl, HealthCheckWorksheetService.ApiHealthCheckWorksheetOptDataHcFormsGetPath, 'get');
    if (params) {
      rb.query('workGroupIds', params.workGroupIds, {});
    }

    return this.http.request(
      rb.build({ responseType: 'json', accept: 'application/json', context })
    ).pipe(
      filter((r: any): r is HttpResponse<any> => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<Array<HcTrnFormRes>>;
      })
    );
  }

  /**
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `apiHealthCheckWorksheetOptDataHcFormsGet$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  apiHealthCheckWorksheetOptDataHcFormsGet(
    params?: {
      workGroupIds?: number;
    },
    context?: HttpContext
  ): Observable<Array<HcTrnFormRes>> {
    return this.apiHealthCheckWorksheetOptDataHcFormsGet$Response(params, context).pipe(
      map((r: StrictHttpResponse<Array<HcTrnFormRes>>): Array<HcTrnFormRes> => r.body)
    );
  }

  /** Path part for operation `apiHealthCheckWorksheetChatMessagesGet()` */
  static readonly ApiHealthCheckWorksheetChatMessagesGetPath = '/api/health-check/worksheet/chat/messages';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `apiHealthCheckWorksheetChatMessagesGet()` instead.
   *
   * This method doesn't expect any request body.
   */
  apiHealthCheckWorksheetChatMessagesGet$Response(
    params?: {
      refId?: string;
    },
    context?: HttpContext
  ): Observable<StrictHttpResponse<Array<HcTrnChatRes>>> {
    const rb = new RequestBuilder(this.rootUrl, HealthCheckWorksheetService.ApiHealthCheckWorksheetChatMessagesGetPath, 'get');
    if (params) {
      rb.query('refId', params.refId, {});
    }

    return this.http.request(
      rb.build({ responseType: 'json', accept: 'application/json', context })
    ).pipe(
      filter((r: any): r is HttpResponse<any> => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<Array<HcTrnChatRes>>;
      })
    );
  }

  /**
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `apiHealthCheckWorksheetChatMessagesGet$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  apiHealthCheckWorksheetChatMessagesGet(
    params?: {
      refId?: string;
    },
    context?: HttpContext
  ): Observable<Array<HcTrnChatRes>> {
    return this.apiHealthCheckWorksheetChatMessagesGet$Response(params, context).pipe(
      map((r: StrictHttpResponse<Array<HcTrnChatRes>>): Array<HcTrnChatRes> => r.body)
    );
  }

  /** Path part for operation `apiHealthCheckWorksheetChatMessagesDelete()` */
  static readonly ApiHealthCheckWorksheetChatMessagesDeletePath = '/api/health-check/worksheet/chat/messages';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `apiHealthCheckWorksheetChatMessagesDelete()` instead.
   *
   * This method doesn't expect any request body.
   */
  apiHealthCheckWorksheetChatMessagesDelete$Response(
    params?: {
      chatId?: number;
    },
    context?: HttpContext
  ): Observable<StrictHttpResponse<void>> {
    const rb = new RequestBuilder(this.rootUrl, HealthCheckWorksheetService.ApiHealthCheckWorksheetChatMessagesDeletePath, 'delete');
    if (params) {
      rb.query('chatId', params.chatId, {});
    }

    return this.http.request(
      rb.build({ responseType: 'text', accept: '*/*', context })
    ).pipe(
      filter((r: any): r is HttpResponse<any> => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return (r as HttpResponse<any>).clone({ body: undefined }) as StrictHttpResponse<void>;
      })
    );
  }

  /**
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `apiHealthCheckWorksheetChatMessagesDelete$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  apiHealthCheckWorksheetChatMessagesDelete(
    params?: {
      chatId?: number;
    },
    context?: HttpContext
  ): Observable<void> {
    return this.apiHealthCheckWorksheetChatMessagesDelete$Response(params, context).pipe(
      map((r: StrictHttpResponse<void>): void => r.body)
    );
  }

  /** Path part for operation `apiHealthCheckWorksheetChatMessagePost()` */
  static readonly ApiHealthCheckWorksheetChatMessagePostPath = '/api/health-check/worksheet/chat/message';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `apiHealthCheckWorksheetChatMessagePost()` instead.
   *
   * This method sends `application/json` and handles request body of type `application/json`.
   */
  apiHealthCheckWorksheetChatMessagePost$Response(
    params?: {
      body?: HcTrnChatReq
    },
    context?: HttpContext
  ): Observable<StrictHttpResponse<void>> {
    const rb = new RequestBuilder(this.rootUrl, HealthCheckWorksheetService.ApiHealthCheckWorksheetChatMessagePostPath, 'post');
    if (params) {
      rb.body(params.body, 'application/json');
    }

    return this.http.request(
      rb.build({ responseType: 'text', accept: '*/*', context })
    ).pipe(
      filter((r: any): r is HttpResponse<any> => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return (r as HttpResponse<any>).clone({ body: undefined }) as StrictHttpResponse<void>;
      })
    );
  }

  /**
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `apiHealthCheckWorksheetChatMessagePost$Response()` instead.
   *
   * This method sends `application/json` and handles request body of type `application/json`.
   */
  apiHealthCheckWorksheetChatMessagePost(
    params?: {
      body?: HcTrnChatReq
    },
    context?: HttpContext
  ): Observable<void> {
    return this.apiHealthCheckWorksheetChatMessagePost$Response(params, context).pipe(
      map((r: StrictHttpResponse<void>): void => r.body)
    );
  }

  /** Path part for operation `apiHealthCheckWorksheetAdminCancelGet()` */
  static readonly ApiHealthCheckWorksheetAdminCancelGetPath = '/api/health-check/worksheet/admin-cancel';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `apiHealthCheckWorksheetAdminCancelGet()` instead.
   *
   * This method doesn't expect any request body.
   */
  apiHealthCheckWorksheetAdminCancelGet$Response(
    params?: {
      pkHcTrnId?: string;
      remark?: string;
    },
    context?: HttpContext
  ): Observable<StrictHttpResponse<void>> {
    const rb = new RequestBuilder(this.rootUrl, HealthCheckWorksheetService.ApiHealthCheckWorksheetAdminCancelGetPath, 'get');
    if (params) {
      rb.query('pkHcTrnId', params.pkHcTrnId, {});
      rb.query('remark', params.remark, {});
    }

    return this.http.request(
      rb.build({ responseType: 'text', accept: '*/*', context })
    ).pipe(
      filter((r: any): r is HttpResponse<any> => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return (r as HttpResponse<any>).clone({ body: undefined }) as StrictHttpResponse<void>;
      })
    );
  }

  /**
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `apiHealthCheckWorksheetAdminCancelGet$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  apiHealthCheckWorksheetAdminCancelGet(
    params?: {
      pkHcTrnId?: string;
      remark?: string;
    },
    context?: HttpContext
  ): Observable<void> {
    return this.apiHealthCheckWorksheetAdminCancelGet$Response(params, context).pipe(
      map((r: StrictHttpResponse<void>): void => r.body)
    );
  }

  /** Path part for operation `apiHealthCheckWorksheetAdminReassignVmAdminGet()` */
  static readonly ApiHealthCheckWorksheetAdminReassignVmAdminGetPath = '/api/health-check/worksheet/admin-reassign-vm-admin';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `apiHealthCheckWorksheetAdminReassignVmAdminGet()` instead.
   *
   * This method doesn't expect any request body.
   */
  apiHealthCheckWorksheetAdminReassignVmAdminGet$Response(
    params?: {
      pkHcTrnId?: string;
      newBuyerId?: string;
      remark?: string;
    },
    context?: HttpContext
  ): Observable<StrictHttpResponse<void>> {
    const rb = new RequestBuilder(this.rootUrl, HealthCheckWorksheetService.ApiHealthCheckWorksheetAdminReassignVmAdminGetPath, 'get');
    if (params) {
      rb.query('pkHcTrnId', params.pkHcTrnId, {});
      rb.query('newBuyerId', params.newBuyerId, {});
      rb.query('remark', params.remark, {});
    }

    return this.http.request(
      rb.build({ responseType: 'text', accept: '*/*', context })
    ).pipe(
      filter((r: any): r is HttpResponse<any> => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return (r as HttpResponse<any>).clone({ body: undefined }) as StrictHttpResponse<void>;
      })
    );
  }

  /**
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `apiHealthCheckWorksheetAdminReassignVmAdminGet$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  apiHealthCheckWorksheetAdminReassignVmAdminGet(
    params?: {
      pkHcTrnId?: string;
      newBuyerId?: string;
      remark?: string;
    },
    context?: HttpContext
  ): Observable<void> {
    return this.apiHealthCheckWorksheetAdminReassignVmAdminGet$Response(params, context).pipe(
      map((r: StrictHttpResponse<void>): void => r.body)
    );
  }

}
