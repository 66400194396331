/* tslint:disable */
/* eslint-disable */
import { HttpClient, HttpContext, HttpResponse } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { filter, map } from 'rxjs/operators';

import { BaseService } from '../base-service';
import { ApiConfiguration } from '../api-configuration';
import { StrictHttpResponse } from '../strict-http-response';
import { RequestBuilder } from '../request-builder';

import { AuditExportRes } from '../models/audit-export-res';
import { AuditTrnRequestFormReq } from '../models/audit-trn-request-form-req';
import { ExportExcelFileRes } from '../models/export-excel-file-res';

@Injectable({ providedIn: 'root' })
export class AuditExportDocumentService extends BaseService {
  constructor(config: ApiConfiguration, http: HttpClient) {
    super(config, http);
  }

  /** Path part for operation `auditExportDocumentRequestFormPost()` */
  static readonly AuditExportDocumentRequestFormPostPath = '/audit/export-document/request-form';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `auditExportDocumentRequestFormPost()` instead.
   *
   * This method sends `application/json` and handles request body of type `application/json`.
   */
  auditExportDocumentRequestFormPost$Response(
    params?: {
      body?: AuditTrnRequestFormReq
    },
    context?: HttpContext
  ): Observable<StrictHttpResponse<AuditExportRes>> {
    const rb = new RequestBuilder(this.rootUrl, AuditExportDocumentService.AuditExportDocumentRequestFormPostPath, 'post');
    if (params) {
      rb.body(params.body, 'application/json');
    }

    return this.http.request(
      rb.build({ responseType: 'json', accept: 'application/json', context })
    ).pipe(
      filter((r: any): r is HttpResponse<any> => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<AuditExportRes>;
      })
    );
  }

  /**
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `auditExportDocumentRequestFormPost$Response()` instead.
   *
   * This method sends `application/json` and handles request body of type `application/json`.
   */
  auditExportDocumentRequestFormPost(
    params?: {
      body?: AuditTrnRequestFormReq
    },
    context?: HttpContext
  ): Observable<AuditExportRes> {
    return this.auditExportDocumentRequestFormPost$Response(params, context).pipe(
      map((r: StrictHttpResponse<AuditExportRes>): AuditExportRes => r.body)
    );
  }

  /** Path part for operation `auditExportDocumentResultFormGet()` */
  static readonly AuditExportDocumentResultFormGetPath = '/audit/export-document/result-form';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `auditExportDocumentResultFormGet()` instead.
   *
   * This method doesn't expect any request body.
   */
  auditExportDocumentResultFormGet$Response(
    params?: {
      auditTrnId?: string;
    },
    context?: HttpContext
  ): Observable<StrictHttpResponse<ExportExcelFileRes>> {
    const rb = new RequestBuilder(this.rootUrl, AuditExportDocumentService.AuditExportDocumentResultFormGetPath, 'get');
    if (params) {
      rb.query('auditTrnId', params.auditTrnId, {});
    }

    return this.http.request(
      rb.build({ responseType: 'json', accept: 'application/json', context })
    ).pipe(
      filter((r: any): r is HttpResponse<any> => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<ExportExcelFileRes>;
      })
    );
  }

  /**
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `auditExportDocumentResultFormGet$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  auditExportDocumentResultFormGet(
    params?: {
      auditTrnId?: string;
    },
    context?: HttpContext
  ): Observable<ExportExcelFileRes> {
    return this.auditExportDocumentResultFormGet$Response(params, context).pipe(
      map((r: StrictHttpResponse<ExportExcelFileRes>): ExportExcelFileRes => r.body)
    );
  }

}
