/* tslint:disable */
/* eslint-disable */
import { HttpClient, HttpContext, HttpResponse } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { filter, map } from 'rxjs/operators';

import { BaseService } from '../base-service';
import { ApiConfiguration } from '../api-configuration';
import { StrictHttpResponse } from '../strict-http-response';
import { RequestBuilder } from '../request-builder';

import { ExportExcelFileRes } from '../models/export-excel-file-res';
import { StbActivityLogRes } from '../models/stb-activity-log-res';
import { StbAdRefQueryReq } from '../models/stb-ad-ref-query-req';
import { StbAdRefQueryRes } from '../models/stb-ad-ref-query-res';
import { StbCreateRes } from '../models/stb-create-res';
import { StbDraftSubmitReq } from '../models/stb-draft-submit-req';
import { StbHcRefQueryReq } from '../models/stb-hc-ref-query-req';
import { StbHcRefQueryRes } from '../models/stb-hc-ref-query-res';
import { StbInitRes } from '../models/stb-init-res';
import { StbOnPlanSaveReq } from '../models/stb-on-plan-save-req';
import { StbOnPlanSubmitReq } from '../models/stb-on-plan-submit-req';
import { StbRefQueryReq } from '../models/stb-ref-query-req';
import { StbRefQueryRes } from '../models/stb-ref-query-res';
import { StbRes } from '../models/stb-res';
import { StbSaveReq } from '../models/stb-save-req';
import { StbSubmitReq } from '../models/stb-submit-req';
import { StbTrnRelatedPeopleRes } from '../models/stb-trn-related-people-res';
import { StbTrnVendorAccountDto } from '../models/stb-trn-vendor-account-dto';
import { StbVendorOptSearchingRes } from '../models/stb-vendor-opt-searching-res';
import { StbVendorProductQueryReq } from '../models/stb-vendor-product-query-req';
import { StbVendorProductQueryRes } from '../models/stb-vendor-product-query-res';
import { VenSchUnspscOptDataRes } from '../models/ven-sch-unspsc-opt-data-res';

@Injectable({ providedIn: 'root' })
export class SustainableWorksheetService extends BaseService {
  constructor(config: ApiConfiguration, http: HttpClient) {
    super(config, http);
  }

  /** Path part for operation `apiSustainableWorksheetDataInitGet()` */
  static readonly ApiSustainableWorksheetDataInitGetPath = '/api/sustainable/worksheet/data-init';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `apiSustainableWorksheetDataInitGet()` instead.
   *
   * This method doesn't expect any request body.
   */
  apiSustainableWorksheetDataInitGet$Response(
    params?: {
    },
    context?: HttpContext
  ): Observable<StrictHttpResponse<StbInitRes>> {
    const rb = new RequestBuilder(this.rootUrl, SustainableWorksheetService.ApiSustainableWorksheetDataInitGetPath, 'get');
    if (params) {
    }

    return this.http.request(
      rb.build({ responseType: 'json', accept: 'application/json', context })
    ).pipe(
      filter((r: any): r is HttpResponse<any> => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<StbInitRes>;
      })
    );
  }

  /**
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `apiSustainableWorksheetDataInitGet$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  apiSustainableWorksheetDataInitGet(
    params?: {
    },
    context?: HttpContext
  ): Observable<StbInitRes> {
    return this.apiSustainableWorksheetDataInitGet$Response(params, context).pipe(
      map((r: StrictHttpResponse<StbInitRes>): StbInitRes => r.body)
    );
  }

  /** Path part for operation `apiSustainableWorksheetGet()` */
  static readonly ApiSustainableWorksheetGetPath = '/api/sustainable/worksheet';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `apiSustainableWorksheetGet()` instead.
   *
   * This method doesn't expect any request body.
   */
  apiSustainableWorksheetGet$Response(
    params?: {
      trnId?: string;
    },
    context?: HttpContext
  ): Observable<StrictHttpResponse<StbRes>> {
    const rb = new RequestBuilder(this.rootUrl, SustainableWorksheetService.ApiSustainableWorksheetGetPath, 'get');
    if (params) {
      rb.query('trnId', params.trnId, {});
    }

    return this.http.request(
      rb.build({ responseType: 'json', accept: 'application/json', context })
    ).pipe(
      filter((r: any): r is HttpResponse<any> => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<StbRes>;
      })
    );
  }

  /**
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `apiSustainableWorksheetGet$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  apiSustainableWorksheetGet(
    params?: {
      trnId?: string;
    },
    context?: HttpContext
  ): Observable<StbRes> {
    return this.apiSustainableWorksheetGet$Response(params, context).pipe(
      map((r: StrictHttpResponse<StbRes>): StbRes => r.body)
    );
  }

  /** Path part for operation `apiSustainableWorksheetFormInitGet()` */
  static readonly ApiSustainableWorksheetFormInitGetPath = '/api/sustainable/worksheet/form-init';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `apiSustainableWorksheetFormInitGet()` instead.
   *
   * This method doesn't expect any request body.
   */
  apiSustainableWorksheetFormInitGet$Response(
    params?: {
      workGroupList?: Array<number>;
    },
    context?: HttpContext
  ): Observable<StrictHttpResponse<StbRes>> {
    const rb = new RequestBuilder(this.rootUrl, SustainableWorksheetService.ApiSustainableWorksheetFormInitGetPath, 'get');
    if (params) {
      rb.query('workGroupList', params.workGroupList, {});
    }

    return this.http.request(
      rb.build({ responseType: 'json', accept: 'application/json', context })
    ).pipe(
      filter((r: any): r is HttpResponse<any> => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<StbRes>;
      })
    );
  }

  /**
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `apiSustainableWorksheetFormInitGet$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  apiSustainableWorksheetFormInitGet(
    params?: {
      workGroupList?: Array<number>;
    },
    context?: HttpContext
  ): Observable<StbRes> {
    return this.apiSustainableWorksheetFormInitGet$Response(params, context).pipe(
      map((r: StrictHttpResponse<StbRes>): StbRes => r.body)
    );
  }

  /** Path part for operation `apiSustainableWorksheetActivityLogsGet()` */
  static readonly ApiSustainableWorksheetActivityLogsGetPath = '/api/sustainable/worksheet/activity-logs';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `apiSustainableWorksheetActivityLogsGet()` instead.
   *
   * This method doesn't expect any request body.
   */
  apiSustainableWorksheetActivityLogsGet$Response(
    params?: {
      trnId?: string;
    },
    context?: HttpContext
  ): Observable<StrictHttpResponse<Array<StbActivityLogRes>>> {
    const rb = new RequestBuilder(this.rootUrl, SustainableWorksheetService.ApiSustainableWorksheetActivityLogsGetPath, 'get');
    if (params) {
      rb.query('trnId', params.trnId, {});
    }

    return this.http.request(
      rb.build({ responseType: 'json', accept: 'application/json', context })
    ).pipe(
      filter((r: any): r is HttpResponse<any> => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<Array<StbActivityLogRes>>;
      })
    );
  }

  /**
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `apiSustainableWorksheetActivityLogsGet$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  apiSustainableWorksheetActivityLogsGet(
    params?: {
      trnId?: string;
    },
    context?: HttpContext
  ): Observable<Array<StbActivityLogRes>> {
    return this.apiSustainableWorksheetActivityLogsGet$Response(params, context).pipe(
      map((r: StrictHttpResponse<Array<StbActivityLogRes>>): Array<StbActivityLogRes> => r.body)
    );
  }

  /** Path part for operation `apiSustainableWorksheetNewRequestSavePost()` */
  static readonly ApiSustainableWorksheetNewRequestSavePostPath = '/api/sustainable/worksheet/new-request/save';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `apiSustainableWorksheetNewRequestSavePost()` instead.
   *
   * This method sends `application/json` and handles request body of type `application/json`.
   */
  apiSustainableWorksheetNewRequestSavePost$Response(
    params?: {
      body?: StbSaveReq
    },
    context?: HttpContext
  ): Observable<StrictHttpResponse<StbCreateRes>> {
    const rb = new RequestBuilder(this.rootUrl, SustainableWorksheetService.ApiSustainableWorksheetNewRequestSavePostPath, 'post');
    if (params) {
      rb.body(params.body, 'application/json');
    }

    return this.http.request(
      rb.build({ responseType: 'json', accept: 'application/json', context })
    ).pipe(
      filter((r: any): r is HttpResponse<any> => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<StbCreateRes>;
      })
    );
  }

  /**
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `apiSustainableWorksheetNewRequestSavePost$Response()` instead.
   *
   * This method sends `application/json` and handles request body of type `application/json`.
   */
  apiSustainableWorksheetNewRequestSavePost(
    params?: {
      body?: StbSaveReq
    },
    context?: HttpContext
  ): Observable<StbCreateRes> {
    return this.apiSustainableWorksheetNewRequestSavePost$Response(params, context).pipe(
      map((r: StrictHttpResponse<StbCreateRes>): StbCreateRes => r.body)
    );
  }

  /** Path part for operation `apiSustainableWorksheetNewRequestSubmitPost()` */
  static readonly ApiSustainableWorksheetNewRequestSubmitPostPath = '/api/sustainable/worksheet/new-request/submit';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `apiSustainableWorksheetNewRequestSubmitPost()` instead.
   *
   * This method sends `application/json` and handles request body of type `application/json`.
   */
  apiSustainableWorksheetNewRequestSubmitPost$Response(
    params?: {
      body?: StbSubmitReq
    },
    context?: HttpContext
  ): Observable<StrictHttpResponse<StbCreateRes>> {
    const rb = new RequestBuilder(this.rootUrl, SustainableWorksheetService.ApiSustainableWorksheetNewRequestSubmitPostPath, 'post');
    if (params) {
      rb.body(params.body, 'application/json');
    }

    return this.http.request(
      rb.build({ responseType: 'json', accept: 'application/json', context })
    ).pipe(
      filter((r: any): r is HttpResponse<any> => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<StbCreateRes>;
      })
    );
  }

  /**
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `apiSustainableWorksheetNewRequestSubmitPost$Response()` instead.
   *
   * This method sends `application/json` and handles request body of type `application/json`.
   */
  apiSustainableWorksheetNewRequestSubmitPost(
    params?: {
      body?: StbSubmitReq
    },
    context?: HttpContext
  ): Observable<StbCreateRes> {
    return this.apiSustainableWorksheetNewRequestSubmitPost$Response(params, context).pipe(
      map((r: StrictHttpResponse<StbCreateRes>): StbCreateRes => r.body)
    );
  }

  /** Path part for operation `apiSustainableWorksheetDraftSavePost()` */
  static readonly ApiSustainableWorksheetDraftSavePostPath = '/api/sustainable/worksheet/draft/save';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `apiSustainableWorksheetDraftSavePost()` instead.
   *
   * This method sends `application/json` and handles request body of type `application/json`.
   */
  apiSustainableWorksheetDraftSavePost$Response(
    params?: {
      trnId?: string;
      body?: StbSaveReq
    },
    context?: HttpContext
  ): Observable<StrictHttpResponse<void>> {
    const rb = new RequestBuilder(this.rootUrl, SustainableWorksheetService.ApiSustainableWorksheetDraftSavePostPath, 'post');
    if (params) {
      rb.query('trnId', params.trnId, {});
      rb.body(params.body, 'application/json');
    }

    return this.http.request(
      rb.build({ responseType: 'text', accept: '*/*', context })
    ).pipe(
      filter((r: any): r is HttpResponse<any> => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return (r as HttpResponse<any>).clone({ body: undefined }) as StrictHttpResponse<void>;
      })
    );
  }

  /**
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `apiSustainableWorksheetDraftSavePost$Response()` instead.
   *
   * This method sends `application/json` and handles request body of type `application/json`.
   */
  apiSustainableWorksheetDraftSavePost(
    params?: {
      trnId?: string;
      body?: StbSaveReq
    },
    context?: HttpContext
  ): Observable<void> {
    return this.apiSustainableWorksheetDraftSavePost$Response(params, context).pipe(
      map((r: StrictHttpResponse<void>): void => r.body)
    );
  }

  /** Path part for operation `apiSustainableWorksheetDraftSubmitPost()` */
  static readonly ApiSustainableWorksheetDraftSubmitPostPath = '/api/sustainable/worksheet/draft/submit';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `apiSustainableWorksheetDraftSubmitPost()` instead.
   *
   * This method sends `application/json` and handles request body of type `application/json`.
   */
  apiSustainableWorksheetDraftSubmitPost$Response(
    params?: {
      body?: StbDraftSubmitReq
    },
    context?: HttpContext
  ): Observable<StrictHttpResponse<StbCreateRes>> {
    const rb = new RequestBuilder(this.rootUrl, SustainableWorksheetService.ApiSustainableWorksheetDraftSubmitPostPath, 'post');
    if (params) {
      rb.body(params.body, 'application/json');
    }

    return this.http.request(
      rb.build({ responseType: 'json', accept: 'application/json', context })
    ).pipe(
      filter((r: any): r is HttpResponse<any> => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<StbCreateRes>;
      })
    );
  }

  /**
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `apiSustainableWorksheetDraftSubmitPost$Response()` instead.
   *
   * This method sends `application/json` and handles request body of type `application/json`.
   */
  apiSustainableWorksheetDraftSubmitPost(
    params?: {
      body?: StbDraftSubmitReq
    },
    context?: HttpContext
  ): Observable<StbCreateRes> {
    return this.apiSustainableWorksheetDraftSubmitPost$Response(params, context).pipe(
      map((r: StrictHttpResponse<StbCreateRes>): StbCreateRes => r.body)
    );
  }

  /** Path part for operation `apiSustainableWorksheetOnPlanSavePost()` */
  static readonly ApiSustainableWorksheetOnPlanSavePostPath = '/api/sustainable/worksheet/on-plan/save';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `apiSustainableWorksheetOnPlanSavePost()` instead.
   *
   * This method sends `application/json` and handles request body of type `application/json`.
   */
  apiSustainableWorksheetOnPlanSavePost$Response(
    params?: {
      trnId?: string;
      body?: StbOnPlanSaveReq
    },
    context?: HttpContext
  ): Observable<StrictHttpResponse<void>> {
    const rb = new RequestBuilder(this.rootUrl, SustainableWorksheetService.ApiSustainableWorksheetOnPlanSavePostPath, 'post');
    if (params) {
      rb.query('trnId', params.trnId, {});
      rb.body(params.body, 'application/json');
    }

    return this.http.request(
      rb.build({ responseType: 'text', accept: '*/*', context })
    ).pipe(
      filter((r: any): r is HttpResponse<any> => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return (r as HttpResponse<any>).clone({ body: undefined }) as StrictHttpResponse<void>;
      })
    );
  }

  /**
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `apiSustainableWorksheetOnPlanSavePost$Response()` instead.
   *
   * This method sends `application/json` and handles request body of type `application/json`.
   */
  apiSustainableWorksheetOnPlanSavePost(
    params?: {
      trnId?: string;
      body?: StbOnPlanSaveReq
    },
    context?: HttpContext
  ): Observable<void> {
    return this.apiSustainableWorksheetOnPlanSavePost$Response(params, context).pipe(
      map((r: StrictHttpResponse<void>): void => r.body)
    );
  }

  /** Path part for operation `apiSustainableWorksheetOnPlanSubmitPost()` */
  static readonly ApiSustainableWorksheetOnPlanSubmitPostPath = '/api/sustainable/worksheet/on-plan/submit';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `apiSustainableWorksheetOnPlanSubmitPost()` instead.
   *
   * This method sends `application/json` and handles request body of type `application/json`.
   */
  apiSustainableWorksheetOnPlanSubmitPost$Response(
    params?: {
      body?: StbOnPlanSubmitReq
    },
    context?: HttpContext
  ): Observable<StrictHttpResponse<StbCreateRes>> {
    const rb = new RequestBuilder(this.rootUrl, SustainableWorksheetService.ApiSustainableWorksheetOnPlanSubmitPostPath, 'post');
    if (params) {
      rb.body(params.body, 'application/json');
    }

    return this.http.request(
      rb.build({ responseType: 'json', accept: 'application/json', context })
    ).pipe(
      filter((r: any): r is HttpResponse<any> => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<StbCreateRes>;
      })
    );
  }

  /**
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `apiSustainableWorksheetOnPlanSubmitPost$Response()` instead.
   *
   * This method sends `application/json` and handles request body of type `application/json`.
   */
  apiSustainableWorksheetOnPlanSubmitPost(
    params?: {
      body?: StbOnPlanSubmitReq
    },
    context?: HttpContext
  ): Observable<StbCreateRes> {
    return this.apiSustainableWorksheetOnPlanSubmitPost$Response(params, context).pipe(
      map((r: StrictHttpResponse<StbCreateRes>): StbCreateRes => r.body)
    );
  }

  /** Path part for operation `apiSustainableWorksheetOptDataRequesterStartWithGet()` */
  static readonly ApiSustainableWorksheetOptDataRequesterStartWithGetPath = '/api/sustainable/worksheet/opt-data/requester/start-with';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `apiSustainableWorksheetOptDataRequesterStartWithGet()` instead.
   *
   * This method doesn't expect any request body.
   */
  apiSustainableWorksheetOptDataRequesterStartWithGet$Response(
    params?: {
      startWith?: string;
    },
    context?: HttpContext
  ): Observable<StrictHttpResponse<Array<StbTrnRelatedPeopleRes>>> {
    const rb = new RequestBuilder(this.rootUrl, SustainableWorksheetService.ApiSustainableWorksheetOptDataRequesterStartWithGetPath, 'get');
    if (params) {
      rb.query('startWith', params.startWith, {});
    }

    return this.http.request(
      rb.build({ responseType: 'json', accept: 'application/json', context })
    ).pipe(
      filter((r: any): r is HttpResponse<any> => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<Array<StbTrnRelatedPeopleRes>>;
      })
    );
  }

  /**
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `apiSustainableWorksheetOptDataRequesterStartWithGet$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  apiSustainableWorksheetOptDataRequesterStartWithGet(
    params?: {
      startWith?: string;
    },
    context?: HttpContext
  ): Observable<Array<StbTrnRelatedPeopleRes>> {
    return this.apiSustainableWorksheetOptDataRequesterStartWithGet$Response(params, context).pipe(
      map((r: StrictHttpResponse<Array<StbTrnRelatedPeopleRes>>): Array<StbTrnRelatedPeopleRes> => r.body)
    );
  }

  /** Path part for operation `apiSustainableWorksheetOptDataVmAdminStartWithGet()` */
  static readonly ApiSustainableWorksheetOptDataVmAdminStartWithGetPath = '/api/sustainable/worksheet/opt-data/vm-admin/start-with';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `apiSustainableWorksheetOptDataVmAdminStartWithGet()` instead.
   *
   * This method doesn't expect any request body.
   */
  apiSustainableWorksheetOptDataVmAdminStartWithGet$Response(
    params?: {
      startWith?: string;
      workGroupList?: Array<number>;
    },
    context?: HttpContext
  ): Observable<StrictHttpResponse<Array<StbTrnRelatedPeopleRes>>> {
    const rb = new RequestBuilder(this.rootUrl, SustainableWorksheetService.ApiSustainableWorksheetOptDataVmAdminStartWithGetPath, 'get');
    if (params) {
      rb.query('startWith', params.startWith, {});
      rb.query('workGroupList', params.workGroupList, {});
    }

    return this.http.request(
      rb.build({ responseType: 'json', accept: 'application/json', context })
    ).pipe(
      filter((r: any): r is HttpResponse<any> => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<Array<StbTrnRelatedPeopleRes>>;
      })
    );
  }

  /**
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `apiSustainableWorksheetOptDataVmAdminStartWithGet$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  apiSustainableWorksheetOptDataVmAdminStartWithGet(
    params?: {
      startWith?: string;
      workGroupList?: Array<number>;
    },
    context?: HttpContext
  ): Observable<Array<StbTrnRelatedPeopleRes>> {
    return this.apiSustainableWorksheetOptDataVmAdminStartWithGet$Response(params, context).pipe(
      map((r: StrictHttpResponse<Array<StbTrnRelatedPeopleRes>>): Array<StbTrnRelatedPeopleRes> => r.body)
    );
  }

  /** Path part for operation `apiSustainableWorksheetOptDataRefPlanQueryPost()` */
  static readonly ApiSustainableWorksheetOptDataRefPlanQueryPostPath = '/api/sustainable/worksheet/opt-data/ref-plan/query';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `apiSustainableWorksheetOptDataRefPlanQueryPost()` instead.
   *
   * This method sends `application/json` and handles request body of type `application/json`.
   */
  apiSustainableWorksheetOptDataRefPlanQueryPost$Response(
    params?: {
      body?: StbRefQueryReq
    },
    context?: HttpContext
  ): Observable<StrictHttpResponse<StbRefQueryRes>> {
    const rb = new RequestBuilder(this.rootUrl, SustainableWorksheetService.ApiSustainableWorksheetOptDataRefPlanQueryPostPath, 'post');
    if (params) {
      rb.body(params.body, 'application/json');
    }

    return this.http.request(
      rb.build({ responseType: 'json', accept: 'application/json', context })
    ).pipe(
      filter((r: any): r is HttpResponse<any> => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<StbRefQueryRes>;
      })
    );
  }

  /**
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `apiSustainableWorksheetOptDataRefPlanQueryPost$Response()` instead.
   *
   * This method sends `application/json` and handles request body of type `application/json`.
   */
  apiSustainableWorksheetOptDataRefPlanQueryPost(
    params?: {
      body?: StbRefQueryReq
    },
    context?: HttpContext
  ): Observable<StbRefQueryRes> {
    return this.apiSustainableWorksheetOptDataRefPlanQueryPost$Response(params, context).pipe(
      map((r: StrictHttpResponse<StbRefQueryRes>): StbRefQueryRes => r.body)
    );
  }

  /** Path part for operation `apiSustainableWorksheetOptDataRefAuditQueryPost()` */
  static readonly ApiSustainableWorksheetOptDataRefAuditQueryPostPath = '/api/sustainable/worksheet/opt-data/ref-audit/query';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `apiSustainableWorksheetOptDataRefAuditQueryPost()` instead.
   *
   * This method sends `application/json` and handles request body of type `application/json`.
   */
  apiSustainableWorksheetOptDataRefAuditQueryPost$Response(
    params?: {
      body?: StbAdRefQueryReq
    },
    context?: HttpContext
  ): Observable<StrictHttpResponse<StbAdRefQueryRes>> {
    const rb = new RequestBuilder(this.rootUrl, SustainableWorksheetService.ApiSustainableWorksheetOptDataRefAuditQueryPostPath, 'post');
    if (params) {
      rb.body(params.body, 'application/json');
    }

    return this.http.request(
      rb.build({ responseType: 'json', accept: 'application/json', context })
    ).pipe(
      filter((r: any): r is HttpResponse<any> => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<StbAdRefQueryRes>;
      })
    );
  }

  /**
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `apiSustainableWorksheetOptDataRefAuditQueryPost$Response()` instead.
   *
   * This method sends `application/json` and handles request body of type `application/json`.
   */
  apiSustainableWorksheetOptDataRefAuditQueryPost(
    params?: {
      body?: StbAdRefQueryReq
    },
    context?: HttpContext
  ): Observable<StbAdRefQueryRes> {
    return this.apiSustainableWorksheetOptDataRefAuditQueryPost$Response(params, context).pipe(
      map((r: StrictHttpResponse<StbAdRefQueryRes>): StbAdRefQueryRes => r.body)
    );
  }

  /** Path part for operation `apiSustainableWorksheetOptDataRefHealthCheckQueryPost()` */
  static readonly ApiSustainableWorksheetOptDataRefHealthCheckQueryPostPath = '/api/sustainable/worksheet/opt-data/ref-health-check/query';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `apiSustainableWorksheetOptDataRefHealthCheckQueryPost()` instead.
   *
   * This method sends `application/json` and handles request body of type `application/json`.
   */
  apiSustainableWorksheetOptDataRefHealthCheckQueryPost$Response(
    params?: {
      body?: StbHcRefQueryReq
    },
    context?: HttpContext
  ): Observable<StrictHttpResponse<StbHcRefQueryRes>> {
    const rb = new RequestBuilder(this.rootUrl, SustainableWorksheetService.ApiSustainableWorksheetOptDataRefHealthCheckQueryPostPath, 'post');
    if (params) {
      rb.body(params.body, 'application/json');
    }

    return this.http.request(
      rb.build({ responseType: 'json', accept: 'application/json', context })
    ).pipe(
      filter((r: any): r is HttpResponse<any> => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<StbHcRefQueryRes>;
      })
    );
  }

  /**
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `apiSustainableWorksheetOptDataRefHealthCheckQueryPost$Response()` instead.
   *
   * This method sends `application/json` and handles request body of type `application/json`.
   */
  apiSustainableWorksheetOptDataRefHealthCheckQueryPost(
    params?: {
      body?: StbHcRefQueryReq
    },
    context?: HttpContext
  ): Observable<StbHcRefQueryRes> {
    return this.apiSustainableWorksheetOptDataRefHealthCheckQueryPost$Response(params, context).pipe(
      map((r: StrictHttpResponse<StbHcRefQueryRes>): StbHcRefQueryRes => r.body)
    );
  }

  /** Path part for operation `apiSustainableWorksheetOptDataVendorSearchGet()` */
  static readonly ApiSustainableWorksheetOptDataVendorSearchGetPath = '/api/sustainable/worksheet/opt-data/vendor/search';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `apiSustainableWorksheetOptDataVendorSearchGet()` instead.
   *
   * This method doesn't expect any request body.
   */
  apiSustainableWorksheetOptDataVendorSearchGet$Response(
    params?: {
      keyword?: string;
      workGroupList?: Array<number>;
    },
    context?: HttpContext
  ): Observable<StrictHttpResponse<Array<StbVendorOptSearchingRes>>> {
    const rb = new RequestBuilder(this.rootUrl, SustainableWorksheetService.ApiSustainableWorksheetOptDataVendorSearchGetPath, 'get');
    if (params) {
      rb.query('keyword', params.keyword, {});
      rb.query('workGroupList', params.workGroupList, {});
    }

    return this.http.request(
      rb.build({ responseType: 'json', accept: 'application/json', context })
    ).pipe(
      filter((r: any): r is HttpResponse<any> => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<Array<StbVendorOptSearchingRes>>;
      })
    );
  }

  /**
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `apiSustainableWorksheetOptDataVendorSearchGet$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  apiSustainableWorksheetOptDataVendorSearchGet(
    params?: {
      keyword?: string;
      workGroupList?: Array<number>;
    },
    context?: HttpContext
  ): Observable<Array<StbVendorOptSearchingRes>> {
    return this.apiSustainableWorksheetOptDataVendorSearchGet$Response(params, context).pipe(
      map((r: StrictHttpResponse<Array<StbVendorOptSearchingRes>>): Array<StbVendorOptSearchingRes> => r.body)
    );
  }

  /** Path part for operation `apiSustainableWorksheetOptDataVendorProductsPost()` */
  static readonly ApiSustainableWorksheetOptDataVendorProductsPostPath = '/api/sustainable/worksheet/opt-data/vendor/products';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `apiSustainableWorksheetOptDataVendorProductsPost()` instead.
   *
   * This method sends `application/json` and handles request body of type `application/json`.
   */
  apiSustainableWorksheetOptDataVendorProductsPost$Response(
    params?: {
      body?: StbVendorProductQueryReq
    },
    context?: HttpContext
  ): Observable<StrictHttpResponse<StbVendorProductQueryRes>> {
    const rb = new RequestBuilder(this.rootUrl, SustainableWorksheetService.ApiSustainableWorksheetOptDataVendorProductsPostPath, 'post');
    if (params) {
      rb.body(params.body, 'application/json');
    }

    return this.http.request(
      rb.build({ responseType: 'json', accept: 'application/json', context })
    ).pipe(
      filter((r: any): r is HttpResponse<any> => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<StbVendorProductQueryRes>;
      })
    );
  }

  /**
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `apiSustainableWorksheetOptDataVendorProductsPost$Response()` instead.
   *
   * This method sends `application/json` and handles request body of type `application/json`.
   */
  apiSustainableWorksheetOptDataVendorProductsPost(
    params?: {
      body?: StbVendorProductQueryReq
    },
    context?: HttpContext
  ): Observable<StbVendorProductQueryRes> {
    return this.apiSustainableWorksheetOptDataVendorProductsPost$Response(params, context).pipe(
      map((r: StrictHttpResponse<StbVendorProductQueryRes>): StbVendorProductQueryRes => r.body)
    );
  }

  /** Path part for operation `apiSustainableWorksheetOptDataUnspscSearchGet()` */
  static readonly ApiSustainableWorksheetOptDataUnspscSearchGetPath = '/api/sustainable/worksheet/opt-data/unspsc/search';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `apiSustainableWorksheetOptDataUnspscSearchGet()` instead.
   *
   * This method doesn't expect any request body.
   */
  apiSustainableWorksheetOptDataUnspscSearchGet$Response(
    params?: {
      keyword?: string;
      max?: number;
    },
    context?: HttpContext
  ): Observable<StrictHttpResponse<Array<VenSchUnspscOptDataRes>>> {
    const rb = new RequestBuilder(this.rootUrl, SustainableWorksheetService.ApiSustainableWorksheetOptDataUnspscSearchGetPath, 'get');
    if (params) {
      rb.query('keyword', params.keyword, {});
      rb.query('max', params.max, {});
    }

    return this.http.request(
      rb.build({ responseType: 'json', accept: 'application/json', context })
    ).pipe(
      filter((r: any): r is HttpResponse<any> => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<Array<VenSchUnspscOptDataRes>>;
      })
    );
  }

  /**
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `apiSustainableWorksheetOptDataUnspscSearchGet$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  apiSustainableWorksheetOptDataUnspscSearchGet(
    params?: {
      keyword?: string;
      max?: number;
    },
    context?: HttpContext
  ): Observable<Array<VenSchUnspscOptDataRes>> {
    return this.apiSustainableWorksheetOptDataUnspscSearchGet$Response(params, context).pipe(
      map((r: StrictHttpResponse<Array<VenSchUnspscOptDataRes>>): Array<VenSchUnspscOptDataRes> => r.body)
    );
  }

  /** Path part for operation `apiSustainableWorksheetOptDataSalesDefaultGet()` */
  static readonly ApiSustainableWorksheetOptDataSalesDefaultGetPath = '/api/sustainable/worksheet/opt-data/sales/default';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `apiSustainableWorksheetOptDataSalesDefaultGet()` instead.
   *
   * This method doesn't expect any request body.
   */
  apiSustainableWorksheetOptDataSalesDefaultGet$Response(
    params?: {
      vendorProfileId?: string;
    },
    context?: HttpContext
  ): Observable<StrictHttpResponse<Array<StbTrnVendorAccountDto>>> {
    const rb = new RequestBuilder(this.rootUrl, SustainableWorksheetService.ApiSustainableWorksheetOptDataSalesDefaultGetPath, 'get');
    if (params) {
      rb.query('vendorProfileId', params.vendorProfileId, {});
    }

    return this.http.request(
      rb.build({ responseType: 'json', accept: 'application/json', context })
    ).pipe(
      filter((r: any): r is HttpResponse<any> => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<Array<StbTrnVendorAccountDto>>;
      })
    );
  }

  /**
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `apiSustainableWorksheetOptDataSalesDefaultGet$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  apiSustainableWorksheetOptDataSalesDefaultGet(
    params?: {
      vendorProfileId?: string;
    },
    context?: HttpContext
  ): Observable<Array<StbTrnVendorAccountDto>> {
    return this.apiSustainableWorksheetOptDataSalesDefaultGet$Response(params, context).pipe(
      map((r: StrictHttpResponse<Array<StbTrnVendorAccountDto>>): Array<StbTrnVendorAccountDto> => r.body)
    );
  }

  /** Path part for operation `apiSustainableWorksheetKpIsExportGet()` */
  static readonly ApiSustainableWorksheetKpIsExportGetPath = '/api/sustainable/worksheet/KPIs/export';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `apiSustainableWorksheetKpIsExportGet()` instead.
   *
   * This method doesn't expect any request body.
   */
  apiSustainableWorksheetKpIsExportGet$Response(
    params?: {
      trnId?: string;
    },
    context?: HttpContext
  ): Observable<StrictHttpResponse<ExportExcelFileRes>> {
    const rb = new RequestBuilder(this.rootUrl, SustainableWorksheetService.ApiSustainableWorksheetKpIsExportGetPath, 'get');
    if (params) {
      rb.query('trnId', params.trnId, {});
    }

    return this.http.request(
      rb.build({ responseType: 'json', accept: 'application/json', context })
    ).pipe(
      filter((r: any): r is HttpResponse<any> => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<ExportExcelFileRes>;
      })
    );
  }

  /**
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `apiSustainableWorksheetKpIsExportGet$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  apiSustainableWorksheetKpIsExportGet(
    params?: {
      trnId?: string;
    },
    context?: HttpContext
  ): Observable<ExportExcelFileRes> {
    return this.apiSustainableWorksheetKpIsExportGet$Response(params, context).pipe(
      map((r: StrictHttpResponse<ExportExcelFileRes>): ExportExcelFileRes => r.body)
    );
  }

}
