/* tslint:disable */
/* eslint-disable */
import { HttpClient, HttpContext, HttpResponse } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { filter, map } from 'rxjs/operators';

import { BaseService } from '../base-service';
import { ApiConfiguration } from '../api-configuration';
import { StrictHttpResponse } from '../strict-http-response';
import { RequestBuilder } from '../request-builder';

import { AccountRes } from '../models/account-res';

@Injectable({ providedIn: 'root' })
export class EmployeeService extends BaseService {
  constructor(config: ApiConfiguration, http: HttpClient) {
    super(config, http);
  }

  /** Path part for operation `apiEmployeeMeGet()` */
  static readonly ApiEmployeeMeGetPath = '/api/employee/me';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `apiEmployeeMeGet()` instead.
   *
   * This method doesn't expect any request body.
   */
  apiEmployeeMeGet$Response(
    params?: {
    },
    context?: HttpContext
  ): Observable<StrictHttpResponse<AccountRes>> {
    const rb = new RequestBuilder(this.rootUrl, EmployeeService.ApiEmployeeMeGetPath, 'get');
    if (params) {
    }

    return this.http.request(
      rb.build({ responseType: 'json', accept: 'application/json', context })
    ).pipe(
      filter((r: any): r is HttpResponse<any> => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<AccountRes>;
      })
    );
  }

  /**
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `apiEmployeeMeGet$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  apiEmployeeMeGet(
    params?: {
    },
    context?: HttpContext
  ): Observable<AccountRes> {
    return this.apiEmployeeMeGet$Response(params, context).pipe(
      map((r: StrictHttpResponse<AccountRes>): AccountRes => r.body)
    );
  }

  /** Path part for operation `apiEmployeeStartwithGet()` */
  static readonly ApiEmployeeStartwithGetPath = '/api/employee/startwith';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `apiEmployeeStartwithGet()` instead.
   *
   * This method doesn't expect any request body.
   */
  apiEmployeeStartwithGet$Response(
    params?: {
      startWith?: string;
      max?: number;
      workGroupIds?: Array<number>;
    },
    context?: HttpContext
  ): Observable<StrictHttpResponse<Array<AccountRes>>> {
    const rb = new RequestBuilder(this.rootUrl, EmployeeService.ApiEmployeeStartwithGetPath, 'get');
    if (params) {
      rb.query('startWith', params.startWith, {});
      rb.query('max', params.max, {});
      rb.query('workGroupIds', params.workGroupIds, {});
    }

    return this.http.request(
      rb.build({ responseType: 'json', accept: 'application/json', context })
    ).pipe(
      filter((r: any): r is HttpResponse<any> => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<Array<AccountRes>>;
      })
    );
  }

  /**
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `apiEmployeeStartwithGet$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  apiEmployeeStartwithGet(
    params?: {
      startWith?: string;
      max?: number;
      workGroupIds?: Array<number>;
    },
    context?: HttpContext
  ): Observable<Array<AccountRes>> {
    return this.apiEmployeeStartwithGet$Response(params, context).pipe(
      map((r: StrictHttpResponse<Array<AccountRes>>): Array<AccountRes> => r.body)
    );
  }

  /** Path part for operation `apiEmployeeListGet()` */
  static readonly ApiEmployeeListGetPath = '/api/employee/list';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `apiEmployeeListGet()` instead.
   *
   * This method sends `application/json` and handles request body of type `application/json`.
   */
  apiEmployeeListGet$Response(
    params?: {
      body?: Array<string>
    },
    context?: HttpContext
  ): Observable<StrictHttpResponse<Array<AccountRes>>> {
    const rb = new RequestBuilder(this.rootUrl, EmployeeService.ApiEmployeeListGetPath, 'get');
    if (params) {
      rb.body(params.body, 'application/json');
    }

    return this.http.request(
      rb.build({ responseType: 'json', accept: 'application/json', context })
    ).pipe(
      filter((r: any): r is HttpResponse<any> => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<Array<AccountRes>>;
      })
    );
  }

  /**
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `apiEmployeeListGet$Response()` instead.
   *
   * This method sends `application/json` and handles request body of type `application/json`.
   */
  apiEmployeeListGet(
    params?: {
      body?: Array<string>
    },
    context?: HttpContext
  ): Observable<Array<AccountRes>> {
    return this.apiEmployeeListGet$Response(params, context).pipe(
      map((r: StrictHttpResponse<Array<AccountRes>>): Array<AccountRes> => r.body)
    );
  }

  /** Path part for operation `apiEmployeeStartWithApprovalGet()` */
  static readonly ApiEmployeeStartWithApprovalGetPath = '/api/employee/startWithApproval';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `apiEmployeeStartWithApprovalGet()` instead.
   *
   * This method doesn't expect any request body.
   */
  apiEmployeeStartWithApprovalGet$Response(
    params?: {
      startWith?: string;
      approvalLevel?: number;
      max?: number;
      isEqualOnly?: boolean;
      workGroupIds?: Array<number>;
    },
    context?: HttpContext
  ): Observable<StrictHttpResponse<Array<AccountRes>>> {
    const rb = new RequestBuilder(this.rootUrl, EmployeeService.ApiEmployeeStartWithApprovalGetPath, 'get');
    if (params) {
      rb.query('startWith', params.startWith, {});
      rb.query('approvalLevel', params.approvalLevel, {});
      rb.query('max', params.max, {});
      rb.query('isEqualOnly', params.isEqualOnly, {});
      rb.query('workGroupIds', params.workGroupIds, {});
    }

    return this.http.request(
      rb.build({ responseType: 'json', accept: 'application/json', context })
    ).pipe(
      filter((r: any): r is HttpResponse<any> => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<Array<AccountRes>>;
      })
    );
  }

  /**
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `apiEmployeeStartWithApprovalGet$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  apiEmployeeStartWithApprovalGet(
    params?: {
      startWith?: string;
      approvalLevel?: number;
      max?: number;
      isEqualOnly?: boolean;
      workGroupIds?: Array<number>;
    },
    context?: HttpContext
  ): Observable<Array<AccountRes>> {
    return this.apiEmployeeStartWithApprovalGet$Response(params, context).pipe(
      map((r: StrictHttpResponse<Array<AccountRes>>): Array<AccountRes> => r.body)
    );
  }

  /** Path part for operation `apiEmployeeChainApproverGet()` */
  static readonly ApiEmployeeChainApproverGetPath = '/api/employee/chainApprover';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `apiEmployeeChainApproverGet()` instead.
   *
   * This method doesn't expect any request body.
   */
  apiEmployeeChainApproverGet$Response(
    params?: {
      employeeId?: string;
    },
    context?: HttpContext
  ): Observable<StrictHttpResponse<Array<AccountRes>>> {
    const rb = new RequestBuilder(this.rootUrl, EmployeeService.ApiEmployeeChainApproverGetPath, 'get');
    if (params) {
      rb.query('employeeId', params.employeeId, {});
    }

    return this.http.request(
      rb.build({ responseType: 'json', accept: 'application/json', context })
    ).pipe(
      filter((r: any): r is HttpResponse<any> => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<Array<AccountRes>>;
      })
    );
  }

  /**
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `apiEmployeeChainApproverGet$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  apiEmployeeChainApproverGet(
    params?: {
      employeeId?: string;
    },
    context?: HttpContext
  ): Observable<Array<AccountRes>> {
    return this.apiEmployeeChainApproverGet$Response(params, context).pipe(
      map((r: StrictHttpResponse<Array<AccountRes>>): Array<AccountRes> => r.body)
    );
  }

}
