/* tslint:disable */
/* eslint-disable */
import { HttpClient, HttpContext, HttpResponse } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { filter, map } from 'rxjs/operators';

import { BaseService } from '../base-service';
import { ApiConfiguration } from '../api-configuration';
import { StrictHttpResponse } from '../strict-http-response';
import { RequestBuilder } from '../request-builder';

import { VocFeedBackOptDataRes } from '../models/voc-feed-back-opt-data-res';
import { VocRequestItem } from '../models/voc-request-item';

@Injectable({ providedIn: 'root' })
export class VocRequestService extends BaseService {
  constructor(config: ApiConfiguration, http: HttpClient) {
    super(config, http);
  }

  /** Path part for operation `apiVocFeedbackRequestGet()` */
  static readonly ApiVocFeedbackRequestGetPath = '/api/voc-feedback/request';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `apiVocFeedbackRequestGet()` instead.
   *
   * This method doesn't expect any request body.
   */
  apiVocFeedbackRequestGet$Response(
    params?: {
      requestId?: string;
    },
    context?: HttpContext
  ): Observable<StrictHttpResponse<VocRequestItem>> {
    const rb = new RequestBuilder(this.rootUrl, VocRequestService.ApiVocFeedbackRequestGetPath, 'get');
    if (params) {
      rb.query('requestId', params.requestId, {});
    }

    return this.http.request(
      rb.build({ responseType: 'json', accept: 'application/json', context })
    ).pipe(
      filter((r: any): r is HttpResponse<any> => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<VocRequestItem>;
      })
    );
  }

  /**
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `apiVocFeedbackRequestGet$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  apiVocFeedbackRequestGet(
    params?: {
      requestId?: string;
    },
    context?: HttpContext
  ): Observable<VocRequestItem> {
    return this.apiVocFeedbackRequestGet$Response(params, context).pipe(
      map((r: StrictHttpResponse<VocRequestItem>): VocRequestItem => r.body)
    );
  }

  /** Path part for operation `apiVocFeedbackRequestNewrequestSubmitPost()` */
  static readonly ApiVocFeedbackRequestNewrequestSubmitPostPath = '/api/voc-feedback/request/newrequest/submit';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `apiVocFeedbackRequestNewrequestSubmitPost()` instead.
   *
   * This method sends `application/json` and handles request body of type `application/json`.
   */
  apiVocFeedbackRequestNewrequestSubmitPost$Response(
    params?: {
      body?: VocRequestItem
    },
    context?: HttpContext
  ): Observable<StrictHttpResponse<void>> {
    const rb = new RequestBuilder(this.rootUrl, VocRequestService.ApiVocFeedbackRequestNewrequestSubmitPostPath, 'post');
    if (params) {
      rb.body(params.body, 'application/json');
    }

    return this.http.request(
      rb.build({ responseType: 'text', accept: '*/*', context })
    ).pipe(
      filter((r: any): r is HttpResponse<any> => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return (r as HttpResponse<any>).clone({ body: undefined }) as StrictHttpResponse<void>;
      })
    );
  }

  /**
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `apiVocFeedbackRequestNewrequestSubmitPost$Response()` instead.
   *
   * This method sends `application/json` and handles request body of type `application/json`.
   */
  apiVocFeedbackRequestNewrequestSubmitPost(
    params?: {
      body?: VocRequestItem
    },
    context?: HttpContext
  ): Observable<void> {
    return this.apiVocFeedbackRequestNewrequestSubmitPost$Response(params, context).pipe(
      map((r: StrictHttpResponse<void>): void => r.body)
    );
  }

  /** Path part for operation `apiVocFeedbackRequestOptDataGet()` */
  static readonly ApiVocFeedbackRequestOptDataGetPath = '/api/voc-feedback/request/opt-data';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `apiVocFeedbackRequestOptDataGet()` instead.
   *
   * This method doesn't expect any request body.
   */
  apiVocFeedbackRequestOptDataGet$Response(
    params?: {
    },
    context?: HttpContext
  ): Observable<StrictHttpResponse<VocFeedBackOptDataRes>> {
    const rb = new RequestBuilder(this.rootUrl, VocRequestService.ApiVocFeedbackRequestOptDataGetPath, 'get');
    if (params) {
    }

    return this.http.request(
      rb.build({ responseType: 'json', accept: 'application/json', context })
    ).pipe(
      filter((r: any): r is HttpResponse<any> => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<VocFeedBackOptDataRes>;
      })
    );
  }

  /**
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `apiVocFeedbackRequestOptDataGet$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  apiVocFeedbackRequestOptDataGet(
    params?: {
    },
    context?: HttpContext
  ): Observable<VocFeedBackOptDataRes> {
    return this.apiVocFeedbackRequestOptDataGet$Response(params, context).pipe(
      map((r: StrictHttpResponse<VocFeedBackOptDataRes>): VocFeedBackOptDataRes => r.body)
    );
  }

}
