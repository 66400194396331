export const environment = {
  production: true,
  base_url: window.location.protocol + "//" + window.location.host,
  azureAd: {
    clientId: 'aab2e180-5a72-4e6f-ae6e-dce93f3159a1',
    authority: 'https://login.microsoftonline.com/9c5113b4-03f7-44cb-a24a-fa892a3cab42',
    authRedirectUrl: 'http://localhost:4200',
  },
  azureLSPAd: {
    clientId: 'e173768b-07b8-4cde-8ceb-0a6ba74a99b0',
    authority: 'https://login.microsoftonline.com/3fd38777-7876-47b2-8a7e-f3aca05610b4',
    authRedirectUrl: 'http://localhost:4200',
  }
}; 
