/* tslint:disable */
/* eslint-disable */
import { HttpClient, HttpContext, HttpResponse } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { filter, map } from 'rxjs/operators';

import { BaseService } from '../base-service';
import { ApiConfiguration } from '../api-configuration';
import { StrictHttpResponse } from '../strict-http-response';
import { RequestBuilder } from '../request-builder';

import { ExportExcelFileRes } from '../models/export-excel-file-res';
import { MasterCategoryAndSeverityResItem } from '../models/master-category-and-severity-res-item';
import { PostEvaluationReportQueryItem } from '../models/post-evaluation-report-query-item';
import { PostEvaluationReportResultByUnspscItem } from '../models/post-evaluation-report-result-by-unspsc-item';
import { PostEvaluationReportResultItem } from '../models/post-evaluation-report-result-item';

@Injectable({ providedIn: 'root' })
export class PostEvaluationReportService extends BaseService {
  constructor(config: ApiConfiguration, http: HttpClient) {
    super(config, http);
  }

  /** Path part for operation `apiPostEvaluationReportQueryPost()` */
  static readonly ApiPostEvaluationReportQueryPostPath = '/api/post-evaluation-report/query';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `apiPostEvaluationReportQueryPost()` instead.
   *
   * This method sends `application/json` and handles request body of type `application/json`.
   */
  apiPostEvaluationReportQueryPost$Response(
    params?: {
      body?: PostEvaluationReportQueryItem
    },
    context?: HttpContext
  ): Observable<StrictHttpResponse<PostEvaluationReportResultItem>> {
    const rb = new RequestBuilder(this.rootUrl, PostEvaluationReportService.ApiPostEvaluationReportQueryPostPath, 'post');
    if (params) {
      rb.body(params.body, 'application/json');
    }

    return this.http.request(
      rb.build({ responseType: 'json', accept: 'application/json', context })
    ).pipe(
      filter((r: any): r is HttpResponse<any> => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<PostEvaluationReportResultItem>;
      })
    );
  }

  /**
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `apiPostEvaluationReportQueryPost$Response()` instead.
   *
   * This method sends `application/json` and handles request body of type `application/json`.
   */
  apiPostEvaluationReportQueryPost(
    params?: {
      body?: PostEvaluationReportQueryItem
    },
    context?: HttpContext
  ): Observable<PostEvaluationReportResultItem> {
    return this.apiPostEvaluationReportQueryPost$Response(params, context).pipe(
      map((r: StrictHttpResponse<PostEvaluationReportResultItem>): PostEvaluationReportResultItem => r.body)
    );
  }

  /** Path part for operation `apiPostEvaluationReportQueryByUnspscPost()` */
  static readonly ApiPostEvaluationReportQueryByUnspscPostPath = '/api/post-evaluation-report/queryByUNSPSC';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `apiPostEvaluationReportQueryByUnspscPost()` instead.
   *
   * This method sends `application/json` and handles request body of type `application/json`.
   */
  apiPostEvaluationReportQueryByUnspscPost$Response(
    params?: {
      body?: PostEvaluationReportQueryItem
    },
    context?: HttpContext
  ): Observable<StrictHttpResponse<PostEvaluationReportResultByUnspscItem>> {
    const rb = new RequestBuilder(this.rootUrl, PostEvaluationReportService.ApiPostEvaluationReportQueryByUnspscPostPath, 'post');
    if (params) {
      rb.body(params.body, 'application/json');
    }

    return this.http.request(
      rb.build({ responseType: 'json', accept: 'application/json', context })
    ).pipe(
      filter((r: any): r is HttpResponse<any> => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<PostEvaluationReportResultByUnspscItem>;
      })
    );
  }

  /**
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `apiPostEvaluationReportQueryByUnspscPost$Response()` instead.
   *
   * This method sends `application/json` and handles request body of type `application/json`.
   */
  apiPostEvaluationReportQueryByUnspscPost(
    params?: {
      body?: PostEvaluationReportQueryItem
    },
    context?: HttpContext
  ): Observable<PostEvaluationReportResultByUnspscItem> {
    return this.apiPostEvaluationReportQueryByUnspscPost$Response(params, context).pipe(
      map((r: StrictHttpResponse<PostEvaluationReportResultByUnspscItem>): PostEvaluationReportResultByUnspscItem => r.body)
    );
  }

  /** Path part for operation `apiPostEvaluationReportExportPost()` */
  static readonly ApiPostEvaluationReportExportPostPath = '/api/post-evaluation-report/export';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `apiPostEvaluationReportExportPost()` instead.
   *
   * This method sends `application/json` and handles request body of type `application/json`.
   */
  apiPostEvaluationReportExportPost$Response(
    params?: {
      body?: PostEvaluationReportQueryItem
    },
    context?: HttpContext
  ): Observable<StrictHttpResponse<ExportExcelFileRes>> {
    const rb = new RequestBuilder(this.rootUrl, PostEvaluationReportService.ApiPostEvaluationReportExportPostPath, 'post');
    if (params) {
      rb.body(params.body, 'application/json');
    }

    return this.http.request(
      rb.build({ responseType: 'json', accept: 'application/json', context })
    ).pipe(
      filter((r: any): r is HttpResponse<any> => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<ExportExcelFileRes>;
      })
    );
  }

  /**
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `apiPostEvaluationReportExportPost$Response()` instead.
   *
   * This method sends `application/json` and handles request body of type `application/json`.
   */
  apiPostEvaluationReportExportPost(
    params?: {
      body?: PostEvaluationReportQueryItem
    },
    context?: HttpContext
  ): Observable<ExportExcelFileRes> {
    return this.apiPostEvaluationReportExportPost$Response(params, context).pipe(
      map((r: StrictHttpResponse<ExportExcelFileRes>): ExportExcelFileRes => r.body)
    );
  }

  /** Path part for operation `apiPostEvaluationReportExportByUnspscPost()` */
  static readonly ApiPostEvaluationReportExportByUnspscPostPath = '/api/post-evaluation-report/exportByUNSPSC';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `apiPostEvaluationReportExportByUnspscPost()` instead.
   *
   * This method sends `application/json` and handles request body of type `application/json`.
   */
  apiPostEvaluationReportExportByUnspscPost$Response(
    params?: {
      body?: PostEvaluationReportQueryItem
    },
    context?: HttpContext
  ): Observable<StrictHttpResponse<ExportExcelFileRes>> {
    const rb = new RequestBuilder(this.rootUrl, PostEvaluationReportService.ApiPostEvaluationReportExportByUnspscPostPath, 'post');
    if (params) {
      rb.body(params.body, 'application/json');
    }

    return this.http.request(
      rb.build({ responseType: 'json', accept: 'application/json', context })
    ).pipe(
      filter((r: any): r is HttpResponse<any> => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<ExportExcelFileRes>;
      })
    );
  }

  /**
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `apiPostEvaluationReportExportByUnspscPost$Response()` instead.
   *
   * This method sends `application/json` and handles request body of type `application/json`.
   */
  apiPostEvaluationReportExportByUnspscPost(
    params?: {
      body?: PostEvaluationReportQueryItem
    },
    context?: HttpContext
  ): Observable<ExportExcelFileRes> {
    return this.apiPostEvaluationReportExportByUnspscPost$Response(params, context).pipe(
      map((r: StrictHttpResponse<ExportExcelFileRes>): ExportExcelFileRes => r.body)
    );
  }

  /** Path part for operation `apiPostEvaluationReportMasterCategorySeverityContainGet()` */
  static readonly ApiPostEvaluationReportMasterCategorySeverityContainGetPath = '/api/post-evaluation-report/master-category-severity-contain';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `apiPostEvaluationReportMasterCategorySeverityContainGet()` instead.
   *
   * This method doesn't expect any request body.
   */
  apiPostEvaluationReportMasterCategorySeverityContainGet$Response(
    params?: {
      containText?: string;
      max?: number;
      workGroupList?: Array<number>;
    },
    context?: HttpContext
  ): Observable<StrictHttpResponse<Array<MasterCategoryAndSeverityResItem>>> {
    const rb = new RequestBuilder(this.rootUrl, PostEvaluationReportService.ApiPostEvaluationReportMasterCategorySeverityContainGetPath, 'get');
    if (params) {
      rb.query('containText', params.containText, {});
      rb.query('max', params.max, {});
      rb.query('workGroupList', params.workGroupList, {});
    }

    return this.http.request(
      rb.build({ responseType: 'json', accept: 'application/json', context })
    ).pipe(
      filter((r: any): r is HttpResponse<any> => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<Array<MasterCategoryAndSeverityResItem>>;
      })
    );
  }

  /**
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `apiPostEvaluationReportMasterCategorySeverityContainGet$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  apiPostEvaluationReportMasterCategorySeverityContainGet(
    params?: {
      containText?: string;
      max?: number;
      workGroupList?: Array<number>;
    },
    context?: HttpContext
  ): Observable<Array<MasterCategoryAndSeverityResItem>> {
    return this.apiPostEvaluationReportMasterCategorySeverityContainGet$Response(params, context).pipe(
      map((r: StrictHttpResponse<Array<MasterCategoryAndSeverityResItem>>): Array<MasterCategoryAndSeverityResItem> => r.body)
    );
  }

}
