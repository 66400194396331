/* tslint:disable */
/* eslint-disable */
import { HttpClient, HttpContext, HttpResponse } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { filter, map } from 'rxjs/operators';

import { BaseService } from '../base-service';
import { ApiConfiguration } from '../api-configuration';
import { StrictHttpResponse } from '../strict-http-response';
import { RequestBuilder } from '../request-builder';

import { MasterVocTopicSubItem } from '../models/master-voc-topic-sub-item';

@Injectable({ providedIn: 'root' })
export class MasterVocTopicSubService extends BaseService {
  constructor(config: ApiConfiguration, http: HttpClient) {
    super(config, http);
  }

  /** Path part for operation `apiMasterVocVocTopicSubGet()` */
  static readonly ApiMasterVocVocTopicSubGetPath = '/api/master/voc/voc-topic-sub';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `apiMasterVocVocTopicSubGet()` instead.
   *
   * This method doesn't expect any request body.
   */
  apiMasterVocVocTopicSubGet$Response(
    params?: {
      id?: string;
    },
    context?: HttpContext
  ): Observable<StrictHttpResponse<MasterVocTopicSubItem>> {
    const rb = new RequestBuilder(this.rootUrl, MasterVocTopicSubService.ApiMasterVocVocTopicSubGetPath, 'get');
    if (params) {
      rb.query('id', params.id, {});
    }

    return this.http.request(
      rb.build({ responseType: 'json', accept: 'application/json', context })
    ).pipe(
      filter((r: any): r is HttpResponse<any> => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<MasterVocTopicSubItem>;
      })
    );
  }

  /**
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `apiMasterVocVocTopicSubGet$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  apiMasterVocVocTopicSubGet(
    params?: {
      id?: string;
    },
    context?: HttpContext
  ): Observable<MasterVocTopicSubItem> {
    return this.apiMasterVocVocTopicSubGet$Response(params, context).pipe(
      map((r: StrictHttpResponse<MasterVocTopicSubItem>): MasterVocTopicSubItem => r.body)
    );
  }

  /** Path part for operation `apiMasterVocVocTopicSubPut()` */
  static readonly ApiMasterVocVocTopicSubPutPath = '/api/master/voc/voc-topic-sub';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `apiMasterVocVocTopicSubPut()` instead.
   *
   * This method sends `application/json` and handles request body of type `application/json`.
   */
  apiMasterVocVocTopicSubPut$Response(
    params?: {
      body?: MasterVocTopicSubItem
    },
    context?: HttpContext
  ): Observable<StrictHttpResponse<MasterVocTopicSubItem>> {
    const rb = new RequestBuilder(this.rootUrl, MasterVocTopicSubService.ApiMasterVocVocTopicSubPutPath, 'put');
    if (params) {
      rb.body(params.body, 'application/json');
    }

    return this.http.request(
      rb.build({ responseType: 'json', accept: 'application/json', context })
    ).pipe(
      filter((r: any): r is HttpResponse<any> => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<MasterVocTopicSubItem>;
      })
    );
  }

  /**
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `apiMasterVocVocTopicSubPut$Response()` instead.
   *
   * This method sends `application/json` and handles request body of type `application/json`.
   */
  apiMasterVocVocTopicSubPut(
    params?: {
      body?: MasterVocTopicSubItem
    },
    context?: HttpContext
  ): Observable<MasterVocTopicSubItem> {
    return this.apiMasterVocVocTopicSubPut$Response(params, context).pipe(
      map((r: StrictHttpResponse<MasterVocTopicSubItem>): MasterVocTopicSubItem => r.body)
    );
  }

  /** Path part for operation `apiMasterVocVocTopicSubPost()` */
  static readonly ApiMasterVocVocTopicSubPostPath = '/api/master/voc/voc-topic-sub';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `apiMasterVocVocTopicSubPost()` instead.
   *
   * This method sends `application/json` and handles request body of type `application/json`.
   */
  apiMasterVocVocTopicSubPost$Response(
    params?: {
      body?: MasterVocTopicSubItem
    },
    context?: HttpContext
  ): Observable<StrictHttpResponse<void>> {
    const rb = new RequestBuilder(this.rootUrl, MasterVocTopicSubService.ApiMasterVocVocTopicSubPostPath, 'post');
    if (params) {
      rb.body(params.body, 'application/json');
    }

    return this.http.request(
      rb.build({ responseType: 'text', accept: '*/*', context })
    ).pipe(
      filter((r: any): r is HttpResponse<any> => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return (r as HttpResponse<any>).clone({ body: undefined }) as StrictHttpResponse<void>;
      })
    );
  }

  /**
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `apiMasterVocVocTopicSubPost$Response()` instead.
   *
   * This method sends `application/json` and handles request body of type `application/json`.
   */
  apiMasterVocVocTopicSubPost(
    params?: {
      body?: MasterVocTopicSubItem
    },
    context?: HttpContext
  ): Observable<void> {
    return this.apiMasterVocVocTopicSubPost$Response(params, context).pipe(
      map((r: StrictHttpResponse<void>): void => r.body)
    );
  }

  /** Path part for operation `apiMasterVocVocTopicSubDelete()` */
  static readonly ApiMasterVocVocTopicSubDeletePath = '/api/master/voc/voc-topic-sub';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `apiMasterVocVocTopicSubDelete()` instead.
   *
   * This method doesn't expect any request body.
   */
  apiMasterVocVocTopicSubDelete$Response(
    params?: {
      id?: string;
    },
    context?: HttpContext
  ): Observable<StrictHttpResponse<void>> {
    const rb = new RequestBuilder(this.rootUrl, MasterVocTopicSubService.ApiMasterVocVocTopicSubDeletePath, 'delete');
    if (params) {
      rb.query('id', params.id, {});
    }

    return this.http.request(
      rb.build({ responseType: 'text', accept: '*/*', context })
    ).pipe(
      filter((r: any): r is HttpResponse<any> => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return (r as HttpResponse<any>).clone({ body: undefined }) as StrictHttpResponse<void>;
      })
    );
  }

  /**
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `apiMasterVocVocTopicSubDelete$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  apiMasterVocVocTopicSubDelete(
    params?: {
      id?: string;
    },
    context?: HttpContext
  ): Observable<void> {
    return this.apiMasterVocVocTopicSubDelete$Response(params, context).pipe(
      map((r: StrictHttpResponse<void>): void => r.body)
    );
  }

  /** Path part for operation `apiMasterVocVocTopicSubAllGet()` */
  static readonly ApiMasterVocVocTopicSubAllGetPath = '/api/master/voc/voc-topic-sub/All';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `apiMasterVocVocTopicSubAllGet()` instead.
   *
   * This method doesn't expect any request body.
   */
  apiMasterVocVocTopicSubAllGet$Response(
    params?: {
    },
    context?: HttpContext
  ): Observable<StrictHttpResponse<Array<MasterVocTopicSubItem>>> {
    const rb = new RequestBuilder(this.rootUrl, MasterVocTopicSubService.ApiMasterVocVocTopicSubAllGetPath, 'get');
    if (params) {
    }

    return this.http.request(
      rb.build({ responseType: 'json', accept: 'application/json', context })
    ).pipe(
      filter((r: any): r is HttpResponse<any> => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<Array<MasterVocTopicSubItem>>;
      })
    );
  }

  /**
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `apiMasterVocVocTopicSubAllGet$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  apiMasterVocVocTopicSubAllGet(
    params?: {
    },
    context?: HttpContext
  ): Observable<Array<MasterVocTopicSubItem>> {
    return this.apiMasterVocVocTopicSubAllGet$Response(params, context).pipe(
      map((r: StrictHttpResponse<Array<MasterVocTopicSubItem>>): Array<MasterVocTopicSubItem> => r.body)
    );
  }

  /** Path part for operation `apiMasterVocVocTopicSubActiveGet()` */
  static readonly ApiMasterVocVocTopicSubActiveGetPath = '/api/master/voc/voc-topic-sub/Active';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `apiMasterVocVocTopicSubActiveGet()` instead.
   *
   * This method doesn't expect any request body.
   */
  apiMasterVocVocTopicSubActiveGet$Response(
    params?: {
    },
    context?: HttpContext
  ): Observable<StrictHttpResponse<Array<MasterVocTopicSubItem>>> {
    const rb = new RequestBuilder(this.rootUrl, MasterVocTopicSubService.ApiMasterVocVocTopicSubActiveGetPath, 'get');
    if (params) {
    }

    return this.http.request(
      rb.build({ responseType: 'json', accept: 'application/json', context })
    ).pipe(
      filter((r: any): r is HttpResponse<any> => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<Array<MasterVocTopicSubItem>>;
      })
    );
  }

  /**
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `apiMasterVocVocTopicSubActiveGet$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  apiMasterVocVocTopicSubActiveGet(
    params?: {
    },
    context?: HttpContext
  ): Observable<Array<MasterVocTopicSubItem>> {
    return this.apiMasterVocVocTopicSubActiveGet$Response(params, context).pipe(
      map((r: StrictHttpResponse<Array<MasterVocTopicSubItem>>): Array<MasterVocTopicSubItem> => r.body)
    );
  }

}
