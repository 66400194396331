/* tslint:disable */
/* eslint-disable */
import { HttpClient, HttpContext, HttpResponse } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { filter, map } from 'rxjs/operators';

import { BaseService } from '../base-service';
import { ApiConfiguration } from '../api-configuration';
import { StrictHttpResponse } from '../strict-http-response';
import { RequestBuilder } from '../request-builder';

import { ManualRequestSchWorklistReq } from '../models/manual-request-sch-worklist-req';
import { ManualRequestSchWorklistRes } from '../models/manual-request-sch-worklist-res';
import { UserByRoleRes } from '../models/user-by-role-res';

@Injectable({ providedIn: 'root' })
export class ManualRequestSearchingService extends BaseService {
  constructor(config: ApiConfiguration, http: HttpClient) {
    super(config, http);
  }

  /** Path part for operation `apiManualRequestSearchingSearchPost()` */
  static readonly ApiManualRequestSearchingSearchPostPath = '/api/manual-request-searching/Search';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `apiManualRequestSearchingSearchPost()` instead.
   *
   * This method sends `application/json` and handles request body of type `application/json`.
   */
  apiManualRequestSearchingSearchPost$Response(
    params?: {
      body?: ManualRequestSchWorklistReq
    },
    context?: HttpContext
  ): Observable<StrictHttpResponse<Array<ManualRequestSchWorklistRes>>> {
    const rb = new RequestBuilder(this.rootUrl, ManualRequestSearchingService.ApiManualRequestSearchingSearchPostPath, 'post');
    if (params) {
      rb.body(params.body, 'application/json');
    }

    return this.http.request(
      rb.build({ responseType: 'json', accept: 'application/json', context })
    ).pipe(
      filter((r: any): r is HttpResponse<any> => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<Array<ManualRequestSchWorklistRes>>;
      })
    );
  }

  /**
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `apiManualRequestSearchingSearchPost$Response()` instead.
   *
   * This method sends `application/json` and handles request body of type `application/json`.
   */
  apiManualRequestSearchingSearchPost(
    params?: {
      body?: ManualRequestSchWorklistReq
    },
    context?: HttpContext
  ): Observable<Array<ManualRequestSchWorklistRes>> {
    return this.apiManualRequestSearchingSearchPost$Response(params, context).pipe(
      map((r: StrictHttpResponse<Array<ManualRequestSchWorklistRes>>): Array<ManualRequestSchWorklistRes> => r.body)
    );
  }

  /** Path part for operation `apiManualRequestSearchingOptAdminPost()` */
  static readonly ApiManualRequestSearchingOptAdminPostPath = '/api/manual-request-searching/opt-admin';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `apiManualRequestSearchingOptAdminPost()` instead.
   *
   * This method doesn't expect any request body.
   */
  apiManualRequestSearchingOptAdminPost$Response(
    params?: {
      workGroupIds?: Array<number>;
    },
    context?: HttpContext
  ): Observable<StrictHttpResponse<Array<UserByRoleRes>>> {
    const rb = new RequestBuilder(this.rootUrl, ManualRequestSearchingService.ApiManualRequestSearchingOptAdminPostPath, 'post');
    if (params) {
      rb.query('workGroupIds', params.workGroupIds, {});
    }

    return this.http.request(
      rb.build({ responseType: 'json', accept: 'application/json', context })
    ).pipe(
      filter((r: any): r is HttpResponse<any> => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<Array<UserByRoleRes>>;
      })
    );
  }

  /**
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `apiManualRequestSearchingOptAdminPost$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  apiManualRequestSearchingOptAdminPost(
    params?: {
      workGroupIds?: Array<number>;
    },
    context?: HttpContext
  ): Observable<Array<UserByRoleRes>> {
    return this.apiManualRequestSearchingOptAdminPost$Response(params, context).pipe(
      map((r: StrictHttpResponse<Array<UserByRoleRes>>): Array<UserByRoleRes> => r.body)
    );
  }

}
