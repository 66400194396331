/* tslint:disable */
/* eslint-disable */
import { HttpClient, HttpContext, HttpResponse } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { filter, map } from 'rxjs/operators';

import { BaseService } from '../base-service';
import { ApiConfiguration } from '../api-configuration';
import { StrictHttpResponse } from '../strict-http-response';
import { RequestBuilder } from '../request-builder';

import { ExportExcelFileRes } from '../models/export-excel-file-res';
import { MasterCategoryAndSeverityResItem } from '../models/master-category-and-severity-res-item';
import { PostEvaluationDashboardDropdownItem } from '../models/post-evaluation-dashboard-dropdown-item';
import { PostEvaluationDashboardQueryItem } from '../models/post-evaluation-dashboard-query-item';
import { PostEvaluationDashboardResultItem } from '../models/post-evaluation-dashboard-result-item';
import { PostEvaluationDashboardResultSummary } from '../models/post-evaluation-dashboard-result-summary';

@Injectable({ providedIn: 'root' })
export class PostEvaluationDashboardService extends BaseService {
  constructor(config: ApiConfiguration, http: HttpClient) {
    super(config, http);
  }

  /** Path part for operation `apiPostEvaluationDashboardQueryPost()` */
  static readonly ApiPostEvaluationDashboardQueryPostPath = '/api/post-evaluation-dashboard/query';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `apiPostEvaluationDashboardQueryPost()` instead.
   *
   * This method sends `application/json` and handles request body of type `application/json`.
   */
  apiPostEvaluationDashboardQueryPost$Response(
    params?: {
      body?: PostEvaluationDashboardQueryItem
    },
    context?: HttpContext
  ): Observable<StrictHttpResponse<PostEvaluationDashboardResultItem>> {
    const rb = new RequestBuilder(this.rootUrl, PostEvaluationDashboardService.ApiPostEvaluationDashboardQueryPostPath, 'post');
    if (params) {
      rb.body(params.body, 'application/json');
    }

    return this.http.request(
      rb.build({ responseType: 'json', accept: 'application/json', context })
    ).pipe(
      filter((r: any): r is HttpResponse<any> => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<PostEvaluationDashboardResultItem>;
      })
    );
  }

  /**
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `apiPostEvaluationDashboardQueryPost$Response()` instead.
   *
   * This method sends `application/json` and handles request body of type `application/json`.
   */
  apiPostEvaluationDashboardQueryPost(
    params?: {
      body?: PostEvaluationDashboardQueryItem
    },
    context?: HttpContext
  ): Observable<PostEvaluationDashboardResultItem> {
    return this.apiPostEvaluationDashboardQueryPost$Response(params, context).pipe(
      map((r: StrictHttpResponse<PostEvaluationDashboardResultItem>): PostEvaluationDashboardResultItem => r.body)
    );
  }

  /** Path part for operation `apiPostEvaluationDashboardSummaryPost()` */
  static readonly ApiPostEvaluationDashboardSummaryPostPath = '/api/post-evaluation-dashboard/summary';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `apiPostEvaluationDashboardSummaryPost()` instead.
   *
   * This method sends `application/json` and handles request body of type `application/json`.
   */
  apiPostEvaluationDashboardSummaryPost$Response(
    params?: {
      body?: PostEvaluationDashboardQueryItem
    },
    context?: HttpContext
  ): Observable<StrictHttpResponse<Array<PostEvaluationDashboardResultSummary>>> {
    const rb = new RequestBuilder(this.rootUrl, PostEvaluationDashboardService.ApiPostEvaluationDashboardSummaryPostPath, 'post');
    if (params) {
      rb.body(params.body, 'application/json');
    }

    return this.http.request(
      rb.build({ responseType: 'json', accept: 'application/json', context })
    ).pipe(
      filter((r: any): r is HttpResponse<any> => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<Array<PostEvaluationDashboardResultSummary>>;
      })
    );
  }

  /**
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `apiPostEvaluationDashboardSummaryPost$Response()` instead.
   *
   * This method sends `application/json` and handles request body of type `application/json`.
   */
  apiPostEvaluationDashboardSummaryPost(
    params?: {
      body?: PostEvaluationDashboardQueryItem
    },
    context?: HttpContext
  ): Observable<Array<PostEvaluationDashboardResultSummary>> {
    return this.apiPostEvaluationDashboardSummaryPost$Response(params, context).pipe(
      map((r: StrictHttpResponse<Array<PostEvaluationDashboardResultSummary>>): Array<PostEvaluationDashboardResultSummary> => r.body)
    );
  }

  /** Path part for operation `apiPostEvaluationDashboardMasterCategorySeverityContainPost()` */
  static readonly ApiPostEvaluationDashboardMasterCategorySeverityContainPostPath = '/api/post-evaluation-dashboard/master-category-severity-contain';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `apiPostEvaluationDashboardMasterCategorySeverityContainPost()` instead.
   *
   * This method doesn't expect any request body.
   */
  apiPostEvaluationDashboardMasterCategorySeverityContainPost$Response(
    params?: {
      containText?: string;
      max?: number;
      workGroupIds?: Array<number>;
    },
    context?: HttpContext
  ): Observable<StrictHttpResponse<Array<MasterCategoryAndSeverityResItem>>> {
    const rb = new RequestBuilder(this.rootUrl, PostEvaluationDashboardService.ApiPostEvaluationDashboardMasterCategorySeverityContainPostPath, 'post');
    if (params) {
      rb.query('containText', params.containText, {});
      rb.query('max', params.max, {});
      rb.query('workGroupIds', params.workGroupIds, {});
    }

    return this.http.request(
      rb.build({ responseType: 'json', accept: 'application/json', context })
    ).pipe(
      filter((r: any): r is HttpResponse<any> => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<Array<MasterCategoryAndSeverityResItem>>;
      })
    );
  }

  /**
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `apiPostEvaluationDashboardMasterCategorySeverityContainPost$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  apiPostEvaluationDashboardMasterCategorySeverityContainPost(
    params?: {
      containText?: string;
      max?: number;
      workGroupIds?: Array<number>;
    },
    context?: HttpContext
  ): Observable<Array<MasterCategoryAndSeverityResItem>> {
    return this.apiPostEvaluationDashboardMasterCategorySeverityContainPost$Response(params, context).pipe(
      map((r: StrictHttpResponse<Array<MasterCategoryAndSeverityResItem>>): Array<MasterCategoryAndSeverityResItem> => r.body)
    );
  }

  /** Path part for operation `apiPostEvaluationDashboardExportPost()` */
  static readonly ApiPostEvaluationDashboardExportPostPath = '/api/post-evaluation-dashboard/export';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `apiPostEvaluationDashboardExportPost()` instead.
   *
   * This method sends `application/json` and handles request body of type `application/json`.
   */
  apiPostEvaluationDashboardExportPost$Response(
    params?: {
      body?: PostEvaluationDashboardQueryItem
    },
    context?: HttpContext
  ): Observable<StrictHttpResponse<ExportExcelFileRes>> {
    const rb = new RequestBuilder(this.rootUrl, PostEvaluationDashboardService.ApiPostEvaluationDashboardExportPostPath, 'post');
    if (params) {
      rb.body(params.body, 'application/json');
    }

    return this.http.request(
      rb.build({ responseType: 'json', accept: 'application/json', context })
    ).pipe(
      filter((r: any): r is HttpResponse<any> => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<ExportExcelFileRes>;
      })
    );
  }

  /**
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `apiPostEvaluationDashboardExportPost$Response()` instead.
   *
   * This method sends `application/json` and handles request body of type `application/json`.
   */
  apiPostEvaluationDashboardExportPost(
    params?: {
      body?: PostEvaluationDashboardQueryItem
    },
    context?: HttpContext
  ): Observable<ExportExcelFileRes> {
    return this.apiPostEvaluationDashboardExportPost$Response(params, context).pipe(
      map((r: StrictHttpResponse<ExportExcelFileRes>): ExportExcelFileRes => r.body)
    );
  }

  /** Path part for operation `apiPostEvaluationDashboardDdlListGet()` */
  static readonly ApiPostEvaluationDashboardDdlListGetPath = '/api/post-evaluation-dashboard/ddl-list';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `apiPostEvaluationDashboardDdlListGet()` instead.
   *
   * This method doesn't expect any request body.
   */
  apiPostEvaluationDashboardDdlListGet$Response(
    params?: {
      workGroupList?: Array<number>;
    },
    context?: HttpContext
  ): Observable<StrictHttpResponse<PostEvaluationDashboardDropdownItem>> {
    const rb = new RequestBuilder(this.rootUrl, PostEvaluationDashboardService.ApiPostEvaluationDashboardDdlListGetPath, 'get');
    if (params) {
      rb.query('workGroupList', params.workGroupList, {});
    }

    return this.http.request(
      rb.build({ responseType: 'json', accept: 'application/json', context })
    ).pipe(
      filter((r: any): r is HttpResponse<any> => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<PostEvaluationDashboardDropdownItem>;
      })
    );
  }

  /**
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `apiPostEvaluationDashboardDdlListGet$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  apiPostEvaluationDashboardDdlListGet(
    params?: {
      workGroupList?: Array<number>;
    },
    context?: HttpContext
  ): Observable<PostEvaluationDashboardDropdownItem> {
    return this.apiPostEvaluationDashboardDdlListGet$Response(params, context).pipe(
      map((r: StrictHttpResponse<PostEvaluationDashboardDropdownItem>): PostEvaluationDashboardDropdownItem => r.body)
    );
  }

}
