/* tslint:disable */
/* eslint-disable */
import { HttpClient, HttpContext, HttpResponse } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { filter, map } from 'rxjs/operators';

import { BaseService } from '../base-service';
import { ApiConfiguration } from '../api-configuration';
import { StrictHttpResponse } from '../strict-http-response';
import { RequestBuilder } from '../request-builder';

import { AppDataInRes } from '../models/app-data-in-res';
import { WorkGroupLabelRes } from '../models/work-group-label-res';

@Injectable({ providedIn: 'root' })
export class AppService extends BaseService {
  constructor(config: ApiConfiguration, http: HttpClient) {
    super(config, http);
  }

  /** Path part for operation `apiAppDataGet()` */
  static readonly ApiAppDataGetPath = '/api/app/data';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `apiAppDataGet()` instead.
   *
   * This method doesn't expect any request body.
   */
  apiAppDataGet$Response(
    params?: {
      config?: string;
    },
    context?: HttpContext
  ): Observable<StrictHttpResponse<AppDataInRes>> {
    const rb = new RequestBuilder(this.rootUrl, AppService.ApiAppDataGetPath, 'get');
    if (params) {
      rb.query('config', params.config, {});
    }

    return this.http.request(
      rb.build({ responseType: 'json', accept: 'application/json', context })
    ).pipe(
      filter((r: any): r is HttpResponse<any> => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<AppDataInRes>;
      })
    );
  }

  /**
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `apiAppDataGet$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  apiAppDataGet(
    params?: {
      config?: string;
    },
    context?: HttpContext
  ): Observable<AppDataInRes> {
    return this.apiAppDataGet$Response(params, context).pipe(
      map((r: StrictHttpResponse<AppDataInRes>): AppDataInRes => r.body)
    );
  }

  /** Path part for operation `apiAppWorkgroupLabelGet()` */
  static readonly ApiAppWorkgroupLabelGetPath = '/api/app/workgroup-label';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `apiAppWorkgroupLabelGet()` instead.
   *
   * This method doesn't expect any request body.
   */
  apiAppWorkgroupLabelGet$Response(
    params?: {
      fkWorkGroupId?: number;
    },
    context?: HttpContext
  ): Observable<StrictHttpResponse<WorkGroupLabelRes>> {
    const rb = new RequestBuilder(this.rootUrl, AppService.ApiAppWorkgroupLabelGetPath, 'get');
    if (params) {
      rb.query('fkWorkGroupId', params.fkWorkGroupId, {});
    }

    return this.http.request(
      rb.build({ responseType: 'json', accept: 'application/json', context })
    ).pipe(
      filter((r: any): r is HttpResponse<any> => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<WorkGroupLabelRes>;
      })
    );
  }

  /**
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `apiAppWorkgroupLabelGet$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  apiAppWorkgroupLabelGet(
    params?: {
      fkWorkGroupId?: number;
    },
    context?: HttpContext
  ): Observable<WorkGroupLabelRes> {
    return this.apiAppWorkgroupLabelGet$Response(params, context).pipe(
      map((r: StrictHttpResponse<WorkGroupLabelRes>): WorkGroupLabelRes => r.body)
    );
  }

  /** Path part for operation `apiAppReloadConfsGet()` */
  static readonly ApiAppReloadConfsGetPath = '/api/app/reload-confs';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `apiAppReloadConfsGet()` instead.
   *
   * This method doesn't expect any request body.
   */
  apiAppReloadConfsGet$Response(
    params?: {
      secret?: string;
    },
    context?: HttpContext
  ): Observable<StrictHttpResponse<boolean>> {
    const rb = new RequestBuilder(this.rootUrl, AppService.ApiAppReloadConfsGetPath, 'get');
    if (params) {
      rb.query('secret', params.secret, {});
    }

    return this.http.request(
      rb.build({ responseType: 'json', accept: 'application/json', context })
    ).pipe(
      filter((r: any): r is HttpResponse<any> => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return (r as HttpResponse<any>).clone({ body: String((r as HttpResponse<any>).body) === 'true' }) as StrictHttpResponse<boolean>;
      })
    );
  }

  /**
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `apiAppReloadConfsGet$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  apiAppReloadConfsGet(
    params?: {
      secret?: string;
    },
    context?: HttpContext
  ): Observable<boolean> {
    return this.apiAppReloadConfsGet$Response(params, context).pipe(
      map((r: StrictHttpResponse<boolean>): boolean => r.body)
    );
  }

  /** Path part for operation `apiAppLoadDefaultContentGet()` */
  static readonly ApiAppLoadDefaultContentGetPath = '/api/app/load-default-content';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `apiAppLoadDefaultContentGet()` instead.
   *
   * This method doesn't expect any request body.
   */
  apiAppLoadDefaultContentGet$Response(
    params?: {
      secret?: string;
    },
    context?: HttpContext
  ): Observable<StrictHttpResponse<boolean>> {
    const rb = new RequestBuilder(this.rootUrl, AppService.ApiAppLoadDefaultContentGetPath, 'get');
    if (params) {
      rb.query('secret', params.secret, {});
    }

    return this.http.request(
      rb.build({ responseType: 'json', accept: 'application/json', context })
    ).pipe(
      filter((r: any): r is HttpResponse<any> => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return (r as HttpResponse<any>).clone({ body: String((r as HttpResponse<any>).body) === 'true' }) as StrictHttpResponse<boolean>;
      })
    );
  }

  /**
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `apiAppLoadDefaultContentGet$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  apiAppLoadDefaultContentGet(
    params?: {
      secret?: string;
    },
    context?: HttpContext
  ): Observable<boolean> {
    return this.apiAppLoadDefaultContentGet$Response(params, context).pipe(
      map((r: StrictHttpResponse<boolean>): boolean => r.body)
    );
  }

  /** Path part for operation `apiAppLoadDefaultEmailTemplatesGet()` */
  static readonly ApiAppLoadDefaultEmailTemplatesGetPath = '/api/app/load-default-email-templates';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `apiAppLoadDefaultEmailTemplatesGet()` instead.
   *
   * This method doesn't expect any request body.
   */
  apiAppLoadDefaultEmailTemplatesGet$Response(
    params?: {
      secret?: string;
    },
    context?: HttpContext
  ): Observable<StrictHttpResponse<boolean>> {
    const rb = new RequestBuilder(this.rootUrl, AppService.ApiAppLoadDefaultEmailTemplatesGetPath, 'get');
    if (params) {
      rb.query('secret', params.secret, {});
    }

    return this.http.request(
      rb.build({ responseType: 'json', accept: 'application/json', context })
    ).pipe(
      filter((r: any): r is HttpResponse<any> => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return (r as HttpResponse<any>).clone({ body: String((r as HttpResponse<any>).body) === 'true' }) as StrictHttpResponse<boolean>;
      })
    );
  }

  /**
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `apiAppLoadDefaultEmailTemplatesGet$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  apiAppLoadDefaultEmailTemplatesGet(
    params?: {
      secret?: string;
    },
    context?: HttpContext
  ): Observable<boolean> {
    return this.apiAppLoadDefaultEmailTemplatesGet$Response(params, context).pipe(
      map((r: StrictHttpResponse<boolean>): boolean => r.body)
    );
  }

  /** Path part for operation `apiAppTryConnectAzureGet()` */
  static readonly ApiAppTryConnectAzureGetPath = '/api/app/try-connect-azure';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `apiAppTryConnectAzureGet()` instead.
   *
   * This method doesn't expect any request body.
   */
  apiAppTryConnectAzureGet$Response(
    params?: {
      secret?: string;
    },
    context?: HttpContext
  ): Observable<StrictHttpResponse<boolean>> {
    const rb = new RequestBuilder(this.rootUrl, AppService.ApiAppTryConnectAzureGetPath, 'get');
    if (params) {
      rb.query('secret', params.secret, {});
    }

    return this.http.request(
      rb.build({ responseType: 'json', accept: 'application/json', context })
    ).pipe(
      filter((r: any): r is HttpResponse<any> => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return (r as HttpResponse<any>).clone({ body: String((r as HttpResponse<any>).body) === 'true' }) as StrictHttpResponse<boolean>;
      })
    );
  }

  /**
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `apiAppTryConnectAzureGet$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  apiAppTryConnectAzureGet(
    params?: {
      secret?: string;
    },
    context?: HttpContext
  ): Observable<boolean> {
    return this.apiAppTryConnectAzureGet$Response(params, context).pipe(
      map((r: StrictHttpResponse<boolean>): boolean => r.body)
    );
  }

  /** Path part for operation `apiAppTryConnectAzureTargetGet()` */
  static readonly ApiAppTryConnectAzureTargetGetPath = '/api/app/try-connect-azure-target';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `apiAppTryConnectAzureTargetGet()` instead.
   *
   * This method doesn't expect any request body.
   */
  apiAppTryConnectAzureTargetGet$Response(
    params?: {
      secret?: string;
      targetAd?: string;
    },
    context?: HttpContext
  ): Observable<StrictHttpResponse<boolean>> {
    const rb = new RequestBuilder(this.rootUrl, AppService.ApiAppTryConnectAzureTargetGetPath, 'get');
    if (params) {
      rb.query('secret', params.secret, {});
      rb.query('targetAd', params.targetAd, {});
    }

    return this.http.request(
      rb.build({ responseType: 'json', accept: 'application/json', context })
    ).pipe(
      filter((r: any): r is HttpResponse<any> => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return (r as HttpResponse<any>).clone({ body: String((r as HttpResponse<any>).body) === 'true' }) as StrictHttpResponse<boolean>;
      })
    );
  }

  /**
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `apiAppTryConnectAzureTargetGet$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  apiAppTryConnectAzureTargetGet(
    params?: {
      secret?: string;
      targetAd?: string;
    },
    context?: HttpContext
  ): Observable<boolean> {
    return this.apiAppTryConnectAzureTargetGet$Response(params, context).pipe(
      map((r: StrictHttpResponse<boolean>): boolean => r.body)
    );
  }

  /** Path part for operation `apiAppErr500Get()` */
  static readonly ApiAppErr500GetPath = '/api/app/err-500';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `apiAppErr500Get()` instead.
   *
   * This method doesn't expect any request body.
   */
  apiAppErr500Get$Response(
    params?: {
    },
    context?: HttpContext
  ): Observable<StrictHttpResponse<string>> {
    const rb = new RequestBuilder(this.rootUrl, AppService.ApiAppErr500GetPath, 'get');
    if (params) {
    }

    return this.http.request(
      rb.build({ responseType: 'json', accept: 'application/json', context })
    ).pipe(
      filter((r: any): r is HttpResponse<any> => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<string>;
      })
    );
  }

  /**
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `apiAppErr500Get$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  apiAppErr500Get(
    params?: {
    },
    context?: HttpContext
  ): Observable<string> {
    return this.apiAppErr500Get$Response(params, context).pipe(
      map((r: StrictHttpResponse<string>): string => r.body)
    );
  }

  /** Path part for operation `apiAppRemoveAllSwapGet()` */
  static readonly ApiAppRemoveAllSwapGetPath = '/api/app/remove-all-swap';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `apiAppRemoveAllSwapGet()` instead.
   *
   * This method doesn't expect any request body.
   */
  apiAppRemoveAllSwapGet$Response(
    params?: {
      secret?: string;
    },
    context?: HttpContext
  ): Observable<StrictHttpResponse<boolean>> {
    const rb = new RequestBuilder(this.rootUrl, AppService.ApiAppRemoveAllSwapGetPath, 'get');
    if (params) {
      rb.query('secret', params.secret, {});
    }

    return this.http.request(
      rb.build({ responseType: 'json', accept: 'application/json', context })
    ).pipe(
      filter((r: any): r is HttpResponse<any> => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return (r as HttpResponse<any>).clone({ body: String((r as HttpResponse<any>).body) === 'true' }) as StrictHttpResponse<boolean>;
      })
    );
  }

  /**
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `apiAppRemoveAllSwapGet$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  apiAppRemoveAllSwapGet(
    params?: {
      secret?: string;
    },
    context?: HttpContext
  ): Observable<boolean> {
    return this.apiAppRemoveAllSwapGet$Response(params, context).pipe(
      map((r: StrictHttpResponse<boolean>): boolean => r.body)
    );
  }

  /** Path part for operation `apiAppRunMasterCatTaskGet()` */
  static readonly ApiAppRunMasterCatTaskGetPath = '/api/app/run-master-cat-task';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `apiAppRunMasterCatTaskGet()` instead.
   *
   * This method doesn't expect any request body.
   */
  apiAppRunMasterCatTaskGet$Response(
    params?: {
      secret?: string;
    },
    context?: HttpContext
  ): Observable<StrictHttpResponse<boolean>> {
    const rb = new RequestBuilder(this.rootUrl, AppService.ApiAppRunMasterCatTaskGetPath, 'get');
    if (params) {
      rb.query('secret', params.secret, {});
    }

    return this.http.request(
      rb.build({ responseType: 'json', accept: 'application/json', context })
    ).pipe(
      filter((r: any): r is HttpResponse<any> => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return (r as HttpResponse<any>).clone({ body: String((r as HttpResponse<any>).body) === 'true' }) as StrictHttpResponse<boolean>;
      })
    );
  }

  /**
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `apiAppRunMasterCatTaskGet$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  apiAppRunMasterCatTaskGet(
    params?: {
      secret?: string;
    },
    context?: HttpContext
  ): Observable<boolean> {
    return this.apiAppRunMasterCatTaskGet$Response(params, context).pipe(
      map((r: StrictHttpResponse<boolean>): boolean => r.body)
    );
  }

}
