/* tslint:disable */
/* eslint-disable */
import { HttpClient, HttpContext, HttpResponse } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { filter, map } from 'rxjs/operators';

import { BaseService } from '../base-service';
import { ApiConfiguration } from '../api-configuration';
import { StrictHttpResponse } from '../strict-http-response';
import { RequestBuilder } from '../request-builder';

import { MasterGreenIndustryComSchQueryReq } from '../models/master-green-industry-com-sch-query-req';
import { MasterGreenIndustryComSchQueryRes } from '../models/master-green-industry-com-sch-query-res';

@Injectable({ providedIn: 'root' })
export class MasterGreenIndustryCompanyService extends BaseService {
  constructor(config: ApiConfiguration, http: HttpClient) {
    super(config, http);
  }

  /** Path part for operation `apiMasterGreenIndustryCompanyImportPost()` */
  static readonly ApiMasterGreenIndustryCompanyImportPostPath = '/api/master/GreenIndustryCompany/Import';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `apiMasterGreenIndustryCompanyImportPost()` instead.
   *
   * This method doesn't expect any request body.
   */
  apiMasterGreenIndustryCompanyImportPost$Response(
    params?: {
    },
    context?: HttpContext
  ): Observable<StrictHttpResponse<void>> {
    const rb = new RequestBuilder(this.rootUrl, MasterGreenIndustryCompanyService.ApiMasterGreenIndustryCompanyImportPostPath, 'post');
    if (params) {
    }

    return this.http.request(
      rb.build({ responseType: 'text', accept: '*/*', context })
    ).pipe(
      filter((r: any): r is HttpResponse<any> => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return (r as HttpResponse<any>).clone({ body: undefined }) as StrictHttpResponse<void>;
      })
    );
  }

  /**
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `apiMasterGreenIndustryCompanyImportPost$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  apiMasterGreenIndustryCompanyImportPost(
    params?: {
    },
    context?: HttpContext
  ): Observable<void> {
    return this.apiMasterGreenIndustryCompanyImportPost$Response(params, context).pipe(
      map((r: StrictHttpResponse<void>): void => r.body)
    );
  }

  /** Path part for operation `apiMasterGreenIndustryCompanySearchPost()` */
  static readonly ApiMasterGreenIndustryCompanySearchPostPath = '/api/master/GreenIndustryCompany/Search';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `apiMasterGreenIndustryCompanySearchPost()` instead.
   *
   * This method sends `application/json` and handles request body of type `application/json`.
   */
  apiMasterGreenIndustryCompanySearchPost$Response(
    params?: {
      body?: MasterGreenIndustryComSchQueryReq
    },
    context?: HttpContext
  ): Observable<StrictHttpResponse<Array<MasterGreenIndustryComSchQueryRes>>> {
    const rb = new RequestBuilder(this.rootUrl, MasterGreenIndustryCompanyService.ApiMasterGreenIndustryCompanySearchPostPath, 'post');
    if (params) {
      rb.body(params.body, 'application/json');
    }

    return this.http.request(
      rb.build({ responseType: 'json', accept: 'application/json', context })
    ).pipe(
      filter((r: any): r is HttpResponse<any> => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<Array<MasterGreenIndustryComSchQueryRes>>;
      })
    );
  }

  /**
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `apiMasterGreenIndustryCompanySearchPost$Response()` instead.
   *
   * This method sends `application/json` and handles request body of type `application/json`.
   */
  apiMasterGreenIndustryCompanySearchPost(
    params?: {
      body?: MasterGreenIndustryComSchQueryReq
    },
    context?: HttpContext
  ): Observable<Array<MasterGreenIndustryComSchQueryRes>> {
    return this.apiMasterGreenIndustryCompanySearchPost$Response(params, context).pipe(
      map((r: StrictHttpResponse<Array<MasterGreenIndustryComSchQueryRes>>): Array<MasterGreenIndustryComSchQueryRes> => r.body)
    );
  }

  /** Path part for operation `apiMasterGreenIndustryCompanyDelete()` */
  static readonly ApiMasterGreenIndustryCompanyDeletePath = '/api/master/GreenIndustryCompany';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `apiMasterGreenIndustryCompanyDelete()` instead.
   *
   * This method doesn't expect any request body.
   */
  apiMasterGreenIndustryCompanyDelete$Response(
    params?: {
      id?: number;
    },
    context?: HttpContext
  ): Observable<StrictHttpResponse<void>> {
    const rb = new RequestBuilder(this.rootUrl, MasterGreenIndustryCompanyService.ApiMasterGreenIndustryCompanyDeletePath, 'delete');
    if (params) {
      rb.query('id', params.id, {});
    }

    return this.http.request(
      rb.build({ responseType: 'text', accept: '*/*', context })
    ).pipe(
      filter((r: any): r is HttpResponse<any> => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return (r as HttpResponse<any>).clone({ body: undefined }) as StrictHttpResponse<void>;
      })
    );
  }

  /**
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `apiMasterGreenIndustryCompanyDelete$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  apiMasterGreenIndustryCompanyDelete(
    params?: {
      id?: number;
    },
    context?: HttpContext
  ): Observable<void> {
    return this.apiMasterGreenIndustryCompanyDelete$Response(params, context).pipe(
      map((r: StrictHttpResponse<void>): void => r.body)
    );
  }

  /** Path part for operation `apiMasterGreenIndustryCompanyGreenIndustryLevelGet()` */
  static readonly ApiMasterGreenIndustryCompanyGreenIndustryLevelGetPath = '/api/master/GreenIndustryCompany/green-industry-level';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `apiMasterGreenIndustryCompanyGreenIndustryLevelGet()` instead.
   *
   * This method doesn't expect any request body.
   */
  apiMasterGreenIndustryCompanyGreenIndustryLevelGet$Response(
    params?: {
    },
    context?: HttpContext
  ): Observable<StrictHttpResponse<Array<string>>> {
    const rb = new RequestBuilder(this.rootUrl, MasterGreenIndustryCompanyService.ApiMasterGreenIndustryCompanyGreenIndustryLevelGetPath, 'get');
    if (params) {
    }

    return this.http.request(
      rb.build({ responseType: 'json', accept: 'application/json', context })
    ).pipe(
      filter((r: any): r is HttpResponse<any> => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<Array<string>>;
      })
    );
  }

  /**
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `apiMasterGreenIndustryCompanyGreenIndustryLevelGet$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  apiMasterGreenIndustryCompanyGreenIndustryLevelGet(
    params?: {
    },
    context?: HttpContext
  ): Observable<Array<string>> {
    return this.apiMasterGreenIndustryCompanyGreenIndustryLevelGet$Response(params, context).pipe(
      map((r: StrictHttpResponse<Array<string>>): Array<string> => r.body)
    );
  }

}
