/* tslint:disable */
/* eslint-disable */
import { HttpClient, HttpContext, HttpResponse } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { filter, map } from 'rxjs/operators';

import { BaseService } from '../base-service';
import { ApiConfiguration } from '../api-configuration';
import { StrictHttpResponse } from '../strict-http-response';
import { RequestBuilder } from '../request-builder';

import { AnnouncementRes } from '../models/announcement-res';
import { ContactRes } from '../models/contact-res';
import { FaqReqSearch } from '../models/faq-req-search';
import { FaqResSearch } from '../models/faq-res-search';

@Injectable({ providedIn: 'root' })
export class HomeService extends BaseService {
  constructor(config: ApiConfiguration, http: HttpClient) {
    super(config, http);
  }

  /** Path part for operation `apiHomeSlideContentsGet()` */
  static readonly ApiHomeSlideContentsGetPath = '/api/home/slide-contents';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `apiHomeSlideContentsGet()` instead.
   *
   * This method doesn't expect any request body.
   */
  apiHomeSlideContentsGet$Response(
    params?: {
      FKWorkGroupIds?: Array<number>;
    },
    context?: HttpContext
  ): Observable<StrictHttpResponse<Array<AnnouncementRes>>> {
    const rb = new RequestBuilder(this.rootUrl, HomeService.ApiHomeSlideContentsGetPath, 'get');
    if (params) {
      rb.query('FKWorkGroupIds', params.FKWorkGroupIds, {});
    }

    return this.http.request(
      rb.build({ responseType: 'json', accept: 'application/json', context })
    ).pipe(
      filter((r: any): r is HttpResponse<any> => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<Array<AnnouncementRes>>;
      })
    );
  }

  /**
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `apiHomeSlideContentsGet$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  apiHomeSlideContentsGet(
    params?: {
      FKWorkGroupIds?: Array<number>;
    },
    context?: HttpContext
  ): Observable<Array<AnnouncementRes>> {
    return this.apiHomeSlideContentsGet$Response(params, context).pipe(
      map((r: StrictHttpResponse<Array<AnnouncementRes>>): Array<AnnouncementRes> => r.body)
    );
  }

  /** Path part for operation `apiHomeAnnouncementsPinAndLastedGet()` */
  static readonly ApiHomeAnnouncementsPinAndLastedGetPath = '/api/home/announcements/pin-and-lasted';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `apiHomeAnnouncementsPinAndLastedGet()` instead.
   *
   * This method doesn't expect any request body.
   */
  apiHomeAnnouncementsPinAndLastedGet$Response(
    params?: {
      FKWorkGroupIds?: Array<number>;
    },
    context?: HttpContext
  ): Observable<StrictHttpResponse<Array<AnnouncementRes>>> {
    const rb = new RequestBuilder(this.rootUrl, HomeService.ApiHomeAnnouncementsPinAndLastedGetPath, 'get');
    if (params) {
      rb.query('FKWorkGroupIds', params.FKWorkGroupIds, {});
    }

    return this.http.request(
      rb.build({ responseType: 'json', accept: 'application/json', context })
    ).pipe(
      filter((r: any): r is HttpResponse<any> => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<Array<AnnouncementRes>>;
      })
    );
  }

  /**
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `apiHomeAnnouncementsPinAndLastedGet$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  apiHomeAnnouncementsPinAndLastedGet(
    params?: {
      FKWorkGroupIds?: Array<number>;
    },
    context?: HttpContext
  ): Observable<Array<AnnouncementRes>> {
    return this.apiHomeAnnouncementsPinAndLastedGet$Response(params, context).pipe(
      map((r: StrictHttpResponse<Array<AnnouncementRes>>): Array<AnnouncementRes> => r.body)
    );
  }

  /** Path part for operation `apiHomeContactsGet()` */
  static readonly ApiHomeContactsGetPath = '/api/home/contacts';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `apiHomeContactsGet()` instead.
   *
   * This method doesn't expect any request body.
   */
  apiHomeContactsGet$Response(
    params?: {
      FKWorkGroupIds?: Array<number>;
    },
    context?: HttpContext
  ): Observable<StrictHttpResponse<Array<ContactRes>>> {
    const rb = new RequestBuilder(this.rootUrl, HomeService.ApiHomeContactsGetPath, 'get');
    if (params) {
      rb.query('FKWorkGroupIds', params.FKWorkGroupIds, {});
    }

    return this.http.request(
      rb.build({ responseType: 'json', accept: 'application/json', context })
    ).pipe(
      filter((r: any): r is HttpResponse<any> => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<Array<ContactRes>>;
      })
    );
  }

  /**
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `apiHomeContactsGet$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  apiHomeContactsGet(
    params?: {
      FKWorkGroupIds?: Array<number>;
    },
    context?: HttpContext
  ): Observable<Array<ContactRes>> {
    return this.apiHomeContactsGet$Response(params, context).pipe(
      map((r: StrictHttpResponse<Array<ContactRes>>): Array<ContactRes> => r.body)
    );
  }

  /** Path part for operation `apiHomeFaqQueryPost()` */
  static readonly ApiHomeFaqQueryPostPath = '/api/home/faq/query';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `apiHomeFaqQueryPost()` instead.
   *
   * This method sends `application/json` and handles request body of type `application/json`.
   */
  apiHomeFaqQueryPost$Response(
    params?: {
      body?: FaqReqSearch
    },
    context?: HttpContext
  ): Observable<StrictHttpResponse<FaqResSearch>> {
    const rb = new RequestBuilder(this.rootUrl, HomeService.ApiHomeFaqQueryPostPath, 'post');
    if (params) {
      rb.body(params.body, 'application/json');
    }

    return this.http.request(
      rb.build({ responseType: 'json', accept: 'application/json', context })
    ).pipe(
      filter((r: any): r is HttpResponse<any> => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<FaqResSearch>;
      })
    );
  }

  /**
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `apiHomeFaqQueryPost$Response()` instead.
   *
   * This method sends `application/json` and handles request body of type `application/json`.
   */
  apiHomeFaqQueryPost(
    params?: {
      body?: FaqReqSearch
    },
    context?: HttpContext
  ): Observable<FaqResSearch> {
    return this.apiHomeFaqQueryPost$Response(params, context).pipe(
      map((r: StrictHttpResponse<FaqResSearch>): FaqResSearch => r.body)
    );
  }

}
