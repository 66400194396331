/* tslint:disable */
/* eslint-disable */
import { HttpClient, HttpContext, HttpResponse } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { filter, map } from 'rxjs/operators';

import { BaseService } from '../base-service';
import { ApiConfiguration } from '../api-configuration';
import { StrictHttpResponse } from '../strict-http-response';
import { RequestBuilder } from '../request-builder';

import { FaqReq } from '../models/faq-req';
import { FaqReqSearch } from '../models/faq-req-search';
import { FaqResSearch } from '../models/faq-res-search';

@Injectable({ providedIn: 'root' })
export class CtMgrFaqService extends BaseService {
  constructor(config: ApiConfiguration, http: HttpClient) {
    super(config, http);
  }

  /** Path part for operation `apiContentMgrFaqQueryPost()` */
  static readonly ApiContentMgrFaqQueryPostPath = '/api/content-mgr/faq/query';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `apiContentMgrFaqQueryPost()` instead.
   *
   * This method sends `application/json` and handles request body of type `application/json`.
   */
  apiContentMgrFaqQueryPost$Response(
    params?: {
      body?: FaqReqSearch
    },
    context?: HttpContext
  ): Observable<StrictHttpResponse<FaqResSearch>> {
    const rb = new RequestBuilder(this.rootUrl, CtMgrFaqService.ApiContentMgrFaqQueryPostPath, 'post');
    if (params) {
      rb.body(params.body, 'application/json');
    }

    return this.http.request(
      rb.build({ responseType: 'json', accept: 'application/json', context })
    ).pipe(
      filter((r: any): r is HttpResponse<any> => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<FaqResSearch>;
      })
    );
  }

  /**
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `apiContentMgrFaqQueryPost$Response()` instead.
   *
   * This method sends `application/json` and handles request body of type `application/json`.
   */
  apiContentMgrFaqQueryPost(
    params?: {
      body?: FaqReqSearch
    },
    context?: HttpContext
  ): Observable<FaqResSearch> {
    return this.apiContentMgrFaqQueryPost$Response(params, context).pipe(
      map((r: StrictHttpResponse<FaqResSearch>): FaqResSearch => r.body)
    );
  }

  /** Path part for operation `apiContentMgrFaqPut()` */
  static readonly ApiContentMgrFaqPutPath = '/api/content-mgr/faq';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `apiContentMgrFaqPut()` instead.
   *
   * This method sends `application/json` and handles request body of type `application/json`.
   */
  apiContentMgrFaqPut$Response(
    params?: {
      body?: FaqReq
    },
    context?: HttpContext
  ): Observable<StrictHttpResponse<void>> {
    const rb = new RequestBuilder(this.rootUrl, CtMgrFaqService.ApiContentMgrFaqPutPath, 'put');
    if (params) {
      rb.body(params.body, 'application/json');
    }

    return this.http.request(
      rb.build({ responseType: 'text', accept: '*/*', context })
    ).pipe(
      filter((r: any): r is HttpResponse<any> => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return (r as HttpResponse<any>).clone({ body: undefined }) as StrictHttpResponse<void>;
      })
    );
  }

  /**
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `apiContentMgrFaqPut$Response()` instead.
   *
   * This method sends `application/json` and handles request body of type `application/json`.
   */
  apiContentMgrFaqPut(
    params?: {
      body?: FaqReq
    },
    context?: HttpContext
  ): Observable<void> {
    return this.apiContentMgrFaqPut$Response(params, context).pipe(
      map((r: StrictHttpResponse<void>): void => r.body)
    );
  }

  /** Path part for operation `apiContentMgrFaqPost()` */
  static readonly ApiContentMgrFaqPostPath = '/api/content-mgr/faq';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `apiContentMgrFaqPost()` instead.
   *
   * This method sends `application/json` and handles request body of type `application/json`.
   */
  apiContentMgrFaqPost$Response(
    params?: {
      body?: FaqReq
    },
    context?: HttpContext
  ): Observable<StrictHttpResponse<void>> {
    const rb = new RequestBuilder(this.rootUrl, CtMgrFaqService.ApiContentMgrFaqPostPath, 'post');
    if (params) {
      rb.body(params.body, 'application/json');
    }

    return this.http.request(
      rb.build({ responseType: 'text', accept: '*/*', context })
    ).pipe(
      filter((r: any): r is HttpResponse<any> => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return (r as HttpResponse<any>).clone({ body: undefined }) as StrictHttpResponse<void>;
      })
    );
  }

  /**
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `apiContentMgrFaqPost$Response()` instead.
   *
   * This method sends `application/json` and handles request body of type `application/json`.
   */
  apiContentMgrFaqPost(
    params?: {
      body?: FaqReq
    },
    context?: HttpContext
  ): Observable<void> {
    return this.apiContentMgrFaqPost$Response(params, context).pipe(
      map((r: StrictHttpResponse<void>): void => r.body)
    );
  }

  /** Path part for operation `apiContentMgrFaqDelete()` */
  static readonly ApiContentMgrFaqDeletePath = '/api/content-mgr/faq';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `apiContentMgrFaqDelete()` instead.
   *
   * This method doesn't expect any request body.
   */
  apiContentMgrFaqDelete$Response(
    params?: {
      faqId?: string;
    },
    context?: HttpContext
  ): Observable<StrictHttpResponse<void>> {
    const rb = new RequestBuilder(this.rootUrl, CtMgrFaqService.ApiContentMgrFaqDeletePath, 'delete');
    if (params) {
      rb.query('faqId', params.faqId, {});
    }

    return this.http.request(
      rb.build({ responseType: 'text', accept: '*/*', context })
    ).pipe(
      filter((r: any): r is HttpResponse<any> => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return (r as HttpResponse<any>).clone({ body: undefined }) as StrictHttpResponse<void>;
      })
    );
  }

  /**
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `apiContentMgrFaqDelete$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  apiContentMgrFaqDelete(
    params?: {
      faqId?: string;
    },
    context?: HttpContext
  ): Observable<void> {
    return this.apiContentMgrFaqDelete$Response(params, context).pipe(
      map((r: StrictHttpResponse<void>): void => r.body)
    );
  }

}
