/* tslint:disable */
/* eslint-disable */
import { HttpClient, HttpContext, HttpResponse } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { filter, map } from 'rxjs/operators';

import { BaseService } from '../base-service';
import { ApiConfiguration } from '../api-configuration';
import { StrictHttpResponse } from '../strict-http-response';
import { RequestBuilder } from '../request-builder';

import { MasterCcrhmReasonEditItemDto } from '../models/master-ccrhm-reason-edit-item-dto';
import { MasterCcrhmReasonRowItem } from '../models/master-ccrhm-reason-row-item';

@Injectable({ providedIn: 'root' })
export class MasterCcrhmReasonService extends BaseService {
  constructor(config: ApiConfiguration, http: HttpClient) {
    super(config, http);
  }

  /** Path part for operation `apiMasterCcrhmReasonGet()` */
  static readonly ApiMasterCcrhmReasonGetPath = '/api/master/ccrhm/reason';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `apiMasterCcrhmReasonGet()` instead.
   *
   * This method doesn't expect any request body.
   */
  apiMasterCcrhmReasonGet$Response(
    params?: {
      id?: number;
    },
    context?: HttpContext
  ): Observable<StrictHttpResponse<MasterCcrhmReasonEditItemDto>> {
    const rb = new RequestBuilder(this.rootUrl, MasterCcrhmReasonService.ApiMasterCcrhmReasonGetPath, 'get');
    if (params) {
      rb.query('id', params.id, {});
    }

    return this.http.request(
      rb.build({ responseType: 'json', accept: 'application/json', context })
    ).pipe(
      filter((r: any): r is HttpResponse<any> => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<MasterCcrhmReasonEditItemDto>;
      })
    );
  }

  /**
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `apiMasterCcrhmReasonGet$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  apiMasterCcrhmReasonGet(
    params?: {
      id?: number;
    },
    context?: HttpContext
  ): Observable<MasterCcrhmReasonEditItemDto> {
    return this.apiMasterCcrhmReasonGet$Response(params, context).pipe(
      map((r: StrictHttpResponse<MasterCcrhmReasonEditItemDto>): MasterCcrhmReasonEditItemDto => r.body)
    );
  }

  /** Path part for operation `apiMasterCcrhmReasonPut()` */
  static readonly ApiMasterCcrhmReasonPutPath = '/api/master/ccrhm/reason';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `apiMasterCcrhmReasonPut()` instead.
   *
   * This method sends `application/json` and handles request body of type `application/json`.
   */
  apiMasterCcrhmReasonPut$Response(
    params?: {
      id?: number;
      body?: MasterCcrhmReasonEditItemDto
    },
    context?: HttpContext
  ): Observable<StrictHttpResponse<void>> {
    const rb = new RequestBuilder(this.rootUrl, MasterCcrhmReasonService.ApiMasterCcrhmReasonPutPath, 'put');
    if (params) {
      rb.query('id', params.id, {});
      rb.body(params.body, 'application/json');
    }

    return this.http.request(
      rb.build({ responseType: 'text', accept: '*/*', context })
    ).pipe(
      filter((r: any): r is HttpResponse<any> => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return (r as HttpResponse<any>).clone({ body: undefined }) as StrictHttpResponse<void>;
      })
    );
  }

  /**
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `apiMasterCcrhmReasonPut$Response()` instead.
   *
   * This method sends `application/json` and handles request body of type `application/json`.
   */
  apiMasterCcrhmReasonPut(
    params?: {
      id?: number;
      body?: MasterCcrhmReasonEditItemDto
    },
    context?: HttpContext
  ): Observable<void> {
    return this.apiMasterCcrhmReasonPut$Response(params, context).pipe(
      map((r: StrictHttpResponse<void>): void => r.body)
    );
  }

  /** Path part for operation `apiMasterCcrhmReasonPost()` */
  static readonly ApiMasterCcrhmReasonPostPath = '/api/master/ccrhm/reason';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `apiMasterCcrhmReasonPost()` instead.
   *
   * This method sends `application/json` and handles request body of type `application/json`.
   */
  apiMasterCcrhmReasonPost$Response(
    params?: {
      body?: MasterCcrhmReasonEditItemDto
    },
    context?: HttpContext
  ): Observable<StrictHttpResponse<MasterCcrhmReasonRowItem>> {
    const rb = new RequestBuilder(this.rootUrl, MasterCcrhmReasonService.ApiMasterCcrhmReasonPostPath, 'post');
    if (params) {
      rb.body(params.body, 'application/json');
    }

    return this.http.request(
      rb.build({ responseType: 'json', accept: 'application/json', context })
    ).pipe(
      filter((r: any): r is HttpResponse<any> => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<MasterCcrhmReasonRowItem>;
      })
    );
  }

  /**
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `apiMasterCcrhmReasonPost$Response()` instead.
   *
   * This method sends `application/json` and handles request body of type `application/json`.
   */
  apiMasterCcrhmReasonPost(
    params?: {
      body?: MasterCcrhmReasonEditItemDto
    },
    context?: HttpContext
  ): Observable<MasterCcrhmReasonRowItem> {
    return this.apiMasterCcrhmReasonPost$Response(params, context).pipe(
      map((r: StrictHttpResponse<MasterCcrhmReasonRowItem>): MasterCcrhmReasonRowItem => r.body)
    );
  }

  /** Path part for operation `apiMasterCcrhmReasonDelete()` */
  static readonly ApiMasterCcrhmReasonDeletePath = '/api/master/ccrhm/reason';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `apiMasterCcrhmReasonDelete()` instead.
   *
   * This method doesn't expect any request body.
   */
  apiMasterCcrhmReasonDelete$Response(
    params?: {
      id?: number;
    },
    context?: HttpContext
  ): Observable<StrictHttpResponse<void>> {
    const rb = new RequestBuilder(this.rootUrl, MasterCcrhmReasonService.ApiMasterCcrhmReasonDeletePath, 'delete');
    if (params) {
      rb.query('id', params.id, {});
    }

    return this.http.request(
      rb.build({ responseType: 'text', accept: '*/*', context })
    ).pipe(
      filter((r: any): r is HttpResponse<any> => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return (r as HttpResponse<any>).clone({ body: undefined }) as StrictHttpResponse<void>;
      })
    );
  }

  /**
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `apiMasterCcrhmReasonDelete$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  apiMasterCcrhmReasonDelete(
    params?: {
      id?: number;
    },
    context?: HttpContext
  ): Observable<void> {
    return this.apiMasterCcrhmReasonDelete$Response(params, context).pipe(
      map((r: StrictHttpResponse<void>): void => r.body)
    );
  }

  /** Path part for operation `apiMasterCcrhmReasonAllGet()` */
  static readonly ApiMasterCcrhmReasonAllGetPath = '/api/master/ccrhm/reason/all';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `apiMasterCcrhmReasonAllGet()` instead.
   *
   * This method doesn't expect any request body.
   */
  apiMasterCcrhmReasonAllGet$Response(
    params?: {
      workingGroups?: Array<number>;
    },
    context?: HttpContext
  ): Observable<StrictHttpResponse<Array<MasterCcrhmReasonRowItem>>> {
    const rb = new RequestBuilder(this.rootUrl, MasterCcrhmReasonService.ApiMasterCcrhmReasonAllGetPath, 'get');
    if (params) {
      rb.query('workingGroups', params.workingGroups, {});
    }

    return this.http.request(
      rb.build({ responseType: 'json', accept: 'application/json', context })
    ).pipe(
      filter((r: any): r is HttpResponse<any> => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<Array<MasterCcrhmReasonRowItem>>;
      })
    );
  }

  /**
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `apiMasterCcrhmReasonAllGet$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  apiMasterCcrhmReasonAllGet(
    params?: {
      workingGroups?: Array<number>;
    },
    context?: HttpContext
  ): Observable<Array<MasterCcrhmReasonRowItem>> {
    return this.apiMasterCcrhmReasonAllGet$Response(params, context).pipe(
      map((r: StrictHttpResponse<Array<MasterCcrhmReasonRowItem>>): Array<MasterCcrhmReasonRowItem> => r.body)
    );
  }

}
