import { HttpBackend, HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { ActivatedRouteSnapshot, CanActivate, Router, RouterStateSnapshot } from '@angular/router';
import { MsalBroadcastService, MsalService } from '@azure/msal-angular';
import { InteractionStatus } from '@azure/msal-browser';
import { isNil } from 'lodash';
import { filter, Observable, of, switchMap } from 'rxjs';
import { AccountService } from '../../api/services';
import { AuthService } from '../../commons/services/auth.service';

@Injectable({ providedIn: 'root' })
export class CanActivateGuard implements CanActivate {
  constructor(private msalService: MsalService,
    private authService: AuthService,
    private accountService: AccountService,
    private router: Router,
    private msalBroadcastService: MsalBroadcastService) { }

  canActivate(route: ActivatedRouteSnapshot, state: RouterStateSnapshot): Observable<boolean> | Promise<boolean> | boolean {
    return this.msalBroadcastService.inProgress$
      .pipe(
        filter((status: InteractionStatus) => status === InteractionStatus.None),
        switchMap(() => {

          if (!isNil(this.authService.getCurrentAD) && this.msalService.instance.getAllAccounts().length > 0) {
            return of(true);
          }
          //this.authService.redirectUrl = state.url;
          //if (!isNil(this.authService.getCurrentAD)) {
          //  this.router.navigate(['/']);
          //} else {
            if (isNil(this.authService.getCurrentAD))
              this.router.navigate(['/login']);
          //}
         
          return of(false);
        })
      );
  }
}
