/* tslint:disable */
/* eslint-disable */
import { HttpClient, HttpContext, HttpResponse } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { filter, map } from 'rxjs/operators';

import { BaseService } from '../base-service';
import { ApiConfiguration } from '../api-configuration';
import { StrictHttpResponse } from '../strict-http-response';
import { RequestBuilder } from '../request-builder';

import { DirectActionItem } from '../models/direct-action-item';
import { InprocessChecker } from '../models/inprocess-checker';
import { ManualRequestLogItem } from '../models/manual-request-log-item';
import { ManualRequestReq } from '../models/manual-request-req';
import { ManualRequestSubmitRes } from '../models/manual-request-submit-res';
import { StepBar } from '../models/step-bar';

@Injectable({ providedIn: 'root' })
export class ManualRequestService extends BaseService {
  constructor(config: ApiConfiguration, http: HttpClient) {
    super(config, http);
  }

  /** Path part for operation `apiManualRequestGetManualRequestProfileIdGet()` */
  static readonly ApiManualRequestGetManualRequestProfileIdGetPath = '/api/manual-request/Get-ManualRequest-ProfileId';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `apiManualRequestGetManualRequestProfileIdGet()` instead.
   *
   * This method doesn't expect any request body.
   */
  apiManualRequestGetManualRequestProfileIdGet$Response(
    params?: {
      id?: string;
      FKWorkGroupId?: number;
    },
    context?: HttpContext
  ): Observable<StrictHttpResponse<ManualRequestReq>> {
    const rb = new RequestBuilder(this.rootUrl, ManualRequestService.ApiManualRequestGetManualRequestProfileIdGetPath, 'get');
    if (params) {
      rb.query('id', params.id, {});
      rb.query('FKWorkGroupId', params.FKWorkGroupId, {});
    }

    return this.http.request(
      rb.build({ responseType: 'json', accept: 'application/json', context })
    ).pipe(
      filter((r: any): r is HttpResponse<any> => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<ManualRequestReq>;
      })
    );
  }

  /**
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `apiManualRequestGetManualRequestProfileIdGet$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  apiManualRequestGetManualRequestProfileIdGet(
    params?: {
      id?: string;
      FKWorkGroupId?: number;
    },
    context?: HttpContext
  ): Observable<ManualRequestReq> {
    return this.apiManualRequestGetManualRequestProfileIdGet$Response(params, context).pipe(
      map((r: StrictHttpResponse<ManualRequestReq>): ManualRequestReq => r.body)
    );
  }

  /** Path part for operation `apiManualRequestManualRequestFileConfirmGet()` */
  static readonly ApiManualRequestManualRequestFileConfirmGetPath = '/api/manual-request/ManualRequest-FileConfirm';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `apiManualRequestManualRequestFileConfirmGet()` instead.
   *
   * This method doesn't expect any request body.
   */
  apiManualRequestManualRequestFileConfirmGet$Response(
    params?: {
      fileId?: string;
    },
    context?: HttpContext
  ): Observable<StrictHttpResponse<void>> {
    const rb = new RequestBuilder(this.rootUrl, ManualRequestService.ApiManualRequestManualRequestFileConfirmGetPath, 'get');
    if (params) {
      rb.query('fileId', params.fileId, {});
    }

    return this.http.request(
      rb.build({ responseType: 'text', accept: '*/*', context })
    ).pipe(
      filter((r: any): r is HttpResponse<any> => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return (r as HttpResponse<any>).clone({ body: undefined }) as StrictHttpResponse<void>;
      })
    );
  }

  /**
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `apiManualRequestManualRequestFileConfirmGet$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  apiManualRequestManualRequestFileConfirmGet(
    params?: {
      fileId?: string;
    },
    context?: HttpContext
  ): Observable<void> {
    return this.apiManualRequestManualRequestFileConfirmGet$Response(params, context).pipe(
      map((r: StrictHttpResponse<void>): void => r.body)
    );
  }

  /** Path part for operation `apiManualRequestGetActivityLogGet()` */
  static readonly ApiManualRequestGetActivityLogGetPath = '/api/manual-request/Get-activity-log';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `apiManualRequestGetActivityLogGet()` instead.
   *
   * This method doesn't expect any request body.
   */
  apiManualRequestGetActivityLogGet$Response(
    params?: {
      id?: string;
    },
    context?: HttpContext
  ): Observable<StrictHttpResponse<Array<ManualRequestLogItem>>> {
    const rb = new RequestBuilder(this.rootUrl, ManualRequestService.ApiManualRequestGetActivityLogGetPath, 'get');
    if (params) {
      rb.query('id', params.id, {});
    }

    return this.http.request(
      rb.build({ responseType: 'json', accept: 'application/json', context })
    ).pipe(
      filter((r: any): r is HttpResponse<any> => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<Array<ManualRequestLogItem>>;
      })
    );
  }

  /**
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `apiManualRequestGetActivityLogGet$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  apiManualRequestGetActivityLogGet(
    params?: {
      id?: string;
    },
    context?: HttpContext
  ): Observable<Array<ManualRequestLogItem>> {
    return this.apiManualRequestGetActivityLogGet$Response(params, context).pipe(
      map((r: StrictHttpResponse<Array<ManualRequestLogItem>>): Array<ManualRequestLogItem> => r.body)
    );
  }

  /** Path part for operation `apiManualRequestGetVmAdminGet()` */
  static readonly ApiManualRequestGetVmAdminGetPath = '/api/manual-request/Get-VM-Admin';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `apiManualRequestGetVmAdminGet()` instead.
   *
   * This method doesn't expect any request body.
   */
  apiManualRequestGetVmAdminGet$Response(
    params?: {
      workGroupIds?: Array<number>;
    },
    context?: HttpContext
  ): Observable<StrictHttpResponse<Array<string>>> {
    const rb = new RequestBuilder(this.rootUrl, ManualRequestService.ApiManualRequestGetVmAdminGetPath, 'get');
    if (params) {
      rb.query('workGroupIds', params.workGroupIds, {});
    }

    return this.http.request(
      rb.build({ responseType: 'json', accept: 'application/json', context })
    ).pipe(
      filter((r: any): r is HttpResponse<any> => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<Array<string>>;
      })
    );
  }

  /**
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `apiManualRequestGetVmAdminGet$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  apiManualRequestGetVmAdminGet(
    params?: {
      workGroupIds?: Array<number>;
    },
    context?: HttpContext
  ): Observable<Array<string>> {
    return this.apiManualRequestGetVmAdminGet$Response(params, context).pipe(
      map((r: StrictHttpResponse<Array<string>>): Array<string> => r.body)
    );
  }

  /** Path part for operation `apiManualRequestGetSmApproverGet()` */
  static readonly ApiManualRequestGetSmApproverGetPath = '/api/manual-request/Get-SM-Approver';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `apiManualRequestGetSmApproverGet()` instead.
   *
   * This method doesn't expect any request body.
   */
  apiManualRequestGetSmApproverGet$Response(
    params?: {
      vmAdminEmail?: string;
    },
    context?: HttpContext
  ): Observable<StrictHttpResponse<Array<string>>> {
    const rb = new RequestBuilder(this.rootUrl, ManualRequestService.ApiManualRequestGetSmApproverGetPath, 'get');
    if (params) {
      rb.query('vmAdminEmail', params.vmAdminEmail, {});
    }

    return this.http.request(
      rb.build({ responseType: 'json', accept: 'application/json', context })
    ).pipe(
      filter((r: any): r is HttpResponse<any> => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<Array<string>>;
      })
    );
  }

  /**
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `apiManualRequestGetSmApproverGet$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  apiManualRequestGetSmApproverGet(
    params?: {
      vmAdminEmail?: string;
    },
    context?: HttpContext
  ): Observable<Array<string>> {
    return this.apiManualRequestGetSmApproverGet$Response(params, context).pipe(
      map((r: StrictHttpResponse<Array<string>>): Array<string> => r.body)
    );
  }

  /** Path part for operation `apiManualRequestGetManualRequestIdGet()` */
  static readonly ApiManualRequestGetManualRequestIdGetPath = '/api/manual-request/Get-ManualRequest-Id';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `apiManualRequestGetManualRequestIdGet()` instead.
   *
   * This method doesn't expect any request body.
   */
  apiManualRequestGetManualRequestIdGet$Response(
    params?: {
      id?: string;
    },
    context?: HttpContext
  ): Observable<StrictHttpResponse<ManualRequestReq>> {
    const rb = new RequestBuilder(this.rootUrl, ManualRequestService.ApiManualRequestGetManualRequestIdGetPath, 'get');
    if (params) {
      rb.query('id', params.id, {});
    }

    return this.http.request(
      rb.build({ responseType: 'json', accept: 'application/json', context })
    ).pipe(
      filter((r: any): r is HttpResponse<any> => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<ManualRequestReq>;
      })
    );
  }

  /**
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `apiManualRequestGetManualRequestIdGet$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  apiManualRequestGetManualRequestIdGet(
    params?: {
      id?: string;
    },
    context?: HttpContext
  ): Observable<ManualRequestReq> {
    return this.apiManualRequestGetManualRequestIdGet$Response(params, context).pipe(
      map((r: StrictHttpResponse<ManualRequestReq>): ManualRequestReq => r.body)
    );
  }

  /** Path part for operation `apiManualRequestInprocessCheckerGet()` */
  static readonly ApiManualRequestInprocessCheckerGetPath = '/api/manual-request/Inprocess-Checker';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `apiManualRequestInprocessCheckerGet()` instead.
   *
   * This method doesn't expect any request body.
   */
  apiManualRequestInprocessCheckerGet$Response(
    params?: {
      pkVendorProfileId?: string;
    },
    context?: HttpContext
  ): Observable<StrictHttpResponse<InprocessChecker>> {
    const rb = new RequestBuilder(this.rootUrl, ManualRequestService.ApiManualRequestInprocessCheckerGetPath, 'get');
    if (params) {
      rb.query('pkVendorProfileId', params.pkVendorProfileId, {});
    }

    return this.http.request(
      rb.build({ responseType: 'json', accept: 'application/json', context })
    ).pipe(
      filter((r: any): r is HttpResponse<any> => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<InprocessChecker>;
      })
    );
  }

  /**
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `apiManualRequestInprocessCheckerGet$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  apiManualRequestInprocessCheckerGet(
    params?: {
      pkVendorProfileId?: string;
    },
    context?: HttpContext
  ): Observable<InprocessChecker> {
    return this.apiManualRequestInprocessCheckerGet$Response(params, context).pipe(
      map((r: StrictHttpResponse<InprocessChecker>): InprocessChecker => r.body)
    );
  }

  /** Path part for operation `apiManualRequestNewRequestActionPost()` */
  static readonly ApiManualRequestNewRequestActionPostPath = '/api/manual-request/NewRequest-Action';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `apiManualRequestNewRequestActionPost()` instead.
   *
   * This method sends `application/json` and handles request body of type `application/json`.
   */
  apiManualRequestNewRequestActionPost$Response(
    params?: {
      body?: ManualRequestReq
    },
    context?: HttpContext
  ): Observable<StrictHttpResponse<ManualRequestSubmitRes>> {
    const rb = new RequestBuilder(this.rootUrl, ManualRequestService.ApiManualRequestNewRequestActionPostPath, 'post');
    if (params) {
      rb.body(params.body, 'application/json');
    }

    return this.http.request(
      rb.build({ responseType: 'json', accept: 'application/json', context })
    ).pipe(
      filter((r: any): r is HttpResponse<any> => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<ManualRequestSubmitRes>;
      })
    );
  }

  /**
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `apiManualRequestNewRequestActionPost$Response()` instead.
   *
   * This method sends `application/json` and handles request body of type `application/json`.
   */
  apiManualRequestNewRequestActionPost(
    params?: {
      body?: ManualRequestReq
    },
    context?: HttpContext
  ): Observable<ManualRequestSubmitRes> {
    return this.apiManualRequestNewRequestActionPost$Response(params, context).pipe(
      map((r: StrictHttpResponse<ManualRequestSubmitRes>): ManualRequestSubmitRes => r.body)
    );
  }

  /** Path part for operation `apiManualRequestCreatorReviseActionPost()` */
  static readonly ApiManualRequestCreatorReviseActionPostPath = '/api/manual-request/CreatorRevise-Action';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `apiManualRequestCreatorReviseActionPost()` instead.
   *
   * This method sends `application/json` and handles request body of type `application/json`.
   */
  apiManualRequestCreatorReviseActionPost$Response(
    params?: {
      body?: ManualRequestReq
    },
    context?: HttpContext
  ): Observable<StrictHttpResponse<ManualRequestSubmitRes>> {
    const rb = new RequestBuilder(this.rootUrl, ManualRequestService.ApiManualRequestCreatorReviseActionPostPath, 'post');
    if (params) {
      rb.body(params.body, 'application/json');
    }

    return this.http.request(
      rb.build({ responseType: 'json', accept: 'application/json', context })
    ).pipe(
      filter((r: any): r is HttpResponse<any> => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<ManualRequestSubmitRes>;
      })
    );
  }

  /**
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `apiManualRequestCreatorReviseActionPost$Response()` instead.
   *
   * This method sends `application/json` and handles request body of type `application/json`.
   */
  apiManualRequestCreatorReviseActionPost(
    params?: {
      body?: ManualRequestReq
    },
    context?: HttpContext
  ): Observable<ManualRequestSubmitRes> {
    return this.apiManualRequestCreatorReviseActionPost$Response(params, context).pipe(
      map((r: StrictHttpResponse<ManualRequestSubmitRes>): ManualRequestSubmitRes => r.body)
    );
  }

  /** Path part for operation `apiManualRequestVmAdminVerifyActionPost()` */
  static readonly ApiManualRequestVmAdminVerifyActionPostPath = '/api/manual-request/VMAdminVerify-Action';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `apiManualRequestVmAdminVerifyActionPost()` instead.
   *
   * This method sends `application/json` and handles request body of type `application/json`.
   */
  apiManualRequestVmAdminVerifyActionPost$Response(
    params?: {
      body?: ManualRequestReq
    },
    context?: HttpContext
  ): Observable<StrictHttpResponse<ManualRequestSubmitRes>> {
    const rb = new RequestBuilder(this.rootUrl, ManualRequestService.ApiManualRequestVmAdminVerifyActionPostPath, 'post');
    if (params) {
      rb.body(params.body, 'application/json');
    }

    return this.http.request(
      rb.build({ responseType: 'json', accept: 'application/json', context })
    ).pipe(
      filter((r: any): r is HttpResponse<any> => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<ManualRequestSubmitRes>;
      })
    );
  }

  /**
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `apiManualRequestVmAdminVerifyActionPost$Response()` instead.
   *
   * This method sends `application/json` and handles request body of type `application/json`.
   */
  apiManualRequestVmAdminVerifyActionPost(
    params?: {
      body?: ManualRequestReq
    },
    context?: HttpContext
  ): Observable<ManualRequestSubmitRes> {
    return this.apiManualRequestVmAdminVerifyActionPost$Response(params, context).pipe(
      map((r: StrictHttpResponse<ManualRequestSubmitRes>): ManualRequestSubmitRes => r.body)
    );
  }

  /** Path part for operation `apiManualRequestSmApproveActionPost()` */
  static readonly ApiManualRequestSmApproveActionPostPath = '/api/manual-request/SMApprove-Action';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `apiManualRequestSmApproveActionPost()` instead.
   *
   * This method sends `application/json` and handles request body of type `application/json`.
   */
  apiManualRequestSmApproveActionPost$Response(
    params?: {
      body?: ManualRequestReq
    },
    context?: HttpContext
  ): Observable<StrictHttpResponse<ManualRequestSubmitRes>> {
    const rb = new RequestBuilder(this.rootUrl, ManualRequestService.ApiManualRequestSmApproveActionPostPath, 'post');
    if (params) {
      rb.body(params.body, 'application/json');
    }

    return this.http.request(
      rb.build({ responseType: 'json', accept: 'application/json', context })
    ).pipe(
      filter((r: any): r is HttpResponse<any> => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<ManualRequestSubmitRes>;
      })
    );
  }

  /**
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `apiManualRequestSmApproveActionPost$Response()` instead.
   *
   * This method sends `application/json` and handles request body of type `application/json`.
   */
  apiManualRequestSmApproveActionPost(
    params?: {
      body?: ManualRequestReq
    },
    context?: HttpContext
  ): Observable<ManualRequestSubmitRes> {
    return this.apiManualRequestSmApproveActionPost$Response(params, context).pipe(
      map((r: StrictHttpResponse<ManualRequestSubmitRes>): ManualRequestSubmitRes => r.body)
    );
  }

  /** Path part for operation `apiManualRequestGetDirectActionItemGet()` */
  static readonly ApiManualRequestGetDirectActionItemGetPath = '/api/manual-request/Get-DirectActionItem';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `apiManualRequestGetDirectActionItemGet()` instead.
   *
   * This method doesn't expect any request body.
   */
  apiManualRequestGetDirectActionItemGet$Response(
    params?: {
      requestItemId?: string;
      defaultAction?: string;
    },
    context?: HttpContext
  ): Observable<StrictHttpResponse<DirectActionItem>> {
    const rb = new RequestBuilder(this.rootUrl, ManualRequestService.ApiManualRequestGetDirectActionItemGetPath, 'get');
    if (params) {
      rb.query('requestItemId', params.requestItemId, {});
      rb.query('defaultAction', params.defaultAction, {});
    }

    return this.http.request(
      rb.build({ responseType: 'json', accept: 'application/json', context })
    ).pipe(
      filter((r: any): r is HttpResponse<any> => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<DirectActionItem>;
      })
    );
  }

  /**
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `apiManualRequestGetDirectActionItemGet$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  apiManualRequestGetDirectActionItemGet(
    params?: {
      requestItemId?: string;
      defaultAction?: string;
    },
    context?: HttpContext
  ): Observable<DirectActionItem> {
    return this.apiManualRequestGetDirectActionItemGet$Response(params, context).pipe(
      map((r: StrictHttpResponse<DirectActionItem>): DirectActionItem => r.body)
    );
  }

  /** Path part for operation `apiManualRequestTakeDirectActionPost()` */
  static readonly ApiManualRequestTakeDirectActionPostPath = '/api/manual-request/Take-DirectAction';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `apiManualRequestTakeDirectActionPost()` instead.
   *
   * This method sends `application/json` and handles request body of type `application/json`.
   */
  apiManualRequestTakeDirectActionPost$Response(
    params?: {
      body?: DirectActionItem
    },
    context?: HttpContext
  ): Observable<StrictHttpResponse<ManualRequestSubmitRes>> {
    const rb = new RequestBuilder(this.rootUrl, ManualRequestService.ApiManualRequestTakeDirectActionPostPath, 'post');
    if (params) {
      rb.body(params.body, 'application/json');
    }

    return this.http.request(
      rb.build({ responseType: 'json', accept: 'application/json', context })
    ).pipe(
      filter((r: any): r is HttpResponse<any> => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<ManualRequestSubmitRes>;
      })
    );
  }

  /**
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `apiManualRequestTakeDirectActionPost$Response()` instead.
   *
   * This method sends `application/json` and handles request body of type `application/json`.
   */
  apiManualRequestTakeDirectActionPost(
    params?: {
      body?: DirectActionItem
    },
    context?: HttpContext
  ): Observable<ManualRequestSubmitRes> {
    return this.apiManualRequestTakeDirectActionPost$Response(params, context).pipe(
      map((r: StrictHttpResponse<ManualRequestSubmitRes>): ManualRequestSubmitRes => r.body)
    );
  }

  /** Path part for operation `apiManualRequestVmAdminCloseActionPost()` */
  static readonly ApiManualRequestVmAdminCloseActionPostPath = '/api/manual-request/VMAdminClose-Action';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `apiManualRequestVmAdminCloseActionPost()` instead.
   *
   * This method sends `application/json` and handles request body of type `application/json`.
   */
  apiManualRequestVmAdminCloseActionPost$Response(
    params?: {
      body?: ManualRequestReq
    },
    context?: HttpContext
  ): Observable<StrictHttpResponse<ManualRequestSubmitRes>> {
    const rb = new RequestBuilder(this.rootUrl, ManualRequestService.ApiManualRequestVmAdminCloseActionPostPath, 'post');
    if (params) {
      rb.body(params.body, 'application/json');
    }

    return this.http.request(
      rb.build({ responseType: 'json', accept: 'application/json', context })
    ).pipe(
      filter((r: any): r is HttpResponse<any> => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<ManualRequestSubmitRes>;
      })
    );
  }

  /**
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `apiManualRequestVmAdminCloseActionPost$Response()` instead.
   *
   * This method sends `application/json` and handles request body of type `application/json`.
   */
  apiManualRequestVmAdminCloseActionPost(
    params?: {
      body?: ManualRequestReq
    },
    context?: HttpContext
  ): Observable<ManualRequestSubmitRes> {
    return this.apiManualRequestVmAdminCloseActionPost$Response(params, context).pipe(
      map((r: StrictHttpResponse<ManualRequestSubmitRes>): ManualRequestSubmitRes => r.body)
    );
  }

  /** Path part for operation `apiManualRequestVmAdminBookingPost()` */
  static readonly ApiManualRequestVmAdminBookingPostPath = '/api/manual-request/VMAdmin-booking';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `apiManualRequestVmAdminBookingPost()` instead.
   *
   * This method sends `application/json` and handles request body of type `application/json`.
   */
  apiManualRequestVmAdminBookingPost$Response(
    params?: {
      body?: ManualRequestReq
    },
    context?: HttpContext
  ): Observable<StrictHttpResponse<ManualRequestSubmitRes>> {
    const rb = new RequestBuilder(this.rootUrl, ManualRequestService.ApiManualRequestVmAdminBookingPostPath, 'post');
    if (params) {
      rb.body(params.body, 'application/json');
    }

    return this.http.request(
      rb.build({ responseType: 'json', accept: 'application/json', context })
    ).pipe(
      filter((r: any): r is HttpResponse<any> => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<ManualRequestSubmitRes>;
      })
    );
  }

  /**
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `apiManualRequestVmAdminBookingPost$Response()` instead.
   *
   * This method sends `application/json` and handles request body of type `application/json`.
   */
  apiManualRequestVmAdminBookingPost(
    params?: {
      body?: ManualRequestReq
    },
    context?: HttpContext
  ): Observable<ManualRequestSubmitRes> {
    return this.apiManualRequestVmAdminBookingPost$Response(params, context).pipe(
      map((r: StrictHttpResponse<ManualRequestSubmitRes>): ManualRequestSubmitRes => r.body)
    );
  }

  /** Path part for operation `apiManualRequestGetStepbarGet()` */
  static readonly ApiManualRequestGetStepbarGetPath = '/api/manual-request/Get-Stepbar';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `apiManualRequestGetStepbarGet()` instead.
   *
   * This method doesn't expect any request body.
   */
  apiManualRequestGetStepbarGet$Response(
    params?: {
      id?: string;
    },
    context?: HttpContext
  ): Observable<StrictHttpResponse<StepBar>> {
    const rb = new RequestBuilder(this.rootUrl, ManualRequestService.ApiManualRequestGetStepbarGetPath, 'get');
    if (params) {
      rb.query('id', params.id, {});
    }

    return this.http.request(
      rb.build({ responseType: 'json', accept: 'application/json', context })
    ).pipe(
      filter((r: any): r is HttpResponse<any> => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<StepBar>;
      })
    );
  }

  /**
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `apiManualRequestGetStepbarGet$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  apiManualRequestGetStepbarGet(
    params?: {
      id?: string;
    },
    context?: HttpContext
  ): Observable<StepBar> {
    return this.apiManualRequestGetStepbarGet$Response(params, context).pipe(
      map((r: StrictHttpResponse<StepBar>): StepBar => r.body)
    );
  }

}
