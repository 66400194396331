/* tslint:disable */
/* eslint-disable */
import { HttpClient, HttpContext, HttpResponse } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { filter, map } from 'rxjs/operators';

import { BaseService } from '../base-service';
import { ApiConfiguration } from '../api-configuration';
import { StrictHttpResponse } from '../strict-http-response';
import { RequestBuilder } from '../request-builder';

import { InboxCountRes } from '../models/inbox-count-res';
import { InboxInternalItemRes } from '../models/inbox-internal-item-res';
import { InboxInternalListRes } from '../models/inbox-internal-list-res';
import { InboxInternalOptDataRes } from '../models/inbox-internal-opt-data-res';
import { InboxInternalReAssignByAdminReq } from '../models/inbox-internal-re-assign-by-admin-req';
import { InboxInternalReAssignByOwnerReq } from '../models/inbox-internal-re-assign-by-owner-req';
import { InboxInternalReq } from '../models/inbox-internal-req';

@Injectable({ providedIn: 'root' })
export class InboxService extends BaseService {
  constructor(config: ApiConfiguration, http: HttpClient) {
    super(config, http);
  }

  /** Path part for operation `apiInboxMyTaskQueryPost()` */
  static readonly ApiInboxMyTaskQueryPostPath = '/api/inbox/my-task/query';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `apiInboxMyTaskQueryPost()` instead.
   *
   * This method sends `application/json` and handles request body of type `application/json`.
   */
  apiInboxMyTaskQueryPost$Response(
    params?: {
      body?: InboxInternalReq
    },
    context?: HttpContext
  ): Observable<StrictHttpResponse<InboxInternalListRes>> {
    const rb = new RequestBuilder(this.rootUrl, InboxService.ApiInboxMyTaskQueryPostPath, 'post');
    if (params) {
      rb.body(params.body, 'application/json');
    }

    return this.http.request(
      rb.build({ responseType: 'json', accept: 'application/json', context })
    ).pipe(
      filter((r: any): r is HttpResponse<any> => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<InboxInternalListRes>;
      })
    );
  }

  /**
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `apiInboxMyTaskQueryPost$Response()` instead.
   *
   * This method sends `application/json` and handles request body of type `application/json`.
   */
  apiInboxMyTaskQueryPost(
    params?: {
      body?: InboxInternalReq
    },
    context?: HttpContext
  ): Observable<InboxInternalListRes> {
    return this.apiInboxMyTaskQueryPost$Response(params, context).pipe(
      map((r: StrictHttpResponse<InboxInternalListRes>): InboxInternalListRes => r.body)
    );
  }

  /** Path part for operation `apiInboxMyTaskGet()` */
  static readonly ApiInboxMyTaskGetPath = '/api/inbox/my-task';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `apiInboxMyTaskGet()` instead.
   *
   * This method doesn't expect any request body.
   */
  apiInboxMyTaskGet$Response(
    params?: {
      inboxInternalId?: number;
    },
    context?: HttpContext
  ): Observable<StrictHttpResponse<InboxInternalItemRes>> {
    const rb = new RequestBuilder(this.rootUrl, InboxService.ApiInboxMyTaskGetPath, 'get');
    if (params) {
      rb.query('inboxInternalId', params.inboxInternalId, {});
    }

    return this.http.request(
      rb.build({ responseType: 'json', accept: 'application/json', context })
    ).pipe(
      filter((r: any): r is HttpResponse<any> => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<InboxInternalItemRes>;
      })
    );
  }

  /**
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `apiInboxMyTaskGet$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  apiInboxMyTaskGet(
    params?: {
      inboxInternalId?: number;
    },
    context?: HttpContext
  ): Observable<InboxInternalItemRes> {
    return this.apiInboxMyTaskGet$Response(params, context).pipe(
      map((r: StrictHttpResponse<InboxInternalItemRes>): InboxInternalItemRes => r.body)
    );
  }

  /** Path part for operation `apiInboxMyRequestQueryPost()` */
  static readonly ApiInboxMyRequestQueryPostPath = '/api/inbox/my-request/query';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `apiInboxMyRequestQueryPost()` instead.
   *
   * This method sends `application/json` and handles request body of type `application/json`.
   */
  apiInboxMyRequestQueryPost$Response(
    params?: {
      body?: InboxInternalReq
    },
    context?: HttpContext
  ): Observable<StrictHttpResponse<InboxInternalListRes>> {
    const rb = new RequestBuilder(this.rootUrl, InboxService.ApiInboxMyRequestQueryPostPath, 'post');
    if (params) {
      rb.body(params.body, 'application/json');
    }

    return this.http.request(
      rb.build({ responseType: 'json', accept: 'application/json', context })
    ).pipe(
      filter((r: any): r is HttpResponse<any> => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<InboxInternalListRes>;
      })
    );
  }

  /**
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `apiInboxMyRequestQueryPost$Response()` instead.
   *
   * This method sends `application/json` and handles request body of type `application/json`.
   */
  apiInboxMyRequestQueryPost(
    params?: {
      body?: InboxInternalReq
    },
    context?: HttpContext
  ): Observable<InboxInternalListRes> {
    return this.apiInboxMyRequestQueryPost$Response(params, context).pipe(
      map((r: StrictHttpResponse<InboxInternalListRes>): InboxInternalListRes => r.body)
    );
  }

  /** Path part for operation `apiInboxMyRequestGet()` */
  static readonly ApiInboxMyRequestGetPath = '/api/inbox/my-request';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `apiInboxMyRequestGet()` instead.
   *
   * This method doesn't expect any request body.
   */
  apiInboxMyRequestGet$Response(
    params?: {
      processKey?: string;
      taskKey?: string;
    },
    context?: HttpContext
  ): Observable<StrictHttpResponse<InboxInternalItemRes>> {
    const rb = new RequestBuilder(this.rootUrl, InboxService.ApiInboxMyRequestGetPath, 'get');
    if (params) {
      rb.query('processKey', params.processKey, {});
      rb.query('taskKey', params.taskKey, {});
    }

    return this.http.request(
      rb.build({ responseType: 'json', accept: 'application/json', context })
    ).pipe(
      filter((r: any): r is HttpResponse<any> => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<InboxInternalItemRes>;
      })
    );
  }

  /**
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `apiInboxMyRequestGet$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  apiInboxMyRequestGet(
    params?: {
      processKey?: string;
      taskKey?: string;
    },
    context?: HttpContext
  ): Observable<InboxInternalItemRes> {
    return this.apiInboxMyRequestGet$Response(params, context).pipe(
      map((r: StrictHttpResponse<InboxInternalItemRes>): InboxInternalItemRes => r.body)
    );
  }

  /** Path part for operation `apiInboxOptDataGet()` */
  static readonly ApiInboxOptDataGetPath = '/api/inbox/opt-data';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `apiInboxOptDataGet()` instead.
   *
   * This method doesn't expect any request body.
   */
  apiInboxOptDataGet$Response(
    params?: {
    },
    context?: HttpContext
  ): Observable<StrictHttpResponse<InboxInternalOptDataRes>> {
    const rb = new RequestBuilder(this.rootUrl, InboxService.ApiInboxOptDataGetPath, 'get');
    if (params) {
    }

    return this.http.request(
      rb.build({ responseType: 'json', accept: 'application/json', context })
    ).pipe(
      filter((r: any): r is HttpResponse<any> => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<InboxInternalOptDataRes>;
      })
    );
  }

  /**
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `apiInboxOptDataGet$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  apiInboxOptDataGet(
    params?: {
    },
    context?: HttpContext
  ): Observable<InboxInternalOptDataRes> {
    return this.apiInboxOptDataGet$Response(params, context).pipe(
      map((r: StrictHttpResponse<InboxInternalOptDataRes>): InboxInternalOptDataRes => r.body)
    );
  }

  /** Path part for operation `apiInboxCounterGet()` */
  static readonly ApiInboxCounterGetPath = '/api/inbox/counter';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `apiInboxCounterGet()` instead.
   *
   * This method doesn't expect any request body.
   */
  apiInboxCounterGet$Response(
    params?: {
      workGroupIds?: Array<number>;
    },
    context?: HttpContext
  ): Observable<StrictHttpResponse<InboxCountRes>> {
    const rb = new RequestBuilder(this.rootUrl, InboxService.ApiInboxCounterGetPath, 'get');
    if (params) {
      rb.query('workGroupIds', params.workGroupIds, {});
    }

    return this.http.request(
      rb.build({ responseType: 'json', accept: 'application/json', context })
    ).pipe(
      filter((r: any): r is HttpResponse<any> => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<InboxCountRes>;
      })
    );
  }

  /**
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `apiInboxCounterGet$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  apiInboxCounterGet(
    params?: {
      workGroupIds?: Array<number>;
    },
    context?: HttpContext
  ): Observable<InboxCountRes> {
    return this.apiInboxCounterGet$Response(params, context).pipe(
      map((r: StrictHttpResponse<InboxCountRes>): InboxCountRes => r.body)
    );
  }

  /** Path part for operation `apiInboxReAssignPost()` */
  static readonly ApiInboxReAssignPostPath = '/api/inbox/re-assign';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `apiInboxReAssignPost()` instead.
   *
   * This method sends `application/json` and handles request body of type `application/json`.
   */
  apiInboxReAssignPost$Response(
    params?: {
      body?: InboxInternalReAssignByOwnerReq
    },
    context?: HttpContext
  ): Observable<StrictHttpResponse<void>> {
    const rb = new RequestBuilder(this.rootUrl, InboxService.ApiInboxReAssignPostPath, 'post');
    if (params) {
      rb.body(params.body, 'application/json');
    }

    return this.http.request(
      rb.build({ responseType: 'text', accept: '*/*', context })
    ).pipe(
      filter((r: any): r is HttpResponse<any> => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return (r as HttpResponse<any>).clone({ body: undefined }) as StrictHttpResponse<void>;
      })
    );
  }

  /**
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `apiInboxReAssignPost$Response()` instead.
   *
   * This method sends `application/json` and handles request body of type `application/json`.
   */
  apiInboxReAssignPost(
    params?: {
      body?: InboxInternalReAssignByOwnerReq
    },
    context?: HttpContext
  ): Observable<void> {
    return this.apiInboxReAssignPost$Response(params, context).pipe(
      map((r: StrictHttpResponse<void>): void => r.body)
    );
  }

  /** Path part for operation `apiInboxAdminReAssignPost()` */
  static readonly ApiInboxAdminReAssignPostPath = '/api/inbox/admin/re-assign';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `apiInboxAdminReAssignPost()` instead.
   *
   * This method sends `application/json` and handles request body of type `application/json`.
   */
  apiInboxAdminReAssignPost$Response(
    params?: {
      body?: InboxInternalReAssignByAdminReq
    },
    context?: HttpContext
  ): Observable<StrictHttpResponse<void>> {
    const rb = new RequestBuilder(this.rootUrl, InboxService.ApiInboxAdminReAssignPostPath, 'post');
    if (params) {
      rb.body(params.body, 'application/json');
    }

    return this.http.request(
      rb.build({ responseType: 'text', accept: '*/*', context })
    ).pipe(
      filter((r: any): r is HttpResponse<any> => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return (r as HttpResponse<any>).clone({ body: undefined }) as StrictHttpResponse<void>;
      })
    );
  }

  /**
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `apiInboxAdminReAssignPost$Response()` instead.
   *
   * This method sends `application/json` and handles request body of type `application/json`.
   */
  apiInboxAdminReAssignPost(
    params?: {
      body?: InboxInternalReAssignByAdminReq
    },
    context?: HttpContext
  ): Observable<void> {
    return this.apiInboxAdminReAssignPost$Response(params, context).pipe(
      map((r: StrictHttpResponse<void>): void => r.body)
    );
  }

}
