/* tslint:disable */
/* eslint-disable */
import { HttpClient, HttpContext, HttpResponse } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { filter, map } from 'rxjs/operators';

import { BaseService } from '../base-service';
import { ApiConfiguration } from '../api-configuration';
import { StrictHttpResponse } from '../strict-http-response';
import { RequestBuilder } from '../request-builder';

import { SettingPicOptDataRes } from '../models/setting-pic-opt-data-res';
import { SettingPicProcessActivityDestEditReq } from '../models/setting-pic-process-activity-dest-edit-req';
import { SettingPicProcessActivityDestRes } from '../models/setting-pic-process-activity-dest-res';
import { SettingPicRoleMemberOptRes } from '../models/setting-pic-role-member-opt-res';

@Injectable({ providedIn: 'root' })
export class PicService extends BaseService {
  constructor(config: ApiConfiguration, http: HttpClient) {
    super(config, http);
  }

  /** Path part for operation `apiPicOptDataGet()` */
  static readonly ApiPicOptDataGetPath = '/api/pic/opt-data';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `apiPicOptDataGet()` instead.
   *
   * This method doesn't expect any request body.
   */
  apiPicOptDataGet$Response(
    params?: {
    },
    context?: HttpContext
  ): Observable<StrictHttpResponse<SettingPicOptDataRes>> {
    const rb = new RequestBuilder(this.rootUrl, PicService.ApiPicOptDataGetPath, 'get');
    if (params) {
    }

    return this.http.request(
      rb.build({ responseType: 'json', accept: 'application/json', context })
    ).pipe(
      filter((r: any): r is HttpResponse<any> => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<SettingPicOptDataRes>;
      })
    );
  }

  /**
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `apiPicOptDataGet$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  apiPicOptDataGet(
    params?: {
    },
    context?: HttpContext
  ): Observable<SettingPicOptDataRes> {
    return this.apiPicOptDataGet$Response(params, context).pipe(
      map((r: StrictHttpResponse<SettingPicOptDataRes>): SettingPicOptDataRes => r.body)
    );
  }

  /** Path part for operation `apiPicOptRolePicsGet()` */
  static readonly ApiPicOptRolePicsGetPath = '/api/pic/opt/role-pics';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `apiPicOptRolePicsGet()` instead.
   *
   * This method doesn't expect any request body.
   */
  apiPicOptRolePicsGet$Response(
    params?: {
      roleCode?: string;
      fkWorkgroupId?: number;
    },
    context?: HttpContext
  ): Observable<StrictHttpResponse<Array<SettingPicRoleMemberOptRes>>> {
    const rb = new RequestBuilder(this.rootUrl, PicService.ApiPicOptRolePicsGetPath, 'get');
    if (params) {
      rb.query('roleCode', params.roleCode, {});
      rb.query('fkWorkgroupId', params.fkWorkgroupId, {});
    }

    return this.http.request(
      rb.build({ responseType: 'json', accept: 'application/json', context })
    ).pipe(
      filter((r: any): r is HttpResponse<any> => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<Array<SettingPicRoleMemberOptRes>>;
      })
    );
  }

  /**
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `apiPicOptRolePicsGet$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  apiPicOptRolePicsGet(
    params?: {
      roleCode?: string;
      fkWorkgroupId?: number;
    },
    context?: HttpContext
  ): Observable<Array<SettingPicRoleMemberOptRes>> {
    return this.apiPicOptRolePicsGet$Response(params, context).pipe(
      map((r: StrictHttpResponse<Array<SettingPicRoleMemberOptRes>>): Array<SettingPicRoleMemberOptRes> => r.body)
    );
  }

  /** Path part for operation `apiPicActivitiesGet()` */
  static readonly ApiPicActivitiesGetPath = '/api/pic/activities';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `apiPicActivitiesGet()` instead.
   *
   * This method doesn't expect any request body.
   */
  apiPicActivitiesGet$Response(
    params?: {
      masterProcessKey?: string;
      workGroupIds?: Array<number>;
    },
    context?: HttpContext
  ): Observable<StrictHttpResponse<Array<SettingPicProcessActivityDestRes>>> {
    const rb = new RequestBuilder(this.rootUrl, PicService.ApiPicActivitiesGetPath, 'get');
    if (params) {
      rb.query('masterProcessKey', params.masterProcessKey, {});
      rb.query('workGroupIds', params.workGroupIds, {});
    }

    return this.http.request(
      rb.build({ responseType: 'json', accept: 'application/json', context })
    ).pipe(
      filter((r: any): r is HttpResponse<any> => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<Array<SettingPicProcessActivityDestRes>>;
      })
    );
  }

  /**
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `apiPicActivitiesGet$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  apiPicActivitiesGet(
    params?: {
      masterProcessKey?: string;
      workGroupIds?: Array<number>;
    },
    context?: HttpContext
  ): Observable<Array<SettingPicProcessActivityDestRes>> {
    return this.apiPicActivitiesGet$Response(params, context).pipe(
      map((r: StrictHttpResponse<Array<SettingPicProcessActivityDestRes>>): Array<SettingPicProcessActivityDestRes> => r.body)
    );
  }

  /** Path part for operation `apiPicActivityUpdatePicPost()` */
  static readonly ApiPicActivityUpdatePicPostPath = '/api/pic/activity/update-pic';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `apiPicActivityUpdatePicPost()` instead.
   *
   * This method sends `application/json` and handles request body of type `application/json`.
   */
  apiPicActivityUpdatePicPost$Response(
    params?: {
      body?: SettingPicProcessActivityDestEditReq
    },
    context?: HttpContext
  ): Observable<StrictHttpResponse<void>> {
    const rb = new RequestBuilder(this.rootUrl, PicService.ApiPicActivityUpdatePicPostPath, 'post');
    if (params) {
      rb.body(params.body, 'application/json');
    }

    return this.http.request(
      rb.build({ responseType: 'text', accept: '*/*', context })
    ).pipe(
      filter((r: any): r is HttpResponse<any> => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return (r as HttpResponse<any>).clone({ body: undefined }) as StrictHttpResponse<void>;
      })
    );
  }

  /**
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `apiPicActivityUpdatePicPost$Response()` instead.
   *
   * This method sends `application/json` and handles request body of type `application/json`.
   */
  apiPicActivityUpdatePicPost(
    params?: {
      body?: SettingPicProcessActivityDestEditReq
    },
    context?: HttpContext
  ): Observable<void> {
    return this.apiPicActivityUpdatePicPost$Response(params, context).pipe(
      map((r: StrictHttpResponse<void>): void => r.body)
    );
  }

}
