/* tslint:disable */
/* eslint-disable */
import { HttpClient, HttpContext, HttpResponse } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { filter, map } from 'rxjs/operators';

import { BaseService } from '../base-service';
import { ApiConfiguration } from '../api-configuration';
import { StrictHttpResponse } from '../strict-http-response';
import { RequestBuilder } from '../request-builder';

import { MasterBankEditItemDto } from '../models/master-bank-edit-item-dto';
import { MasterBankItem } from '../models/master-bank-item';

@Injectable({ providedIn: 'root' })
export class MasterBankService extends BaseService {
  constructor(config: ApiConfiguration, http: HttpClient) {
    super(config, http);
  }

  /** Path part for operation `apiMasterCcrhmBankGet()` */
  static readonly ApiMasterCcrhmBankGetPath = '/api/master/ccrhm/bank';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `apiMasterCcrhmBankGet()` instead.
   *
   * This method doesn't expect any request body.
   */
  apiMasterCcrhmBankGet$Response(
    params?: {
      id?: string;
    },
    context?: HttpContext
  ): Observable<StrictHttpResponse<MasterBankItem>> {
    const rb = new RequestBuilder(this.rootUrl, MasterBankService.ApiMasterCcrhmBankGetPath, 'get');
    if (params) {
      rb.query('id', params.id, {});
    }

    return this.http.request(
      rb.build({ responseType: 'json', accept: 'application/json', context })
    ).pipe(
      filter((r: any): r is HttpResponse<any> => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<MasterBankItem>;
      })
    );
  }

  /**
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `apiMasterCcrhmBankGet$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  apiMasterCcrhmBankGet(
    params?: {
      id?: string;
    },
    context?: HttpContext
  ): Observable<MasterBankItem> {
    return this.apiMasterCcrhmBankGet$Response(params, context).pipe(
      map((r: StrictHttpResponse<MasterBankItem>): MasterBankItem => r.body)
    );
  }

  /** Path part for operation `apiMasterCcrhmBankPut()` */
  static readonly ApiMasterCcrhmBankPutPath = '/api/master/ccrhm/bank';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `apiMasterCcrhmBankPut()` instead.
   *
   * This method sends `application/json` and handles request body of type `application/json`.
   */
  apiMasterCcrhmBankPut$Response(
    params?: {
      id?: string;
      body?: MasterBankEditItemDto
    },
    context?: HttpContext
  ): Observable<StrictHttpResponse<void>> {
    const rb = new RequestBuilder(this.rootUrl, MasterBankService.ApiMasterCcrhmBankPutPath, 'put');
    if (params) {
      rb.query('id', params.id, {});
      rb.body(params.body, 'application/json');
    }

    return this.http.request(
      rb.build({ responseType: 'text', accept: '*/*', context })
    ).pipe(
      filter((r: any): r is HttpResponse<any> => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return (r as HttpResponse<any>).clone({ body: undefined }) as StrictHttpResponse<void>;
      })
    );
  }

  /**
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `apiMasterCcrhmBankPut$Response()` instead.
   *
   * This method sends `application/json` and handles request body of type `application/json`.
   */
  apiMasterCcrhmBankPut(
    params?: {
      id?: string;
      body?: MasterBankEditItemDto
    },
    context?: HttpContext
  ): Observable<void> {
    return this.apiMasterCcrhmBankPut$Response(params, context).pipe(
      map((r: StrictHttpResponse<void>): void => r.body)
    );
  }

  /** Path part for operation `apiMasterCcrhmBankPost()` */
  static readonly ApiMasterCcrhmBankPostPath = '/api/master/ccrhm/bank';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `apiMasterCcrhmBankPost()` instead.
   *
   * This method sends `application/json` and handles request body of type `application/json`.
   */
  apiMasterCcrhmBankPost$Response(
    params?: {
      body?: MasterBankEditItemDto
    },
    context?: HttpContext
  ): Observable<StrictHttpResponse<MasterBankItem>> {
    const rb = new RequestBuilder(this.rootUrl, MasterBankService.ApiMasterCcrhmBankPostPath, 'post');
    if (params) {
      rb.body(params.body, 'application/json');
    }

    return this.http.request(
      rb.build({ responseType: 'json', accept: 'application/json', context })
    ).pipe(
      filter((r: any): r is HttpResponse<any> => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<MasterBankItem>;
      })
    );
  }

  /**
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `apiMasterCcrhmBankPost$Response()` instead.
   *
   * This method sends `application/json` and handles request body of type `application/json`.
   */
  apiMasterCcrhmBankPost(
    params?: {
      body?: MasterBankEditItemDto
    },
    context?: HttpContext
  ): Observable<MasterBankItem> {
    return this.apiMasterCcrhmBankPost$Response(params, context).pipe(
      map((r: StrictHttpResponse<MasterBankItem>): MasterBankItem => r.body)
    );
  }

  /** Path part for operation `apiMasterCcrhmBankDelete()` */
  static readonly ApiMasterCcrhmBankDeletePath = '/api/master/ccrhm/bank';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `apiMasterCcrhmBankDelete()` instead.
   *
   * This method doesn't expect any request body.
   */
  apiMasterCcrhmBankDelete$Response(
    params?: {
      id?: string;
    },
    context?: HttpContext
  ): Observable<StrictHttpResponse<void>> {
    const rb = new RequestBuilder(this.rootUrl, MasterBankService.ApiMasterCcrhmBankDeletePath, 'delete');
    if (params) {
      rb.query('id', params.id, {});
    }

    return this.http.request(
      rb.build({ responseType: 'text', accept: '*/*', context })
    ).pipe(
      filter((r: any): r is HttpResponse<any> => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return (r as HttpResponse<any>).clone({ body: undefined }) as StrictHttpResponse<void>;
      })
    );
  }

  /**
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `apiMasterCcrhmBankDelete$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  apiMasterCcrhmBankDelete(
    params?: {
      id?: string;
    },
    context?: HttpContext
  ): Observable<void> {
    return this.apiMasterCcrhmBankDelete$Response(params, context).pipe(
      map((r: StrictHttpResponse<void>): void => r.body)
    );
  }

  /** Path part for operation `apiMasterCcrhmBankAllGet()` */
  static readonly ApiMasterCcrhmBankAllGetPath = '/api/master/ccrhm/bank/all';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `apiMasterCcrhmBankAllGet()` instead.
   *
   * This method doesn't expect any request body.
   */
  apiMasterCcrhmBankAllGet$Response(
    params?: {
      workgroups?: Array<number>;
    },
    context?: HttpContext
  ): Observable<StrictHttpResponse<Array<MasterBankItem>>> {
    const rb = new RequestBuilder(this.rootUrl, MasterBankService.ApiMasterCcrhmBankAllGetPath, 'get');
    if (params) {
      rb.query('workgroups', params.workgroups, {});
    }

    return this.http.request(
      rb.build({ responseType: 'json', accept: 'application/json', context })
    ).pipe(
      filter((r: any): r is HttpResponse<any> => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<Array<MasterBankItem>>;
      })
    );
  }

  /**
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `apiMasterCcrhmBankAllGet$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  apiMasterCcrhmBankAllGet(
    params?: {
      workgroups?: Array<number>;
    },
    context?: HttpContext
  ): Observable<Array<MasterBankItem>> {
    return this.apiMasterCcrhmBankAllGet$Response(params, context).pipe(
      map((r: StrictHttpResponse<Array<MasterBankItem>>): Array<MasterBankItem> => r.body)
    );
  }

  /** Path part for operation `apiMasterCcrhmBankActiveGet()` */
  static readonly ApiMasterCcrhmBankActiveGetPath = '/api/master/ccrhm/bank/active';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `apiMasterCcrhmBankActiveGet()` instead.
   *
   * This method doesn't expect any request body.
   */
  apiMasterCcrhmBankActiveGet$Response(
    params?: {
    },
    context?: HttpContext
  ): Observable<StrictHttpResponse<Array<MasterBankItem>>> {
    const rb = new RequestBuilder(this.rootUrl, MasterBankService.ApiMasterCcrhmBankActiveGetPath, 'get');
    if (params) {
    }

    return this.http.request(
      rb.build({ responseType: 'json', accept: 'application/json', context })
    ).pipe(
      filter((r: any): r is HttpResponse<any> => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<Array<MasterBankItem>>;
      })
    );
  }

  /**
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `apiMasterCcrhmBankActiveGet$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  apiMasterCcrhmBankActiveGet(
    params?: {
    },
    context?: HttpContext
  ): Observable<Array<MasterBankItem>> {
    return this.apiMasterCcrhmBankActiveGet$Response(params, context).pipe(
      map((r: StrictHttpResponse<Array<MasterBankItem>>): Array<MasterBankItem> => r.body)
    );
  }

  /** Path part for operation `apiMasterCcrhmBankByCompanyCodeGet()` */
  static readonly ApiMasterCcrhmBankByCompanyCodeGetPath = '/api/master/ccrhm/bank/byCompanyCode';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `apiMasterCcrhmBankByCompanyCodeGet()` instead.
   *
   * This method doesn't expect any request body.
   */
  apiMasterCcrhmBankByCompanyCodeGet$Response(
    params?: {
      CompanyCode?: string;
      workgroupIds?: Array<number>;
    },
    context?: HttpContext
  ): Observable<StrictHttpResponse<Array<MasterBankItem>>> {
    const rb = new RequestBuilder(this.rootUrl, MasterBankService.ApiMasterCcrhmBankByCompanyCodeGetPath, 'get');
    if (params) {
      rb.query('CompanyCode', params.CompanyCode, {});
      rb.query('workgroupIds', params.workgroupIds, {});
    }

    return this.http.request(
      rb.build({ responseType: 'json', accept: 'application/json', context })
    ).pipe(
      filter((r: any): r is HttpResponse<any> => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<Array<MasterBankItem>>;
      })
    );
  }

  /**
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `apiMasterCcrhmBankByCompanyCodeGet$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  apiMasterCcrhmBankByCompanyCodeGet(
    params?: {
      CompanyCode?: string;
      workgroupIds?: Array<number>;
    },
    context?: HttpContext
  ): Observable<Array<MasterBankItem>> {
    return this.apiMasterCcrhmBankByCompanyCodeGet$Response(params, context).pipe(
      map((r: StrictHttpResponse<Array<MasterBankItem>>): Array<MasterBankItem> => r.body)
    );
  }

}
