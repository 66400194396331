/* tslint:disable */
/* eslint-disable */
import { HttpClient, HttpContext, HttpResponse } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { filter, map } from 'rxjs/operators';

import { BaseService } from '../base-service';
import { ApiConfiguration } from '../api-configuration';
import { StrictHttpResponse } from '../strict-http-response';
import { RequestBuilder } from '../request-builder';

import { MasterFormDefaultTemplateItemRes } from '../models/master-form-default-template-item-res';
import { MasterFormDisplayItemRes } from '../models/master-form-display-item-res';
import { MasterFormDropdownItemRes } from '../models/master-form-dropdown-item-res';
import { MasterFormEditItemDto } from '../models/master-form-edit-item-dto';

@Injectable({ providedIn: 'root' })
export class MasterFormService extends BaseService {
  constructor(config: ApiConfiguration, http: HttpClient) {
    super(config, http);
  }

  /** Path part for operation `apiMasterFormTemplateMaintainGet()` */
  static readonly ApiMasterFormTemplateMaintainGetPath = '/api/master/form-template/maintain';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `apiMasterFormTemplateMaintainGet()` instead.
   *
   * This method doesn't expect any request body.
   */
  apiMasterFormTemplateMaintainGet$Response(
    params?: {
      workgroups?: Array<number>;
    },
    context?: HttpContext
  ): Observable<StrictHttpResponse<Array<MasterFormDisplayItemRes>>> {
    const rb = new RequestBuilder(this.rootUrl, MasterFormService.ApiMasterFormTemplateMaintainGetPath, 'get');
    if (params) {
      rb.query('workgroups', params.workgroups, {});
    }

    return this.http.request(
      rb.build({ responseType: 'json', accept: 'application/json', context })
    ).pipe(
      filter((r: any): r is HttpResponse<any> => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<Array<MasterFormDisplayItemRes>>;
      })
    );
  }

  /**
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `apiMasterFormTemplateMaintainGet$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  apiMasterFormTemplateMaintainGet(
    params?: {
      workgroups?: Array<number>;
    },
    context?: HttpContext
  ): Observable<Array<MasterFormDisplayItemRes>> {
    return this.apiMasterFormTemplateMaintainGet$Response(params, context).pipe(
      map((r: StrictHttpResponse<Array<MasterFormDisplayItemRes>>): Array<MasterFormDisplayItemRes> => r.body)
    );
  }

  /** Path part for operation `apiMasterFormTemplateGet()` */
  static readonly ApiMasterFormTemplateGetPath = '/api/master/form-template';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `apiMasterFormTemplateGet()` instead.
   *
   * This method doesn't expect any request body.
   */
  apiMasterFormTemplateGet$Response(
    params?: {
      id?: string;
    },
    context?: HttpContext
  ): Observable<StrictHttpResponse<MasterFormEditItemDto>> {
    const rb = new RequestBuilder(this.rootUrl, MasterFormService.ApiMasterFormTemplateGetPath, 'get');
    if (params) {
      rb.query('id', params.id, {});
    }

    return this.http.request(
      rb.build({ responseType: 'json', accept: 'application/json', context })
    ).pipe(
      filter((r: any): r is HttpResponse<any> => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<MasterFormEditItemDto>;
      })
    );
  }

  /**
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `apiMasterFormTemplateGet$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  apiMasterFormTemplateGet(
    params?: {
      id?: string;
    },
    context?: HttpContext
  ): Observable<MasterFormEditItemDto> {
    return this.apiMasterFormTemplateGet$Response(params, context).pipe(
      map((r: StrictHttpResponse<MasterFormEditItemDto>): MasterFormEditItemDto => r.body)
    );
  }

  /** Path part for operation `apiMasterFormTemplatePut()` */
  static readonly ApiMasterFormTemplatePutPath = '/api/master/form-template';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `apiMasterFormTemplatePut()` instead.
   *
   * This method sends `application/json` and handles request body of type `application/json`.
   */
  apiMasterFormTemplatePut$Response(
    params?: {
      id?: string;
      body?: MasterFormEditItemDto
    },
    context?: HttpContext
  ): Observable<StrictHttpResponse<void>> {
    const rb = new RequestBuilder(this.rootUrl, MasterFormService.ApiMasterFormTemplatePutPath, 'put');
    if (params) {
      rb.query('id', params.id, {});
      rb.body(params.body, 'application/json');
    }

    return this.http.request(
      rb.build({ responseType: 'text', accept: '*/*', context })
    ).pipe(
      filter((r: any): r is HttpResponse<any> => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return (r as HttpResponse<any>).clone({ body: undefined }) as StrictHttpResponse<void>;
      })
    );
  }

  /**
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `apiMasterFormTemplatePut$Response()` instead.
   *
   * This method sends `application/json` and handles request body of type `application/json`.
   */
  apiMasterFormTemplatePut(
    params?: {
      id?: string;
      body?: MasterFormEditItemDto
    },
    context?: HttpContext
  ): Observable<void> {
    return this.apiMasterFormTemplatePut$Response(params, context).pipe(
      map((r: StrictHttpResponse<void>): void => r.body)
    );
  }

  /** Path part for operation `apiMasterFormTemplatePost()` */
  static readonly ApiMasterFormTemplatePostPath = '/api/master/form-template';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `apiMasterFormTemplatePost()` instead.
   *
   * This method sends `application/json` and handles request body of type `application/json`.
   */
  apiMasterFormTemplatePost$Response(
    params?: {
      body?: MasterFormEditItemDto
    },
    context?: HttpContext
  ): Observable<StrictHttpResponse<void>> {
    const rb = new RequestBuilder(this.rootUrl, MasterFormService.ApiMasterFormTemplatePostPath, 'post');
    if (params) {
      rb.body(params.body, 'application/json');
    }

    return this.http.request(
      rb.build({ responseType: 'text', accept: '*/*', context })
    ).pipe(
      filter((r: any): r is HttpResponse<any> => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return (r as HttpResponse<any>).clone({ body: undefined }) as StrictHttpResponse<void>;
      })
    );
  }

  /**
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `apiMasterFormTemplatePost$Response()` instead.
   *
   * This method sends `application/json` and handles request body of type `application/json`.
   */
  apiMasterFormTemplatePost(
    params?: {
      body?: MasterFormEditItemDto
    },
    context?: HttpContext
  ): Observable<void> {
    return this.apiMasterFormTemplatePost$Response(params, context).pipe(
      map((r: StrictHttpResponse<void>): void => r.body)
    );
  }

  /** Path part for operation `apiMasterFormTemplateDelete()` */
  static readonly ApiMasterFormTemplateDeletePath = '/api/master/form-template';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `apiMasterFormTemplateDelete()` instead.
   *
   * This method doesn't expect any request body.
   */
  apiMasterFormTemplateDelete$Response(
    params?: {
      id?: string;
    },
    context?: HttpContext
  ): Observable<StrictHttpResponse<void>> {
    const rb = new RequestBuilder(this.rootUrl, MasterFormService.ApiMasterFormTemplateDeletePath, 'delete');
    if (params) {
      rb.query('id', params.id, {});
    }

    return this.http.request(
      rb.build({ responseType: 'text', accept: '*/*', context })
    ).pipe(
      filter((r: any): r is HttpResponse<any> => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return (r as HttpResponse<any>).clone({ body: undefined }) as StrictHttpResponse<void>;
      })
    );
  }

  /**
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `apiMasterFormTemplateDelete$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  apiMasterFormTemplateDelete(
    params?: {
      id?: string;
    },
    context?: HttpContext
  ): Observable<void> {
    return this.apiMasterFormTemplateDelete$Response(params, context).pipe(
      map((r: StrictHttpResponse<void>): void => r.body)
    );
  }

  /** Path part for operation `apiMasterFormTemplateDefaultTemplateGet()` */
  static readonly ApiMasterFormTemplateDefaultTemplateGetPath = '/api/master/form-template/defaultTemplate';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `apiMasterFormTemplateDefaultTemplateGet()` instead.
   *
   * This method doesn't expect any request body.
   */
  apiMasterFormTemplateDefaultTemplateGet$Response(
    params?: {
      FKWorkGroupId?: number;
    },
    context?: HttpContext
  ): Observable<StrictHttpResponse<MasterFormDefaultTemplateItemRes>> {
    const rb = new RequestBuilder(this.rootUrl, MasterFormService.ApiMasterFormTemplateDefaultTemplateGetPath, 'get');
    if (params) {
      rb.query('FKWorkGroupId', params.FKWorkGroupId, {});
    }

    return this.http.request(
      rb.build({ responseType: 'json', accept: 'application/json', context })
    ).pipe(
      filter((r: any): r is HttpResponse<any> => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<MasterFormDefaultTemplateItemRes>;
      })
    );
  }

  /**
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `apiMasterFormTemplateDefaultTemplateGet$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  apiMasterFormTemplateDefaultTemplateGet(
    params?: {
      FKWorkGroupId?: number;
    },
    context?: HttpContext
  ): Observable<MasterFormDefaultTemplateItemRes> {
    return this.apiMasterFormTemplateDefaultTemplateGet$Response(params, context).pipe(
      map((r: StrictHttpResponse<MasterFormDefaultTemplateItemRes>): MasterFormDefaultTemplateItemRes => r.body)
    );
  }

  /** Path part for operation `apiMasterFormTemplateDropdownSelectionGet()` */
  static readonly ApiMasterFormTemplateDropdownSelectionGetPath = '/api/master/form-template/DropdownSelection';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `apiMasterFormTemplateDropdownSelectionGet()` instead.
   *
   * This method doesn't expect any request body.
   */
  apiMasterFormTemplateDropdownSelectionGet$Response(
    params?: {
    },
    context?: HttpContext
  ): Observable<StrictHttpResponse<MasterFormDropdownItemRes>> {
    const rb = new RequestBuilder(this.rootUrl, MasterFormService.ApiMasterFormTemplateDropdownSelectionGetPath, 'get');
    if (params) {
    }

    return this.http.request(
      rb.build({ responseType: 'json', accept: 'application/json', context })
    ).pipe(
      filter((r: any): r is HttpResponse<any> => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<MasterFormDropdownItemRes>;
      })
    );
  }

  /**
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `apiMasterFormTemplateDropdownSelectionGet$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  apiMasterFormTemplateDropdownSelectionGet(
    params?: {
    },
    context?: HttpContext
  ): Observable<MasterFormDropdownItemRes> {
    return this.apiMasterFormTemplateDropdownSelectionGet$Response(params, context).pipe(
      map((r: StrictHttpResponse<MasterFormDropdownItemRes>): MasterFormDropdownItemRes => r.body)
    );
  }

}
