/* tslint:disable */
/* eslint-disable */
import { HttpClient, HttpContext, HttpResponse } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { filter, map } from 'rxjs/operators';

import { BaseService } from '../base-service';
import { ApiConfiguration } from '../api-configuration';
import { StrictHttpResponse } from '../strict-http-response';
import { RequestBuilder } from '../request-builder';

import { AdActivityLogRes } from '../models/ad-activity-log-res';
import { AdApproveReq } from '../models/ad-approve-req';
import { AdApproveRes } from '../models/ad-approve-res';
import { AdApprovedLevelReq } from '../models/ad-approved-level-req';
import { AdApprovedLevelRes } from '../models/ad-approved-level-res';
import { AdConclusionReq } from '../models/ad-conclusion-req';
import { AdCreateEventReq } from '../models/ad-create-event-req';
import { AdCreateEventSaveReq } from '../models/ad-create-event-save-req';
import { AdCreateReq } from '../models/ad-create-req';
import { AdCreateRes } from '../models/ad-create-res';
import { AdCriteriaRes } from '../models/ad-criteria-res';
import { AdEventFormAnswerReq } from '../models/ad-event-form-answer-req';
import { AdEventReq } from '../models/ad-event-req';
import { AdEventSaveReq } from '../models/ad-event-save-req';
import { AdFinalApprovedLevelReq } from '../models/ad-final-approved-level-req';
import { AdFormOptRes } from '../models/ad-form-opt-res';
import { AdInitRes } from '../models/ad-init-res';
import { AdRefAuditOptQueryReq } from '../models/ad-ref-audit-opt-query-req';
import { AdRefAuditOptQueryRes } from '../models/ad-ref-audit-opt-query-res';
import { AdRes } from '../models/ad-res';
import { AdReviseReq } from '../models/ad-revise-req';
import { AdStartAuditFormReq } from '../models/ad-start-audit-form-req';
import { AdStatementTemplateRes } from '../models/ad-statement-template-res';
import { AdStepBarRes } from '../models/ad-step-bar-res';
import { AdTrnMgrRes } from '../models/ad-trn-mgr-res';
import { AdTrnMgrsRes } from '../models/ad-trn-mgrs-res';
import { AdTrnProductRes } from '../models/ad-trn-product-res';
import { AdTrnRelatedPeopleRes } from '../models/ad-trn-related-people-res';
import { AdTrnVendorAccountDto } from '../models/ad-trn-vendor-account-dto';
import { AdVendorOptSearchingRes } from '../models/ad-vendor-opt-searching-res';
import { AuditTrnChatReq } from '../models/audit-trn-chat-req';
import { AuditTrnChatRes } from '../models/audit-trn-chat-res';

@Injectable({ providedIn: 'root' })
export class AuditWorksheetService extends BaseService {
  constructor(config: ApiConfiguration, http: HttpClient) {
    super(config, http);
  }

  /** Path part for operation `apiAuditDataInitGet()` */
  static readonly ApiAuditDataInitGetPath = '/api/audit/data-init';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `apiAuditDataInitGet()` instead.
   *
   * This method doesn't expect any request body.
   */
  apiAuditDataInitGet$Response(
    params?: {
    },
    context?: HttpContext
  ): Observable<StrictHttpResponse<AdInitRes>> {
    const rb = new RequestBuilder(this.rootUrl, AuditWorksheetService.ApiAuditDataInitGetPath, 'get');
    if (params) {
    }

    return this.http.request(
      rb.build({ responseType: 'json', accept: 'application/json', context })
    ).pipe(
      filter((r: any): r is HttpResponse<any> => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<AdInitRes>;
      })
    );
  }

  /**
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `apiAuditDataInitGet$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  apiAuditDataInitGet(
    params?: {
    },
    context?: HttpContext
  ): Observable<AdInitRes> {
    return this.apiAuditDataInitGet$Response(params, context).pipe(
      map((r: StrictHttpResponse<AdInitRes>): AdInitRes => r.body)
    );
  }

  /** Path part for operation `apiAuditGet()` */
  static readonly ApiAuditGetPath = '/api/audit';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `apiAuditGet()` instead.
   *
   * This method doesn't expect any request body.
   */
  apiAuditGet$Response(
    params?: {
      trnId?: string;
    },
    context?: HttpContext
  ): Observable<StrictHttpResponse<AdRes>> {
    const rb = new RequestBuilder(this.rootUrl, AuditWorksheetService.ApiAuditGetPath, 'get');
    if (params) {
      rb.query('trnId', params.trnId, {});
    }

    return this.http.request(
      rb.build({ responseType: 'json', accept: 'application/json', context })
    ).pipe(
      filter((r: any): r is HttpResponse<any> => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<AdRes>;
      })
    );
  }

  /**
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `apiAuditGet$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  apiAuditGet(
    params?: {
      trnId?: string;
    },
    context?: HttpContext
  ): Observable<AdRes> {
    return this.apiAuditGet$Response(params, context).pipe(
      map((r: StrictHttpResponse<AdRes>): AdRes => r.body)
    );
  }

  /** Path part for operation `apiAuditFormInitGet()` */
  static readonly ApiAuditFormInitGetPath = '/api/audit/form-init';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `apiAuditFormInitGet()` instead.
   *
   * This method doesn't expect any request body.
   */
  apiAuditFormInitGet$Response(
    params?: {
      workGroupList?: Array<number>;
    },
    context?: HttpContext
  ): Observable<StrictHttpResponse<AdRes>> {
    const rb = new RequestBuilder(this.rootUrl, AuditWorksheetService.ApiAuditFormInitGetPath, 'get');
    if (params) {
      rb.query('workGroupList', params.workGroupList, {});
    }

    return this.http.request(
      rb.build({ responseType: 'json', accept: 'application/json', context })
    ).pipe(
      filter((r: any): r is HttpResponse<any> => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<AdRes>;
      })
    );
  }

  /**
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `apiAuditFormInitGet$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  apiAuditFormInitGet(
    params?: {
      workGroupList?: Array<number>;
    },
    context?: HttpContext
  ): Observable<AdRes> {
    return this.apiAuditFormInitGet$Response(params, context).pipe(
      map((r: StrictHttpResponse<AdRes>): AdRes => r.body)
    );
  }

  /** Path part for operation `apiAuditStepbarGet()` */
  static readonly ApiAuditStepbarGetPath = '/api/audit/stepbar';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `apiAuditStepbarGet()` instead.
   *
   * This method doesn't expect any request body.
   */
  apiAuditStepbarGet$Response(
    params?: {
      trnId?: string;
    },
    context?: HttpContext
  ): Observable<StrictHttpResponse<AdStepBarRes>> {
    const rb = new RequestBuilder(this.rootUrl, AuditWorksheetService.ApiAuditStepbarGetPath, 'get');
    if (params) {
      rb.query('trnId', params.trnId, {});
    }

    return this.http.request(
      rb.build({ responseType: 'json', accept: 'application/json', context })
    ).pipe(
      filter((r: any): r is HttpResponse<any> => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<AdStepBarRes>;
      })
    );
  }

  /**
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `apiAuditStepbarGet$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  apiAuditStepbarGet(
    params?: {
      trnId?: string;
    },
    context?: HttpContext
  ): Observable<AdStepBarRes> {
    return this.apiAuditStepbarGet$Response(params, context).pipe(
      map((r: StrictHttpResponse<AdStepBarRes>): AdStepBarRes => r.body)
    );
  }

  /** Path part for operation `apiAuditActivityLogsGet()` */
  static readonly ApiAuditActivityLogsGetPath = '/api/audit/activity-logs';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `apiAuditActivityLogsGet()` instead.
   *
   * This method doesn't expect any request body.
   */
  apiAuditActivityLogsGet$Response(
    params?: {
      trnId?: string;
    },
    context?: HttpContext
  ): Observable<StrictHttpResponse<Array<AdActivityLogRes>>> {
    const rb = new RequestBuilder(this.rootUrl, AuditWorksheetService.ApiAuditActivityLogsGetPath, 'get');
    if (params) {
      rb.query('trnId', params.trnId, {});
    }

    return this.http.request(
      rb.build({ responseType: 'json', accept: 'application/json', context })
    ).pipe(
      filter((r: any): r is HttpResponse<any> => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<Array<AdActivityLogRes>>;
      })
    );
  }

  /**
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `apiAuditActivityLogsGet$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  apiAuditActivityLogsGet(
    params?: {
      trnId?: string;
    },
    context?: HttpContext
  ): Observable<Array<AdActivityLogRes>> {
    return this.apiAuditActivityLogsGet$Response(params, context).pipe(
      map((r: StrictHttpResponse<Array<AdActivityLogRes>>): Array<AdActivityLogRes> => r.body)
    );
  }

  /** Path part for operation `apiAuditNewRequestSubmitPost()` */
  static readonly ApiAuditNewRequestSubmitPostPath = '/api/audit/new-request/submit';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `apiAuditNewRequestSubmitPost()` instead.
   *
   * This method sends `application/json` and handles request body of type `application/json`.
   */
  apiAuditNewRequestSubmitPost$Response(
    params?: {
      body?: AdCreateReq
    },
    context?: HttpContext
  ): Observable<StrictHttpResponse<AdCreateRes>> {
    const rb = new RequestBuilder(this.rootUrl, AuditWorksheetService.ApiAuditNewRequestSubmitPostPath, 'post');
    if (params) {
      rb.body(params.body, 'application/json');
    }

    return this.http.request(
      rb.build({ responseType: 'json', accept: 'application/json', context })
    ).pipe(
      filter((r: any): r is HttpResponse<any> => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<AdCreateRes>;
      })
    );
  }

  /**
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `apiAuditNewRequestSubmitPost$Response()` instead.
   *
   * This method sends `application/json` and handles request body of type `application/json`.
   */
  apiAuditNewRequestSubmitPost(
    params?: {
      body?: AdCreateReq
    },
    context?: HttpContext
  ): Observable<AdCreateRes> {
    return this.apiAuditNewRequestSubmitPost$Response(params, context).pipe(
      map((r: StrictHttpResponse<AdCreateRes>): AdCreateRes => r.body)
    );
  }

  /** Path part for operation `apiAuditRequesterMgrApprovePost()` */
  static readonly ApiAuditRequesterMgrApprovePostPath = '/api/audit/requester-mgr-approve';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `apiAuditRequesterMgrApprovePost()` instead.
   *
   * This method sends `application/json` and handles request body of type `application/json`.
   */
  apiAuditRequesterMgrApprovePost$Response(
    params?: {
      body?: AdApproveReq
    },
    context?: HttpContext
  ): Observable<StrictHttpResponse<AdApproveRes>> {
    const rb = new RequestBuilder(this.rootUrl, AuditWorksheetService.ApiAuditRequesterMgrApprovePostPath, 'post');
    if (params) {
      rb.body(params.body, 'application/json');
    }

    return this.http.request(
      rb.build({ responseType: 'json', accept: 'application/json', context })
    ).pipe(
      filter((r: any): r is HttpResponse<any> => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<AdApproveRes>;
      })
    );
  }

  /**
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `apiAuditRequesterMgrApprovePost$Response()` instead.
   *
   * This method sends `application/json` and handles request body of type `application/json`.
   */
  apiAuditRequesterMgrApprovePost(
    params?: {
      body?: AdApproveReq
    },
    context?: HttpContext
  ): Observable<AdApproveRes> {
    return this.apiAuditRequesterMgrApprovePost$Response(params, context).pipe(
      map((r: StrictHttpResponse<AdApproveRes>): AdApproveRes => r.body)
    );
  }

  /** Path part for operation `apiAuditRequesterRevisePost()` */
  static readonly ApiAuditRequesterRevisePostPath = '/api/audit/requester-revise';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `apiAuditRequesterRevisePost()` instead.
   *
   * This method sends `application/json` and handles request body of type `application/json`.
   */
  apiAuditRequesterRevisePost$Response(
    params?: {
      body?: AdReviseReq
    },
    context?: HttpContext
  ): Observable<StrictHttpResponse<void>> {
    const rb = new RequestBuilder(this.rootUrl, AuditWorksheetService.ApiAuditRequesterRevisePostPath, 'post');
    if (params) {
      rb.body(params.body, 'application/json');
    }

    return this.http.request(
      rb.build({ responseType: 'text', accept: '*/*', context })
    ).pipe(
      filter((r: any): r is HttpResponse<any> => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return (r as HttpResponse<any>).clone({ body: undefined }) as StrictHttpResponse<void>;
      })
    );
  }

  /**
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `apiAuditRequesterRevisePost$Response()` instead.
   *
   * This method sends `application/json` and handles request body of type `application/json`.
   */
  apiAuditRequesterRevisePost(
    params?: {
      body?: AdReviseReq
    },
    context?: HttpContext
  ): Observable<void> {
    return this.apiAuditRequesterRevisePost$Response(params, context).pipe(
      map((r: StrictHttpResponse<void>): void => r.body)
    );
  }

  /** Path part for operation `apiAuditCreateEventSavePost()` */
  static readonly ApiAuditCreateEventSavePostPath = '/api/audit/create-event/save';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `apiAuditCreateEventSavePost()` instead.
   *
   * This method sends `application/json` and handles request body of type `application/json`.
   */
  apiAuditCreateEventSavePost$Response(
    params?: {
      body?: AdCreateEventSaveReq
    },
    context?: HttpContext
  ): Observable<StrictHttpResponse<void>> {
    const rb = new RequestBuilder(this.rootUrl, AuditWorksheetService.ApiAuditCreateEventSavePostPath, 'post');
    if (params) {
      rb.body(params.body, 'application/json');
    }

    return this.http.request(
      rb.build({ responseType: 'text', accept: '*/*', context })
    ).pipe(
      filter((r: any): r is HttpResponse<any> => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return (r as HttpResponse<any>).clone({ body: undefined }) as StrictHttpResponse<void>;
      })
    );
  }

  /**
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `apiAuditCreateEventSavePost$Response()` instead.
   *
   * This method sends `application/json` and handles request body of type `application/json`.
   */
  apiAuditCreateEventSavePost(
    params?: {
      body?: AdCreateEventSaveReq
    },
    context?: HttpContext
  ): Observable<void> {
    return this.apiAuditCreateEventSavePost$Response(params, context).pipe(
      map((r: StrictHttpResponse<void>): void => r.body)
    );
  }

  /** Path part for operation `apiAuditCreateEventActionPost()` */
  static readonly ApiAuditCreateEventActionPostPath = '/api/audit/create-event/action';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `apiAuditCreateEventActionPost()` instead.
   *
   * This method sends `application/json` and handles request body of type `application/json`.
   */
  apiAuditCreateEventActionPost$Response(
    params?: {
      body?: AdCreateEventReq
    },
    context?: HttpContext
  ): Observable<StrictHttpResponse<void>> {
    const rb = new RequestBuilder(this.rootUrl, AuditWorksheetService.ApiAuditCreateEventActionPostPath, 'post');
    if (params) {
      rb.body(params.body, 'application/json');
    }

    return this.http.request(
      rb.build({ responseType: 'text', accept: '*/*', context })
    ).pipe(
      filter((r: any): r is HttpResponse<any> => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return (r as HttpResponse<any>).clone({ body: undefined }) as StrictHttpResponse<void>;
      })
    );
  }

  /**
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `apiAuditCreateEventActionPost$Response()` instead.
   *
   * This method sends `application/json` and handles request body of type `application/json`.
   */
  apiAuditCreateEventActionPost(
    params?: {
      body?: AdCreateEventReq
    },
    context?: HttpContext
  ): Observable<void> {
    return this.apiAuditCreateEventActionPost$Response(params, context).pipe(
      map((r: StrictHttpResponse<void>): void => r.body)
    );
  }

  /** Path part for operation `apiAuditEventSaveFormPost()` */
  static readonly ApiAuditEventSaveFormPostPath = '/api/audit/event/save-form';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `apiAuditEventSaveFormPost()` instead.
   *
   * This method sends `application/json` and handles request body of type `application/json`.
   */
  apiAuditEventSaveFormPost$Response(
    params?: {
      trnId?: string;
      body?: AdEventFormAnswerReq
    },
    context?: HttpContext
  ): Observable<StrictHttpResponse<void>> {
    const rb = new RequestBuilder(this.rootUrl, AuditWorksheetService.ApiAuditEventSaveFormPostPath, 'post');
    if (params) {
      rb.query('trnId', params.trnId, {});
      rb.body(params.body, 'application/json');
    }

    return this.http.request(
      rb.build({ responseType: 'text', accept: '*/*', context })
    ).pipe(
      filter((r: any): r is HttpResponse<any> => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return (r as HttpResponse<any>).clone({ body: undefined }) as StrictHttpResponse<void>;
      })
    );
  }

  /**
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `apiAuditEventSaveFormPost$Response()` instead.
   *
   * This method sends `application/json` and handles request body of type `application/json`.
   */
  apiAuditEventSaveFormPost(
    params?: {
      trnId?: string;
      body?: AdEventFormAnswerReq
    },
    context?: HttpContext
  ): Observable<void> {
    return this.apiAuditEventSaveFormPost$Response(params, context).pipe(
      map((r: StrictHttpResponse<void>): void => r.body)
    );
  }

  /** Path part for operation `apiAuditEventSubmitFormPost()` */
  static readonly ApiAuditEventSubmitFormPostPath = '/api/audit/event/submit-form';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `apiAuditEventSubmitFormPost()` instead.
   *
   * This method sends `application/json` and handles request body of type `application/json`.
   */
  apiAuditEventSubmitFormPost$Response(
    params?: {
      trnId?: string;
      body?: AdEventFormAnswerReq
    },
    context?: HttpContext
  ): Observable<StrictHttpResponse<void>> {
    const rb = new RequestBuilder(this.rootUrl, AuditWorksheetService.ApiAuditEventSubmitFormPostPath, 'post');
    if (params) {
      rb.query('trnId', params.trnId, {});
      rb.body(params.body, 'application/json');
    }

    return this.http.request(
      rb.build({ responseType: 'text', accept: '*/*', context })
    ).pipe(
      filter((r: any): r is HttpResponse<any> => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return (r as HttpResponse<any>).clone({ body: undefined }) as StrictHttpResponse<void>;
      })
    );
  }

  /**
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `apiAuditEventSubmitFormPost$Response()` instead.
   *
   * This method sends `application/json` and handles request body of type `application/json`.
   */
  apiAuditEventSubmitFormPost(
    params?: {
      trnId?: string;
      body?: AdEventFormAnswerReq
    },
    context?: HttpContext
  ): Observable<void> {
    return this.apiAuditEventSubmitFormPost$Response(params, context).pipe(
      map((r: StrictHttpResponse<void>): void => r.body)
    );
  }

  /** Path part for operation `apiAuditEventSavePost()` */
  static readonly ApiAuditEventSavePostPath = '/api/audit/event/save';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `apiAuditEventSavePost()` instead.
   *
   * This method sends `application/json` and handles request body of type `application/json`.
   */
  apiAuditEventSavePost$Response(
    params?: {
      body?: AdEventSaveReq
    },
    context?: HttpContext
  ): Observable<StrictHttpResponse<void>> {
    const rb = new RequestBuilder(this.rootUrl, AuditWorksheetService.ApiAuditEventSavePostPath, 'post');
    if (params) {
      rb.body(params.body, 'application/json');
    }

    return this.http.request(
      rb.build({ responseType: 'text', accept: '*/*', context })
    ).pipe(
      filter((r: any): r is HttpResponse<any> => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return (r as HttpResponse<any>).clone({ body: undefined }) as StrictHttpResponse<void>;
      })
    );
  }

  /**
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `apiAuditEventSavePost$Response()` instead.
   *
   * This method sends `application/json` and handles request body of type `application/json`.
   */
  apiAuditEventSavePost(
    params?: {
      body?: AdEventSaveReq
    },
    context?: HttpContext
  ): Observable<void> {
    return this.apiAuditEventSavePost$Response(params, context).pipe(
      map((r: StrictHttpResponse<void>): void => r.body)
    );
  }

  /** Path part for operation `apiAuditEventActionPost()` */
  static readonly ApiAuditEventActionPostPath = '/api/audit/event/action';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `apiAuditEventActionPost()` instead.
   *
   * This method sends `application/json` and handles request body of type `application/json`.
   */
  apiAuditEventActionPost$Response(
    params?: {
      body?: AdEventReq
    },
    context?: HttpContext
  ): Observable<StrictHttpResponse<void>> {
    const rb = new RequestBuilder(this.rootUrl, AuditWorksheetService.ApiAuditEventActionPostPath, 'post');
    if (params) {
      rb.body(params.body, 'application/json');
    }

    return this.http.request(
      rb.build({ responseType: 'text', accept: '*/*', context })
    ).pipe(
      filter((r: any): r is HttpResponse<any> => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return (r as HttpResponse<any>).clone({ body: undefined }) as StrictHttpResponse<void>;
      })
    );
  }

  /**
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `apiAuditEventActionPost$Response()` instead.
   *
   * This method sends `application/json` and handles request body of type `application/json`.
   */
  apiAuditEventActionPost(
    params?: {
      body?: AdEventReq
    },
    context?: HttpContext
  ): Observable<void> {
    return this.apiAuditEventActionPost$Response(params, context).pipe(
      map((r: StrictHttpResponse<void>): void => r.body)
    );
  }

  /** Path part for operation `apiAuditConclusionActionPost()` */
  static readonly ApiAuditConclusionActionPostPath = '/api/audit/conclusion/action';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `apiAuditConclusionActionPost()` instead.
   *
   * This method sends `application/json` and handles request body of type `application/json`.
   */
  apiAuditConclusionActionPost$Response(
    params?: {
      body?: AdConclusionReq
    },
    context?: HttpContext
  ): Observable<StrictHttpResponse<void>> {
    const rb = new RequestBuilder(this.rootUrl, AuditWorksheetService.ApiAuditConclusionActionPostPath, 'post');
    if (params) {
      rb.body(params.body, 'application/json');
    }

    return this.http.request(
      rb.build({ responseType: 'text', accept: '*/*', context })
    ).pipe(
      filter((r: any): r is HttpResponse<any> => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return (r as HttpResponse<any>).clone({ body: undefined }) as StrictHttpResponse<void>;
      })
    );
  }

  /**
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `apiAuditConclusionActionPost$Response()` instead.
   *
   * This method sends `application/json` and handles request body of type `application/json`.
   */
  apiAuditConclusionActionPost(
    params?: {
      body?: AdConclusionReq
    },
    context?: HttpContext
  ): Observable<void> {
    return this.apiAuditConclusionActionPost$Response(params, context).pipe(
      map((r: StrictHttpResponse<void>): void => r.body)
    );
  }

  /** Path part for operation `apiAuditFinalMgrApprovePost()` */
  static readonly ApiAuditFinalMgrApprovePostPath = '/api/audit/final-mgr-approve';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `apiAuditFinalMgrApprovePost()` instead.
   *
   * This method sends `application/json` and handles request body of type `application/json`.
   */
  apiAuditFinalMgrApprovePost$Response(
    params?: {
      body?: AdApproveReq
    },
    context?: HttpContext
  ): Observable<StrictHttpResponse<AdApproveRes>> {
    const rb = new RequestBuilder(this.rootUrl, AuditWorksheetService.ApiAuditFinalMgrApprovePostPath, 'post');
    if (params) {
      rb.body(params.body, 'application/json');
    }

    return this.http.request(
      rb.build({ responseType: 'json', accept: 'application/json', context })
    ).pipe(
      filter((r: any): r is HttpResponse<any> => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<AdApproveRes>;
      })
    );
  }

  /**
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `apiAuditFinalMgrApprovePost$Response()` instead.
   *
   * This method sends `application/json` and handles request body of type `application/json`.
   */
  apiAuditFinalMgrApprovePost(
    params?: {
      body?: AdApproveReq
    },
    context?: HttpContext
  ): Observable<AdApproveRes> {
    return this.apiAuditFinalMgrApprovePost$Response(params, context).pipe(
      map((r: StrictHttpResponse<AdApproveRes>): AdApproveRes => r.body)
    );
  }

  /** Path part for operation `apiAuditStartAuditFormPost()` */
  static readonly ApiAuditStartAuditFormPostPath = '/api/audit/start-audit-form';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `apiAuditStartAuditFormPost()` instead.
   *
   * This method sends `application/json` and handles request body of type `application/json`.
   */
  apiAuditStartAuditFormPost$Response(
    params?: {
      body?: AdStartAuditFormReq
    },
    context?: HttpContext
  ): Observable<StrictHttpResponse<void>> {
    const rb = new RequestBuilder(this.rootUrl, AuditWorksheetService.ApiAuditStartAuditFormPostPath, 'post');
    if (params) {
      rb.body(params.body, 'application/json');
    }

    return this.http.request(
      rb.build({ responseType: 'text', accept: '*/*', context })
    ).pipe(
      filter((r: any): r is HttpResponse<any> => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return (r as HttpResponse<any>).clone({ body: undefined }) as StrictHttpResponse<void>;
      })
    );
  }

  /**
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `apiAuditStartAuditFormPost$Response()` instead.
   *
   * This method sends `application/json` and handles request body of type `application/json`.
   */
  apiAuditStartAuditFormPost(
    params?: {
      body?: AdStartAuditFormReq
    },
    context?: HttpContext
  ): Observable<void> {
    return this.apiAuditStartAuditFormPost$Response(params, context).pipe(
      map((r: StrictHttpResponse<void>): void => r.body)
    );
  }

  /** Path part for operation `apiAuditOptDataRequesterStartWithGet()` */
  static readonly ApiAuditOptDataRequesterStartWithGetPath = '/api/audit/opt-data/requester/start-with';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `apiAuditOptDataRequesterStartWithGet()` instead.
   *
   * This method doesn't expect any request body.
   */
  apiAuditOptDataRequesterStartWithGet$Response(
    params?: {
      startWith?: string;
      workGroupIds?: Array<number>;
    },
    context?: HttpContext
  ): Observable<StrictHttpResponse<Array<AdTrnRelatedPeopleRes>>> {
    const rb = new RequestBuilder(this.rootUrl, AuditWorksheetService.ApiAuditOptDataRequesterStartWithGetPath, 'get');
    if (params) {
      rb.query('startWith', params.startWith, {});
      rb.query('workGroupIds', params.workGroupIds, {});
    }

    return this.http.request(
      rb.build({ responseType: 'json', accept: 'application/json', context })
    ).pipe(
      filter((r: any): r is HttpResponse<any> => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<Array<AdTrnRelatedPeopleRes>>;
      })
    );
  }

  /**
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `apiAuditOptDataRequesterStartWithGet$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  apiAuditOptDataRequesterStartWithGet(
    params?: {
      startWith?: string;
      workGroupIds?: Array<number>;
    },
    context?: HttpContext
  ): Observable<Array<AdTrnRelatedPeopleRes>> {
    return this.apiAuditOptDataRequesterStartWithGet$Response(params, context).pipe(
      map((r: StrictHttpResponse<Array<AdTrnRelatedPeopleRes>>): Array<AdTrnRelatedPeopleRes> => r.body)
    );
  }

  /** Path part for operation `apiAuditOptDataVmAdminStartWithGet()` */
  static readonly ApiAuditOptDataVmAdminStartWithGetPath = '/api/audit/opt-data/vm-admin/start-with';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `apiAuditOptDataVmAdminStartWithGet()` instead.
   *
   * This method doesn't expect any request body.
   */
  apiAuditOptDataVmAdminStartWithGet$Response(
    params?: {
      startWith?: string;
      workGroupIds?: Array<number>;
    },
    context?: HttpContext
  ): Observable<StrictHttpResponse<Array<AdTrnRelatedPeopleRes>>> {
    const rb = new RequestBuilder(this.rootUrl, AuditWorksheetService.ApiAuditOptDataVmAdminStartWithGetPath, 'get');
    if (params) {
      rb.query('startWith', params.startWith, {});
      rb.query('workGroupIds', params.workGroupIds, {});
    }

    return this.http.request(
      rb.build({ responseType: 'json', accept: 'application/json', context })
    ).pipe(
      filter((r: any): r is HttpResponse<any> => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<Array<AdTrnRelatedPeopleRes>>;
      })
    );
  }

  /**
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `apiAuditOptDataVmAdminStartWithGet$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  apiAuditOptDataVmAdminStartWithGet(
    params?: {
      startWith?: string;
      workGroupIds?: Array<number>;
    },
    context?: HttpContext
  ): Observable<Array<AdTrnRelatedPeopleRes>> {
    return this.apiAuditOptDataVmAdminStartWithGet$Response(params, context).pipe(
      map((r: StrictHttpResponse<Array<AdTrnRelatedPeopleRes>>): Array<AdTrnRelatedPeopleRes> => r.body)
    );
  }

  /** Path part for operation `apiAuditOptDataMemberStartWithGet()` */
  static readonly ApiAuditOptDataMemberStartWithGetPath = '/api/audit/opt-data/member/start-with';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `apiAuditOptDataMemberStartWithGet()` instead.
   *
   * This method doesn't expect any request body.
   */
  apiAuditOptDataMemberStartWithGet$Response(
    params?: {
      startWith?: string;
      workGroupIds?: Array<number>;
    },
    context?: HttpContext
  ): Observable<StrictHttpResponse<Array<AdTrnRelatedPeopleRes>>> {
    const rb = new RequestBuilder(this.rootUrl, AuditWorksheetService.ApiAuditOptDataMemberStartWithGetPath, 'get');
    if (params) {
      rb.query('startWith', params.startWith, {});
      rb.query('workGroupIds', params.workGroupIds, {});
    }

    return this.http.request(
      rb.build({ responseType: 'json', accept: 'application/json', context })
    ).pipe(
      filter((r: any): r is HttpResponse<any> => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<Array<AdTrnRelatedPeopleRes>>;
      })
    );
  }

  /**
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `apiAuditOptDataMemberStartWithGet$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  apiAuditOptDataMemberStartWithGet(
    params?: {
      startWith?: string;
      workGroupIds?: Array<number>;
    },
    context?: HttpContext
  ): Observable<Array<AdTrnRelatedPeopleRes>> {
    return this.apiAuditOptDataMemberStartWithGet$Response(params, context).pipe(
      map((r: StrictHttpResponse<Array<AdTrnRelatedPeopleRes>>): Array<AdTrnRelatedPeopleRes> => r.body)
    );
  }

  /** Path part for operation `apiAuditOptDataFormCriteriasGet()` */
  static readonly ApiAuditOptDataFormCriteriasGetPath = '/api/audit/opt-data/form-criterias';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `apiAuditOptDataFormCriteriasGet()` instead.
   *
   * This method doesn't expect any request body.
   */
  apiAuditOptDataFormCriteriasGet$Response(
    params?: {
      workGroupId?: number;
    },
    context?: HttpContext
  ): Observable<StrictHttpResponse<Array<AdCriteriaRes>>> {
    const rb = new RequestBuilder(this.rootUrl, AuditWorksheetService.ApiAuditOptDataFormCriteriasGetPath, 'get');
    if (params) {
      rb.query('workGroupId', params.workGroupId, {});
    }

    return this.http.request(
      rb.build({ responseType: 'json', accept: 'application/json', context })
    ).pipe(
      filter((r: any): r is HttpResponse<any> => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<Array<AdCriteriaRes>>;
      })
    );
  }

  /**
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `apiAuditOptDataFormCriteriasGet$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  apiAuditOptDataFormCriteriasGet(
    params?: {
      workGroupId?: number;
    },
    context?: HttpContext
  ): Observable<Array<AdCriteriaRes>> {
    return this.apiAuditOptDataFormCriteriasGet$Response(params, context).pipe(
      map((r: StrictHttpResponse<Array<AdCriteriaRes>>): Array<AdCriteriaRes> => r.body)
    );
  }

  /** Path part for operation `apiAuditOptDataStatementTemplatesGet()` */
  static readonly ApiAuditOptDataStatementTemplatesGetPath = '/api/audit/opt-data/statement-templates';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `apiAuditOptDataStatementTemplatesGet()` instead.
   *
   * This method doesn't expect any request body.
   */
  apiAuditOptDataStatementTemplatesGet$Response(
    params?: {
      workGroupId?: number;
    },
    context?: HttpContext
  ): Observable<StrictHttpResponse<Array<AdStatementTemplateRes>>> {
    const rb = new RequestBuilder(this.rootUrl, AuditWorksheetService.ApiAuditOptDataStatementTemplatesGetPath, 'get');
    if (params) {
      rb.query('workGroupId', params.workGroupId, {});
    }

    return this.http.request(
      rb.build({ responseType: 'json', accept: 'application/json', context })
    ).pipe(
      filter((r: any): r is HttpResponse<any> => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<Array<AdStatementTemplateRes>>;
      })
    );
  }

  /**
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `apiAuditOptDataStatementTemplatesGet$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  apiAuditOptDataStatementTemplatesGet(
    params?: {
      workGroupId?: number;
    },
    context?: HttpContext
  ): Observable<Array<AdStatementTemplateRes>> {
    return this.apiAuditOptDataStatementTemplatesGet$Response(params, context).pipe(
      map((r: StrictHttpResponse<Array<AdStatementTemplateRes>>): Array<AdStatementTemplateRes> => r.body)
    );
  }

  /** Path part for operation `apiAuditOptDataFormsGet()` */
  static readonly ApiAuditOptDataFormsGetPath = '/api/audit/opt-data/forms';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `apiAuditOptDataFormsGet()` instead.
   *
   * This method doesn't expect any request body.
   */
  apiAuditOptDataFormsGet$Response(
    params?: {
      criteriaShortName?: string;
      workGroupId?: number;
    },
    context?: HttpContext
  ): Observable<StrictHttpResponse<Array<AdFormOptRes>>> {
    const rb = new RequestBuilder(this.rootUrl, AuditWorksheetService.ApiAuditOptDataFormsGetPath, 'get');
    if (params) {
      rb.query('criteriaShortName', params.criteriaShortName, {});
      rb.query('workGroupId', params.workGroupId, {});
    }

    return this.http.request(
      rb.build({ responseType: 'json', accept: 'application/json', context })
    ).pipe(
      filter((r: any): r is HttpResponse<any> => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<Array<AdFormOptRes>>;
      })
    );
  }

  /**
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `apiAuditOptDataFormsGet$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  apiAuditOptDataFormsGet(
    params?: {
      criteriaShortName?: string;
      workGroupId?: number;
    },
    context?: HttpContext
  ): Observable<Array<AdFormOptRes>> {
    return this.apiAuditOptDataFormsGet$Response(params, context).pipe(
      map((r: StrictHttpResponse<Array<AdFormOptRes>>): Array<AdFormOptRes> => r.body)
    );
  }

  /** Path part for operation `apiAuditOptDataVendorSearchGet()` */
  static readonly ApiAuditOptDataVendorSearchGetPath = '/api/audit/opt-data/vendor/search';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `apiAuditOptDataVendorSearchGet()` instead.
   *
   * This method doesn't expect any request body.
   */
  apiAuditOptDataVendorSearchGet$Response(
    params?: {
      keyword?: string;
      workGroupList?: Array<number>;
    },
    context?: HttpContext
  ): Observable<StrictHttpResponse<Array<AdVendorOptSearchingRes>>> {
    const rb = new RequestBuilder(this.rootUrl, AuditWorksheetService.ApiAuditOptDataVendorSearchGetPath, 'get');
    if (params) {
      rb.query('keyword', params.keyword, {});
      rb.query('workGroupList', params.workGroupList, {});
    }

    return this.http.request(
      rb.build({ responseType: 'json', accept: 'application/json', context })
    ).pipe(
      filter((r: any): r is HttpResponse<any> => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<Array<AdVendorOptSearchingRes>>;
      })
    );
  }

  /**
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `apiAuditOptDataVendorSearchGet$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  apiAuditOptDataVendorSearchGet(
    params?: {
      keyword?: string;
      workGroupList?: Array<number>;
    },
    context?: HttpContext
  ): Observable<Array<AdVendorOptSearchingRes>> {
    return this.apiAuditOptDataVendorSearchGet$Response(params, context).pipe(
      map((r: StrictHttpResponse<Array<AdVendorOptSearchingRes>>): Array<AdVendorOptSearchingRes> => r.body)
    );
  }

  /** Path part for operation `apiAuditOptDataVendorProductsGet()` */
  static readonly ApiAuditOptDataVendorProductsGetPath = '/api/audit/opt-data/vendor/products';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `apiAuditOptDataVendorProductsGet()` instead.
   *
   * This method doesn't expect any request body.
   */
  apiAuditOptDataVendorProductsGet$Response(
    params?: {
      vendorProfileId?: string;
      FKWorkGroupId?: number;
    },
    context?: HttpContext
  ): Observable<StrictHttpResponse<Array<AdTrnProductRes>>> {
    const rb = new RequestBuilder(this.rootUrl, AuditWorksheetService.ApiAuditOptDataVendorProductsGetPath, 'get');
    if (params) {
      rb.query('vendorProfileId', params.vendorProfileId, {});
      rb.query('FKWorkGroupId', params.FKWorkGroupId, {});
    }

    return this.http.request(
      rb.build({ responseType: 'json', accept: 'application/json', context })
    ).pipe(
      filter((r: any): r is HttpResponse<any> => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<Array<AdTrnProductRes>>;
      })
    );
  }

  /**
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `apiAuditOptDataVendorProductsGet$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  apiAuditOptDataVendorProductsGet(
    params?: {
      vendorProfileId?: string;
      FKWorkGroupId?: number;
    },
    context?: HttpContext
  ): Observable<Array<AdTrnProductRes>> {
    return this.apiAuditOptDataVendorProductsGet$Response(params, context).pipe(
      map((r: StrictHttpResponse<Array<AdTrnProductRes>>): Array<AdTrnProductRes> => r.body)
    );
  }

  /** Path part for operation `apiAuditOptDataRefAuditDocQueryPost()` */
  static readonly ApiAuditOptDataRefAuditDocQueryPostPath = '/api/audit/opt-data/ref-audit-doc/query';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `apiAuditOptDataRefAuditDocQueryPost()` instead.
   *
   * This method sends `application/json` and handles request body of type `application/json`.
   */
  apiAuditOptDataRefAuditDocQueryPost$Response(
    params?: {
      body?: AdRefAuditOptQueryReq
    },
    context?: HttpContext
  ): Observable<StrictHttpResponse<AdRefAuditOptQueryRes>> {
    const rb = new RequestBuilder(this.rootUrl, AuditWorksheetService.ApiAuditOptDataRefAuditDocQueryPostPath, 'post');
    if (params) {
      rb.body(params.body, 'application/json');
    }

    return this.http.request(
      rb.build({ responseType: 'json', accept: 'application/json', context })
    ).pipe(
      filter((r: any): r is HttpResponse<any> => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<AdRefAuditOptQueryRes>;
      })
    );
  }

  /**
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `apiAuditOptDataRefAuditDocQueryPost$Response()` instead.
   *
   * This method sends `application/json` and handles request body of type `application/json`.
   */
  apiAuditOptDataRefAuditDocQueryPost(
    params?: {
      body?: AdRefAuditOptQueryReq
    },
    context?: HttpContext
  ): Observable<AdRefAuditOptQueryRes> {
    return this.apiAuditOptDataRefAuditDocQueryPost$Response(params, context).pipe(
      map((r: StrictHttpResponse<AdRefAuditOptQueryRes>): AdRefAuditOptQueryRes => r.body)
    );
  }

  /** Path part for operation `apiAuditOptDataApprovedLevelPost()` */
  static readonly ApiAuditOptDataApprovedLevelPostPath = '/api/audit/opt-data/approved-level';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `apiAuditOptDataApprovedLevelPost()` instead.
   *
   * This method sends `application/json` and handles request body of type `application/json`.
   */
  apiAuditOptDataApprovedLevelPost$Response(
    params?: {
      body?: AdApprovedLevelReq
    },
    context?: HttpContext
  ): Observable<StrictHttpResponse<AdApprovedLevelRes>> {
    const rb = new RequestBuilder(this.rootUrl, AuditWorksheetService.ApiAuditOptDataApprovedLevelPostPath, 'post');
    if (params) {
      rb.body(params.body, 'application/json');
    }

    return this.http.request(
      rb.build({ responseType: 'json', accept: 'application/json', context })
    ).pipe(
      filter((r: any): r is HttpResponse<any> => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<AdApprovedLevelRes>;
      })
    );
  }

  /**
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `apiAuditOptDataApprovedLevelPost$Response()` instead.
   *
   * This method sends `application/json` and handles request body of type `application/json`.
   */
  apiAuditOptDataApprovedLevelPost(
    params?: {
      body?: AdApprovedLevelReq
    },
    context?: HttpContext
  ): Observable<AdApprovedLevelRes> {
    return this.apiAuditOptDataApprovedLevelPost$Response(params, context).pipe(
      map((r: StrictHttpResponse<AdApprovedLevelRes>): AdApprovedLevelRes => r.body)
    );
  }

  /** Path part for operation `apiAuditOptDataApproverDefaultGet()` */
  static readonly ApiAuditOptDataApproverDefaultGetPath = '/api/audit/opt-data/approver/default';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `apiAuditOptDataApproverDefaultGet()` instead.
   *
   * This method doesn't expect any request body.
   */
  apiAuditOptDataApproverDefaultGet$Response(
    params?: {
      requiredApprovedLevel?: number;
      requesterEmployeeId?: string;
    },
    context?: HttpContext
  ): Observable<StrictHttpResponse<AdTrnMgrsRes>> {
    const rb = new RequestBuilder(this.rootUrl, AuditWorksheetService.ApiAuditOptDataApproverDefaultGetPath, 'get');
    if (params) {
      rb.query('requiredApprovedLevel', params.requiredApprovedLevel, {});
      rb.query('requesterEmployeeId', params.requesterEmployeeId, {});
    }

    return this.http.request(
      rb.build({ responseType: 'json', accept: 'application/json', context })
    ).pipe(
      filter((r: any): r is HttpResponse<any> => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<AdTrnMgrsRes>;
      })
    );
  }

  /**
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `apiAuditOptDataApproverDefaultGet$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  apiAuditOptDataApproverDefaultGet(
    params?: {
      requiredApprovedLevel?: number;
      requesterEmployeeId?: string;
    },
    context?: HttpContext
  ): Observable<AdTrnMgrsRes> {
    return this.apiAuditOptDataApproverDefaultGet$Response(params, context).pipe(
      map((r: StrictHttpResponse<AdTrnMgrsRes>): AdTrnMgrsRes => r.body)
    );
  }

  /** Path part for operation `apiAuditOptDataApproverStartWithGet()` */
  static readonly ApiAuditOptDataApproverStartWithGetPath = '/api/audit/opt-data/approver/start-with';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `apiAuditOptDataApproverStartWithGet()` instead.
   *
   * This method doesn't expect any request body.
   */
  apiAuditOptDataApproverStartWithGet$Response(
    params?: {
      startWith?: string;
      requiredApprovedLevel?: number;
      workGroupIds?: Array<number>;
    },
    context?: HttpContext
  ): Observable<StrictHttpResponse<Array<AdTrnMgrRes>>> {
    const rb = new RequestBuilder(this.rootUrl, AuditWorksheetService.ApiAuditOptDataApproverStartWithGetPath, 'get');
    if (params) {
      rb.query('startWith', params.startWith, {});
      rb.query('requiredApprovedLevel', params.requiredApprovedLevel, {});
      rb.query('workGroupIds', params.workGroupIds, {});
    }

    return this.http.request(
      rb.build({ responseType: 'json', accept: 'application/json', context })
    ).pipe(
      filter((r: any): r is HttpResponse<any> => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<Array<AdTrnMgrRes>>;
      })
    );
  }

  /**
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `apiAuditOptDataApproverStartWithGet$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  apiAuditOptDataApproverStartWithGet(
    params?: {
      startWith?: string;
      requiredApprovedLevel?: number;
      workGroupIds?: Array<number>;
    },
    context?: HttpContext
  ): Observable<Array<AdTrnMgrRes>> {
    return this.apiAuditOptDataApproverStartWithGet$Response(params, context).pipe(
      map((r: StrictHttpResponse<Array<AdTrnMgrRes>>): Array<AdTrnMgrRes> => r.body)
    );
  }

  /** Path part for operation `apiAuditOptDataFinalApprovedLevelPost()` */
  static readonly ApiAuditOptDataFinalApprovedLevelPostPath = '/api/audit/opt-data/final-approved-level';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `apiAuditOptDataFinalApprovedLevelPost()` instead.
   *
   * This method sends `application/json` and handles request body of type `application/json`.
   */
  apiAuditOptDataFinalApprovedLevelPost$Response(
    params?: {
      body?: AdFinalApprovedLevelReq
    },
    context?: HttpContext
  ): Observable<StrictHttpResponse<AdApprovedLevelRes>> {
    const rb = new RequestBuilder(this.rootUrl, AuditWorksheetService.ApiAuditOptDataFinalApprovedLevelPostPath, 'post');
    if (params) {
      rb.body(params.body, 'application/json');
    }

    return this.http.request(
      rb.build({ responseType: 'json', accept: 'application/json', context })
    ).pipe(
      filter((r: any): r is HttpResponse<any> => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<AdApprovedLevelRes>;
      })
    );
  }

  /**
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `apiAuditOptDataFinalApprovedLevelPost$Response()` instead.
   *
   * This method sends `application/json` and handles request body of type `application/json`.
   */
  apiAuditOptDataFinalApprovedLevelPost(
    params?: {
      body?: AdFinalApprovedLevelReq
    },
    context?: HttpContext
  ): Observable<AdApprovedLevelRes> {
    return this.apiAuditOptDataFinalApprovedLevelPost$Response(params, context).pipe(
      map((r: StrictHttpResponse<AdApprovedLevelRes>): AdApprovedLevelRes => r.body)
    );
  }

  /** Path part for operation `apiAuditOptDataSalesDefaultGet()` */
  static readonly ApiAuditOptDataSalesDefaultGetPath = '/api/audit/opt-data/sales/default';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `apiAuditOptDataSalesDefaultGet()` instead.
   *
   * This method doesn't expect any request body.
   */
  apiAuditOptDataSalesDefaultGet$Response(
    params?: {
      vendorProfileId?: string;
    },
    context?: HttpContext
  ): Observable<StrictHttpResponse<Array<AdTrnVendorAccountDto>>> {
    const rb = new RequestBuilder(this.rootUrl, AuditWorksheetService.ApiAuditOptDataSalesDefaultGetPath, 'get');
    if (params) {
      rb.query('vendorProfileId', params.vendorProfileId, {});
    }

    return this.http.request(
      rb.build({ responseType: 'json', accept: 'application/json', context })
    ).pipe(
      filter((r: any): r is HttpResponse<any> => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<Array<AdTrnVendorAccountDto>>;
      })
    );
  }

  /**
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `apiAuditOptDataSalesDefaultGet$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  apiAuditOptDataSalesDefaultGet(
    params?: {
      vendorProfileId?: string;
    },
    context?: HttpContext
  ): Observable<Array<AdTrnVendorAccountDto>> {
    return this.apiAuditOptDataSalesDefaultGet$Response(params, context).pipe(
      map((r: StrictHttpResponse<Array<AdTrnVendorAccountDto>>): Array<AdTrnVendorAccountDto> => r.body)
    );
  }

  /** Path part for operation `apiAuditChatMessagesGet()` */
  static readonly ApiAuditChatMessagesGetPath = '/api/audit/chat/messages';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `apiAuditChatMessagesGet()` instead.
   *
   * This method doesn't expect any request body.
   */
  apiAuditChatMessagesGet$Response(
    params?: {
      refId?: string;
    },
    context?: HttpContext
  ): Observable<StrictHttpResponse<Array<AuditTrnChatRes>>> {
    const rb = new RequestBuilder(this.rootUrl, AuditWorksheetService.ApiAuditChatMessagesGetPath, 'get');
    if (params) {
      rb.query('refId', params.refId, {});
    }

    return this.http.request(
      rb.build({ responseType: 'json', accept: 'application/json', context })
    ).pipe(
      filter((r: any): r is HttpResponse<any> => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<Array<AuditTrnChatRes>>;
      })
    );
  }

  /**
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `apiAuditChatMessagesGet$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  apiAuditChatMessagesGet(
    params?: {
      refId?: string;
    },
    context?: HttpContext
  ): Observable<Array<AuditTrnChatRes>> {
    return this.apiAuditChatMessagesGet$Response(params, context).pipe(
      map((r: StrictHttpResponse<Array<AuditTrnChatRes>>): Array<AuditTrnChatRes> => r.body)
    );
  }

  /** Path part for operation `apiAuditChatMessagesDelete()` */
  static readonly ApiAuditChatMessagesDeletePath = '/api/audit/chat/messages';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `apiAuditChatMessagesDelete()` instead.
   *
   * This method doesn't expect any request body.
   */
  apiAuditChatMessagesDelete$Response(
    params?: {
      chatId?: number;
    },
    context?: HttpContext
  ): Observable<StrictHttpResponse<void>> {
    const rb = new RequestBuilder(this.rootUrl, AuditWorksheetService.ApiAuditChatMessagesDeletePath, 'delete');
    if (params) {
      rb.query('chatId', params.chatId, {});
    }

    return this.http.request(
      rb.build({ responseType: 'text', accept: '*/*', context })
    ).pipe(
      filter((r: any): r is HttpResponse<any> => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return (r as HttpResponse<any>).clone({ body: undefined }) as StrictHttpResponse<void>;
      })
    );
  }

  /**
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `apiAuditChatMessagesDelete$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  apiAuditChatMessagesDelete(
    params?: {
      chatId?: number;
    },
    context?: HttpContext
  ): Observable<void> {
    return this.apiAuditChatMessagesDelete$Response(params, context).pipe(
      map((r: StrictHttpResponse<void>): void => r.body)
    );
  }

  /** Path part for operation `apiAuditChatMessagePost()` */
  static readonly ApiAuditChatMessagePostPath = '/api/audit/chat/message';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `apiAuditChatMessagePost()` instead.
   *
   * This method sends `application/json` and handles request body of type `application/json`.
   */
  apiAuditChatMessagePost$Response(
    params?: {
      body?: AuditTrnChatReq
    },
    context?: HttpContext
  ): Observable<StrictHttpResponse<void>> {
    const rb = new RequestBuilder(this.rootUrl, AuditWorksheetService.ApiAuditChatMessagePostPath, 'post');
    if (params) {
      rb.body(params.body, 'application/json');
    }

    return this.http.request(
      rb.build({ responseType: 'text', accept: '*/*', context })
    ).pipe(
      filter((r: any): r is HttpResponse<any> => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return (r as HttpResponse<any>).clone({ body: undefined }) as StrictHttpResponse<void>;
      })
    );
  }

  /**
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `apiAuditChatMessagePost$Response()` instead.
   *
   * This method sends `application/json` and handles request body of type `application/json`.
   */
  apiAuditChatMessagePost(
    params?: {
      body?: AuditTrnChatReq
    },
    context?: HttpContext
  ): Observable<void> {
    return this.apiAuditChatMessagePost$Response(params, context).pipe(
      map((r: StrictHttpResponse<void>): void => r.body)
    );
  }

}
