/* tslint:disable */
/* eslint-disable */
import { HttpClient, HttpContext, HttpResponse } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { filter, map } from 'rxjs/operators';

import { BaseService } from '../base-service';
import { ApiConfiguration } from '../api-configuration';
import { StrictHttpResponse } from '../strict-http-response';
import { RequestBuilder } from '../request-builder';

import { MasterCustomsOfficerDto } from '../models/master-customs-officer-dto';

@Injectable({ providedIn: 'root' })
export class MasterCustomsOfficerService extends BaseService {
  constructor(config: ApiConfiguration, http: HttpClient) {
    super(config, http);
  }

  /** Path part for operation `apiMasterCustomsOfficerAllGet()` */
  static readonly ApiMasterCustomsOfficerAllGetPath = '/api/MasterCustomsOfficer/all';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `apiMasterCustomsOfficerAllGet()` instead.
   *
   * This method doesn't expect any request body.
   */
  apiMasterCustomsOfficerAllGet$Response(
    params?: {
      workgroups?: Array<number>;
    },
    context?: HttpContext
  ): Observable<StrictHttpResponse<Array<MasterCustomsOfficerDto>>> {
    const rb = new RequestBuilder(this.rootUrl, MasterCustomsOfficerService.ApiMasterCustomsOfficerAllGetPath, 'get');
    if (params) {
      rb.query('workgroups', params.workgroups, {});
    }

    return this.http.request(
      rb.build({ responseType: 'json', accept: 'application/json', context })
    ).pipe(
      filter((r: any): r is HttpResponse<any> => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<Array<MasterCustomsOfficerDto>>;
      })
    );
  }

  /**
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `apiMasterCustomsOfficerAllGet$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  apiMasterCustomsOfficerAllGet(
    params?: {
      workgroups?: Array<number>;
    },
    context?: HttpContext
  ): Observable<Array<MasterCustomsOfficerDto>> {
    return this.apiMasterCustomsOfficerAllGet$Response(params, context).pipe(
      map((r: StrictHttpResponse<Array<MasterCustomsOfficerDto>>): Array<MasterCustomsOfficerDto> => r.body)
    );
  }

  /** Path part for operation `apiMasterCustomsOfficerPost()` */
  static readonly ApiMasterCustomsOfficerPostPath = '/api/MasterCustomsOfficer';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `apiMasterCustomsOfficerPost()` instead.
   *
   * This method sends `application/json` and handles request body of type `application/json`.
   */
  apiMasterCustomsOfficerPost$Response(
    params?: {
      body?: MasterCustomsOfficerDto
    },
    context?: HttpContext
  ): Observable<StrictHttpResponse<void>> {
    const rb = new RequestBuilder(this.rootUrl, MasterCustomsOfficerService.ApiMasterCustomsOfficerPostPath, 'post');
    if (params) {
      rb.body(params.body, 'application/json');
    }

    return this.http.request(
      rb.build({ responseType: 'text', accept: '*/*', context })
    ).pipe(
      filter((r: any): r is HttpResponse<any> => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return (r as HttpResponse<any>).clone({ body: undefined }) as StrictHttpResponse<void>;
      })
    );
  }

  /**
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `apiMasterCustomsOfficerPost$Response()` instead.
   *
   * This method sends `application/json` and handles request body of type `application/json`.
   */
  apiMasterCustomsOfficerPost(
    params?: {
      body?: MasterCustomsOfficerDto
    },
    context?: HttpContext
  ): Observable<void> {
    return this.apiMasterCustomsOfficerPost$Response(params, context).pipe(
      map((r: StrictHttpResponse<void>): void => r.body)
    );
  }

  /** Path part for operation `apiMasterCustomsOfficerDelete()` */
  static readonly ApiMasterCustomsOfficerDeletePath = '/api/MasterCustomsOfficer';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `apiMasterCustomsOfficerDelete()` instead.
   *
   * This method doesn't expect any request body.
   */
  apiMasterCustomsOfficerDelete$Response(
    params?: {
      companyCode?: string;
    },
    context?: HttpContext
  ): Observable<StrictHttpResponse<void>> {
    const rb = new RequestBuilder(this.rootUrl, MasterCustomsOfficerService.ApiMasterCustomsOfficerDeletePath, 'delete');
    if (params) {
      rb.query('companyCode', params.companyCode, {});
    }

    return this.http.request(
      rb.build({ responseType: 'text', accept: '*/*', context })
    ).pipe(
      filter((r: any): r is HttpResponse<any> => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return (r as HttpResponse<any>).clone({ body: undefined }) as StrictHttpResponse<void>;
      })
    );
  }

  /**
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `apiMasterCustomsOfficerDelete$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  apiMasterCustomsOfficerDelete(
    params?: {
      companyCode?: string;
    },
    context?: HttpContext
  ): Observable<void> {
    return this.apiMasterCustomsOfficerDelete$Response(params, context).pipe(
      map((r: StrictHttpResponse<void>): void => r.body)
    );
  }

}
