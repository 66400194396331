/* tslint:disable */
/* eslint-disable */
import { HttpClient, HttpContext, HttpResponse } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { filter, map } from 'rxjs/operators';

import { BaseService } from '../base-service';
import { ApiConfiguration } from '../api-configuration';
import { StrictHttpResponse } from '../strict-http-response';
import { RequestBuilder } from '../request-builder';

import { MasterProcess } from '../models/master-process';
import { MasterStatementTemplateEditItemDto } from '../models/master-statement-template-edit-item-dto';
import { MasterStatementTemplateRowItemRes } from '../models/master-statement-template-row-item-res';

@Injectable({ providedIn: 'root' })
export class MasterStatementTemplateService extends BaseService {
  constructor(config: ApiConfiguration, http: HttpClient) {
    super(config, http);
  }

  /** Path part for operation `apiMasterStatementTemplateAllGet()` */
  static readonly ApiMasterStatementTemplateAllGetPath = '/api/master/statement-template/all';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `apiMasterStatementTemplateAllGet()` instead.
   *
   * This method doesn't expect any request body.
   */
  apiMasterStatementTemplateAllGet$Response(
    params?: {
      workgroups?: Array<number>;
    },
    context?: HttpContext
  ): Observable<StrictHttpResponse<Array<MasterStatementTemplateRowItemRes>>> {
    const rb = new RequestBuilder(this.rootUrl, MasterStatementTemplateService.ApiMasterStatementTemplateAllGetPath, 'get');
    if (params) {
      rb.query('workgroups', params.workgroups, {});
    }

    return this.http.request(
      rb.build({ responseType: 'json', accept: 'application/json', context })
    ).pipe(
      filter((r: any): r is HttpResponse<any> => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<Array<MasterStatementTemplateRowItemRes>>;
      })
    );
  }

  /**
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `apiMasterStatementTemplateAllGet$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  apiMasterStatementTemplateAllGet(
    params?: {
      workgroups?: Array<number>;
    },
    context?: HttpContext
  ): Observable<Array<MasterStatementTemplateRowItemRes>> {
    return this.apiMasterStatementTemplateAllGet$Response(params, context).pipe(
      map((r: StrictHttpResponse<Array<MasterStatementTemplateRowItemRes>>): Array<MasterStatementTemplateRowItemRes> => r.body)
    );
  }

  /** Path part for operation `apiMasterStatementTemplateGet()` */
  static readonly ApiMasterStatementTemplateGetPath = '/api/master/statement-template';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `apiMasterStatementTemplateGet()` instead.
   *
   * This method doesn't expect any request body.
   */
  apiMasterStatementTemplateGet$Response(
    params?: {
      id?: string;
    },
    context?: HttpContext
  ): Observable<StrictHttpResponse<MasterStatementTemplateEditItemDto>> {
    const rb = new RequestBuilder(this.rootUrl, MasterStatementTemplateService.ApiMasterStatementTemplateGetPath, 'get');
    if (params) {
      rb.query('id', params.id, {});
    }

    return this.http.request(
      rb.build({ responseType: 'json', accept: 'application/json', context })
    ).pipe(
      filter((r: any): r is HttpResponse<any> => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<MasterStatementTemplateEditItemDto>;
      })
    );
  }

  /**
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `apiMasterStatementTemplateGet$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  apiMasterStatementTemplateGet(
    params?: {
      id?: string;
    },
    context?: HttpContext
  ): Observable<MasterStatementTemplateEditItemDto> {
    return this.apiMasterStatementTemplateGet$Response(params, context).pipe(
      map((r: StrictHttpResponse<MasterStatementTemplateEditItemDto>): MasterStatementTemplateEditItemDto => r.body)
    );
  }

  /** Path part for operation `apiMasterStatementTemplatePut()` */
  static readonly ApiMasterStatementTemplatePutPath = '/api/master/statement-template';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `apiMasterStatementTemplatePut()` instead.
   *
   * This method sends `application/json` and handles request body of type `application/json`.
   */
  apiMasterStatementTemplatePut$Response(
    params?: {
      id?: string;
      body?: MasterStatementTemplateEditItemDto
    },
    context?: HttpContext
  ): Observable<StrictHttpResponse<void>> {
    const rb = new RequestBuilder(this.rootUrl, MasterStatementTemplateService.ApiMasterStatementTemplatePutPath, 'put');
    if (params) {
      rb.query('id', params.id, {});
      rb.body(params.body, 'application/json');
    }

    return this.http.request(
      rb.build({ responseType: 'text', accept: '*/*', context })
    ).pipe(
      filter((r: any): r is HttpResponse<any> => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return (r as HttpResponse<any>).clone({ body: undefined }) as StrictHttpResponse<void>;
      })
    );
  }

  /**
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `apiMasterStatementTemplatePut$Response()` instead.
   *
   * This method sends `application/json` and handles request body of type `application/json`.
   */
  apiMasterStatementTemplatePut(
    params?: {
      id?: string;
      body?: MasterStatementTemplateEditItemDto
    },
    context?: HttpContext
  ): Observable<void> {
    return this.apiMasterStatementTemplatePut$Response(params, context).pipe(
      map((r: StrictHttpResponse<void>): void => r.body)
    );
  }

  /** Path part for operation `apiMasterStatementTemplatePost()` */
  static readonly ApiMasterStatementTemplatePostPath = '/api/master/statement-template';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `apiMasterStatementTemplatePost()` instead.
   *
   * This method sends `application/json` and handles request body of type `application/json`.
   */
  apiMasterStatementTemplatePost$Response(
    params?: {
      body?: MasterStatementTemplateEditItemDto
    },
    context?: HttpContext
  ): Observable<StrictHttpResponse<void>> {
    const rb = new RequestBuilder(this.rootUrl, MasterStatementTemplateService.ApiMasterStatementTemplatePostPath, 'post');
    if (params) {
      rb.body(params.body, 'application/json');
    }

    return this.http.request(
      rb.build({ responseType: 'text', accept: '*/*', context })
    ).pipe(
      filter((r: any): r is HttpResponse<any> => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return (r as HttpResponse<any>).clone({ body: undefined }) as StrictHttpResponse<void>;
      })
    );
  }

  /**
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `apiMasterStatementTemplatePost$Response()` instead.
   *
   * This method sends `application/json` and handles request body of type `application/json`.
   */
  apiMasterStatementTemplatePost(
    params?: {
      body?: MasterStatementTemplateEditItemDto
    },
    context?: HttpContext
  ): Observable<void> {
    return this.apiMasterStatementTemplatePost$Response(params, context).pipe(
      map((r: StrictHttpResponse<void>): void => r.body)
    );
  }

  /** Path part for operation `apiMasterStatementTemplateDelete()` */
  static readonly ApiMasterStatementTemplateDeletePath = '/api/master/statement-template';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `apiMasterStatementTemplateDelete()` instead.
   *
   * This method doesn't expect any request body.
   */
  apiMasterStatementTemplateDelete$Response(
    params?: {
      id?: string;
    },
    context?: HttpContext
  ): Observable<StrictHttpResponse<void>> {
    const rb = new RequestBuilder(this.rootUrl, MasterStatementTemplateService.ApiMasterStatementTemplateDeletePath, 'delete');
    if (params) {
      rb.query('id', params.id, {});
    }

    return this.http.request(
      rb.build({ responseType: 'text', accept: '*/*', context })
    ).pipe(
      filter((r: any): r is HttpResponse<any> => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return (r as HttpResponse<any>).clone({ body: undefined }) as StrictHttpResponse<void>;
      })
    );
  }

  /**
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `apiMasterStatementTemplateDelete$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  apiMasterStatementTemplateDelete(
    params?: {
      id?: string;
    },
    context?: HttpContext
  ): Observable<void> {
    return this.apiMasterStatementTemplateDelete$Response(params, context).pipe(
      map((r: StrictHttpResponse<void>): void => r.body)
    );
  }

  /** Path part for operation `apiMasterStatementTemplateOptDataProcessesGet()` */
  static readonly ApiMasterStatementTemplateOptDataProcessesGetPath = '/api/master/statement-template/opt-data/processes';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `apiMasterStatementTemplateOptDataProcessesGet()` instead.
   *
   * This method doesn't expect any request body.
   */
  apiMasterStatementTemplateOptDataProcessesGet$Response(
    params?: {
    },
    context?: HttpContext
  ): Observable<StrictHttpResponse<Array<MasterProcess>>> {
    const rb = new RequestBuilder(this.rootUrl, MasterStatementTemplateService.ApiMasterStatementTemplateOptDataProcessesGetPath, 'get');
    if (params) {
    }

    return this.http.request(
      rb.build({ responseType: 'json', accept: 'application/json', context })
    ).pipe(
      filter((r: any): r is HttpResponse<any> => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<Array<MasterProcess>>;
      })
    );
  }

  /**
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `apiMasterStatementTemplateOptDataProcessesGet$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  apiMasterStatementTemplateOptDataProcessesGet(
    params?: {
    },
    context?: HttpContext
  ): Observable<Array<MasterProcess>> {
    return this.apiMasterStatementTemplateOptDataProcessesGet$Response(params, context).pipe(
      map((r: StrictHttpResponse<Array<MasterProcess>>): Array<MasterProcess> => r.body)
    );
  }

}
