/* tslint:disable */
/* eslint-disable */
import { HttpClient, HttpContext, HttpResponse } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { filter, map } from 'rxjs/operators';

import { BaseService } from '../base-service';
import { ApiConfiguration } from '../api-configuration';
import { StrictHttpResponse } from '../strict-http-response';
import { RequestBuilder } from '../request-builder';

import { MasterCapaTemplatEditItemDto } from '../models/master-capa-templat-edit-item-dto';
import { MasterCapaTemplateRowItemRes } from '../models/master-capa-template-row-item-res';

@Injectable({ providedIn: 'root' })
export class MasterCapaTemplateService extends BaseService {
  constructor(config: ApiConfiguration, http: HttpClient) {
    super(config, http);
  }

  /** Path part for operation `apiMasterCcrhmCapaTemplateAllGet()` */
  static readonly ApiMasterCcrhmCapaTemplateAllGetPath = '/api/master/ccrhm/capa-template/all';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `apiMasterCcrhmCapaTemplateAllGet()` instead.
   *
   * This method doesn't expect any request body.
   */
  apiMasterCcrhmCapaTemplateAllGet$Response(
    params?: {
      workGroupIds?: Array<number>;
    },
    context?: HttpContext
  ): Observable<StrictHttpResponse<Array<MasterCapaTemplateRowItemRes>>> {
    const rb = new RequestBuilder(this.rootUrl, MasterCapaTemplateService.ApiMasterCcrhmCapaTemplateAllGetPath, 'get');
    if (params) {
      rb.query('workGroupIds', params.workGroupIds, {});
    }

    return this.http.request(
      rb.build({ responseType: 'json', accept: 'application/json', context })
    ).pipe(
      filter((r: any): r is HttpResponse<any> => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<Array<MasterCapaTemplateRowItemRes>>;
      })
    );
  }

  /**
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `apiMasterCcrhmCapaTemplateAllGet$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  apiMasterCcrhmCapaTemplateAllGet(
    params?: {
      workGroupIds?: Array<number>;
    },
    context?: HttpContext
  ): Observable<Array<MasterCapaTemplateRowItemRes>> {
    return this.apiMasterCcrhmCapaTemplateAllGet$Response(params, context).pipe(
      map((r: StrictHttpResponse<Array<MasterCapaTemplateRowItemRes>>): Array<MasterCapaTemplateRowItemRes> => r.body)
    );
  }

  /** Path part for operation `apiMasterCcrhmCapaTemplateGet()` */
  static readonly ApiMasterCcrhmCapaTemplateGetPath = '/api/master/ccrhm/capa-template';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `apiMasterCcrhmCapaTemplateGet()` instead.
   *
   * This method doesn't expect any request body.
   */
  apiMasterCcrhmCapaTemplateGet$Response(
    params?: {
      id?: string;
    },
    context?: HttpContext
  ): Observable<StrictHttpResponse<MasterCapaTemplatEditItemDto>> {
    const rb = new RequestBuilder(this.rootUrl, MasterCapaTemplateService.ApiMasterCcrhmCapaTemplateGetPath, 'get');
    if (params) {
      rb.query('id', params.id, {});
    }

    return this.http.request(
      rb.build({ responseType: 'json', accept: 'application/json', context })
    ).pipe(
      filter((r: any): r is HttpResponse<any> => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<MasterCapaTemplatEditItemDto>;
      })
    );
  }

  /**
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `apiMasterCcrhmCapaTemplateGet$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  apiMasterCcrhmCapaTemplateGet(
    params?: {
      id?: string;
    },
    context?: HttpContext
  ): Observable<MasterCapaTemplatEditItemDto> {
    return this.apiMasterCcrhmCapaTemplateGet$Response(params, context).pipe(
      map((r: StrictHttpResponse<MasterCapaTemplatEditItemDto>): MasterCapaTemplatEditItemDto => r.body)
    );
  }

  /** Path part for operation `apiMasterCcrhmCapaTemplatePut()` */
  static readonly ApiMasterCcrhmCapaTemplatePutPath = '/api/master/ccrhm/capa-template';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `apiMasterCcrhmCapaTemplatePut()` instead.
   *
   * This method sends `application/json` and handles request body of type `application/json`.
   */
  apiMasterCcrhmCapaTemplatePut$Response(
    params?: {
      id?: string;
      body?: MasterCapaTemplatEditItemDto
    },
    context?: HttpContext
  ): Observable<StrictHttpResponse<void>> {
    const rb = new RequestBuilder(this.rootUrl, MasterCapaTemplateService.ApiMasterCcrhmCapaTemplatePutPath, 'put');
    if (params) {
      rb.query('id', params.id, {});
      rb.body(params.body, 'application/json');
    }

    return this.http.request(
      rb.build({ responseType: 'text', accept: '*/*', context })
    ).pipe(
      filter((r: any): r is HttpResponse<any> => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return (r as HttpResponse<any>).clone({ body: undefined }) as StrictHttpResponse<void>;
      })
    );
  }

  /**
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `apiMasterCcrhmCapaTemplatePut$Response()` instead.
   *
   * This method sends `application/json` and handles request body of type `application/json`.
   */
  apiMasterCcrhmCapaTemplatePut(
    params?: {
      id?: string;
      body?: MasterCapaTemplatEditItemDto
    },
    context?: HttpContext
  ): Observable<void> {
    return this.apiMasterCcrhmCapaTemplatePut$Response(params, context).pipe(
      map((r: StrictHttpResponse<void>): void => r.body)
    );
  }

  /** Path part for operation `apiMasterCcrhmCapaTemplatePost()` */
  static readonly ApiMasterCcrhmCapaTemplatePostPath = '/api/master/ccrhm/capa-template';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `apiMasterCcrhmCapaTemplatePost()` instead.
   *
   * This method sends `application/json` and handles request body of type `application/json`.
   */
  apiMasterCcrhmCapaTemplatePost$Response(
    params?: {
      body?: MasterCapaTemplatEditItemDto
    },
    context?: HttpContext
  ): Observable<StrictHttpResponse<void>> {
    const rb = new RequestBuilder(this.rootUrl, MasterCapaTemplateService.ApiMasterCcrhmCapaTemplatePostPath, 'post');
    if (params) {
      rb.body(params.body, 'application/json');
    }

    return this.http.request(
      rb.build({ responseType: 'text', accept: '*/*', context })
    ).pipe(
      filter((r: any): r is HttpResponse<any> => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return (r as HttpResponse<any>).clone({ body: undefined }) as StrictHttpResponse<void>;
      })
    );
  }

  /**
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `apiMasterCcrhmCapaTemplatePost$Response()` instead.
   *
   * This method sends `application/json` and handles request body of type `application/json`.
   */
  apiMasterCcrhmCapaTemplatePost(
    params?: {
      body?: MasterCapaTemplatEditItemDto
    },
    context?: HttpContext
  ): Observable<void> {
    return this.apiMasterCcrhmCapaTemplatePost$Response(params, context).pipe(
      map((r: StrictHttpResponse<void>): void => r.body)
    );
  }

  /** Path part for operation `apiMasterCcrhmCapaTemplateDelete()` */
  static readonly ApiMasterCcrhmCapaTemplateDeletePath = '/api/master/ccrhm/capa-template';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `apiMasterCcrhmCapaTemplateDelete()` instead.
   *
   * This method doesn't expect any request body.
   */
  apiMasterCcrhmCapaTemplateDelete$Response(
    params?: {
      id?: string;
    },
    context?: HttpContext
  ): Observable<StrictHttpResponse<void>> {
    const rb = new RequestBuilder(this.rootUrl, MasterCapaTemplateService.ApiMasterCcrhmCapaTemplateDeletePath, 'delete');
    if (params) {
      rb.query('id', params.id, {});
    }

    return this.http.request(
      rb.build({ responseType: 'text', accept: '*/*', context })
    ).pipe(
      filter((r: any): r is HttpResponse<any> => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return (r as HttpResponse<any>).clone({ body: undefined }) as StrictHttpResponse<void>;
      })
    );
  }

  /**
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `apiMasterCcrhmCapaTemplateDelete$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  apiMasterCcrhmCapaTemplateDelete(
    params?: {
      id?: string;
    },
    context?: HttpContext
  ): Observable<void> {
    return this.apiMasterCcrhmCapaTemplateDelete$Response(params, context).pipe(
      map((r: StrictHttpResponse<void>): void => r.body)
    );
  }

}
