/* tslint:disable */
/* eslint-disable */
import { HttpClient, HttpContext, HttpResponse } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { filter, map } from 'rxjs/operators';

import { BaseService } from '../base-service';
import { ApiConfiguration } from '../api-configuration';
import { StrictHttpResponse } from '../strict-http-response';
import { RequestBuilder } from '../request-builder';

import { CxAccountMigrateModel } from '../models/cx-account-migrate-model';
import { CxAccountMigrateVendorIdModel } from '../models/cx-account-migrate-vendor-id-model';

@Injectable({ providedIn: 'root' })
export class VendorMigrationService extends BaseService {
  constructor(config: ApiConfiguration, http: HttpClient) {
    super(config, http);
  }

  /** Path part for operation `apiVendorMigrationVendorPost()` */
  static readonly ApiVendorMigrationVendorPostPath = '/api/vendor-migration/Vendor';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `apiVendorMigrationVendorPost()` instead.
   *
   * This method sends `multipart/form-data` and handles request body of type `multipart/form-data`.
   */
  apiVendorMigrationVendorPost$Response(
    params?: {
      body?: {
'file'?: Blob;
'ActionUser'?: string;
'EmailTo'?: string;
'Secret'?: string;
}
    },
    context?: HttpContext
  ): Observable<StrictHttpResponse<void>> {
    const rb = new RequestBuilder(this.rootUrl, VendorMigrationService.ApiVendorMigrationVendorPostPath, 'post');
    if (params) {
      rb.body(params.body, 'multipart/form-data');
    }

    return this.http.request(
      rb.build({ responseType: 'text', accept: '*/*', context })
    ).pipe(
      filter((r: any): r is HttpResponse<any> => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return (r as HttpResponse<any>).clone({ body: undefined }) as StrictHttpResponse<void>;
      })
    );
  }

  /**
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `apiVendorMigrationVendorPost$Response()` instead.
   *
   * This method sends `multipart/form-data` and handles request body of type `multipart/form-data`.
   */
  apiVendorMigrationVendorPost(
    params?: {
      body?: {
'file'?: Blob;
'ActionUser'?: string;
'EmailTo'?: string;
'Secret'?: string;
}
    },
    context?: HttpContext
  ): Observable<void> {
    return this.apiVendorMigrationVendorPost$Response(params, context).pipe(
      map((r: StrictHttpResponse<void>): void => r.body)
    );
  }

  /** Path part for operation `apiVendorMigrationCxAccountPost()` */
  static readonly ApiVendorMigrationCxAccountPostPath = '/api/vendor-migration/CXAccount';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `apiVendorMigrationCxAccountPost()` instead.
   *
   * This method sends `application/json` and handles request body of type `application/json`.
   */
  apiVendorMigrationCxAccountPost$Response(
    params?: {
      body?: CxAccountMigrateModel
    },
    context?: HttpContext
  ): Observable<StrictHttpResponse<void>> {
    const rb = new RequestBuilder(this.rootUrl, VendorMigrationService.ApiVendorMigrationCxAccountPostPath, 'post');
    if (params) {
      rb.body(params.body, 'application/json');
    }

    return this.http.request(
      rb.build({ responseType: 'text', accept: '*/*', context })
    ).pipe(
      filter((r: any): r is HttpResponse<any> => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return (r as HttpResponse<any>).clone({ body: undefined }) as StrictHttpResponse<void>;
      })
    );
  }

  /**
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `apiVendorMigrationCxAccountPost$Response()` instead.
   *
   * This method sends `application/json` and handles request body of type `application/json`.
   */
  apiVendorMigrationCxAccountPost(
    params?: {
      body?: CxAccountMigrateModel
    },
    context?: HttpContext
  ): Observable<void> {
    return this.apiVendorMigrationCxAccountPost$Response(params, context).pipe(
      map((r: StrictHttpResponse<void>): void => r.body)
    );
  }

  /** Path part for operation `apiVendorMigrationCxAccountVendorIdPost()` */
  static readonly ApiVendorMigrationCxAccountVendorIdPostPath = '/api/vendor-migration/CXAccount/vendorId';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `apiVendorMigrationCxAccountVendorIdPost()` instead.
   *
   * This method sends `application/json` and handles request body of type `application/json`.
   */
  apiVendorMigrationCxAccountVendorIdPost$Response(
    params?: {
      body?: CxAccountMigrateVendorIdModel
    },
    context?: HttpContext
  ): Observable<StrictHttpResponse<void>> {
    const rb = new RequestBuilder(this.rootUrl, VendorMigrationService.ApiVendorMigrationCxAccountVendorIdPostPath, 'post');
    if (params) {
      rb.body(params.body, 'application/json');
    }

    return this.http.request(
      rb.build({ responseType: 'text', accept: '*/*', context })
    ).pipe(
      filter((r: any): r is HttpResponse<any> => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return (r as HttpResponse<any>).clone({ body: undefined }) as StrictHttpResponse<void>;
      })
    );
  }

  /**
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `apiVendorMigrationCxAccountVendorIdPost$Response()` instead.
   *
   * This method sends `application/json` and handles request body of type `application/json`.
   */
  apiVendorMigrationCxAccountVendorIdPost(
    params?: {
      body?: CxAccountMigrateVendorIdModel
    },
    context?: HttpContext
  ): Observable<void> {
    return this.apiVendorMigrationCxAccountVendorIdPost$Response(params, context).pipe(
      map((r: StrictHttpResponse<void>): void => r.body)
    );
  }

}
