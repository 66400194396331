/* tslint:disable */
/* eslint-disable */
import { HttpClient, HttpContext, HttpResponse } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { filter, map } from 'rxjs/operators';

import { BaseService } from '../base-service';
import { ApiConfiguration } from '../api-configuration';
import { StrictHttpResponse } from '../strict-http-response';
import { RequestBuilder } from '../request-builder';

import { ContentDto } from '../models/content-dto';

@Injectable({ providedIn: 'root' })
export class CtMgrAppContentService extends BaseService {
  constructor(config: ApiConfiguration, http: HttpClient) {
    super(config, http);
  }

  /** Path part for operation `apiContentMgrAppContentCodeOfConductGet()` */
  static readonly ApiContentMgrAppContentCodeOfConductGetPath = '/api/content-mgr/app-content/code-of-conduct';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `apiContentMgrAppContentCodeOfConductGet()` instead.
   *
   * This method doesn't expect any request body.
   */
  apiContentMgrAppContentCodeOfConductGet$Response(
    params?: {
      FKWorkGroupIds?: Array<number>;
    },
    context?: HttpContext
  ): Observable<StrictHttpResponse<ContentDto>> {
    const rb = new RequestBuilder(this.rootUrl, CtMgrAppContentService.ApiContentMgrAppContentCodeOfConductGetPath, 'get');
    if (params) {
      rb.query('FKWorkGroupIds', params.FKWorkGroupIds, {});
    }

    return this.http.request(
      rb.build({ responseType: 'json', accept: 'application/json', context })
    ).pipe(
      filter((r: any): r is HttpResponse<any> => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<ContentDto>;
      })
    );
  }

  /**
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `apiContentMgrAppContentCodeOfConductGet$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  apiContentMgrAppContentCodeOfConductGet(
    params?: {
      FKWorkGroupIds?: Array<number>;
    },
    context?: HttpContext
  ): Observable<ContentDto> {
    return this.apiContentMgrAppContentCodeOfConductGet$Response(params, context).pipe(
      map((r: StrictHttpResponse<ContentDto>): ContentDto => r.body)
    );
  }

  /** Path part for operation `apiContentMgrAppContentCodeOfConductPost()` */
  static readonly ApiContentMgrAppContentCodeOfConductPostPath = '/api/content-mgr/app-content/code-of-conduct';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `apiContentMgrAppContentCodeOfConductPost()` instead.
   *
   * This method sends `application/json` and handles request body of type `application/json`.
   */
  apiContentMgrAppContentCodeOfConductPost$Response(
    params?: {
      body?: ContentDto
    },
    context?: HttpContext
  ): Observable<StrictHttpResponse<void>> {
    const rb = new RequestBuilder(this.rootUrl, CtMgrAppContentService.ApiContentMgrAppContentCodeOfConductPostPath, 'post');
    if (params) {
      rb.body(params.body, 'application/json');
    }

    return this.http.request(
      rb.build({ responseType: 'text', accept: '*/*', context })
    ).pipe(
      filter((r: any): r is HttpResponse<any> => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return (r as HttpResponse<any>).clone({ body: undefined }) as StrictHttpResponse<void>;
      })
    );
  }

  /**
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `apiContentMgrAppContentCodeOfConductPost$Response()` instead.
   *
   * This method sends `application/json` and handles request body of type `application/json`.
   */
  apiContentMgrAppContentCodeOfConductPost(
    params?: {
      body?: ContentDto
    },
    context?: HttpContext
  ): Observable<void> {
    return this.apiContentMgrAppContentCodeOfConductPost$Response(params, context).pipe(
      map((r: StrictHttpResponse<void>): void => r.body)
    );
  }

  /** Path part for operation `apiContentMgrAppContentTermAndConditionGet()` */
  static readonly ApiContentMgrAppContentTermAndConditionGetPath = '/api/content-mgr/app-content/term-and-condition';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `apiContentMgrAppContentTermAndConditionGet()` instead.
   *
   * This method doesn't expect any request body.
   */
  apiContentMgrAppContentTermAndConditionGet$Response(
    params?: {
      FKWorkGroupIds?: Array<number>;
    },
    context?: HttpContext
  ): Observable<StrictHttpResponse<ContentDto>> {
    const rb = new RequestBuilder(this.rootUrl, CtMgrAppContentService.ApiContentMgrAppContentTermAndConditionGetPath, 'get');
    if (params) {
      rb.query('FKWorkGroupIds', params.FKWorkGroupIds, {});
    }

    return this.http.request(
      rb.build({ responseType: 'json', accept: 'application/json', context })
    ).pipe(
      filter((r: any): r is HttpResponse<any> => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<ContentDto>;
      })
    );
  }

  /**
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `apiContentMgrAppContentTermAndConditionGet$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  apiContentMgrAppContentTermAndConditionGet(
    params?: {
      FKWorkGroupIds?: Array<number>;
    },
    context?: HttpContext
  ): Observable<ContentDto> {
    return this.apiContentMgrAppContentTermAndConditionGet$Response(params, context).pipe(
      map((r: StrictHttpResponse<ContentDto>): ContentDto => r.body)
    );
  }

  /** Path part for operation `apiContentMgrAppContentTermAndConditionPost()` */
  static readonly ApiContentMgrAppContentTermAndConditionPostPath = '/api/content-mgr/app-content/term-and-condition';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `apiContentMgrAppContentTermAndConditionPost()` instead.
   *
   * This method sends `application/json` and handles request body of type `application/json`.
   */
  apiContentMgrAppContentTermAndConditionPost$Response(
    params?: {
      body?: ContentDto
    },
    context?: HttpContext
  ): Observable<StrictHttpResponse<void>> {
    const rb = new RequestBuilder(this.rootUrl, CtMgrAppContentService.ApiContentMgrAppContentTermAndConditionPostPath, 'post');
    if (params) {
      rb.body(params.body, 'application/json');
    }

    return this.http.request(
      rb.build({ responseType: 'text', accept: '*/*', context })
    ).pipe(
      filter((r: any): r is HttpResponse<any> => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return (r as HttpResponse<any>).clone({ body: undefined }) as StrictHttpResponse<void>;
      })
    );
  }

  /**
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `apiContentMgrAppContentTermAndConditionPost$Response()` instead.
   *
   * This method sends `application/json` and handles request body of type `application/json`.
   */
  apiContentMgrAppContentTermAndConditionPost(
    params?: {
      body?: ContentDto
    },
    context?: HttpContext
  ): Observable<void> {
    return this.apiContentMgrAppContentTermAndConditionPost$Response(params, context).pipe(
      map((r: StrictHttpResponse<void>): void => r.body)
    );
  }

  /** Path part for operation `apiContentMgrAppContentLifeSavingRuleGet()` */
  static readonly ApiContentMgrAppContentLifeSavingRuleGetPath = '/api/content-mgr/app-content/life-saving-rule';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `apiContentMgrAppContentLifeSavingRuleGet()` instead.
   *
   * This method doesn't expect any request body.
   */
  apiContentMgrAppContentLifeSavingRuleGet$Response(
    params?: {
      FKWorkGroupIds?: Array<number>;
    },
    context?: HttpContext
  ): Observable<StrictHttpResponse<ContentDto>> {
    const rb = new RequestBuilder(this.rootUrl, CtMgrAppContentService.ApiContentMgrAppContentLifeSavingRuleGetPath, 'get');
    if (params) {
      rb.query('FKWorkGroupIds', params.FKWorkGroupIds, {});
    }

    return this.http.request(
      rb.build({ responseType: 'json', accept: 'application/json', context })
    ).pipe(
      filter((r: any): r is HttpResponse<any> => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<ContentDto>;
      })
    );
  }

  /**
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `apiContentMgrAppContentLifeSavingRuleGet$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  apiContentMgrAppContentLifeSavingRuleGet(
    params?: {
      FKWorkGroupIds?: Array<number>;
    },
    context?: HttpContext
  ): Observable<ContentDto> {
    return this.apiContentMgrAppContentLifeSavingRuleGet$Response(params, context).pipe(
      map((r: StrictHttpResponse<ContentDto>): ContentDto => r.body)
    );
  }

  /** Path part for operation `apiContentMgrAppContentLifeSavingRulePost()` */
  static readonly ApiContentMgrAppContentLifeSavingRulePostPath = '/api/content-mgr/app-content/life-saving-rule';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `apiContentMgrAppContentLifeSavingRulePost()` instead.
   *
   * This method sends `application/json` and handles request body of type `application/json`.
   */
  apiContentMgrAppContentLifeSavingRulePost$Response(
    params?: {
      body?: ContentDto
    },
    context?: HttpContext
  ): Observable<StrictHttpResponse<void>> {
    const rb = new RequestBuilder(this.rootUrl, CtMgrAppContentService.ApiContentMgrAppContentLifeSavingRulePostPath, 'post');
    if (params) {
      rb.body(params.body, 'application/json');
    }

    return this.http.request(
      rb.build({ responseType: 'text', accept: '*/*', context })
    ).pipe(
      filter((r: any): r is HttpResponse<any> => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return (r as HttpResponse<any>).clone({ body: undefined }) as StrictHttpResponse<void>;
      })
    );
  }

  /**
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `apiContentMgrAppContentLifeSavingRulePost$Response()` instead.
   *
   * This method sends `application/json` and handles request body of type `application/json`.
   */
  apiContentMgrAppContentLifeSavingRulePost(
    params?: {
      body?: ContentDto
    },
    context?: HttpContext
  ): Observable<void> {
    return this.apiContentMgrAppContentLifeSavingRulePost$Response(params, context).pipe(
      map((r: StrictHttpResponse<void>): void => r.body)
    );
  }

  /** Path part for operation `apiContentMgrAppContentPolicyGet()` */
  static readonly ApiContentMgrAppContentPolicyGetPath = '/api/content-mgr/app-content/policy';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `apiContentMgrAppContentPolicyGet()` instead.
   *
   * This method doesn't expect any request body.
   */
  apiContentMgrAppContentPolicyGet$Response(
    params?: {
      FKWorkGroupIds?: Array<number>;
    },
    context?: HttpContext
  ): Observable<StrictHttpResponse<ContentDto>> {
    const rb = new RequestBuilder(this.rootUrl, CtMgrAppContentService.ApiContentMgrAppContentPolicyGetPath, 'get');
    if (params) {
      rb.query('FKWorkGroupIds', params.FKWorkGroupIds, {});
    }

    return this.http.request(
      rb.build({ responseType: 'json', accept: 'application/json', context })
    ).pipe(
      filter((r: any): r is HttpResponse<any> => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<ContentDto>;
      })
    );
  }

  /**
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `apiContentMgrAppContentPolicyGet$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  apiContentMgrAppContentPolicyGet(
    params?: {
      FKWorkGroupIds?: Array<number>;
    },
    context?: HttpContext
  ): Observable<ContentDto> {
    return this.apiContentMgrAppContentPolicyGet$Response(params, context).pipe(
      map((r: StrictHttpResponse<ContentDto>): ContentDto => r.body)
    );
  }

  /** Path part for operation `apiContentMgrAppContentPolicyPost()` */
  static readonly ApiContentMgrAppContentPolicyPostPath = '/api/content-mgr/app-content/policy';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `apiContentMgrAppContentPolicyPost()` instead.
   *
   * This method sends `application/json` and handles request body of type `application/json`.
   */
  apiContentMgrAppContentPolicyPost$Response(
    params?: {
      body?: ContentDto
    },
    context?: HttpContext
  ): Observable<StrictHttpResponse<void>> {
    const rb = new RequestBuilder(this.rootUrl, CtMgrAppContentService.ApiContentMgrAppContentPolicyPostPath, 'post');
    if (params) {
      rb.body(params.body, 'application/json');
    }

    return this.http.request(
      rb.build({ responseType: 'text', accept: '*/*', context })
    ).pipe(
      filter((r: any): r is HttpResponse<any> => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return (r as HttpResponse<any>).clone({ body: undefined }) as StrictHttpResponse<void>;
      })
    );
  }

  /**
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `apiContentMgrAppContentPolicyPost$Response()` instead.
   *
   * This method sends `application/json` and handles request body of type `application/json`.
   */
  apiContentMgrAppContentPolicyPost(
    params?: {
      body?: ContentDto
    },
    context?: HttpContext
  ): Observable<void> {
    return this.apiContentMgrAppContentPolicyPost$Response(params, context).pipe(
      map((r: StrictHttpResponse<void>): void => r.body)
    );
  }

}
