import { HttpEvent, HttpHandler, HttpInterceptor, HttpRequest } from "@angular/common/http";
import { Injectable } from "@angular/core";
import { Observable } from "rxjs";
import { finalize } from "rxjs/operators";
import { v4 as uuidv4 } from 'uuid';
import { environment } from "../../environments/environment";
import { includes, replace } from "lodash";
import { SpinnerService } from "./services/spinner.service";

@Injectable()
export class SpinInterceptor implements HttpInterceptor {

  private ignoreUrls: string[] = [
    "/assets/i18n/th.json",
    "/api/ccr/request/opt/requester/startwith",
    "/api/ccr/request/opt/mgr/startwith",
    "/api/ccr/request/opt/buyer/startwith",
    "/api/ccr/request/opt/vendor/contain",
    "/api/ccr/request/opt/unspsc/contain",
    "/api/ccr/request/opt/pic/startwith",
    "/api/vendorprofile-searching/opt-data/unspsc/contain",
    "/api/health-check/worksheet/opt-data/requester/start-with",
    "/api/health-check/worksheet/opt-data/user/start-with",
    "/api/health-check/worksheet/opt-data/sourcing/start-with",
    "/api/health-check/worksheet/opt-data/approver/start-with",
    "/api/health-check/worksheet/opt-data/vendor/search",
    "/api/health-check/worksheet/opt-data/unspsc/search",
    "/api/sustainable/worksheet/opt-data/requester/start-with",
    "/api/sustainable/worksheet/opt-data/vm-admin/start-with",
    "/api/sustainable/worksheet/opt-data/vendor/search",
    "/api/sustainable/worksheet/opt-data/unspsc/search",
    "/api/sustainable/worksheet/",
    "/api/audit/opt-data/requester/start-with",
    "/api/audit/opt-data/vm-admin/start-with",
    "/api/audit/opt-data/vendor/search",
    "/api/audit/opt-data/approver/start-with",
    "/api/audit/opt-data/member/start-with",
  ];

  constructor(private spin: SpinnerService) { }

  intercept(request: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {
    let uuid: string | null = null;
    const url = replace(request.url, environment.base_url, "");
    const ignore = includes(this.ignoreUrls, url);
    if (!ignore) {
      uuid = uuidv4();
      this.spin.add(uuid);
    }

    return next.handle(request).pipe(
      finalize(() => {
        if (!ignore && uuid) {
          this.spin.stop(uuid);
        }
      })
    );
  }
}
