/* tslint:disable */
/* eslint-disable */
import { HttpClient, HttpContext, HttpResponse } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { filter, map } from 'rxjs/operators';

import { BaseService } from '../base-service';
import { ApiConfiguration } from '../api-configuration';
import { StrictHttpResponse } from '../strict-http-response';
import { RequestBuilder } from '../request-builder';

import { CiRoleCollectionOptDataRes } from '../models/ci-role-collection-opt-data-res';
import { CiRoleGroupDto } from '../models/ci-role-group-dto';
import { CiRoleGroupListRes } from '../models/ci-role-group-list-res';
import { RoleByUserRes } from '../models/role-by-user-res';
import { UserByRoleRes } from '../models/user-by-role-res';

@Injectable({ providedIn: 'root' })
export class RoleService extends BaseService {
  constructor(config: ApiConfiguration, http: HttpClient) {
    super(config, http);
  }

  /** Path part for operation `apiRoleAllGet()` */
  static readonly ApiRoleAllGetPath = '/api/role/all';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `apiRoleAllGet()` instead.
   *
   * This method doesn't expect any request body.
   */
  apiRoleAllGet$Response(
    params?: {
      workGroupIds?: Array<number>;
    },
    context?: HttpContext
  ): Observable<StrictHttpResponse<Array<CiRoleGroupListRes>>> {
    const rb = new RequestBuilder(this.rootUrl, RoleService.ApiRoleAllGetPath, 'get');
    if (params) {
      rb.query('workGroupIds', params.workGroupIds, {});
    }

    return this.http.request(
      rb.build({ responseType: 'json', accept: 'application/json', context })
    ).pipe(
      filter((r: any): r is HttpResponse<any> => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<Array<CiRoleGroupListRes>>;
      })
    );
  }

  /**
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `apiRoleAllGet$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  apiRoleAllGet(
    params?: {
      workGroupIds?: Array<number>;
    },
    context?: HttpContext
  ): Observable<Array<CiRoleGroupListRes>> {
    return this.apiRoleAllGet$Response(params, context).pipe(
      map((r: StrictHttpResponse<Array<CiRoleGroupListRes>>): Array<CiRoleGroupListRes> => r.body)
    );
  }

  /** Path part for operation `apiRoleGet()` */
  static readonly ApiRoleGetPath = '/api/role';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `apiRoleGet()` instead.
   *
   * This method doesn't expect any request body.
   */
  apiRoleGet$Response(
    params?: {
      roleGroupId?: string;
    },
    context?: HttpContext
  ): Observable<StrictHttpResponse<CiRoleGroupDto>> {
    const rb = new RequestBuilder(this.rootUrl, RoleService.ApiRoleGetPath, 'get');
    if (params) {
      rb.query('roleGroupId', params.roleGroupId, {});
    }

    return this.http.request(
      rb.build({ responseType: 'json', accept: 'application/json', context })
    ).pipe(
      filter((r: any): r is HttpResponse<any> => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<CiRoleGroupDto>;
      })
    );
  }

  /**
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `apiRoleGet$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  apiRoleGet(
    params?: {
      roleGroupId?: string;
    },
    context?: HttpContext
  ): Observable<CiRoleGroupDto> {
    return this.apiRoleGet$Response(params, context).pipe(
      map((r: StrictHttpResponse<CiRoleGroupDto>): CiRoleGroupDto => r.body)
    );
  }

  /** Path part for operation `apiRolePut()` */
  static readonly ApiRolePutPath = '/api/role';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `apiRolePut()` instead.
   *
   * This method sends `application/json` and handles request body of type `application/json`.
   */
  apiRolePut$Response(
    params?: {
      id?: string;
      body?: CiRoleGroupDto
    },
    context?: HttpContext
  ): Observable<StrictHttpResponse<void>> {
    const rb = new RequestBuilder(this.rootUrl, RoleService.ApiRolePutPath, 'put');
    if (params) {
      rb.query('id', params.id, {});
      rb.body(params.body, 'application/json');
    }

    return this.http.request(
      rb.build({ responseType: 'text', accept: '*/*', context })
    ).pipe(
      filter((r: any): r is HttpResponse<any> => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return (r as HttpResponse<any>).clone({ body: undefined }) as StrictHttpResponse<void>;
      })
    );
  }

  /**
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `apiRolePut$Response()` instead.
   *
   * This method sends `application/json` and handles request body of type `application/json`.
   */
  apiRolePut(
    params?: {
      id?: string;
      body?: CiRoleGroupDto
    },
    context?: HttpContext
  ): Observable<void> {
    return this.apiRolePut$Response(params, context).pipe(
      map((r: StrictHttpResponse<void>): void => r.body)
    );
  }

  /** Path part for operation `apiRolePost()` */
  static readonly ApiRolePostPath = '/api/role';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `apiRolePost()` instead.
   *
   * This method sends `application/json` and handles request body of type `application/json`.
   */
  apiRolePost$Response(
    params?: {
      body?: CiRoleGroupDto
    },
    context?: HttpContext
  ): Observable<StrictHttpResponse<string>> {
    const rb = new RequestBuilder(this.rootUrl, RoleService.ApiRolePostPath, 'post');
    if (params) {
      rb.body(params.body, 'application/json');
    }

    return this.http.request(
      rb.build({ responseType: 'json', accept: 'application/json', context })
    ).pipe(
      filter((r: any): r is HttpResponse<any> => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<string>;
      })
    );
  }

  /**
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `apiRolePost$Response()` instead.
   *
   * This method sends `application/json` and handles request body of type `application/json`.
   */
  apiRolePost(
    params?: {
      body?: CiRoleGroupDto
    },
    context?: HttpContext
  ): Observable<string> {
    return this.apiRolePost$Response(params, context).pipe(
      map((r: StrictHttpResponse<string>): string => r.body)
    );
  }

  /** Path part for operation `apiRoleDelete()` */
  static readonly ApiRoleDeletePath = '/api/role';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `apiRoleDelete()` instead.
   *
   * This method doesn't expect any request body.
   */
  apiRoleDelete$Response(
    params?: {
      id?: string;
    },
    context?: HttpContext
  ): Observable<StrictHttpResponse<void>> {
    const rb = new RequestBuilder(this.rootUrl, RoleService.ApiRoleDeletePath, 'delete');
    if (params) {
      rb.query('id', params.id, {});
    }

    return this.http.request(
      rb.build({ responseType: 'text', accept: '*/*', context })
    ).pipe(
      filter((r: any): r is HttpResponse<any> => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return (r as HttpResponse<any>).clone({ body: undefined }) as StrictHttpResponse<void>;
      })
    );
  }

  /**
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `apiRoleDelete$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  apiRoleDelete(
    params?: {
      id?: string;
    },
    context?: HttpContext
  ): Observable<void> {
    return this.apiRoleDelete$Response(params, context).pipe(
      map((r: StrictHttpResponse<void>): void => r.body)
    );
  }

  /** Path part for operation `apiRoleOptRoleCollectionsGet()` */
  static readonly ApiRoleOptRoleCollectionsGetPath = '/api/role/opt/role-collections';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `apiRoleOptRoleCollectionsGet()` instead.
   *
   * This method doesn't expect any request body.
   */
  apiRoleOptRoleCollectionsGet$Response(
    params?: {
    },
    context?: HttpContext
  ): Observable<StrictHttpResponse<Array<CiRoleCollectionOptDataRes>>> {
    const rb = new RequestBuilder(this.rootUrl, RoleService.ApiRoleOptRoleCollectionsGetPath, 'get');
    if (params) {
    }

    return this.http.request(
      rb.build({ responseType: 'json', accept: 'application/json', context })
    ).pipe(
      filter((r: any): r is HttpResponse<any> => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<Array<CiRoleCollectionOptDataRes>>;
      })
    );
  }

  /**
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `apiRoleOptRoleCollectionsGet$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  apiRoleOptRoleCollectionsGet(
    params?: {
    },
    context?: HttpContext
  ): Observable<Array<CiRoleCollectionOptDataRes>> {
    return this.apiRoleOptRoleCollectionsGet$Response(params, context).pipe(
      map((r: StrictHttpResponse<Array<CiRoleCollectionOptDataRes>>): Array<CiRoleCollectionOptDataRes> => r.body)
    );
  }

  /** Path part for operation `apiRoleCheckUserByRolePost()` */
  static readonly ApiRoleCheckUserByRolePostPath = '/api/role/check-user-by-role';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `apiRoleCheckUserByRolePost()` instead.
   *
   * This method doesn't expect any request body.
   */
  apiRoleCheckUserByRolePost$Response(
    params?: {
      roleCode?: string;
      workGroupIds?: Array<number>;
    },
    context?: HttpContext
  ): Observable<StrictHttpResponse<Array<UserByRoleRes>>> {
    const rb = new RequestBuilder(this.rootUrl, RoleService.ApiRoleCheckUserByRolePostPath, 'post');
    if (params) {
      rb.query('roleCode', params.roleCode, {});
      rb.query('workGroupIds', params.workGroupIds, {});
    }

    return this.http.request(
      rb.build({ responseType: 'json', accept: 'application/json', context })
    ).pipe(
      filter((r: any): r is HttpResponse<any> => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<Array<UserByRoleRes>>;
      })
    );
  }

  /**
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `apiRoleCheckUserByRolePost$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  apiRoleCheckUserByRolePost(
    params?: {
      roleCode?: string;
      workGroupIds?: Array<number>;
    },
    context?: HttpContext
  ): Observable<Array<UserByRoleRes>> {
    return this.apiRoleCheckUserByRolePost$Response(params, context).pipe(
      map((r: StrictHttpResponse<Array<UserByRoleRes>>): Array<UserByRoleRes> => r.body)
    );
  }

  /** Path part for operation `apiRoleCheckRoleByUserPost()` */
  static readonly ApiRoleCheckRoleByUserPostPath = '/api/role/check-role-by-user';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `apiRoleCheckRoleByUserPost()` instead.
   *
   * This method doesn't expect any request body.
   */
  apiRoleCheckRoleByUserPost$Response(
    params?: {
      email?: string;
    },
    context?: HttpContext
  ): Observable<StrictHttpResponse<Array<RoleByUserRes>>> {
    const rb = new RequestBuilder(this.rootUrl, RoleService.ApiRoleCheckRoleByUserPostPath, 'post');
    if (params) {
      rb.query('email', params.email, {});
    }

    return this.http.request(
      rb.build({ responseType: 'json', accept: 'application/json', context })
    ).pipe(
      filter((r: any): r is HttpResponse<any> => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<Array<RoleByUserRes>>;
      })
    );
  }

  /**
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `apiRoleCheckRoleByUserPost$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  apiRoleCheckRoleByUserPost(
    params?: {
      email?: string;
    },
    context?: HttpContext
  ): Observable<Array<RoleByUserRes>> {
    return this.apiRoleCheckRoleByUserPost$Response(params, context).pipe(
      map((r: StrictHttpResponse<Array<RoleByUserRes>>): Array<RoleByUserRes> => r.body)
    );
  }

}
