/* tslint:disable */
/* eslint-disable */
import { NgModule, ModuleWithProviders, SkipSelf, Optional } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { ApiConfiguration, ApiConfigurationParams } from './api-configuration';

import { AccountService } from './services/account.service';
import { AnnouncementService } from './services/announcement.service';
import { AppService } from './services/app.service';
import { AuditDashboardService } from './services/audit-dashboard.service';
import { AuditExportDocumentService } from './services/audit-export-document.service';
import { AuditWorksheetService } from './services/audit-worksheet.service';
import { CcrRequestService } from './services/ccr-request.service';
import { CcrRequestFileService } from './services/ccr-request-file.service';
import { CcrWorklistService } from './services/ccr-worklist.service';
import { CompanyService } from './services/company.service';
import { CtMgrAnnouncementService } from './services/ct-mgr-announcement.service';
import { CtMgrAppContentService } from './services/ct-mgr-app-content.service';
import { CtMgrContactService } from './services/ct-mgr-contact.service';
import { CtMgrEventService } from './services/ct-mgr-event.service';
import { CtMgrFaqService } from './services/ct-mgr-faq.service';
import { EmailTemplateService } from './services/email-template.service';
import { EmployeeService } from './services/employee.service';
import { FaqService } from './services/faq.service';
import { FileService } from './services/file.service';
import { FileApiService } from './services/file-api.service';
import { HealthCheckDashboardService } from './services/health-check-dashboard.service';
import { HealthCheckExportDocumentService } from './services/health-check-export-document.service';
import { HealthCheckWorksheetService } from './services/health-check-worksheet.service';
import { HomeService } from './services/home.service';
import { InboxService } from './services/inbox.service';
import { ManualRequestService } from './services/manual-request.service';
import { ManualRequestExportService } from './services/manual-request-export.service';
import { ManualRequestSearchingService } from './services/manual-request-searching.service';
import { MasterBankService } from './services/master-bank.service';
import { MasterCapaTemplateService } from './services/master-capa-template.service';
import { MasterCategorySeverityService } from './services/master-category-severity.service';
import { MasterCcrhmReasonService } from './services/master-ccrhm-reason.service';
import { MasterCriteriaService } from './services/master-criteria.service';
import { MasterCustomsOfficerService } from './services/master-customs-officer.service';
import { MasterFormService } from './services/master-form.service';
import { MasterGreenIndustryCompanyService } from './services/master-green-industry-company.service';
import { MasterModuleTemplateService } from './services/master-module-template.service';
import { MasterStatementTemplateService } from './services/master-statement-template.service';
import { MasterVendorService } from './services/master-vendor.service';
import { MasterVendorPortfolioKeeperService } from './services/master-vendor-portfolio-keeper.service';
import { MasterVocTopicService } from './services/master-voc-topic.service';
import { MasterVocTopicSubService } from './services/master-voc-topic-sub.service';
import { PicService } from './services/pic.service';
import { PostEvaluationService } from './services/post-evaluation.service';
import { PostEvaluationDashboardService } from './services/post-evaluation-dashboard.service';
import { PostEvaluationReportService } from './services/post-evaluation-report.service';
import { PostEvaluationTaskService } from './services/post-evaluation-task.service';
import { RoleService } from './services/role.service';
import { ServerService } from './services/server.service';
import { SustainableDashboardService } from './services/sustainable-dashboard.service';
import { SustainableWorksheetService } from './services/sustainable-worksheet.service';
import { VendorMigrationService } from './services/vendor-migration.service';
import { VendorProfileService } from './services/vendor-profile.service';
import { VendorProfileSearchingService } from './services/vendor-profile-searching.service';
import { VocRequestService } from './services/voc-request.service';
import { VocWorklistService } from './services/voc-worklist.service';

/**
 * Module that provides all services and configuration.
 */
@NgModule({
  imports: [],
  exports: [],
  declarations: [],
  providers: [
    AccountService,
    AnnouncementService,
    AppService,
    AuditDashboardService,
    AuditExportDocumentService,
    AuditWorksheetService,
    CcrRequestService,
    CcrRequestFileService,
    CcrWorklistService,
    CompanyService,
    CtMgrAnnouncementService,
    CtMgrAppContentService,
    CtMgrContactService,
    CtMgrEventService,
    CtMgrFaqService,
    EmailTemplateService,
    EmployeeService,
    FaqService,
    FileService,
    FileApiService,
    HealthCheckDashboardService,
    HealthCheckExportDocumentService,
    HealthCheckWorksheetService,
    HomeService,
    InboxService,
    ManualRequestService,
    ManualRequestExportService,
    ManualRequestSearchingService,
    MasterBankService,
    MasterCapaTemplateService,
    MasterCategorySeverityService,
    MasterCcrhmReasonService,
    MasterCriteriaService,
    MasterCustomsOfficerService,
    MasterFormService,
    MasterGreenIndustryCompanyService,
    MasterModuleTemplateService,
    MasterStatementTemplateService,
    MasterVendorService,
    MasterVendorPortfolioKeeperService,
    MasterVocTopicService,
    MasterVocTopicSubService,
    PicService,
    PostEvaluationService,
    PostEvaluationDashboardService,
    PostEvaluationReportService,
    PostEvaluationTaskService,
    RoleService,
    ServerService,
    SustainableDashboardService,
    SustainableWorksheetService,
    VendorMigrationService,
    VendorProfileService,
    VendorProfileSearchingService,
    VocRequestService,
    VocWorklistService,
    ApiConfiguration
  ],
})
export class ApiModule {
  static forRoot(params: ApiConfigurationParams): ModuleWithProviders<ApiModule> {
    return {
      ngModule: ApiModule,
      providers: [
        {
          provide: ApiConfiguration,
          useValue: params
        }
      ]
    }
  }

  constructor( 
    @Optional() @SkipSelf() parentModule: ApiModule,
    @Optional() http: HttpClient
  ) {
    if (parentModule) {
      throw new Error('ApiModule is already loaded. Import in your base AppModule only.');
    }
    if (!http) {
      throw new Error('You need to import the HttpClientModule in your AppModule! \n' +
      'See also https://github.com/angular/angular/issues/20575');
    }
  }
}
