/* tslint:disable */
/* eslint-disable */
import { HttpClient, HttpContext, HttpResponse } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { filter, map } from 'rxjs/operators';

import { BaseService } from '../base-service';
import { ApiConfiguration } from '../api-configuration';
import { StrictHttpResponse } from '../strict-http-response';
import { RequestBuilder } from '../request-builder';

import { ExportExcelFileRes } from '../models/export-excel-file-res';
import { MasterCategoryAndSeverityResItem } from '../models/master-category-and-severity-res-item';
import { StbDashboardDropdownItem } from '../models/stb-dashboard-dropdown-item';
import { StbDashboardQueryReq } from '../models/stb-dashboard-query-req';
import { StbDashboardResultRes } from '../models/stb-dashboard-result-res';

@Injectable({ providedIn: 'root' })
export class SustainableDashboardService extends BaseService {
  constructor(config: ApiConfiguration, http: HttpClient) {
    super(config, http);
  }

  /** Path part for operation `apiSustainableDashboardQueryPost()` */
  static readonly ApiSustainableDashboardQueryPostPath = '/api/sustainable/dashboard/query';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `apiSustainableDashboardQueryPost()` instead.
   *
   * This method sends `application/json` and handles request body of type `application/json`.
   */
  apiSustainableDashboardQueryPost$Response(
    params?: {
      body?: StbDashboardQueryReq
    },
    context?: HttpContext
  ): Observable<StrictHttpResponse<StbDashboardResultRes>> {
    const rb = new RequestBuilder(this.rootUrl, SustainableDashboardService.ApiSustainableDashboardQueryPostPath, 'post');
    if (params) {
      rb.body(params.body, 'application/json');
    }

    return this.http.request(
      rb.build({ responseType: 'json', accept: 'application/json', context })
    ).pipe(
      filter((r: any): r is HttpResponse<any> => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<StbDashboardResultRes>;
      })
    );
  }

  /**
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `apiSustainableDashboardQueryPost$Response()` instead.
   *
   * This method sends `application/json` and handles request body of type `application/json`.
   */
  apiSustainableDashboardQueryPost(
    params?: {
      body?: StbDashboardQueryReq
    },
    context?: HttpContext
  ): Observable<StbDashboardResultRes> {
    return this.apiSustainableDashboardQueryPost$Response(params, context).pipe(
      map((r: StrictHttpResponse<StbDashboardResultRes>): StbDashboardResultRes => r.body)
    );
  }

  /** Path part for operation `apiSustainableDashboardBookingJobPost()` */
  static readonly ApiSustainableDashboardBookingJobPostPath = '/api/sustainable/dashboard/booking-job';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `apiSustainableDashboardBookingJobPost()` instead.
   *
   * This method sends `application/json` and handles request body of type `application/json`.
   */
  apiSustainableDashboardBookingJobPost$Response(
    params?: {
      body?: Array<string>
    },
    context?: HttpContext
  ): Observable<StrictHttpResponse<void>> {
    const rb = new RequestBuilder(this.rootUrl, SustainableDashboardService.ApiSustainableDashboardBookingJobPostPath, 'post');
    if (params) {
      rb.body(params.body, 'application/json');
    }

    return this.http.request(
      rb.build({ responseType: 'text', accept: '*/*', context })
    ).pipe(
      filter((r: any): r is HttpResponse<any> => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return (r as HttpResponse<any>).clone({ body: undefined }) as StrictHttpResponse<void>;
      })
    );
  }

  /**
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `apiSustainableDashboardBookingJobPost$Response()` instead.
   *
   * This method sends `application/json` and handles request body of type `application/json`.
   */
  apiSustainableDashboardBookingJobPost(
    params?: {
      body?: Array<string>
    },
    context?: HttpContext
  ): Observable<void> {
    return this.apiSustainableDashboardBookingJobPost$Response(params, context).pipe(
      map((r: StrictHttpResponse<void>): void => r.body)
    );
  }

  /** Path part for operation `apiSustainableDashboardMasterCategorySeverityContainPost()` */
  static readonly ApiSustainableDashboardMasterCategorySeverityContainPostPath = '/api/sustainable/dashboard/master-category-severity-contain';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `apiSustainableDashboardMasterCategorySeverityContainPost()` instead.
   *
   * This method doesn't expect any request body.
   */
  apiSustainableDashboardMasterCategorySeverityContainPost$Response(
    params?: {
      containText?: string;
      max?: number;
      workGroupList?: Array<number>;
    },
    context?: HttpContext
  ): Observable<StrictHttpResponse<Array<MasterCategoryAndSeverityResItem>>> {
    const rb = new RequestBuilder(this.rootUrl, SustainableDashboardService.ApiSustainableDashboardMasterCategorySeverityContainPostPath, 'post');
    if (params) {
      rb.query('containText', params.containText, {});
      rb.query('max', params.max, {});
      rb.query('workGroupList', params.workGroupList, {});
    }

    return this.http.request(
      rb.build({ responseType: 'json', accept: 'application/json', context })
    ).pipe(
      filter((r: any): r is HttpResponse<any> => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<Array<MasterCategoryAndSeverityResItem>>;
      })
    );
  }

  /**
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `apiSustainableDashboardMasterCategorySeverityContainPost$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  apiSustainableDashboardMasterCategorySeverityContainPost(
    params?: {
      containText?: string;
      max?: number;
      workGroupList?: Array<number>;
    },
    context?: HttpContext
  ): Observable<Array<MasterCategoryAndSeverityResItem>> {
    return this.apiSustainableDashboardMasterCategorySeverityContainPost$Response(params, context).pipe(
      map((r: StrictHttpResponse<Array<MasterCategoryAndSeverityResItem>>): Array<MasterCategoryAndSeverityResItem> => r.body)
    );
  }

  /** Path part for operation `apiSustainableDashboardExportPost()` */
  static readonly ApiSustainableDashboardExportPostPath = '/api/sustainable/dashboard/export';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `apiSustainableDashboardExportPost()` instead.
   *
   * This method sends `application/json` and handles request body of type `application/json`.
   */
  apiSustainableDashboardExportPost$Response(
    params?: {
      body?: StbDashboardQueryReq
    },
    context?: HttpContext
  ): Observable<StrictHttpResponse<ExportExcelFileRes>> {
    const rb = new RequestBuilder(this.rootUrl, SustainableDashboardService.ApiSustainableDashboardExportPostPath, 'post');
    if (params) {
      rb.body(params.body, 'application/json');
    }

    return this.http.request(
      rb.build({ responseType: 'json', accept: 'application/json', context })
    ).pipe(
      filter((r: any): r is HttpResponse<any> => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<ExportExcelFileRes>;
      })
    );
  }

  /**
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `apiSustainableDashboardExportPost$Response()` instead.
   *
   * This method sends `application/json` and handles request body of type `application/json`.
   */
  apiSustainableDashboardExportPost(
    params?: {
      body?: StbDashboardQueryReq
    },
    context?: HttpContext
  ): Observable<ExportExcelFileRes> {
    return this.apiSustainableDashboardExportPost$Response(params, context).pipe(
      map((r: StrictHttpResponse<ExportExcelFileRes>): ExportExcelFileRes => r.body)
    );
  }

  /** Path part for operation `apiSustainableDashboardDdlListGet()` */
  static readonly ApiSustainableDashboardDdlListGetPath = '/api/sustainable/dashboard/ddl-list';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `apiSustainableDashboardDdlListGet()` instead.
   *
   * This method doesn't expect any request body.
   */
  apiSustainableDashboardDdlListGet$Response(
    params?: {
    },
    context?: HttpContext
  ): Observable<StrictHttpResponse<StbDashboardDropdownItem>> {
    const rb = new RequestBuilder(this.rootUrl, SustainableDashboardService.ApiSustainableDashboardDdlListGetPath, 'get');
    if (params) {
    }

    return this.http.request(
      rb.build({ responseType: 'json', accept: 'application/json', context })
    ).pipe(
      filter((r: any): r is HttpResponse<any> => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<StbDashboardDropdownItem>;
      })
    );
  }

  /**
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `apiSustainableDashboardDdlListGet$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  apiSustainableDashboardDdlListGet(
    params?: {
    },
    context?: HttpContext
  ): Observable<StbDashboardDropdownItem> {
    return this.apiSustainableDashboardDdlListGet$Response(params, context).pipe(
      map((r: StrictHttpResponse<StbDashboardDropdownItem>): StbDashboardDropdownItem => r.body)
    );
  }

}
