/* tslint:disable */
/* eslint-disable */
import { HttpClient, HttpContext, HttpResponse } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { filter, map } from 'rxjs/operators';

import { BaseService } from '../base-service';
import { ApiConfiguration } from '../api-configuration';
import { StrictHttpResponse } from '../strict-http-response';
import { RequestBuilder } from '../request-builder';

import { MasterVocTopicItem } from '../models/master-voc-topic-item';

@Injectable({ providedIn: 'root' })
export class MasterVocTopicService extends BaseService {
  constructor(config: ApiConfiguration, http: HttpClient) {
    super(config, http);
  }

  /** Path part for operation `apiMasterVocTopicGet()` */
  static readonly ApiMasterVocTopicGetPath = '/api/master/voc-topic';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `apiMasterVocTopicGet()` instead.
   *
   * This method doesn't expect any request body.
   */
  apiMasterVocTopicGet$Response(
    params?: {
      id?: string;
    },
    context?: HttpContext
  ): Observable<StrictHttpResponse<MasterVocTopicItem>> {
    const rb = new RequestBuilder(this.rootUrl, MasterVocTopicService.ApiMasterVocTopicGetPath, 'get');
    if (params) {
      rb.query('id', params.id, {});
    }

    return this.http.request(
      rb.build({ responseType: 'json', accept: 'application/json', context })
    ).pipe(
      filter((r: any): r is HttpResponse<any> => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<MasterVocTopicItem>;
      })
    );
  }

  /**
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `apiMasterVocTopicGet$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  apiMasterVocTopicGet(
    params?: {
      id?: string;
    },
    context?: HttpContext
  ): Observable<MasterVocTopicItem> {
    return this.apiMasterVocTopicGet$Response(params, context).pipe(
      map((r: StrictHttpResponse<MasterVocTopicItem>): MasterVocTopicItem => r.body)
    );
  }

  /** Path part for operation `apiMasterVocTopicPut()` */
  static readonly ApiMasterVocTopicPutPath = '/api/master/voc-topic';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `apiMasterVocTopicPut()` instead.
   *
   * This method sends `application/json` and handles request body of type `application/json`.
   */
  apiMasterVocTopicPut$Response(
    params?: {
      body?: MasterVocTopicItem
    },
    context?: HttpContext
  ): Observable<StrictHttpResponse<MasterVocTopicItem>> {
    const rb = new RequestBuilder(this.rootUrl, MasterVocTopicService.ApiMasterVocTopicPutPath, 'put');
    if (params) {
      rb.body(params.body, 'application/json');
    }

    return this.http.request(
      rb.build({ responseType: 'json', accept: 'application/json', context })
    ).pipe(
      filter((r: any): r is HttpResponse<any> => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<MasterVocTopicItem>;
      })
    );
  }

  /**
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `apiMasterVocTopicPut$Response()` instead.
   *
   * This method sends `application/json` and handles request body of type `application/json`.
   */
  apiMasterVocTopicPut(
    params?: {
      body?: MasterVocTopicItem
    },
    context?: HttpContext
  ): Observable<MasterVocTopicItem> {
    return this.apiMasterVocTopicPut$Response(params, context).pipe(
      map((r: StrictHttpResponse<MasterVocTopicItem>): MasterVocTopicItem => r.body)
    );
  }

  /** Path part for operation `apiMasterVocTopicPost()` */
  static readonly ApiMasterVocTopicPostPath = '/api/master/voc-topic';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `apiMasterVocTopicPost()` instead.
   *
   * This method sends `application/json` and handles request body of type `application/json`.
   */
  apiMasterVocTopicPost$Response(
    params?: {
      body?: MasterVocTopicItem
    },
    context?: HttpContext
  ): Observable<StrictHttpResponse<void>> {
    const rb = new RequestBuilder(this.rootUrl, MasterVocTopicService.ApiMasterVocTopicPostPath, 'post');
    if (params) {
      rb.body(params.body, 'application/json');
    }

    return this.http.request(
      rb.build({ responseType: 'text', accept: '*/*', context })
    ).pipe(
      filter((r: any): r is HttpResponse<any> => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return (r as HttpResponse<any>).clone({ body: undefined }) as StrictHttpResponse<void>;
      })
    );
  }

  /**
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `apiMasterVocTopicPost$Response()` instead.
   *
   * This method sends `application/json` and handles request body of type `application/json`.
   */
  apiMasterVocTopicPost(
    params?: {
      body?: MasterVocTopicItem
    },
    context?: HttpContext
  ): Observable<void> {
    return this.apiMasterVocTopicPost$Response(params, context).pipe(
      map((r: StrictHttpResponse<void>): void => r.body)
    );
  }

  /** Path part for operation `apiMasterVocTopicDelete()` */
  static readonly ApiMasterVocTopicDeletePath = '/api/master/voc-topic';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `apiMasterVocTopicDelete()` instead.
   *
   * This method doesn't expect any request body.
   */
  apiMasterVocTopicDelete$Response(
    params?: {
      id?: string;
    },
    context?: HttpContext
  ): Observable<StrictHttpResponse<void>> {
    const rb = new RequestBuilder(this.rootUrl, MasterVocTopicService.ApiMasterVocTopicDeletePath, 'delete');
    if (params) {
      rb.query('id', params.id, {});
    }

    return this.http.request(
      rb.build({ responseType: 'text', accept: '*/*', context })
    ).pipe(
      filter((r: any): r is HttpResponse<any> => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return (r as HttpResponse<any>).clone({ body: undefined }) as StrictHttpResponse<void>;
      })
    );
  }

  /**
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `apiMasterVocTopicDelete$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  apiMasterVocTopicDelete(
    params?: {
      id?: string;
    },
    context?: HttpContext
  ): Observable<void> {
    return this.apiMasterVocTopicDelete$Response(params, context).pipe(
      map((r: StrictHttpResponse<void>): void => r.body)
    );
  }

  /** Path part for operation `apiMasterVocTopicAllGet()` */
  static readonly ApiMasterVocTopicAllGetPath = '/api/master/voc-topic/All';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `apiMasterVocTopicAllGet()` instead.
   *
   * This method doesn't expect any request body.
   */
  apiMasterVocTopicAllGet$Response(
    params?: {
    },
    context?: HttpContext
  ): Observable<StrictHttpResponse<Array<MasterVocTopicItem>>> {
    const rb = new RequestBuilder(this.rootUrl, MasterVocTopicService.ApiMasterVocTopicAllGetPath, 'get');
    if (params) {
    }

    return this.http.request(
      rb.build({ responseType: 'json', accept: 'application/json', context })
    ).pipe(
      filter((r: any): r is HttpResponse<any> => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<Array<MasterVocTopicItem>>;
      })
    );
  }

  /**
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `apiMasterVocTopicAllGet$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  apiMasterVocTopicAllGet(
    params?: {
    },
    context?: HttpContext
  ): Observable<Array<MasterVocTopicItem>> {
    return this.apiMasterVocTopicAllGet$Response(params, context).pipe(
      map((r: StrictHttpResponse<Array<MasterVocTopicItem>>): Array<MasterVocTopicItem> => r.body)
    );
  }

  /** Path part for operation `apiMasterVocTopicActiveGet()` */
  static readonly ApiMasterVocTopicActiveGetPath = '/api/master/voc-topic/Active';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `apiMasterVocTopicActiveGet()` instead.
   *
   * This method doesn't expect any request body.
   */
  apiMasterVocTopicActiveGet$Response(
    params?: {
    },
    context?: HttpContext
  ): Observable<StrictHttpResponse<Array<MasterVocTopicItem>>> {
    const rb = new RequestBuilder(this.rootUrl, MasterVocTopicService.ApiMasterVocTopicActiveGetPath, 'get');
    if (params) {
    }

    return this.http.request(
      rb.build({ responseType: 'json', accept: 'application/json', context })
    ).pipe(
      filter((r: any): r is HttpResponse<any> => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<Array<MasterVocTopicItem>>;
      })
    );
  }

  /**
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `apiMasterVocTopicActiveGet$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  apiMasterVocTopicActiveGet(
    params?: {
    },
    context?: HttpContext
  ): Observable<Array<MasterVocTopicItem>> {
    return this.apiMasterVocTopicActiveGet$Response(params, context).pipe(
      map((r: StrictHttpResponse<Array<MasterVocTopicItem>>): Array<MasterVocTopicItem> => r.body)
    );
  }

}
