import { enableProdMode } from '@angular/core';
import { platformBrowserDynamic } from '@angular/platform-browser-dynamic';
import { isNil } from 'lodash';

import { AppModule } from './app/app.module';
import { appData } from './app/utils/appInitializer';
import { environment } from './environments/environment';

if (environment.production) {
  enableProdMode();
}
const currentAD = isNil(localStorage.getItem('currentAD')) ? '': localStorage.getItem('currentAD');

fetch(environment.base_url + "/api/app/data?config=" + currentAD).then(response => response.json()).then(res => {
  console.log(res);
  Object.assign(appData, res);

  platformBrowserDynamic().bootstrapModule(AppModule)
    .catch(err => console.error(err));
})
