import { Injectable } from '@angular/core';
import { BehaviorSubject } from 'rxjs';
import { AccountWithRoleRes } from '../../api/models';

@Injectable({
  providedIn: 'root'
})
export class AuthService {

  currentAccount: AccountWithRoleRes | null = null;

  private subjectLoginDisplay = new BehaviorSubject<boolean>(false);
  private subjectAccountInfo = new BehaviorSubject<AccountWithRoleRes>(null);

  get getCurrentAD():string | null {
    return localStorage.getItem('currentAD');
  }

  constructor() {
    this.subjectLoginDisplay.next(false);
  }

  setLoginDisplay(loginDisplay: boolean) {
    this.subjectLoginDisplay.next(loginDisplay);
  }

  getLoginDisplay() {
    return this.subjectLoginDisplay.asObservable();
  }

  setAccount(account: AccountWithRoleRes) {
    this.currentAccount = account;
    this.subjectAccountInfo.next(account);
  }

  getAccount() {
    return this.subjectAccountInfo.asObservable();
  }

}
