/* tslint:disable */
/* eslint-disable */
import { HttpClient, HttpContext, HttpResponse } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { filter, map } from 'rxjs/operators';

import { BaseService } from '../base-service';
import { ApiConfiguration } from '../api-configuration';
import { StrictHttpResponse } from '../strict-http-response';
import { RequestBuilder } from '../request-builder';

import { MasterVendorPortfolioChangeKeeperRes } from '../models/master-vendor-portfolio-change-keeper-res';
import { MasterVendorPortfolioKeeperSchQueryReq } from '../models/master-vendor-portfolio-keeper-sch-query-req';
import { TopicReq } from '../models/topic-req';

@Injectable({ providedIn: 'root' })
export class MasterVendorPortfolioKeeperService extends BaseService {
  constructor(config: ApiConfiguration, http: HttpClient) {
    super(config, http);
  }

  /** Path part for operation `apiMasterManualRequestSettingUpdatePost()` */
  static readonly ApiMasterManualRequestSettingUpdatePostPath = '/api/master/ManualRequestSetting/Update';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `apiMasterManualRequestSettingUpdatePost()` instead.
   *
   * This method sends `application/json` and handles request body of type `application/json`.
   */
  apiMasterManualRequestSettingUpdatePost$Response(
    params?: {
      body?: Array<MasterVendorPortfolioChangeKeeperRes>
    },
    context?: HttpContext
  ): Observable<StrictHttpResponse<void>> {
    const rb = new RequestBuilder(this.rootUrl, MasterVendorPortfolioKeeperService.ApiMasterManualRequestSettingUpdatePostPath, 'post');
    if (params) {
      rb.body(params.body, 'application/json');
    }

    return this.http.request(
      rb.build({ responseType: 'text', accept: '*/*', context })
    ).pipe(
      filter((r: any): r is HttpResponse<any> => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return (r as HttpResponse<any>).clone({ body: undefined }) as StrictHttpResponse<void>;
      })
    );
  }

  /**
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `apiMasterManualRequestSettingUpdatePost$Response()` instead.
   *
   * This method sends `application/json` and handles request body of type `application/json`.
   */
  apiMasterManualRequestSettingUpdatePost(
    params?: {
      body?: Array<MasterVendorPortfolioChangeKeeperRes>
    },
    context?: HttpContext
  ): Observable<void> {
    return this.apiMasterManualRequestSettingUpdatePost$Response(params, context).pipe(
      map((r: StrictHttpResponse<void>): void => r.body)
    );
  }

  /** Path part for operation `apiMasterManualRequestSettingSearchPost()` */
  static readonly ApiMasterManualRequestSettingSearchPostPath = '/api/master/ManualRequestSetting/Search';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `apiMasterManualRequestSettingSearchPost()` instead.
   *
   * This method sends `application/json` and handles request body of type `application/json`.
   */
  apiMasterManualRequestSettingSearchPost$Response(
    params?: {
      body?: MasterVendorPortfolioKeeperSchQueryReq
    },
    context?: HttpContext
  ): Observable<StrictHttpResponse<Array<MasterVendorPortfolioChangeKeeperRes>>> {
    const rb = new RequestBuilder(this.rootUrl, MasterVendorPortfolioKeeperService.ApiMasterManualRequestSettingSearchPostPath, 'post');
    if (params) {
      rb.body(params.body, 'application/json');
    }

    return this.http.request(
      rb.build({ responseType: 'json', accept: 'application/json', context })
    ).pipe(
      filter((r: any): r is HttpResponse<any> => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<Array<MasterVendorPortfolioChangeKeeperRes>>;
      })
    );
  }

  /**
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `apiMasterManualRequestSettingSearchPost$Response()` instead.
   *
   * This method sends `application/json` and handles request body of type `application/json`.
   */
  apiMasterManualRequestSettingSearchPost(
    params?: {
      body?: MasterVendorPortfolioKeeperSchQueryReq
    },
    context?: HttpContext
  ): Observable<Array<MasterVendorPortfolioChangeKeeperRes>> {
    return this.apiMasterManualRequestSettingSearchPost$Response(params, context).pipe(
      map((r: StrictHttpResponse<Array<MasterVendorPortfolioChangeKeeperRes>>): Array<MasterVendorPortfolioChangeKeeperRes> => r.body)
    );
  }

  /** Path part for operation `apiMasterManualRequestSettingGetTopicGet()` */
  static readonly ApiMasterManualRequestSettingGetTopicGetPath = '/api/master/ManualRequestSetting/Get-Topic';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `apiMasterManualRequestSettingGetTopicGet()` instead.
   *
   * This method doesn't expect any request body.
   */
  apiMasterManualRequestSettingGetTopicGet$Response(
    params?: {
    },
    context?: HttpContext
  ): Observable<StrictHttpResponse<Array<TopicReq>>> {
    const rb = new RequestBuilder(this.rootUrl, MasterVendorPortfolioKeeperService.ApiMasterManualRequestSettingGetTopicGetPath, 'get');
    if (params) {
    }

    return this.http.request(
      rb.build({ responseType: 'json', accept: 'application/json', context })
    ).pipe(
      filter((r: any): r is HttpResponse<any> => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<Array<TopicReq>>;
      })
    );
  }

  /**
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `apiMasterManualRequestSettingGetTopicGet$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  apiMasterManualRequestSettingGetTopicGet(
    params?: {
    },
    context?: HttpContext
  ): Observable<Array<TopicReq>> {
    return this.apiMasterManualRequestSettingGetTopicGet$Response(params, context).pipe(
      map((r: StrictHttpResponse<Array<TopicReq>>): Array<TopicReq> => r.body)
    );
  }

  /** Path part for operation `apiMasterManualRequestSettingGetLabelGet()` */
  static readonly ApiMasterManualRequestSettingGetLabelGetPath = '/api/master/ManualRequestSetting/Get-Label';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `apiMasterManualRequestSettingGetLabelGet()` instead.
   *
   * This method doesn't expect any request body.
   */
  apiMasterManualRequestSettingGetLabelGet$Response(
    params?: {
      workgroupIds?: Array<number>;
    },
    context?: HttpContext
  ): Observable<StrictHttpResponse<Array<string>>> {
    const rb = new RequestBuilder(this.rootUrl, MasterVendorPortfolioKeeperService.ApiMasterManualRequestSettingGetLabelGetPath, 'get');
    if (params) {
      rb.query('workgroupIds', params.workgroupIds, {});
    }

    return this.http.request(
      rb.build({ responseType: 'json', accept: 'application/json', context })
    ).pipe(
      filter((r: any): r is HttpResponse<any> => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<Array<string>>;
      })
    );
  }

  /**
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `apiMasterManualRequestSettingGetLabelGet$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  apiMasterManualRequestSettingGetLabelGet(
    params?: {
      workgroupIds?: Array<number>;
    },
    context?: HttpContext
  ): Observable<Array<string>> {
    return this.apiMasterManualRequestSettingGetLabelGet$Response(params, context).pipe(
      map((r: StrictHttpResponse<Array<string>>): Array<string> => r.body)
    );
  }

}
