/* tslint:disable */
/* eslint-disable */
import { HttpClient, HttpContext, HttpResponse } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { filter, map } from 'rxjs/operators';

import { BaseService } from '../base-service';
import { ApiConfiguration } from '../api-configuration';
import { StrictHttpResponse } from '../strict-http-response';
import { RequestBuilder } from '../request-builder';

import { CompanyItem } from '../models/company-item';
import { WorkGroupRes } from '../models/work-group-res';

@Injectable({ providedIn: 'root' })
export class CompanyService extends BaseService {
  constructor(config: ApiConfiguration, http: HttpClient) {
    super(config, http);
  }

  /** Path part for operation `apiCompanyPut()` */
  static readonly ApiCompanyPutPath = '/api/company';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `apiCompanyPut()` instead.
   *
   * This method sends `application/json` and handles request body of type `application/json`.
   */
  apiCompanyPut$Response(
    params?: {
      body?: CompanyItem
    },
    context?: HttpContext
  ): Observable<StrictHttpResponse<CompanyItem>> {
    const rb = new RequestBuilder(this.rootUrl, CompanyService.ApiCompanyPutPath, 'put');
    if (params) {
      rb.body(params.body, 'application/json');
    }

    return this.http.request(
      rb.build({ responseType: 'json', accept: 'application/json', context })
    ).pipe(
      filter((r: any): r is HttpResponse<any> => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<CompanyItem>;
      })
    );
  }

  /**
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `apiCompanyPut$Response()` instead.
   *
   * This method sends `application/json` and handles request body of type `application/json`.
   */
  apiCompanyPut(
    params?: {
      body?: CompanyItem
    },
    context?: HttpContext
  ): Observable<CompanyItem> {
    return this.apiCompanyPut$Response(params, context).pipe(
      map((r: StrictHttpResponse<CompanyItem>): CompanyItem => r.body)
    );
  }

  /** Path part for operation `apiCompanyClearQueryCacheGet()` */
  static readonly ApiCompanyClearQueryCacheGetPath = '/api/company/clearQueryCache';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `apiCompanyClearQueryCacheGet()` instead.
   *
   * This method doesn't expect any request body.
   */
  apiCompanyClearQueryCacheGet$Response(
    params?: {
    },
    context?: HttpContext
  ): Observable<StrictHttpResponse<Array<CompanyItem>>> {
    const rb = new RequestBuilder(this.rootUrl, CompanyService.ApiCompanyClearQueryCacheGetPath, 'get');
    if (params) {
    }

    return this.http.request(
      rb.build({ responseType: 'json', accept: 'application/json', context })
    ).pipe(
      filter((r: any): r is HttpResponse<any> => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<Array<CompanyItem>>;
      })
    );
  }

  /**
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `apiCompanyClearQueryCacheGet$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  apiCompanyClearQueryCacheGet(
    params?: {
    },
    context?: HttpContext
  ): Observable<Array<CompanyItem>> {
    return this.apiCompanyClearQueryCacheGet$Response(params, context).pipe(
      map((r: StrictHttpResponse<Array<CompanyItem>>): Array<CompanyItem> => r.body)
    );
  }

  /** Path part for operation `apiCompanyAllGet()` */
  static readonly ApiCompanyAllGetPath = '/api/company/all';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `apiCompanyAllGet()` instead.
   *
   * This method doesn't expect any request body.
   */
  apiCompanyAllGet$Response(
    params?: {
      workgroups?: Array<number>;
    },
    context?: HttpContext
  ): Observable<StrictHttpResponse<Array<CompanyItem>>> {
    const rb = new RequestBuilder(this.rootUrl, CompanyService.ApiCompanyAllGetPath, 'get');
    if (params) {
      rb.query('workgroups', params.workgroups, {});
    }

    return this.http.request(
      rb.build({ responseType: 'json', accept: 'application/json', context })
    ).pipe(
      filter((r: any): r is HttpResponse<any> => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<Array<CompanyItem>>;
      })
    );
  }

  /**
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `apiCompanyAllGet$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  apiCompanyAllGet(
    params?: {
      workgroups?: Array<number>;
    },
    context?: HttpContext
  ): Observable<Array<CompanyItem>> {
    return this.apiCompanyAllGet$Response(params, context).pipe(
      map((r: StrictHttpResponse<Array<CompanyItem>>): Array<CompanyItem> => r.body)
    );
  }

  /** Path part for operation `apiCompanyVenusAllGet()` */
  static readonly ApiCompanyVenusAllGetPath = '/api/company/venusAll';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `apiCompanyVenusAllGet()` instead.
   *
   * This method doesn't expect any request body.
   */
  apiCompanyVenusAllGet$Response(
    params?: {
      workGroupIds?: Array<number>;
    },
    context?: HttpContext
  ): Observable<StrictHttpResponse<Array<CompanyItem>>> {
    const rb = new RequestBuilder(this.rootUrl, CompanyService.ApiCompanyVenusAllGetPath, 'get');
    if (params) {
      rb.query('workGroupIds', params.workGroupIds, {});
    }

    return this.http.request(
      rb.build({ responseType: 'json', accept: 'application/json', context })
    ).pipe(
      filter((r: any): r is HttpResponse<any> => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<Array<CompanyItem>>;
      })
    );
  }

  /**
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `apiCompanyVenusAllGet$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  apiCompanyVenusAllGet(
    params?: {
      workGroupIds?: Array<number>;
    },
    context?: HttpContext
  ): Observable<Array<CompanyItem>> {
    return this.apiCompanyVenusAllGet$Response(params, context).pipe(
      map((r: StrictHttpResponse<Array<CompanyItem>>): Array<CompanyItem> => r.body)
    );
  }

  /** Path part for operation `apiCompanyWorkgroupAllGet()` */
  static readonly ApiCompanyWorkgroupAllGetPath = '/api/company/workgroup/all';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `apiCompanyWorkgroupAllGet()` instead.
   *
   * This method doesn't expect any request body.
   */
  apiCompanyWorkgroupAllGet$Response(
    params?: {
    },
    context?: HttpContext
  ): Observable<StrictHttpResponse<Array<WorkGroupRes>>> {
    const rb = new RequestBuilder(this.rootUrl, CompanyService.ApiCompanyWorkgroupAllGetPath, 'get');
    if (params) {
    }

    return this.http.request(
      rb.build({ responseType: 'json', accept: 'application/json', context })
    ).pipe(
      filter((r: any): r is HttpResponse<any> => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<Array<WorkGroupRes>>;
      })
    );
  }

  /**
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `apiCompanyWorkgroupAllGet$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  apiCompanyWorkgroupAllGet(
    params?: {
    },
    context?: HttpContext
  ): Observable<Array<WorkGroupRes>> {
    return this.apiCompanyWorkgroupAllGet$Response(params, context).pipe(
      map((r: StrictHttpResponse<Array<WorkGroupRes>>): Array<WorkGroupRes> => r.body)
    );
  }

}
