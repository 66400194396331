import { HttpBackend, HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { appData } from '../../utils/appInitializer';

@Injectable({
  providedIn: 'root'
})
export class AppDataService {
  private http: HttpClient;

  constructor(private readonly httpHandler: HttpBackend) {
    this.http = new HttpClient(httpHandler);
  }

  getSettingObject() {
    return appData;
  }
}
