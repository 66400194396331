/* tslint:disable */
/* eslint-disable */
import { HttpClient, HttpContext, HttpResponse } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { filter, map } from 'rxjs/operators';

import { BaseService } from '../base-service';
import { ApiConfiguration } from '../api-configuration';
import { StrictHttpResponse } from '../strict-http-response';
import { RequestBuilder } from '../request-builder';

import { ManualRequestExportReq } from '../models/manual-request-export-req';
import { ManualRequestExportRes } from '../models/manual-request-export-res';

@Injectable({ providedIn: 'root' })
export class ManualRequestExportService extends BaseService {
  constructor(config: ApiConfiguration, http: HttpClient) {
    super(config, http);
  }

  /** Path part for operation `apiManualRequestExportChangeDetailPost()` */
  static readonly ApiManualRequestExportChangeDetailPostPath = '/api/manual-request-export/change-detail';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `apiManualRequestExportChangeDetailPost()` instead.
   *
   * This method sends `application/json` and handles request body of type `application/json`.
   */
  apiManualRequestExportChangeDetailPost$Response(
    params?: {
      body?: ManualRequestExportReq
    },
    context?: HttpContext
  ): Observable<StrictHttpResponse<ManualRequestExportRes>> {
    const rb = new RequestBuilder(this.rootUrl, ManualRequestExportService.ApiManualRequestExportChangeDetailPostPath, 'post');
    if (params) {
      rb.body(params.body, 'application/json');
    }

    return this.http.request(
      rb.build({ responseType: 'json', accept: 'application/json', context })
    ).pipe(
      filter((r: any): r is HttpResponse<any> => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<ManualRequestExportRes>;
      })
    );
  }

  /**
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `apiManualRequestExportChangeDetailPost$Response()` instead.
   *
   * This method sends `application/json` and handles request body of type `application/json`.
   */
  apiManualRequestExportChangeDetailPost(
    params?: {
      body?: ManualRequestExportReq
    },
    context?: HttpContext
  ): Observable<ManualRequestExportRes> {
    return this.apiManualRequestExportChangeDetailPost$Response(params, context).pipe(
      map((r: StrictHttpResponse<ManualRequestExportRes>): ManualRequestExportRes => r.body)
    );
  }

}
