/* tslint:disable */
/* eslint-disable */
import { HttpClient, HttpContext, HttpResponse } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { filter, map } from 'rxjs/operators';

import { BaseService } from '../base-service';
import { ApiConfiguration } from '../api-configuration';
import { StrictHttpResponse } from '../strict-http-response';
import { RequestBuilder } from '../request-builder';

import { MasterCategoryAndSeverityResItem } from '../models/master-category-and-severity-res-item';
import { PostEvaluationMasterData } from '../models/post-evaluation-master-data';
import { PostEvaluationPoReferenceCriteria } from '../models/post-evaluation-po-reference-criteria';
import { PostEvaluationPoReferenceResult } from '../models/post-evaluation-po-reference-result';
import { PostEvaluationRequestItem } from '../models/post-evaluation-request-item';
import { VenSchAutoCompleteRes } from '../models/ven-sch-auto-complete-res';

@Injectable({ providedIn: 'root' })
export class PostEvaluationService extends BaseService {
  constructor(config: ApiConfiguration, http: HttpClient) {
    super(config, http);
  }

  /** Path part for operation `apiPostEvaluationRequestGet()` */
  static readonly ApiPostEvaluationRequestGetPath = '/api/post-evaluation/request';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `apiPostEvaluationRequestGet()` instead.
   *
   * This method doesn't expect any request body.
   */
  apiPostEvaluationRequestGet$Response(
    params?: {
      pk?: string;
    },
    context?: HttpContext
  ): Observable<StrictHttpResponse<PostEvaluationRequestItem>> {
    const rb = new RequestBuilder(this.rootUrl, PostEvaluationService.ApiPostEvaluationRequestGetPath, 'get');
    if (params) {
      rb.query('pk', params.pk, {});
    }

    return this.http.request(
      rb.build({ responseType: 'json', accept: 'application/json', context })
    ).pipe(
      filter((r: any): r is HttpResponse<any> => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<PostEvaluationRequestItem>;
      })
    );
  }

  /**
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `apiPostEvaluationRequestGet$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  apiPostEvaluationRequestGet(
    params?: {
      pk?: string;
    },
    context?: HttpContext
  ): Observable<PostEvaluationRequestItem> {
    return this.apiPostEvaluationRequestGet$Response(params, context).pipe(
      map((r: StrictHttpResponse<PostEvaluationRequestItem>): PostEvaluationRequestItem => r.body)
    );
  }

  /** Path part for operation `apiPostEvaluationTakeActionPost()` */
  static readonly ApiPostEvaluationTakeActionPostPath = '/api/post-evaluation/take-action';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `apiPostEvaluationTakeActionPost()` instead.
   *
   * This method sends `application/json` and handles request body of type `application/json`.
   */
  apiPostEvaluationTakeActionPost$Response(
    params?: {
      body?: PostEvaluationRequestItem
    },
    context?: HttpContext
  ): Observable<StrictHttpResponse<PostEvaluationRequestItem>> {
    const rb = new RequestBuilder(this.rootUrl, PostEvaluationService.ApiPostEvaluationTakeActionPostPath, 'post');
    if (params) {
      rb.body(params.body, 'application/json');
    }

    return this.http.request(
      rb.build({ responseType: 'json', accept: 'application/json', context })
    ).pipe(
      filter((r: any): r is HttpResponse<any> => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<PostEvaluationRequestItem>;
      })
    );
  }

  /**
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `apiPostEvaluationTakeActionPost$Response()` instead.
   *
   * This method sends `application/json` and handles request body of type `application/json`.
   */
  apiPostEvaluationTakeActionPost(
    params?: {
      body?: PostEvaluationRequestItem
    },
    context?: HttpContext
  ): Observable<PostEvaluationRequestItem> {
    return this.apiPostEvaluationTakeActionPost$Response(params, context).pipe(
      map((r: StrictHttpResponse<PostEvaluationRequestItem>): PostEvaluationRequestItem => r.body)
    );
  }

  /** Path part for operation `apiPostEvaluationInitialDataGet()` */
  static readonly ApiPostEvaluationInitialDataGetPath = '/api/post-evaluation/initial-data';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `apiPostEvaluationInitialDataGet()` instead.
   *
   * This method doesn't expect any request body.
   */
  apiPostEvaluationInitialDataGet$Response(
    params?: {
      workGroupId?: number;
    },
    context?: HttpContext
  ): Observable<StrictHttpResponse<PostEvaluationRequestItem>> {
    const rb = new RequestBuilder(this.rootUrl, PostEvaluationService.ApiPostEvaluationInitialDataGetPath, 'get');
    if (params) {
      rb.query('workGroupId', params.workGroupId, {});
    }

    return this.http.request(
      rb.build({ responseType: 'json', accept: 'application/json', context })
    ).pipe(
      filter((r: any): r is HttpResponse<any> => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<PostEvaluationRequestItem>;
      })
    );
  }

  /**
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `apiPostEvaluationInitialDataGet$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  apiPostEvaluationInitialDataGet(
    params?: {
      workGroupId?: number;
    },
    context?: HttpContext
  ): Observable<PostEvaluationRequestItem> {
    return this.apiPostEvaluationInitialDataGet$Response(params, context).pipe(
      map((r: StrictHttpResponse<PostEvaluationRequestItem>): PostEvaluationRequestItem => r.body)
    );
  }

  /** Path part for operation `apiPostEvaluationPoReferencePost()` */
  static readonly ApiPostEvaluationPoReferencePostPath = '/api/post-evaluation/po-reference';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `apiPostEvaluationPoReferencePost()` instead.
   *
   * This method sends `application/json` and handles request body of type `application/json`.
   */
  apiPostEvaluationPoReferencePost$Response(
    params?: {
      body?: PostEvaluationPoReferenceCriteria
    },
    context?: HttpContext
  ): Observable<StrictHttpResponse<Array<PostEvaluationPoReferenceResult>>> {
    const rb = new RequestBuilder(this.rootUrl, PostEvaluationService.ApiPostEvaluationPoReferencePostPath, 'post');
    if (params) {
      rb.body(params.body, 'application/json');
    }

    return this.http.request(
      rb.build({ responseType: 'json', accept: 'application/json', context })
    ).pipe(
      filter((r: any): r is HttpResponse<any> => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<Array<PostEvaluationPoReferenceResult>>;
      })
    );
  }

  /**
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `apiPostEvaluationPoReferencePost$Response()` instead.
   *
   * This method sends `application/json` and handles request body of type `application/json`.
   */
  apiPostEvaluationPoReferencePost(
    params?: {
      body?: PostEvaluationPoReferenceCriteria
    },
    context?: HttpContext
  ): Observable<Array<PostEvaluationPoReferenceResult>> {
    return this.apiPostEvaluationPoReferencePost$Response(params, context).pipe(
      map((r: StrictHttpResponse<Array<PostEvaluationPoReferenceResult>>): Array<PostEvaluationPoReferenceResult> => r.body)
    );
  }

  /** Path part for operation `apiPostEvaluationContractReferencePost()` */
  static readonly ApiPostEvaluationContractReferencePostPath = '/api/post-evaluation/contract-reference';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `apiPostEvaluationContractReferencePost()` instead.
   *
   * This method doesn't expect any request body.
   */
  apiPostEvaluationContractReferencePost$Response(
    params?: {
    },
    context?: HttpContext
  ): Observable<StrictHttpResponse<void>> {
    const rb = new RequestBuilder(this.rootUrl, PostEvaluationService.ApiPostEvaluationContractReferencePostPath, 'post');
    if (params) {
    }

    return this.http.request(
      rb.build({ responseType: 'text', accept: '*/*', context })
    ).pipe(
      filter((r: any): r is HttpResponse<any> => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return (r as HttpResponse<any>).clone({ body: undefined }) as StrictHttpResponse<void>;
      })
    );
  }

  /**
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `apiPostEvaluationContractReferencePost$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  apiPostEvaluationContractReferencePost(
    params?: {
    },
    context?: HttpContext
  ): Observable<void> {
    return this.apiPostEvaluationContractReferencePost$Response(params, context).pipe(
      map((r: StrictHttpResponse<void>): void => r.body)
    );
  }

  /** Path part for operation `apiPostEvaluationMasterCategorySeverityContainGet()` */
  static readonly ApiPostEvaluationMasterCategorySeverityContainGetPath = '/api/post-evaluation/master-category-severity-contain';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `apiPostEvaluationMasterCategorySeverityContainGet()` instead.
   *
   * This method doesn't expect any request body.
   */
  apiPostEvaluationMasterCategorySeverityContainGet$Response(
    params?: {
      containText?: string;
      max?: number;
      workGroupIds?: Array<number>;
    },
    context?: HttpContext
  ): Observable<StrictHttpResponse<Array<MasterCategoryAndSeverityResItem>>> {
    const rb = new RequestBuilder(this.rootUrl, PostEvaluationService.ApiPostEvaluationMasterCategorySeverityContainGetPath, 'get');
    if (params) {
      rb.query('containText', params.containText, {});
      rb.query('max', params.max, {});
      rb.query('workGroupIds', params.workGroupIds, {});
    }

    return this.http.request(
      rb.build({ responseType: 'json', accept: 'application/json', context })
    ).pipe(
      filter((r: any): r is HttpResponse<any> => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<Array<MasterCategoryAndSeverityResItem>>;
      })
    );
  }

  /**
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `apiPostEvaluationMasterCategorySeverityContainGet$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  apiPostEvaluationMasterCategorySeverityContainGet(
    params?: {
      containText?: string;
      max?: number;
      workGroupIds?: Array<number>;
    },
    context?: HttpContext
  ): Observable<Array<MasterCategoryAndSeverityResItem>> {
    return this.apiPostEvaluationMasterCategorySeverityContainGet$Response(params, context).pipe(
      map((r: StrictHttpResponse<Array<MasterCategoryAndSeverityResItem>>): Array<MasterCategoryAndSeverityResItem> => r.body)
    );
  }

  /** Path part for operation `apiPostEvaluationVendorContainGet()` */
  static readonly ApiPostEvaluationVendorContainGetPath = '/api/post-evaluation/vendor-contain';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `apiPostEvaluationVendorContainGet()` instead.
   *
   * This method doesn't expect any request body.
   */
  apiPostEvaluationVendorContainGet$Response(
    params?: {
      containText?: string;
      max?: number;
      workGroupIds?: Array<number>;
    },
    context?: HttpContext
  ): Observable<StrictHttpResponse<Array<VenSchAutoCompleteRes>>> {
    const rb = new RequestBuilder(this.rootUrl, PostEvaluationService.ApiPostEvaluationVendorContainGetPath, 'get');
    if (params) {
      rb.query('containText', params.containText, {});
      rb.query('max', params.max, {});
      rb.query('workGroupIds', params.workGroupIds, {});
    }

    return this.http.request(
      rb.build({ responseType: 'json', accept: 'application/json', context })
    ).pipe(
      filter((r: any): r is HttpResponse<any> => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<Array<VenSchAutoCompleteRes>>;
      })
    );
  }

  /**
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `apiPostEvaluationVendorContainGet$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  apiPostEvaluationVendorContainGet(
    params?: {
      containText?: string;
      max?: number;
      workGroupIds?: Array<number>;
    },
    context?: HttpContext
  ): Observable<Array<VenSchAutoCompleteRes>> {
    return this.apiPostEvaluationVendorContainGet$Response(params, context).pipe(
      map((r: StrictHttpResponse<Array<VenSchAutoCompleteRes>>): Array<VenSchAutoCompleteRes> => r.body)
    );
  }

  /** Path part for operation `apiPostEvaluationMasterDataGet()` */
  static readonly ApiPostEvaluationMasterDataGetPath = '/api/post-evaluation/master-data';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `apiPostEvaluationMasterDataGet()` instead.
   *
   * This method doesn't expect any request body.
   */
  apiPostEvaluationMasterDataGet$Response(
    params?: {
      workGroupList?: Array<number>;
    },
    context?: HttpContext
  ): Observable<StrictHttpResponse<PostEvaluationMasterData>> {
    const rb = new RequestBuilder(this.rootUrl, PostEvaluationService.ApiPostEvaluationMasterDataGetPath, 'get');
    if (params) {
      rb.query('workGroupList', params.workGroupList, {});
    }

    return this.http.request(
      rb.build({ responseType: 'json', accept: 'application/json', context })
    ).pipe(
      filter((r: any): r is HttpResponse<any> => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<PostEvaluationMasterData>;
      })
    );
  }

  /**
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `apiPostEvaluationMasterDataGet$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  apiPostEvaluationMasterDataGet(
    params?: {
      workGroupList?: Array<number>;
    },
    context?: HttpContext
  ): Observable<PostEvaluationMasterData> {
    return this.apiPostEvaluationMasterDataGet$Response(params, context).pipe(
      map((r: StrictHttpResponse<PostEvaluationMasterData>): PostEvaluationMasterData => r.body)
    );
  }

}
