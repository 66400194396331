/* tslint:disable */
/* eslint-disable */
import { HttpClient, HttpContext, HttpResponse } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { filter, map } from 'rxjs/operators';

import { BaseService } from '../base-service';
import { ApiConfiguration } from '../api-configuration';
import { StrictHttpResponse } from '../strict-http-response';
import { RequestBuilder } from '../request-builder';

import { CxAccAndRoleOptDataRes } from '../models/cx-acc-and-role-opt-data-res';
import { CxAccountDto } from '../models/cx-account-dto';
import { CxAccountListRes } from '../models/cx-account-list-res';
import { VenDataConstRes } from '../models/ven-data-const-res';
import { VenPerformance } from '../models/ven-performance';
import { VendorProfileRes } from '../models/vendor-profile-res';

@Injectable({ providedIn: 'root' })
export class VendorProfileService extends BaseService {
  constructor(config: ApiConfiguration, http: HttpClient) {
    super(config, http);
  }

  /** Path part for operation `apiVendorprofileAllGet()` */
  static readonly ApiVendorprofileAllGetPath = '/api/vendorprofile/all';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `apiVendorprofileAllGet()` instead.
   *
   * This method doesn't expect any request body.
   */
  apiVendorprofileAllGet$Response(
    params?: {
    },
    context?: HttpContext
  ): Observable<StrictHttpResponse<Array<VendorProfileRes>>> {
    const rb = new RequestBuilder(this.rootUrl, VendorProfileService.ApiVendorprofileAllGetPath, 'get');
    if (params) {
    }

    return this.http.request(
      rb.build({ responseType: 'json', accept: 'application/json', context })
    ).pipe(
      filter((r: any): r is HttpResponse<any> => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<Array<VendorProfileRes>>;
      })
    );
  }

  /**
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `apiVendorprofileAllGet$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  apiVendorprofileAllGet(
    params?: {
    },
    context?: HttpContext
  ): Observable<Array<VendorProfileRes>> {
    return this.apiVendorprofileAllGet$Response(params, context).pipe(
      map((r: StrictHttpResponse<Array<VendorProfileRes>>): Array<VendorProfileRes> => r.body)
    );
  }

  /** Path part for operation `apiVendorprofileByidGet()` */
  static readonly ApiVendorprofileByidGetPath = '/api/vendorprofile/byid';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `apiVendorprofileByidGet()` instead.
   *
   * This method doesn't expect any request body.
   */
  apiVendorprofileByidGet$Response(
    params?: {
      id?: string;
    },
    context?: HttpContext
  ): Observable<StrictHttpResponse<VendorProfileRes>> {
    const rb = new RequestBuilder(this.rootUrl, VendorProfileService.ApiVendorprofileByidGetPath, 'get');
    if (params) {
      rb.query('id', params.id, {});
    }

    return this.http.request(
      rb.build({ responseType: 'json', accept: 'application/json', context })
    ).pipe(
      filter((r: any): r is HttpResponse<any> => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<VendorProfileRes>;
      })
    );
  }

  /**
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `apiVendorprofileByidGet$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  apiVendorprofileByidGet(
    params?: {
      id?: string;
    },
    context?: HttpContext
  ): Observable<VendorProfileRes> {
    return this.apiVendorprofileByidGet$Response(params, context).pipe(
      map((r: StrictHttpResponse<VendorProfileRes>): VendorProfileRes => r.body)
    );
  }

  /** Path part for operation `apiVendorprofilePerformanceByIdGet()` */
  static readonly ApiVendorprofilePerformanceByIdGetPath = '/api/vendorprofile/performance-by-id';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `apiVendorprofilePerformanceByIdGet()` instead.
   *
   * This method doesn't expect any request body.
   */
  apiVendorprofilePerformanceByIdGet$Response(
    params?: {
      id?: string;
    },
    context?: HttpContext
  ): Observable<StrictHttpResponse<VenPerformance>> {
    const rb = new RequestBuilder(this.rootUrl, VendorProfileService.ApiVendorprofilePerformanceByIdGetPath, 'get');
    if (params) {
      rb.query('id', params.id, {});
    }

    return this.http.request(
      rb.build({ responseType: 'json', accept: 'application/json', context })
    ).pipe(
      filter((r: any): r is HttpResponse<any> => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<VenPerformance>;
      })
    );
  }

  /**
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `apiVendorprofilePerformanceByIdGet$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  apiVendorprofilePerformanceByIdGet(
    params?: {
      id?: string;
    },
    context?: HttpContext
  ): Observable<VenPerformance> {
    return this.apiVendorprofilePerformanceByIdGet$Response(params, context).pipe(
      map((r: StrictHttpResponse<VenPerformance>): VenPerformance => r.body)
    );
  }

  /** Path part for operation `apiVendorprofileDataConstGet()` */
  static readonly ApiVendorprofileDataConstGetPath = '/api/vendorprofile/data-const';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `apiVendorprofileDataConstGet()` instead.
   *
   * This method doesn't expect any request body.
   */
  apiVendorprofileDataConstGet$Response(
    params?: {
    },
    context?: HttpContext
  ): Observable<StrictHttpResponse<VenDataConstRes>> {
    const rb = new RequestBuilder(this.rootUrl, VendorProfileService.ApiVendorprofileDataConstGetPath, 'get');
    if (params) {
    }

    return this.http.request(
      rb.build({ responseType: 'json', accept: 'application/json', context })
    ).pipe(
      filter((r: any): r is HttpResponse<any> => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<VenDataConstRes>;
      })
    );
  }

  /**
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `apiVendorprofileDataConstGet$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  apiVendorprofileDataConstGet(
    params?: {
    },
    context?: HttpContext
  ): Observable<VenDataConstRes> {
    return this.apiVendorprofileDataConstGet$Response(params, context).pipe(
      map((r: StrictHttpResponse<VenDataConstRes>): VenDataConstRes => r.body)
    );
  }

  /** Path part for operation `apiVendorprofileAccountPermissionAllGet()` */
  static readonly ApiVendorprofileAccountPermissionAllGetPath = '/api/vendorprofile/account-permission/all';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `apiVendorprofileAccountPermissionAllGet()` instead.
   *
   * This method doesn't expect any request body.
   */
  apiVendorprofileAccountPermissionAllGet$Response(
    params?: {
      vendorProfileId?: string;
    },
    context?: HttpContext
  ): Observable<StrictHttpResponse<Array<CxAccountListRes>>> {
    const rb = new RequestBuilder(this.rootUrl, VendorProfileService.ApiVendorprofileAccountPermissionAllGetPath, 'get');
    if (params) {
      rb.query('vendorProfileId', params.vendorProfileId, {});
    }

    return this.http.request(
      rb.build({ responseType: 'json', accept: 'application/json', context })
    ).pipe(
      filter((r: any): r is HttpResponse<any> => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<Array<CxAccountListRes>>;
      })
    );
  }

  /**
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `apiVendorprofileAccountPermissionAllGet$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  apiVendorprofileAccountPermissionAllGet(
    params?: {
      vendorProfileId?: string;
    },
    context?: HttpContext
  ): Observable<Array<CxAccountListRes>> {
    return this.apiVendorprofileAccountPermissionAllGet$Response(params, context).pipe(
      map((r: StrictHttpResponse<Array<CxAccountListRes>>): Array<CxAccountListRes> => r.body)
    );
  }

  /** Path part for operation `apiVendorprofileAccountPermissionGet()` */
  static readonly ApiVendorprofileAccountPermissionGetPath = '/api/vendorprofile/account-permission';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `apiVendorprofileAccountPermissionGet()` instead.
   *
   * This method doesn't expect any request body.
   */
  apiVendorprofileAccountPermissionGet$Response(
    params?: {
      pkCXAccountId?: string;
    },
    context?: HttpContext
  ): Observable<StrictHttpResponse<CxAccountDto>> {
    const rb = new RequestBuilder(this.rootUrl, VendorProfileService.ApiVendorprofileAccountPermissionGetPath, 'get');
    if (params) {
      rb.query('pkCXAccountId', params.pkCXAccountId, {});
    }

    return this.http.request(
      rb.build({ responseType: 'json', accept: 'application/json', context })
    ).pipe(
      filter((r: any): r is HttpResponse<any> => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<CxAccountDto>;
      })
    );
  }

  /**
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `apiVendorprofileAccountPermissionGet$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  apiVendorprofileAccountPermissionGet(
    params?: {
      pkCXAccountId?: string;
    },
    context?: HttpContext
  ): Observable<CxAccountDto> {
    return this.apiVendorprofileAccountPermissionGet$Response(params, context).pipe(
      map((r: StrictHttpResponse<CxAccountDto>): CxAccountDto => r.body)
    );
  }

  /** Path part for operation `apiVendorprofileAccountPermissionPut()` */
  static readonly ApiVendorprofileAccountPermissionPutPath = '/api/vendorprofile/account-permission';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `apiVendorprofileAccountPermissionPut()` instead.
   *
   * This method sends `application/json` and handles request body of type `application/json`.
   */
  apiVendorprofileAccountPermissionPut$Response(
    params?: {
      id?: string;
      body?: CxAccountDto
    },
    context?: HttpContext
  ): Observable<StrictHttpResponse<void>> {
    const rb = new RequestBuilder(this.rootUrl, VendorProfileService.ApiVendorprofileAccountPermissionPutPath, 'put');
    if (params) {
      rb.query('id', params.id, {});
      rb.body(params.body, 'application/json');
    }

    return this.http.request(
      rb.build({ responseType: 'text', accept: '*/*', context })
    ).pipe(
      filter((r: any): r is HttpResponse<any> => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return (r as HttpResponse<any>).clone({ body: undefined }) as StrictHttpResponse<void>;
      })
    );
  }

  /**
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `apiVendorprofileAccountPermissionPut$Response()` instead.
   *
   * This method sends `application/json` and handles request body of type `application/json`.
   */
  apiVendorprofileAccountPermissionPut(
    params?: {
      id?: string;
      body?: CxAccountDto
    },
    context?: HttpContext
  ): Observable<void> {
    return this.apiVendorprofileAccountPermissionPut$Response(params, context).pipe(
      map((r: StrictHttpResponse<void>): void => r.body)
    );
  }

  /** Path part for operation `apiVendorprofileAccountPermissionDelete()` */
  static readonly ApiVendorprofileAccountPermissionDeletePath = '/api/vendorprofile/account-permission';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `apiVendorprofileAccountPermissionDelete()` instead.
   *
   * This method doesn't expect any request body.
   */
  apiVendorprofileAccountPermissionDelete$Response(
    params?: {
      id?: string;
    },
    context?: HttpContext
  ): Observable<StrictHttpResponse<void>> {
    const rb = new RequestBuilder(this.rootUrl, VendorProfileService.ApiVendorprofileAccountPermissionDeletePath, 'delete');
    if (params) {
      rb.query('id', params.id, {});
    }

    return this.http.request(
      rb.build({ responseType: 'text', accept: '*/*', context })
    ).pipe(
      filter((r: any): r is HttpResponse<any> => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return (r as HttpResponse<any>).clone({ body: undefined }) as StrictHttpResponse<void>;
      })
    );
  }

  /**
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `apiVendorprofileAccountPermissionDelete$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  apiVendorprofileAccountPermissionDelete(
    params?: {
      id?: string;
    },
    context?: HttpContext
  ): Observable<void> {
    return this.apiVendorprofileAccountPermissionDelete$Response(params, context).pipe(
      map((r: StrictHttpResponse<void>): void => r.body)
    );
  }

  /** Path part for operation `apiVendorprofileAccountPermissionResendResetPasswordEmailPost()` */
  static readonly ApiVendorprofileAccountPermissionResendResetPasswordEmailPostPath = '/api/vendorprofile/account-permission/resend-reset-password-email';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `apiVendorprofileAccountPermissionResendResetPasswordEmailPost()` instead.
   *
   * This method doesn't expect any request body.
   */
  apiVendorprofileAccountPermissionResendResetPasswordEmailPost$Response(
    params?: {
      id?: string;
    },
    context?: HttpContext
  ): Observable<StrictHttpResponse<void>> {
    const rb = new RequestBuilder(this.rootUrl, VendorProfileService.ApiVendorprofileAccountPermissionResendResetPasswordEmailPostPath, 'post');
    if (params) {
      rb.query('id', params.id, {});
    }

    return this.http.request(
      rb.build({ responseType: 'text', accept: '*/*', context })
    ).pipe(
      filter((r: any): r is HttpResponse<any> => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return (r as HttpResponse<any>).clone({ body: undefined }) as StrictHttpResponse<void>;
      })
    );
  }

  /**
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `apiVendorprofileAccountPermissionResendResetPasswordEmailPost$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  apiVendorprofileAccountPermissionResendResetPasswordEmailPost(
    params?: {
      id?: string;
    },
    context?: HttpContext
  ): Observable<void> {
    return this.apiVendorprofileAccountPermissionResendResetPasswordEmailPost$Response(params, context).pipe(
      map((r: StrictHttpResponse<void>): void => r.body)
    );
  }

  /** Path part for operation `apiVendorprofileAccountPermissionOptDataGet()` */
  static readonly ApiVendorprofileAccountPermissionOptDataGetPath = '/api/vendorprofile/account-permission/opt-data';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `apiVendorprofileAccountPermissionOptDataGet()` instead.
   *
   * This method doesn't expect any request body.
   */
  apiVendorprofileAccountPermissionOptDataGet$Response(
    params?: {
    },
    context?: HttpContext
  ): Observable<StrictHttpResponse<CxAccAndRoleOptDataRes>> {
    const rb = new RequestBuilder(this.rootUrl, VendorProfileService.ApiVendorprofileAccountPermissionOptDataGetPath, 'get');
    if (params) {
    }

    return this.http.request(
      rb.build({ responseType: 'json', accept: 'application/json', context })
    ).pipe(
      filter((r: any): r is HttpResponse<any> => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<CxAccAndRoleOptDataRes>;
      })
    );
  }

  /**
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `apiVendorprofileAccountPermissionOptDataGet$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  apiVendorprofileAccountPermissionOptDataGet(
    params?: {
    },
    context?: HttpContext
  ): Observable<CxAccAndRoleOptDataRes> {
    return this.apiVendorprofileAccountPermissionOptDataGet$Response(params, context).pipe(
      map((r: StrictHttpResponse<CxAccAndRoleOptDataRes>): CxAccAndRoleOptDataRes => r.body)
    );
  }

}
