import { Component, OnInit } from '@angular/core';
import { FormControl, FormGroup, NonNullableFormBuilder, Validators } from '@angular/forms';
import { MsalService } from '@azure/msal-angular';
import { AuthenticationResult } from '@azure/msal-browser';
import { TranslateService } from '@ngx-translate/core';
import { AuthService } from '../commons/services/auth.service';
import { LSP_MSALInstanceFactory, SCG_MSALInstanceFactory } from '../msal/configs/msal.config';
import { isNil } from 'lodash';

@Component({
  selector: 'app-login',
  templateUrl: './login.component.html',
  styleUrls: ['./login.component.less']
})
export class LoginComponent implements OnInit {

  currentLang: string = "en";
  translations: any = {};

  constructor(private fb: NonNullableFormBuilder,
    private msalService: MsalService,
    private translate: TranslateService,
    private authService: AuthService)
  {
    this.translations = translate.translations[translate.currentLang];
    this.currentLang = translate.currentLang;

    translate.onLangChange.subscribe((event) => {
      this.currentLang = event.lang;
      this.translations = event.translations;
    });
  }

  ngOnInit(): void {
    if (!isNil(this.authService.getCurrentAD) && this.msalService.instance.getAllAccounts().length > 0) {
      window.location.href = '/';
    }

  }

  //validateForm: FormGroup<{
  //  userName: FormControl<string>;
  //  password: FormControl<string>;
  //  remember: FormControl<boolean>;
  //}> = this.fb.group({
  //  userName: ['', [Validators.required]],
  //  password: ['', [Validators.required]],
  //  remember: [true]
  //});

  //submitForm(): void {
  //  if (this.validateForm.valid) {
  //    console.log('submit', this.validateForm.value);
  //  } else {
  //    Object.values(this.validateForm.controls).forEach(control => {
  //      if (control.invalid) {
  //        control.markAsDirty();
  //        control.updateValueAndValidity({ onlySelf: true });
  //      }
  //    });
  //  }
  //}

  loginSCG() {
    //this.msalService.instance = SCG_MSALInstanceFactory();
    if (this.authService.getCurrentAD != 'AzureAd') {
      sessionStorage.clear();
      localStorage.clear();
    }
    localStorage.setItem("tenantName", "SCGC");
    localStorage.setItem("currentAD", "AzureAd");
    window.location.href = '/';
    //this.msalService.loginPopup()
    //  .subscribe({
    //    next: (authenResult: AuthenticationResult) => {
    //      //this.spinner.show();
    //      console.log("login SCG", authenResult);
    //      this.msalService.instance.setActiveAccount(authenResult.account);
    //      //this.setToken(authenResult);
    //      //this.setLogInStatus();
    //    },
    //    error: (error) => {
    //      //this.spinner.hide();
    //      alert(error + "\n\nSuggestion: Try to clear all browsing history.");
    //    }
    //  });
  }

  loginLSP() {
    //this.msalService.instance = LSP_MSALInstanceFactory();
    if (this.authService.getCurrentAD != 'AzureLSPAd') {
      sessionStorage.clear();
      localStorage.clear();
    }
    localStorage.setItem("tenantName", "LSP");
    localStorage.setItem("currentAD", "AzureLSPAd");
    window.location.href = '/';
    //this.msalService.loginPopup()
    //  .subscribe({
    //    next: (authenResult: AuthenticationResult) => {
    //      //this.spinner.show();
    //      console.log("login LSP", authenResult);
    //      this.msalService.instance.setActiveAccount(authenResult.account);
    //      //this.setToken(authenResult);
    //      //this.setLogInStatus();
    //    },
    //    error: (error) => {
    //      //this.spinner.hide();
    //      alert(error + "\n\nSuggestion: Try to clear all browsing history.");
    //    }
    //  });
  }
}
