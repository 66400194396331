/* tslint:disable */
/* eslint-disable */
import { HttpClient, HttpContext, HttpResponse } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { filter, map } from 'rxjs/operators';

import { BaseService } from '../base-service';
import { ApiConfiguration } from '../api-configuration';
import { StrictHttpResponse } from '../strict-http-response';
import { RequestBuilder } from '../request-builder';

import { EmailTemplateDto } from '../models/email-template-dto';
import { EmailTemplateQueryRes } from '../models/email-template-query-res';

@Injectable({ providedIn: 'root' })
export class EmailTemplateService extends BaseService {
  constructor(config: ApiConfiguration, http: HttpClient) {
    super(config, http);
  }

  /** Path part for operation `apiEmailTemplateAllGet()` */
  static readonly ApiEmailTemplateAllGetPath = '/api/email-template/all';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `apiEmailTemplateAllGet()` instead.
   *
   * This method doesn't expect any request body.
   */
  apiEmailTemplateAllGet$Response(
    params?: {
      workGroupIds?: Array<number>;
    },
    context?: HttpContext
  ): Observable<StrictHttpResponse<Array<EmailTemplateQueryRes>>> {
    const rb = new RequestBuilder(this.rootUrl, EmailTemplateService.ApiEmailTemplateAllGetPath, 'get');
    if (params) {
      rb.query('workGroupIds', params.workGroupIds, {});
    }

    return this.http.request(
      rb.build({ responseType: 'json', accept: 'application/json', context })
    ).pipe(
      filter((r: any): r is HttpResponse<any> => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<Array<EmailTemplateQueryRes>>;
      })
    );
  }

  /**
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `apiEmailTemplateAllGet$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  apiEmailTemplateAllGet(
    params?: {
      workGroupIds?: Array<number>;
    },
    context?: HttpContext
  ): Observable<Array<EmailTemplateQueryRes>> {
    return this.apiEmailTemplateAllGet$Response(params, context).pipe(
      map((r: StrictHttpResponse<Array<EmailTemplateQueryRes>>): Array<EmailTemplateQueryRes> => r.body)
    );
  }

  /** Path part for operation `apiEmailTemplateGet()` */
  static readonly ApiEmailTemplateGetPath = '/api/email-template';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `apiEmailTemplateGet()` instead.
   *
   * This method doesn't expect any request body.
   */
  apiEmailTemplateGet$Response(
    params?: {
      service?: string;
      activity?: string;
      typeOfRecipient?: string;
      workGroupId?: number;
    },
    context?: HttpContext
  ): Observable<StrictHttpResponse<EmailTemplateDto>> {
    const rb = new RequestBuilder(this.rootUrl, EmailTemplateService.ApiEmailTemplateGetPath, 'get');
    if (params) {
      rb.query('service', params.service, {});
      rb.query('activity', params.activity, {});
      rb.query('typeOfRecipient', params.typeOfRecipient, {});
      rb.query('workGroupId', params.workGroupId, {});
    }

    return this.http.request(
      rb.build({ responseType: 'json', accept: 'application/json', context })
    ).pipe(
      filter((r: any): r is HttpResponse<any> => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<EmailTemplateDto>;
      })
    );
  }

  /**
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `apiEmailTemplateGet$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  apiEmailTemplateGet(
    params?: {
      service?: string;
      activity?: string;
      typeOfRecipient?: string;
      workGroupId?: number;
    },
    context?: HttpContext
  ): Observable<EmailTemplateDto> {
    return this.apiEmailTemplateGet$Response(params, context).pipe(
      map((r: StrictHttpResponse<EmailTemplateDto>): EmailTemplateDto => r.body)
    );
  }

  /** Path part for operation `apiEmailTemplatePut()` */
  static readonly ApiEmailTemplatePutPath = '/api/email-template';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `apiEmailTemplatePut()` instead.
   *
   * This method sends `application/json` and handles request body of type `application/json`.
   */
  apiEmailTemplatePut$Response(
    params?: {
      body?: EmailTemplateDto
    },
    context?: HttpContext
  ): Observable<StrictHttpResponse<void>> {
    const rb = new RequestBuilder(this.rootUrl, EmailTemplateService.ApiEmailTemplatePutPath, 'put');
    if (params) {
      rb.body(params.body, 'application/json');
    }

    return this.http.request(
      rb.build({ responseType: 'text', accept: '*/*', context })
    ).pipe(
      filter((r: any): r is HttpResponse<any> => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return (r as HttpResponse<any>).clone({ body: undefined }) as StrictHttpResponse<void>;
      })
    );
  }

  /**
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `apiEmailTemplatePut$Response()` instead.
   *
   * This method sends `application/json` and handles request body of type `application/json`.
   */
  apiEmailTemplatePut(
    params?: {
      body?: EmailTemplateDto
    },
    context?: HttpContext
  ): Observable<void> {
    return this.apiEmailTemplatePut$Response(params, context).pipe(
      map((r: StrictHttpResponse<void>): void => r.body)
    );
  }

}
