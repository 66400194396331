/* tslint:disable */
/* eslint-disable */
import { HttpClient, HttpContext, HttpResponse } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { filter, map } from 'rxjs/operators';

import { BaseService } from '../base-service';
import { ApiConfiguration } from '../api-configuration';
import { StrictHttpResponse } from '../strict-http-response';
import { RequestBuilder } from '../request-builder';

import { CcrFileStreamResp } from '../models/ccr-file-stream-resp';
import { CcrWorklistQueryReq } from '../models/ccr-worklist-query-req';
import { CcrWorklistQueryResp } from '../models/ccr-worklist-query-resp';
import { PicOptResp } from '../models/pic-opt-resp';

@Injectable({ providedIn: 'root' })
export class CcrWorklistService extends BaseService {
  constructor(config: ApiConfiguration, http: HttpClient) {
    super(config, http);
  }

  /** Path part for operation `apiCcrWorklistQueryPost()` */
  static readonly ApiCcrWorklistQueryPostPath = '/api/ccr/worklist/query';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `apiCcrWorklistQueryPost()` instead.
   *
   * This method sends `application/json` and handles request body of type `application/json`.
   */
  apiCcrWorklistQueryPost$Response(
    params?: {
      body?: CcrWorklistQueryReq
    },
    context?: HttpContext
  ): Observable<StrictHttpResponse<Array<CcrWorklistQueryResp>>> {
    const rb = new RequestBuilder(this.rootUrl, CcrWorklistService.ApiCcrWorklistQueryPostPath, 'post');
    if (params) {
      rb.body(params.body, 'application/json');
    }

    return this.http.request(
      rb.build({ responseType: 'json', accept: 'application/json', context })
    ).pipe(
      filter((r: any): r is HttpResponse<any> => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<Array<CcrWorklistQueryResp>>;
      })
    );
  }

  /**
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `apiCcrWorklistQueryPost$Response()` instead.
   *
   * This method sends `application/json` and handles request body of type `application/json`.
   */
  apiCcrWorklistQueryPost(
    params?: {
      body?: CcrWorklistQueryReq
    },
    context?: HttpContext
  ): Observable<Array<CcrWorklistQueryResp>> {
    return this.apiCcrWorklistQueryPost$Response(params, context).pipe(
      map((r: StrictHttpResponse<Array<CcrWorklistQueryResp>>): Array<CcrWorklistQueryResp> => r.body)
    );
  }

  /** Path part for operation `apiCcrWorklistExportPost()` */
  static readonly ApiCcrWorklistExportPostPath = '/api/ccr/worklist/export';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `apiCcrWorklistExportPost()` instead.
   *
   * This method sends `application/json` and handles request body of type `application/json`.
   */
  apiCcrWorklistExportPost$Response(
    params?: {
      body?: CcrWorklistQueryReq
    },
    context?: HttpContext
  ): Observable<StrictHttpResponse<CcrFileStreamResp>> {
    const rb = new RequestBuilder(this.rootUrl, CcrWorklistService.ApiCcrWorklistExportPostPath, 'post');
    if (params) {
      rb.body(params.body, 'application/json');
    }

    return this.http.request(
      rb.build({ responseType: 'json', accept: 'application/json', context })
    ).pipe(
      filter((r: any): r is HttpResponse<any> => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<CcrFileStreamResp>;
      })
    );
  }

  /**
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `apiCcrWorklistExportPost$Response()` instead.
   *
   * This method sends `application/json` and handles request body of type `application/json`.
   */
  apiCcrWorklistExportPost(
    params?: {
      body?: CcrWorklistQueryReq
    },
    context?: HttpContext
  ): Observable<CcrFileStreamResp> {
    return this.apiCcrWorklistExportPost$Response(params, context).pipe(
      map((r: StrictHttpResponse<CcrFileStreamResp>): CcrFileStreamResp => r.body)
    );
  }

  /** Path part for operation `apiCcrWorklistCriteriaDefaultGet()` */
  static readonly ApiCcrWorklistCriteriaDefaultGetPath = '/api/ccr/worklist/criteria/default';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `apiCcrWorklistCriteriaDefaultGet()` instead.
   *
   * This method doesn't expect any request body.
   */
  apiCcrWorklistCriteriaDefaultGet$Response(
    params?: {
    },
    context?: HttpContext
  ): Observable<StrictHttpResponse<CcrWorklistQueryReq>> {
    const rb = new RequestBuilder(this.rootUrl, CcrWorklistService.ApiCcrWorklistCriteriaDefaultGetPath, 'get');
    if (params) {
    }

    return this.http.request(
      rb.build({ responseType: 'json', accept: 'application/json', context })
    ).pipe(
      filter((r: any): r is HttpResponse<any> => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<CcrWorklistQueryReq>;
      })
    );
  }

  /**
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `apiCcrWorklistCriteriaDefaultGet$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  apiCcrWorklistCriteriaDefaultGet(
    params?: {
    },
    context?: HttpContext
  ): Observable<CcrWorklistQueryReq> {
    return this.apiCcrWorklistCriteriaDefaultGet$Response(params, context).pipe(
      map((r: StrictHttpResponse<CcrWorklistQueryReq>): CcrWorklistQueryReq => r.body)
    );
  }

  /** Path part for operation `apiCcrWorklistOptStatusesAllGet()` */
  static readonly ApiCcrWorklistOptStatusesAllGetPath = '/api/ccr/worklist/opt/statuses/all';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `apiCcrWorklistOptStatusesAllGet()` instead.
   *
   * This method doesn't expect any request body.
   */
  apiCcrWorklistOptStatusesAllGet$Response(
    params?: {
    },
    context?: HttpContext
  ): Observable<StrictHttpResponse<Array<string>>> {
    const rb = new RequestBuilder(this.rootUrl, CcrWorklistService.ApiCcrWorklistOptStatusesAllGetPath, 'get');
    if (params) {
    }

    return this.http.request(
      rb.build({ responseType: 'json', accept: 'application/json', context })
    ).pipe(
      filter((r: any): r is HttpResponse<any> => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<Array<string>>;
      })
    );
  }

  /**
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `apiCcrWorklistOptStatusesAllGet$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  apiCcrWorklistOptStatusesAllGet(
    params?: {
    },
    context?: HttpContext
  ): Observable<Array<string>> {
    return this.apiCcrWorklistOptStatusesAllGet$Response(params, context).pipe(
      map((r: StrictHttpResponse<Array<string>>): Array<string> => r.body)
    );
  }

  /** Path part for operation `apiCcrWorklistOptStatusesUnprocessGet()` */
  static readonly ApiCcrWorklistOptStatusesUnprocessGetPath = '/api/ccr/worklist/opt/statuses/unprocess';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `apiCcrWorklistOptStatusesUnprocessGet()` instead.
   *
   * This method doesn't expect any request body.
   */
  apiCcrWorklistOptStatusesUnprocessGet$Response(
    params?: {
    },
    context?: HttpContext
  ): Observable<StrictHttpResponse<Array<string>>> {
    const rb = new RequestBuilder(this.rootUrl, CcrWorklistService.ApiCcrWorklistOptStatusesUnprocessGetPath, 'get');
    if (params) {
    }

    return this.http.request(
      rb.build({ responseType: 'json', accept: 'application/json', context })
    ).pipe(
      filter((r: any): r is HttpResponse<any> => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<Array<string>>;
      })
    );
  }

  /**
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `apiCcrWorklistOptStatusesUnprocessGet$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  apiCcrWorklistOptStatusesUnprocessGet(
    params?: {
    },
    context?: HttpContext
  ): Observable<Array<string>> {
    return this.apiCcrWorklistOptStatusesUnprocessGet$Response(params, context).pipe(
      map((r: StrictHttpResponse<Array<string>>): Array<string> => r.body)
    );
  }

  /** Path part for operation `apiCcrWorklistOptStatusesInprocessGet()` */
  static readonly ApiCcrWorklistOptStatusesInprocessGetPath = '/api/ccr/worklist/opt/statuses/inprocess';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `apiCcrWorklistOptStatusesInprocessGet()` instead.
   *
   * This method doesn't expect any request body.
   */
  apiCcrWorklistOptStatusesInprocessGet$Response(
    params?: {
    },
    context?: HttpContext
  ): Observable<StrictHttpResponse<Array<string>>> {
    const rb = new RequestBuilder(this.rootUrl, CcrWorklistService.ApiCcrWorklistOptStatusesInprocessGetPath, 'get');
    if (params) {
    }

    return this.http.request(
      rb.build({ responseType: 'json', accept: 'application/json', context })
    ).pipe(
      filter((r: any): r is HttpResponse<any> => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<Array<string>>;
      })
    );
  }

  /**
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `apiCcrWorklistOptStatusesInprocessGet$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  apiCcrWorklistOptStatusesInprocessGet(
    params?: {
    },
    context?: HttpContext
  ): Observable<Array<string>> {
    return this.apiCcrWorklistOptStatusesInprocessGet$Response(params, context).pipe(
      map((r: StrictHttpResponse<Array<string>>): Array<string> => r.body)
    );
  }

  /** Path part for operation `apiCcrWorklistOptStatusesDefaultSelectedGet()` */
  static readonly ApiCcrWorklistOptStatusesDefaultSelectedGetPath = '/api/ccr/worklist/opt/statuses/default-selected';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `apiCcrWorklistOptStatusesDefaultSelectedGet()` instead.
   *
   * This method doesn't expect any request body.
   */
  apiCcrWorklistOptStatusesDefaultSelectedGet$Response(
    params?: {
      FKWorkGroupId?: number;
    },
    context?: HttpContext
  ): Observable<StrictHttpResponse<Array<string>>> {
    const rb = new RequestBuilder(this.rootUrl, CcrWorklistService.ApiCcrWorklistOptStatusesDefaultSelectedGetPath, 'get');
    if (params) {
      rb.query('FKWorkGroupId', params.FKWorkGroupId, {});
    }

    return this.http.request(
      rb.build({ responseType: 'json', accept: 'application/json', context })
    ).pipe(
      filter((r: any): r is HttpResponse<any> => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<Array<string>>;
      })
    );
  }

  /**
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `apiCcrWorklistOptStatusesDefaultSelectedGet$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  apiCcrWorklistOptStatusesDefaultSelectedGet(
    params?: {
      FKWorkGroupId?: number;
    },
    context?: HttpContext
  ): Observable<Array<string>> {
    return this.apiCcrWorklistOptStatusesDefaultSelectedGet$Response(params, context).pipe(
      map((r: StrictHttpResponse<Array<string>>): Array<string> => r.body)
    );
  }

  /** Path part for operation `apiCcrWorklistOptOrganizationContainGet()` */
  static readonly ApiCcrWorklistOptOrganizationContainGetPath = '/api/ccr/worklist/opt/organization/contain';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `apiCcrWorklistOptOrganizationContainGet()` instead.
   *
   * This method doesn't expect any request body.
   */
  apiCcrWorklistOptOrganizationContainGet$Response(
    params?: {
      keyword?: string;
      workgroupIds?: Array<number>;
    },
    context?: HttpContext
  ): Observable<StrictHttpResponse<Array<string>>> {
    const rb = new RequestBuilder(this.rootUrl, CcrWorklistService.ApiCcrWorklistOptOrganizationContainGetPath, 'get');
    if (params) {
      rb.query('keyword', params.keyword, {});
      rb.query('workgroupIds', params.workgroupIds, {});
    }

    return this.http.request(
      rb.build({ responseType: 'json', accept: 'application/json', context })
    ).pipe(
      filter((r: any): r is HttpResponse<any> => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<Array<string>>;
      })
    );
  }

  /**
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `apiCcrWorklistOptOrganizationContainGet$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  apiCcrWorklistOptOrganizationContainGet(
    params?: {
      keyword?: string;
      workgroupIds?: Array<number>;
    },
    context?: HttpContext
  ): Observable<Array<string>> {
    return this.apiCcrWorklistOptOrganizationContainGet$Response(params, context).pipe(
      map((r: StrictHttpResponse<Array<string>>): Array<string> => r.body)
    );
  }

  /** Path part for operation `apiCcrWorklistOptVendorContainGet()` */
  static readonly ApiCcrWorklistOptVendorContainGetPath = '/api/ccr/worklist/opt/vendor/contain';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `apiCcrWorklistOptVendorContainGet()` instead.
   *
   * This method doesn't expect any request body.
   */
  apiCcrWorklistOptVendorContainGet$Response(
    params?: {
      keyword?: string;
    },
    context?: HttpContext
  ): Observable<StrictHttpResponse<Array<string>>> {
    const rb = new RequestBuilder(this.rootUrl, CcrWorklistService.ApiCcrWorklistOptVendorContainGetPath, 'get');
    if (params) {
      rb.query('keyword', params.keyword, {});
    }

    return this.http.request(
      rb.build({ responseType: 'json', accept: 'application/json', context })
    ).pipe(
      filter((r: any): r is HttpResponse<any> => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<Array<string>>;
      })
    );
  }

  /**
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `apiCcrWorklistOptVendorContainGet$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  apiCcrWorklistOptVendorContainGet(
    params?: {
      keyword?: string;
    },
    context?: HttpContext
  ): Observable<Array<string>> {
    return this.apiCcrWorklistOptVendorContainGet$Response(params, context).pipe(
      map((r: StrictHttpResponse<Array<string>>): Array<string> => r.body)
    );
  }

  /** Path part for operation `apiCcrWorklistOptBuyerAllGet()` */
  static readonly ApiCcrWorklistOptBuyerAllGetPath = '/api/ccr/worklist/opt/buyer/all';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `apiCcrWorklistOptBuyerAllGet()` instead.
   *
   * This method doesn't expect any request body.
   */
  apiCcrWorklistOptBuyerAllGet$Response(
    params?: {
      workgroupIds?: Array<number>;
    },
    context?: HttpContext
  ): Observable<StrictHttpResponse<Array<PicOptResp>>> {
    const rb = new RequestBuilder(this.rootUrl, CcrWorklistService.ApiCcrWorklistOptBuyerAllGetPath, 'get');
    if (params) {
      rb.query('workgroupIds', params.workgroupIds, {});
    }

    return this.http.request(
      rb.build({ responseType: 'json', accept: 'application/json', context })
    ).pipe(
      filter((r: any): r is HttpResponse<any> => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<Array<PicOptResp>>;
      })
    );
  }

  /**
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `apiCcrWorklistOptBuyerAllGet$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  apiCcrWorklistOptBuyerAllGet(
    params?: {
      workgroupIds?: Array<number>;
    },
    context?: HttpContext
  ): Observable<Array<PicOptResp>> {
    return this.apiCcrWorklistOptBuyerAllGet$Response(params, context).pipe(
      map((r: StrictHttpResponse<Array<PicOptResp>>): Array<PicOptResp> => r.body)
    );
  }

}
