/* tslint:disable */
/* eslint-disable */
import { HttpClient, HttpContext, HttpResponse } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { filter, map } from 'rxjs/operators';

import { BaseService } from '../base-service';
import { ApiConfiguration } from '../api-configuration';
import { StrictHttpResponse } from '../strict-http-response';
import { RequestBuilder } from '../request-builder';

import { AnnouncementRes } from '../models/announcement-res';
import { AnnouncementShcQueryReq } from '../models/announcement-shc-query-req';
import { AnnouncementShcQueryRes } from '../models/announcement-shc-query-res';

@Injectable({ providedIn: 'root' })
export class AnnouncementService extends BaseService {
  constructor(config: ApiConfiguration, http: HttpClient) {
    super(config, http);
  }

  /** Path part for operation `apiAnnouncementQueryPost()` */
  static readonly ApiAnnouncementQueryPostPath = '/api/announcement/query';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `apiAnnouncementQueryPost()` instead.
   *
   * This method sends `application/json` and handles request body of type `application/json`.
   */
  apiAnnouncementQueryPost$Response(
    params?: {
      body?: AnnouncementShcQueryReq
    },
    context?: HttpContext
  ): Observable<StrictHttpResponse<AnnouncementShcQueryRes>> {
    const rb = new RequestBuilder(this.rootUrl, AnnouncementService.ApiAnnouncementQueryPostPath, 'post');
    if (params) {
      rb.body(params.body, 'application/json');
    }

    return this.http.request(
      rb.build({ responseType: 'json', accept: 'application/json', context })
    ).pipe(
      filter((r: any): r is HttpResponse<any> => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<AnnouncementShcQueryRes>;
      })
    );
  }

  /**
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `apiAnnouncementQueryPost$Response()` instead.
   *
   * This method sends `application/json` and handles request body of type `application/json`.
   */
  apiAnnouncementQueryPost(
    params?: {
      body?: AnnouncementShcQueryReq
    },
    context?: HttpContext
  ): Observable<AnnouncementShcQueryRes> {
    return this.apiAnnouncementQueryPost$Response(params, context).pipe(
      map((r: StrictHttpResponse<AnnouncementShcQueryRes>): AnnouncementShcQueryRes => r.body)
    );
  }

  /** Path part for operation `apiAnnouncementGet()` */
  static readonly ApiAnnouncementGetPath = '/api/announcement';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `apiAnnouncementGet()` instead.
   *
   * This method doesn't expect any request body.
   */
  apiAnnouncementGet$Response(
    params?: {
      announcementId?: string;
    },
    context?: HttpContext
  ): Observable<StrictHttpResponse<AnnouncementRes>> {
    const rb = new RequestBuilder(this.rootUrl, AnnouncementService.ApiAnnouncementGetPath, 'get');
    if (params) {
      rb.query('announcementId', params.announcementId, {});
    }

    return this.http.request(
      rb.build({ responseType: 'json', accept: 'application/json', context })
    ).pipe(
      filter((r: any): r is HttpResponse<any> => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<AnnouncementRes>;
      })
    );
  }

  /**
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `apiAnnouncementGet$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  apiAnnouncementGet(
    params?: {
      announcementId?: string;
    },
    context?: HttpContext
  ): Observable<AnnouncementRes> {
    return this.apiAnnouncementGet$Response(params, context).pipe(
      map((r: StrictHttpResponse<AnnouncementRes>): AnnouncementRes => r.body)
    );
  }

}
